import Axios from "axios";

const url = '/manufacturer';

const manufacturerApi = {
  get: () => {
    return Axios.get(`${url}`);
  },
  authenticateTableau: {
    get: () => {
      return Axios.get(`${url}/authenticate-tableau`);
    }
  },
  opportunities: {
    post: (params) => {
      return Axios.post(`${url}/customer-opportunities`, params);
    }
  },
  agreements: {
    post: (params) => {
      return Axios.post(`${url}/manufacturer-agreements`, params);
    }
  },
  details: {
    post: (params) => {
      return Axios.post(`${url}/details`, params)
    }
  },
  filters: {
    post: (params) => {
      return Axios.post(`${url}/list-filters`, params)
    }
  },
  itemSearch: {
    post: (params) => {
      return Axios.post(`${url}/item-search`, params)
    }
  },
  itemMatch: {
    post: (params) => {
      return Axios.post(`${url}/item-match`, params)
    }
  },
  connectCustomer: {
    post: (params) => {
      return Axios.post(`${url}/connect-customer`, params)
    }
  }
};

export default manufacturerApi;