import React from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button } from "reactstrap";
import { IoMdInformationCircle } from "react-icons/io";
import { Tooltip } from "@mui/material";
import useDataSuite from "../useDataSuite";
import { useEffect } from "react";
import { AiOutlineRise, AiOutlineFall } from "react-icons/ai";
import formatters from "../../../config/formatters";
import useDataSuiteWidgets from "../Widgets/useDataSuiteWidgets";
import { Theme } from "../../../config/constants";

const DataSuiteHighlightsCard = () => {
    const { getHighlights, getCategoryTrendingReport, getComplianceLeakageReport,getOperatorTrendingReport, getTopOperatorReport } = useDataSuite.useDataSuite();
    const { getSalesByOperator, getSalesByCategory, getNewLocations, getLeakage } = useDataSuite.useDataSuite();
    const salesByOperatorData = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesByOperatorData);
    const salesByCategoryData = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesByCategoryData);
    const newLocationsData = useSelector(({datasuite}) => datasuite.widgetDetailsData.newLocationsData);
    const leakageData = useSelector(({datasuite}) => datasuite.widgetDetailsData.leakageData);
    const detailsDrawerOpen = useSelector(({ui}) => ui.datasuiteWidgetDetailsSlideDrawer);
    const detailsDrawerTitle = useSelector(({datasuite})=> datasuite.widgetDetailsDrawer.title);
    const { widgetDetailsContent : salesByOperatorDetailsContent } = useDataSuiteWidgets.useSalesByOperatorWidget();
    const { widgetDetailsContent : salesByCategoryDetailsContent } = useDataSuiteWidgets.useSalesByCategoryWidget();
    const { widgetDetailsContent : newLocationsDetailsContent } = useDataSuiteWidgets.useNewLocationsWidget();
    const { widgetDetailsContent : leakageDetailContent } = useDataSuiteWidgets.useLeakageWidget();
    const highlights = useSelector(({datasuite}) => datasuite.highlights);
    const { numberFormatter} = formatters();
    const percentFormatter = Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 2,
    });

    const initializePage = () => {
        getHighlights();  
        if (salesByOperatorData.length <= 0) {
            getSalesByOperator(true);
        }
        if (salesByCategoryData.length <= 0) {
            getSalesByCategory(true);
        }
        if (newLocationsData.length <= 0) {
            getNewLocations(true);
        }
        if (leakageData.length <= 0) {
            getLeakage(true);
        }
    }

    // eslint-disable-next-line
    useEffect(initializePage, []);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "Sales By Operator")) {getTopOperatorReport(salesByOperatorDetailsContent());}}, [salesByOperatorData]);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "New Locations")) {getOperatorTrendingReport(newLocationsDetailsContent());}}, [newLocationsData]);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "Sales By Category")) {getCategoryTrendingReport(salesByCategoryDetailsContent());}}, [salesByCategoryData]);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "Leakage")) {getComplianceLeakageReport(leakageDetailContent());}}, [leakageData]);

    return (
        <Col className="py-2">
            <Row className="py-2" style={{height: '100%', paddingBottom: '55px'}}>
                <Col className='col-3 px-3' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">TOP OPERATOR REVIEW</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='The operator purchasing the most in case volume from you over the last three months, as well as the category that they are purchasing the most cases in.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2" style={{paddingBottom: '55px'}}>
                        <Col>
                            <Row>
                                <Col style={{height: '48px'}}>
                                    {!highlights?.topOperator?.location_name && 
                                    <div className="w-100 h-100 animated-background" ></div>
                                    }
                                    <div id="highlightsLocationName" title={highlights?.topOperator?.location_name} className="main-header w-100 text-size-32" style={{overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap'}}>{highlights?.topOperator?.location_name}</div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="main-header text-gray text-size-12">Purchasing the Highest Volume in Cases in the {highlights?.topOperator?.category} Category</div>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getTopOperatorReport(salesByOperatorDetailsContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
                <Col className='col-3 px-3' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">OPERATOR TRENDING SALES</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='The total number of new locations/stores that started purchasing from you YTD who had not purchased in previous years.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2" style={{paddingBottom: '55px'}}>
                        <Col className="col-9 ps-3">
                            <Row>
                                <Col className="w-fc pe-0">
                                    {(!highlights?.trendingSales?.newLocationCount && !highlights?.trendingSales?.lostLocationCount) && 
                                    <div className="animated-background" style={{height: '48px', width: '48px', marginBottom: '1px'}}></div>
                                    }
                                    {(highlights?.trendingSales?.newLocationCount >= -1/*highlights?.trendingSales?.lostLocationCount*/) &&
                                    <div className="main-header text-green text-size-32">{numberFormatter.format(highlights?.trendingSales?.newLocationCount)}</div>
                                    }
                                    {/* {(highlights?.trendingSales?.newLocationCount < highlights?.trendingSales?.lostLocationCount) &&
                                    <div className="main-header text-red text-size-32">{numberFormatter.format(highlights?.trendingSales?.lostLocationCount)}</div>
                                    } */}
                                </Col>
                                <Col className="w-fc ps-2">
                                    <div className="main-header text-black text-size-24" style={{position: 'relative', bottom: '-9px'}}>Locations</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {(!highlights?.trendingSales?.newLocationCount && !highlights?.trendingSales?.lostLocationCount) &&
                                    <div className="animated-background" style={{height: '18px', width: '150px'}}></div> 
                                    }
                                    {(highlights?.trendingSales?.newLocationCount >= -1/*highlights?.trendingSales?.lostLocationCount*/) &&
                                        <div className="main-header text-gray text-size-12">Started Purchasing YTD</div>
                                    }
                                    {/* {(highlights?.trendingSales?.newLocationCount < highlights?.trendingSales?.lostLocationCount) &&
                                        <div className="main-header text-gray text-size-12">Stopped Purchasing YTD</div>
                                    } */}
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-3 p-0">
                            {/* {(highlights?.trendingSales?.newLocationCount >= highlights?.trendingSales?.lostLocationCount) && */}
                                <AiOutlineRise size={'74px'} color='#00A86B'/>
                            {/* } */}
                            {/* {(highlights?.trendingSales?.newLocationCount < highlights?.trendingSales?.lostLocationCount) &&
                                <AiOutlineFall size={'74px'} color='#DC3545'/>
                            } */}
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getOperatorTrendingReport(newLocationsDetailsContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
                <Col className='col-3 px-3' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">YOUR TRENDING CATEGORY</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='Your category with the largest positive or negative shift in total case quantity over the last 3 months.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2 px-3" style={{paddingBottom: '55px'}}>
                        <Col className="col-9 px-0">
                            <Row>
                                {!highlights?.trendingCategory?.trend && 
                                <div className="animated-background" style={{height: '48px', width: '100px', marginBottom:'1px'}}></div> 
                                }
                                {highlights?.trendingCategory?.trend &&
                                <div className={"main-header text-size-32 " + (highlights?.trendingCategory?.trend > 0 ? 'text-green' : 'text-red')}>{percentFormatter.format(highlights?.trendingCategory?.trend)}</div>
                                }
                            </Row>
                            <Row>
                                {!highlights?.trendingCategory?.category && 
                                <div className="animated-background" style={{height: '18px', width: '150px'}}></div> 
                                }
                                <div className="main-header text-gray text-size-12" style={{textTransform: 'capitalize'}}>{highlights?.trendingCategory?.category}</div>
                            </Row>
                        </Col>
                        <Col className="col-3 p-0">
                            {(highlights?.trendingCategory?.trend > 0) &&
                            <AiOutlineRise size={'74px'} color='#00A86B'/>
                            }
                            {(highlights?.trendingCategory?.trend <= 0) &&
                            <AiOutlineFall size={'74px'} color='#DC3545'/>
                            }
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getCategoryTrendingReport(salesByCategoryDetailsContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
                <Col className='col-3 px-3'>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">COMPLIANCE & LEAKAGE</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='A shortcut to your leakage report.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2" style={{paddingBottom: '55px'}}>
                        <Col>
                            <AiOutlineRise size={'74px'} color='#00A86B'/>
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getComplianceLeakageReport(leakageDetailContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
            </Row>
        </Col>
    )   
}

export default DataSuiteHighlightsCard;