import React from "react";
import { Col, Row, Button, Card } from "reactstrap";
import CompetitiveInsightsChart from "../DashboardCharts/CompetitiveInsightsChart";
import DashboardDistributorBreakdownChart from "../DashboardCharts/DashboardDistributorBreakdownChart";
import DashboardTotalOpportunititesChart from "../DashboardCharts/DashboardTotalOpportunitiesChart";
import useDashboard from "../useDashboard";
import { isMobileOnly } from "react-device-detect";

const DashboardChartsCard = () => {
    const { handleClickViewCampaignManagement } = useDashboard.useDashboard();

    if (isMobileOnly) {
        return (
            <Col>
                <Row className="px-0">
                    <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" >
                    <Card className=' px-1'>
                        <DashboardDistributorBreakdownChart />
                        </Card>
                    </Col>
                    <Col>
                    <Card className=' px-1'>
                        <CompetitiveInsightsChart />
                        </Card>
                    </Col>
                </Row>
                <Row className="px-0">
                    <Col className="col-xxl-6 col-xl-6">
                    <Card className=' px-1'>
                        <DashboardTotalOpportunititesChart />
                        </Card>
                    </Col>
                    <Col >
                    <Card className="my-auto w-100 d-flex flex-column justify-content-center h-50" style={{minHeight: '250px'}}>
                        <Row className="mx-auto w-fc">
                            <img src={'./vectorcharts.png'} alt='Fake Charts and Graphs' style={{height: '59px', width: '211px'}}/>
                        </Row>
                        <Row className="mx-auto w-fc">
                            <div className="main-header text-size-18">Want more Campaign Insights?</div>
                        </Row>
                        <Row className="mx-auto w-fc">
                            <div className="text-grayText text-center">Access our Campaign Management page for full insights on you campaigns.</div>
                        </Row>
                        <Row className="mx-auto my-1 w-fc">
                            <Button 
                                block 
                                className='py-2 button-label' 
                                size='sm' 
                                style={{width: '250px'}}
                                onClick={() => handleClickViewCampaignManagement()}
                                color='beplMagenta'>View Campaign Management
                            </Button>
                        </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
        )   

    } else return (
        <Col>
        <Card className=' px-3' style={{minHeight: '850px', height: '100%'}}>
            <Row className="m-0">
                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"  style={window.innerWidth < 770 ? {borderBottom: '1px lightgray solid'} : {borderRight: '1px lightgray solid', borderBottom: '1px lightgray solid'}}>
                    <DashboardDistributorBreakdownChart />
                </Col>
                <Col  style={window.innerWidth <= 1024 ? {borderBottom: '1px lightgray solid'} : {borderRight: '1px lightgray solid'}}>
                    <CompetitiveInsightsChart />
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"  style={window.innerWidth < 770 ? {borderBottom: '1px lightgray solid'} : {borderRight: '1px lightgray solid'}}>
                    <DashboardTotalOpportunititesChart />
                </Col>
                <Col className="my-auto w-100 d-flex flex-column justify-content-center h-50" style={{minHeight: '350px'}}>
                    <Row className="mx-auto w-fc">
                        <img src={'./vectorcharts.png'} alt='Fake Charts and Graphs' style={{height: '59px', width: '211px'}}/>
                    </Row>
                    <Row className="mx-auto w-fc">
                        <div className="main-header text-size-18">Want more Campaign Insights?</div>
                    </Row>
                    <Row className="mx-auto w-fc">
                        <div className="text-grayText text-center">Access our Campaign Management page for full insights on you campaigns.</div>
                    </Row>
                    <Row className="mx-auto my-1 w-fc">
                        <Button 
                            block 
                            className='py-2 button-label' 
                            size='sm' 
                            style={{width: '250px'}}
                            onClick={() => handleClickViewCampaignManagement()}
                            color='beplMagenta'>View Campaign Management
                        </Button>
                    </Row>
                </Col>
            </Row>
            </Card>
        </Col>
    )   
}

export default DashboardChartsCard;