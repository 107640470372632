import { createStore, combineReducers } from 'redux';
import user from './UserReducer/UserReducer';
import intel from './IntelReducer/IntelReducer';
import rebate from './RebateReducer/RebateReducer';
import ui from './UIReducer/UIReducer';
import modals from './ModalsReducer/ModalsReducer';
import competitiveInsights from './CompetitiveInsightsReducer/CompetitiveInsightsReducer';
import opportunities from './OpportunitiesReducer/OpportunitiesReducer';
import dates from './DateReducer/DateReducer';
import marketing from './MarketingCampaignsReducer/MarketingCampaignsReducer';
import dashboard from './DashboardReducer/DashboardReducer';
import datasuite from './DatasuiteReducer/DataSuiteReducer';

const reducer = combineReducers({
  user,
  intel,
  rebate,
  ui,
  modals,
  competitiveInsights,
  opportunities,
  dates,
  marketing,
  dashboard,
  datasuite
});

export default createStore(reducer);