import React from 'react';
//import { useDispatch } from 'react-redux';
import useModal from '../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Label, Row} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import AgreementStatusIndicator from '../../../sharedComponents/AgreementStatusIndicator/AgreementStatusIndicator';
import useOpportunities from '../useOpportunities';
import { Theme } from '../../../config/constants';

const OpportunitiesSeeDetailsModal = ({id, ...props}) => {
  //const dispatch = useDispatch();
  const { onClickContactMfrRelations } = useOpportunities.useOpportunities();
  const { modalProps, headerProps, toggle } = useModal(id, {hideClose: false, header: {color: 'black', background: 'white'}});
  var {
    buttonRightColor, buttonRightOutline, detailsData
  } = props;

  //eslint-disable-next-line
  detailsData = {
    ...detailsData,
    marketingContact: "Caitlyn Donchak"
  }

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: '0'
  });

  var fieldToggles = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  var fieldNames = ["Customer ID", "Current Item Description", "Switch Item Description", "Switch Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "Current Item Packsize", "Current Item Market Index", "Current Item Total Cases", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "Contact MFR Relations", "Opportunity Manager"];

  switch (detailsData.complianceactivity) {
    case ("Manufacturer Portal"):
      fieldToggles = [true, true, true, false, true, true, true, true, true, true, true, true, true, false, false, false, false, false];
      fieldNames = ["Customer ID", "Current Item Description", "Switch Item Description", "Switch Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "Current Item Packsize", "Current Item Market Index", "Current Item Total Cases", "Compliance Activity", "Opportunity Identified Date", "Status", "", "", "", "", ""];
      break;
    case ("Platform Portal"):
      fieldToggles = [true, true, false, true, true, true, true, false, false, true, true, true, true, false, false, false, false, false];
      fieldNames = ["Customer ID", "Opportunity - Item Description", "", "Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "", "", "Case Quantity", "Compliance Activity", "Opportunity Identified Date", "Status", "", "", "", "", ""];
      break;
    case ("Custom Email Campaign"):
    case ("Marketing Event (summit)"):
    case ("MFR Mailing Sample Campaigns"):
    case ("MFR Spotlight"):
    case ("Newsletter"):
    case ("Social Media"):
    case ("Webinar"):
      fieldToggles = [true, true, false, true, true, true, true, false, false, true, true, true, true, true, true, true, false, false];
      fieldNames = ["Customer ID", "Opportunity - Item Description", "", "Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "", "", "Case Quantity", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "", ""];
      break;
    case ("Client Manager Request"):
    case ("Business Review"):
    case ("Comparison"):
    case ("Strategic MFR Target"):
      fieldToggles = [true, false, false, false, true, false, false, false, false, false, true, true, true, false, false, false, true, true];
      fieldNames = ["Customer ID", "Opportunity - Item Description", "", "Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "Case Size", "", "Case Quantity", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "Contact MFR Relations", "Opportunity Manager"];
      break;
    default:
      fieldToggles = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true];
      fieldNames = ["Customer ID", "Current Item Description", "Switch Item Description", "Switch Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "Current Item Packsize", "Current Item Market Index", "Current Item Total Cases", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "Contact MFR Relations", "Opportunity Manager"];
      break;
  };

  const onRightClick = () => {
    toggle();
  };

  return (
    <Modal {...modalProps} modalClassName="opportunities-see-details-modal">
      <ModalHeader {...headerProps} className="main-header text-size-20">Opportunity Details</ModalHeader>
      <ModalBody className={isMobile ? 'px-2' : 'pt-0'} >
        <Row style={{"display":(fieldToggles[0] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[0]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.customerplatformid}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[1] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[1]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.originalProductDescription}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[2] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[2]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.description}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[3] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[3]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.switchMin ? detailsData.switchMin : 'N/A'}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[4] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[4]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.category}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[7] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[7]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.packsize}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[8] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[8]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">${detailsData?.marketIndex}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[9] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[9]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{formatter.format(detailsData?.totalCases)}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[5] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[5]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.growthspecialistname}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[6] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[6]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.clientmanagername}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[13] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[13]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.marketingContact}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[17] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[17]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.opportunitymanagername}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[10] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[10]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.complianceactivity}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[14] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[14]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.campaignSource}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[11] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[11]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{new Date(detailsData?.createddate).toLocaleDateString("en-US")}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[12] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[12]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2"><AgreementStatusIndicator status={detailsData?.analysisstatus}/></Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[15] ? "flex" : "none")}}>
          <Col>
            <Label className="label-text-header text-grayDark my-2">{fieldNames[15]}</Label>
          </Col>
          <Col>
          <Label className="label-text-header my-2">{detailsData?.additionalInformation}</Label>
          </Col>
        </Row>
        <Row style={{"display":(fieldToggles[16] ? "flex" : "none")}}>
          <Col className="justify-content-center text-center">
          <Label className="label-text-header mb-2 mt-4">Have Questions about this opportunity?</Label>
          <Label className="label-text-header clickable" style={{color: Theme().colors.accent}} onClick={() => onClickContactMfrRelations(detailsData.customer)}>Contact Manufacturer Relations</Label>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='justify-content-center'>
        <Col className='pr-0'>
            <Button onClick={onRightClick} block outline={buttonRightOutline} className='py-2' style={{float: 'right'}} color={buttonRightColor}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default OpportunitiesSeeDetailsModal;
