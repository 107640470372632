
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import { useNavigate } from 'react-router-dom';
import ChangePasswordModal from './Modals/ChangePasswordModal';
import UsersRolesModal from './Modals/UsersRolesModal';

const useUser = () => {
  const [tabPassword, setTabPassword] = useState(true);
  const [tabRol, setTabRol] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clickTabPassword = () => {
    setTabPassword(true);
    setTabRol(false);
  }
  
  const clickTabRol = () => {
    setTabPassword(false);
    setTabRol(true);
  }

  const clickChangePassword = () => {
    dispatch(showModal(ChangePasswordModal, ''));
  }

  const clickUsersRoles = () => {
    dispatch(showModal(UsersRolesModal, ''));
  }

  const logOut = () => {
    navigate('/logout');
  };

   return { clickChangePassword, clickUsersRoles, clickTabPassword, tabPassword, clickTabRol, tabRol, logOut }
}

// eslint-disable-next-line
export default {
    useUser
} 
