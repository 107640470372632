import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { FiX } from 'react-icons/fi';
import { isMobile } from 'react-device-detect';
import ExportFiltersCard from './SlideDrawerModules/ExportFiltersCard';

const ExportSlideDrawer = (props) => {
  const dispatch = useDispatch();
  const enabled = useSelector(({ui}) => ui.exportSlideDrawer);

  return (
    <If condition={enabled}>
    <Then>
      <div className="mask" style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
        <div className={(isMobile ? "side-drawer-mobile open" : "side-drawer open")}>
            <Row className="m-3 mt-4">
                <Col>
                    <div className="text-black main-header text-size-20">Export {props.title}</div>
                </Col>
                <Col className="w-fc text-size-24">
                    <FiX style={{color: 'gray'}} className="clickable" onClick={() => dispatch(slideDrawer.subtractExport())}></FiX>
                </Col>
            </Row>
            <Row className='w-100 ms-0'>
                <div style={{width: '100%', height: '1px', background: '#D7D8D9'}}></div>
            </Row>
            <Row className="mx-3" style={{overflowY: 'auto', height: '87%'}}>
                <Col className="h-fc" style={{overflowX: 'hidden'}}>
                    <ExportFiltersCard title={props.title}/>
                </Col>
            </Row>
        </div>
      </div>
    </Then>
    <Else>
      <div className="mask hide">
        <div className={(isMobile ? "side-drawer-mobile" : "side-drawer")}>
        </div>
      </div>
    </Else>
  </If>
  );
}

export default ExportSlideDrawer;