export const setMarketingCampaignsData = (marketingCampaigns) => {
    return {
      type: 'SET_MARKETING_CAMPAIGNS_DATA',
      payload: {
        marketingCampaigns
      }
    }
  };
  
  export const clearMarketingCampaignsData = () => {
    return {
      type: 'CLEAR_MARKETING_CAMPAIGNS_DATA'
    }
  };

  export const enableEditDetailsState = () => {
    return {
      type: 'ENABLE_EDIT_DETAILS'
    }
  };

  export const disableEditDetailsState = () => {
    return {
      type: 'DISABLE_EDIT_DETAILS'
    }
  };
  
  export const dataLoading = () => {
    return {
      type: 'LOADING'
    }
  };