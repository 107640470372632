import React, { useState, useRef, useEffect } from "react";
import { AutoSizer, Column, Table, defaultTableRowRenderer } from "react-virtualized";
import formatters from "../../config/formatters";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import useDataSuite from "../../AppMain/DataSuite/useDataSuite";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";

const CollapsibleDataTable = ({ list, tableColumns, tableName, widgetTitle }) => {
  const { usCurrencyFormatter, numberFormatter, percentFormatter } = formatters();
  const { getNewLocationsItemLevel, getSalesByCategoryItemLevel, getSalesByDistributorItemLevel, 
          getGrowthOpportunityItemLevel, getSalesByOperatorItemLevel, getSalesVoidItemLevel, getLeakageItemLevel } = useDataSuite.useDataSuite();
  const itemLevelDescriptionData = useSelector(({datasuite}) => datasuite.itemLevelDescriptionData);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const tableRef = useRef();
  const autoSizerRef = useRef();

  const formatData = (formatter, data) =>{
    switch (formatter) {
        case ("usCurrency") : {
            return usCurrencyFormatter.format(data);
        }
        case ("percentage") : {
            return percentFormatter.format(data);
        }
        case ("number") : {
            return numberFormatter.format(data);
        }
        default : {
            return data;
        }
    }
  }

  const columnMap = tableColumns?.map((item, idx) => 
    <Column
        key={idx}
        className={idx === 0 ? "searchHighlight": ''}
        label={item?.label}
        dataKey={item?.dataKey}
        width={autoSizerRef?.current?.state?.width * item?.width}
        cellRenderer={item?.cellRenderer ? item?.cellRenderer : ({cellData}) => formatData(item?.formatter, cellData)}
    />
  );

  const itemLevelTable = (
        <Table className={"table border-grayLight pb-1"} id={"itemLevelTable"}
          headerHeight={34.5}
          height={226}
          overscanRowCount={10}
          rowHeight={38}
          rowGetter={({index}) => itemLevelDescriptionData[index]}
          rowCount={itemLevelDescriptionData?.length}
          width={autoSizerRef?.current?.state?.width * .9715}
        >
          <Column
          dataKey="description"
          label="Description"
          width={autoSizerRef?.current?.state?.width * .45}
          />
          <Column
          dataKey="category"
          label="Category"
          width={autoSizerRef?.current?.state?.width * .25}
          />
          <Column
          dataKey="pack"
          label="Packsize"
          width={autoSizerRef?.current?.state?.width * .15}
          />
          <Column
          dataKey="total_cases"
          label="Your Cases"
          width={autoSizerRef?.current?.state?.width * .15}
          cellRenderer={({cellData}) => formatData("number", cellData)}
          />
        </Table>
  );

  const clickItemListDetails = (index) => {
    if (index >= 0) {
        switch (widgetTitle) {
            case ("New Locations") : {
              getNewLocationsItemLevel([list[index].location_name]); 
              break;
            }
            case ("Sales By Operator") : {
              getSalesByOperatorItemLevel([list[index].location_name], [list[index].category]);
              break;
            }
            case ("Sales By Category") : {
              getSalesByCategoryItemLevel([list[index].category]);
              break;
            }
            case ("Growth Opportunities") : {
              getGrowthOpportunityItemLevel([list[index].location_name], [list[index].category]);
              break;
            }
            case ("Sales By Distributor") : {
              getSalesByDistributorItemLevel([list[index].distributor_name]);
              break;
            }
            case ("Sales Void Opportunities") : {
              getSalesVoidItemLevel([list[index].location_name]);
              break;
            }
            case ("Leakage") : {
              getLeakageItemLevel([list[index].location_name], [list[index].category]);
              break;
            }
            default : {
                //Do nothing
            }
        }
    }
    setSelectedIndex(index); 
  };

  const Details = ({ children, index }) => (
    <div key={index} style={{ cursor: "pointer" }} onClick={() => clickItemListDetails(index)}>
      {children}
    </div>
  );

  const _getDatum = index => list[index % list.length];
  const _getRowHeight = ({ index }) => (index === selectedIndex ? 300 : 38);
  const rowGetter = ({ index }) => _getDatum(index);
  const cellRenderer = ({ rowIndex }) => {
    if (rowIndex !== selectedIndex) {
      return <Details index={rowIndex}><IoIosArrowForward/></Details>;
    } else {
      return <Details index={-1}><IoIosArrowDown/></Details>;
    }
  };

  useEffect(() => {
    if (list.length === 1) {
      setSelectedIndex(0);
      clickItemListDetails(0);
    } else {
      setSelectedIndex(-1);
    }
    // eslint-disable-next-line
  }, [list]);

  useEffect(
    () => {
      tableRef.current.recomputeRowHeights();
    },
    [selectedIndex]
  );

  const rowRenderer = props => {
    const { index, style, className } = props;
    if (index === selectedIndex) {
      return (
        <div
          style={{ ...style, display: "flex", flexDirection: "column", paddingBottom: '0px' }}
          className={className}
          key={index}
        >
          {defaultTableRowRenderer({
            ...props,
            style: { width: style.width, height: 38, paddingBottom: '0.5rem' }
          })}
          <div className="py-3" style={{width: '100%', background: '#F0F0F2'}}>
            <div className="mx-auto" style={{overflowY: "none", width: "98%", background: "#FFFFFF", 'filter' : 'drop-shadow(0px 1px 1px gray)'}}>
              {itemLevelDescriptionData?.length > 0 && itemLevelTable}
              {itemLevelDescriptionData?.length <=0 && 
                  <div className="mx-auto my-auto" style={{height:"228px", width:"2.5%"}}>
                      <Spinner style={{marginTop: "100px"}} color='beplBlue' animation="border" />
                  </div>
              }
            </div>
          </div>
        </div>
      );
    } else {
      return defaultTableRowRenderer(props);
    }
  };

  return (
    <div style={{ height: "76vh" }}>
      <AutoSizer ref={autoSizerRef}>
        {({ width, height }) => (
          <Table className={"table border-grayLight " + tableName} id={tableName}
            headerHeight={34.5}
            height={height}
            overscanRowCount={10}
            rowHeight={_getRowHeight}
            rowGetter={rowGetter}
            rowCount={list.length}
            width={width}
            ref={tableRef}
            rowRenderer={rowRenderer}
          >
            <Column
              cellDataGetter={({ rowData }) => rowData?.length}
              cellRenderer={cellRenderer}
              dataKey="index"
              width={20}
            />
            {columnMap}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

export default CollapsibleDataTable;
