import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row, Button, Input, InputGroup, InputGroupText } from "reactstrap";
import ReactSelect, { createFilter, components } from "react-select";
import { selectStyles, opportunitySources, opportunityStatuses, Theme } from "../../config/constants";
import CustomMenuList from "../../sharedComponents/Helper/CustomMenuList";
import { BsCaretDownFill, BsSearch } from "react-icons/bs";
import useMarketingCampaigns from "./useMarketingCampaigns";
import { FaFilter } from "react-icons/fa";

const MarketingCampaignsFiltersCard = (props) => {
    const location = useLocation();
    const opportunitiesList = useSelector(({opportunities}) => opportunities.opportunitiesItems);
    const selectedClientManager = useSelector(({dashboard}) => dashboard.selectedFilters.selectedClientManager);
    const selectedSource = useSelector(({dashboard}) => dashboard.selectedFilters.selectedSource);
    const selectedStatus = useSelector(({dashboard}) => dashboard.selectedFilters.selectedStatus);

    const { filterOpportunityStatusTable, clearOpportunityFilters, setSelectedClientManager, setSelectedSource, setSelectedStatus } = useMarketingCampaigns.useMarketingCampaigns();

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };
    
    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
          values = `${values.length} Selected`;
        }
        return (
          <components.ValueContainer {...props}>
            {values}
            {input}
          </components.ValueContainer>
        );
      };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              <input className="me-2" type="checkbox" checked={isSelected} disabled={isDisabled} />
              {children}
            </components.Option>
        );
      };

    const showClearButton = (selectedSource?.length > 0 || selectedClientManager?.length > 0 || selectedStatus?.length > 0);

    const clientManagerMap = opportunitiesList?.data?.filter((data) => data.clientmanagername).map((data) => {
        return (
            {value: data.clientmanagername, label: data.clientmanagername}
        );
    });

    const selectedClientManagerMap = selectedClientManager?.sort().map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const opportunitiesSourceMap = opportunitySources?.sort().map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const selectedSourceMap = selectedSource?.sort().map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const opportunitiesStatusMap = opportunityStatuses?.sort().map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const selectedStatusMap = selectedStatus?.sort().map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const handleClearFilters = () => {
        clearOpportunityFilters();
        document.getElementById("opportunityStatusSearchInput").value = "";
    };

    return (
        <Col className="h-100 mb-2 card" style={{backgroundImage: 'url("FiltersBackground.png")', backgroundSize: 'cover', boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.2)', borderRadius: '5px'}}>
            <Row id={`filtersOpportunitiesMain`} className="w-100 mx-0">
                <Col>
                    <Row className="px-0">
                        <Col>
                            <Row className="my-2">
                                <Col className="w-fc">
                                    <div className="main-header text-size-20" style={{color: '#00A6CC', paddingTop: '5px'}}><FaFilter fontSize={'15px'} style={{marginBottom: '5px'}}/> Filters</div>
                                </Col>
                                <Col className='col-2'>
                                    <InputGroup className='ps-0' size='md' style={{height: '38px'}}>
                                        <InputGroupText>
                                            <BsSearch/>
                                        </InputGroupText>
                                        <Input className='text-input' id="opportunityStatusSearchInput" placeholder='Search...' onChange={() => filterOpportunityStatusTable()}/> 
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <ReactSelect
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        name='clientManager'
                                        styles={selectStyles} 
                                        options = {clientManagerMap}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator}}
                                        onChange = {(e) => setSelectedClientManager(e)}
                                        value = {selectedClientManagerMap}
                                        placeholder='Select Client Manager'
                                    />
                                </Col>
                                <Col className="col-2">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator }} 
                                        name='source' 
                                        styles={selectStyles} 
                                        options = {opportunitiesSourceMap}
                                        onChange = {(e) => setSelectedSource(e)}
                                        value = {selectedSourceMap}
                                        placeholder='Select Source'
                                    />
                                </Col>
                                <Col className="col-2">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                                        name='operator' 
                                        styles={selectStyles} 
                                        options = {opportunitiesStatusMap}
                                        onChange = {(e) => setSelectedStatus(e)}
                                        value = {selectedStatusMap}
                                        placeholder='Select Status'
                                    />
                                </Col>
                                {showClearButton && 
                                <Col className="w-fc">
                                    <Button
                                        outline={false}
                                        block
                                        id="clearFiltersButton"
                                        className="py-2 button-label"
                                        size="sm"
                                        style={{ width: "120px", color: 'white'}}
                                        color='blueFilter'
                                        onClick={handleClearFilters}>
                                        <span style={{fontWeight: '600'}}>Clear Filters</span>
                                    </Button>
                                </Col>
                                }
                                {location?.state && 
                                <Col className='w-fc me-0'>
                                    *Data filtered by {location?.state?.campaignName}
                                </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )   
}

export default MarketingCampaignsFiltersCard;