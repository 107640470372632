import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { isMobile, isMobileOnly} from 'react-device-detect';
import { IoIosArrowBack } from "react-icons/io";
import ChangeApps from '../../AppMain/ChangeApps/ChangeApps';

const ChangeAppsSlideDrawer = (props) => {
  const dispatch = useDispatch();
  const enabled = useSelector(({ui}) => ui.appsMenuSlideDrawer);

  return (
    <div className={(isMobileOnly ? "left-side-drawer-mobileonly" : (isMobile ? "left-side-drawer-mobile" : "left-side-drawer")) + (enabled ? " open" : "")}>
    {isMobileOnly ?
        <Row className="mx-0" style={{ borderBottom: '1px solid lightgray' }}>
            <Col className='ms-2' style={{ alignSelf: 'center' }}>
                <div className="text-black main-header text-size-20">Change Portals</div>
            </Col>
            <Col className="w-fc me-0 text-size-32 pb-1" style={{alignSelf: 'center'}}>
                <IoIosArrowBack style={{color: 'black'}} className="clickable" onClick={() => dispatch(slideDrawer.subtractAppsMenu())}></IoIosArrowBack>
            </Col>
        </Row>
        :
        <Row className="mx-0" style={{ borderBottom: '1px solid lightgray'}}>
            <Col className='ms-2' style={{ alignSelf: 'center' }}>
                <div className="text-black main-header text-size-20">Change Portals</div>
            </Col>
            <Col className="w-fc me-0 text-size-32 pb-1" style={{ alignSelf: 'center' }}>
                <IoIosArrowBack style={{ color: 'black' }} className="clickable" onClick={() => dispatch(slideDrawer.subtractAppsMenu())}></IoIosArrowBack>
            </Col>
        </Row>
    }
        <Row className="mx-0 my-2">
            <ChangeApps />
        </Row>
    </div>
  );
}

export default ChangeAppsSlideDrawer