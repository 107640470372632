export const setCompetitiveInsightsData = (data) => {
    return {
      type: 'SET_CI_DATA',
      payload: {
        data
      }
    }
  };
  
  export const clearCompetitiveInsightsData = () => {
    return {
      type: 'CLEAR_CI_DATA'
    }
  };

  export const setTop5Data = (data) => {
    return {
      type: 'SET_CITOP5_DATA',
      payload: {
        data
      }
    }
  };

  export const setCategoriesOverviewData = (data) => {
    return {
      type: 'SET_CICATEGORIES_DATA',
      payload: {
        data
      }
    }
  };

  export const setMfrPurchasesData = (data) => {
    return {
      type: 'SET_CI_MFR_PURCHASES_DATA',
      payload: {
        data
      }
    }
  };

  export const setCompsPurchasesData = (data) => {
    return {
      type: 'SET_CI_COMPS_PURCHASES_DATA',
      payload: {
        data
      }
    }
  };

  export const setDetailsFilter = (data) => {
    return {
      type: 'SET_CI_DETAILS_FILTERS',
      payload: {
        data
      }
    }
  };

  export const setAllFilters = (data) => {
    return {
      type: 'SET_CI_ALL_FILTERS',
      payload: {
        data
      }
    }
  };

  export const setTop5OpportunitiesFilters = (data) => {
    return {
      type: 'SET_CI_TOP_5_OPPS_FILTERS',
      payload: {
        data
      }
    }
  };

  export const setBetterMatchData = (data) => {
    return {
      type: 'SET_CI_BETTER_MATCH_DATA',
      payload: {
        data
      }
    }
  };

  export const clearBetterMatchData = () => {
    return {
      type: 'CLEAR_CI_BETTER_MATCH_DATA',
    }
  };

  export const setCompetitiveDetailsSortBy = ( key, order) => {
    return {
      type: 'SET_COMPETITIVE_DETAILS_SORT_BY',
      payload: {
        key: key,
        order
      }
    }
  };

  export const setManufacturerDetailsSortBy = ( key, order) => {
    return {
      type: 'SET_MANUFACTURER_DETAILS_SORT_BY',
      payload: {
        key: key,
        order
      }
    }
  };
  
  export const dataLoading = () => {
    return {
      type: 'LOADING'
    }
  };

  export const dataLoadingTop5Opps = () => {
    return {
      type: 'LOADING_TOP_5_OPPS'
    }
  };