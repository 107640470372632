import Axios from "axios";

const url = '/widget';

const widgetApi = {
  all: () => {
    return Axios.get(`${url}/all`);
  },
  get: () => {
    return Axios.get(`${url}`);
  },
  patch: (params) => {
    return Axios.patch(`${url}`, params);
  },
  delete: () => {
    return Axios.delete(`${url}`);
  }, 
  subscribe: (params) => {
    return Axios.post(`${url}/subscribe`, params);
  },
  unsubscribe: (params) => {
    return Axios.post(`${url}/unsubscribe`, params);
  },
  replace: (params) => {
    return Axios.post(`${url}/replace`, params);
  }
};

export default widgetApi;