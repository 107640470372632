import React, { Fragment } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import HeaderLogo from '../../sharedComponents/Header/HeaderLogo';
import LoginForm from './LoginForm';
import { useLocation } from 'react-router-dom/dist';

const Login = () => {
  const location = useLocation();
  const logo = localStorage.getItem('branding') === "ARROWSTREAM" ? './arrowstreamLogoWhite.png' : process.env.REACT_APP_COMPANY_LOGO;

  return (
    <Fragment>
      <HeaderLogo logo={logo} />
      <Container fluid className='flex-grow-1' style={{marginTop: '7vh'}}>
        <Card className='mx-auto px-5' style={{maxWidth: '700px'}} >
          <Row className='justify-content-center'>
            <Col style={{maxWidth: '400px'}}>
              <Row className='p-4'>
                <Col className='text-center text-size-36 main-header'>Login</Col>
              </Row>
              <LoginForm activateMsg={location.state && location.state.activate ? true : false} />
            </Col>
          </Row>
        </Card>
      </Container>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
};

export default Login;