export default function reducer(state = {
    opportunitiesItems: [],
    opportunitiesAllFilterData: [],
    opportunitiesFilterData: [],
    opportunitiesTableData: [],
    opportunitiesListOptions: {
      sortBy: 'opportunityStatus',
      sortDesc: true
    },
    isLoading: false,
    }, action) {
      switch(action.type) {
        case 'SET_OPPORTUNITIES_DATA':
          return {
            ...state,
            opportunitiesItems: action.payload,
            isLoading: false
          }
        case 'SET_OPPORTUNITIES_SORT_BY':
        return {
          ...state,
          opportunitiesListOptions: {
            ...state.competitiveListOptions,
            sortBy: action.payload.key,
            sortDesc: action.payload.order
          }
        }
        case 'SET_OPPORTUNITIES_FILTERS_DATA':
          return {
            ...state,
            opportunitiesFilterData: action.payload
          }
        case 'SET_OPPORTUNITIES_ALL_FILTERS_DATA':
          return {
            ...state,
            opportunitiesAllFilterData: action.payload
          }
        case 'SET_OPPORTUNITIES_TABLE_DATA':
          return {
            ...state,
            opportunitiesTableData: action.payload
          }
        case 'CLEAR_OPPORTUNITIES_DATA':
          return {
            ...state,
            opportunitiesItems: [],
            opportunitiesAllFilterData: [],
            opportunitiesFilterData: [],
            opportunitiesTableData: []
          }
        case 'LOADING':
          return {
            ...state,
            isLoading: true
          }
        default:
          return state;
      };
  };