import React from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal, toggleModal, removeModal } from '../../reducers/ModalsReducer/ModalsActions';
import { IoMdClose } from 'react-icons/io';

const useModal = (id, {centered, isMenu, hideClose, header = { color: 'white', background: 'beplBlue'}, isFullscreen}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(({modals}) => modals.modals.filter(m => m.props.id === id)[0].props.isOpen);

  const toggle = () => {
    dispatch(toggleModal(id));
  };

  const hide = () => {
    dispatch(hideModal(id));
  };

  const onClosed = () => {
    dispatch(removeModal(id));
  };

  const closedBtn = <button className='close' onClick={toggle}><IoMdClose /></button>;

  return {
    modalProps: {
      id,
      isOpen,
      className: isMobile ? ( isMenu ? 'm-0 mw-100 '+( isFullscreen ? 'h-100' : 'mh-100') : 'mh-100 mt-5') : (centered ? 'modal-dialog-centered' : ''),
      modalClassName: isMobile ? (isMenu ? 'd-flex flex-column justify-content-end fade-down' : '') : '',
      contentClassName: isMobile ? (isMenu ? 'border-0 ' : '')+'d-flex flex-column overflow-hidden '+( isFullscreen ? 'h-100' : 'mh-100') : '',
      toggle: hideClose ? null : toggle,
      onClosed
    },
    bodyProps: {
      className: (isMobile ? 'overflow-auto' : '') + ' py-4'
    },
    headerProps: {
      toggle: hideClose ? null : toggle,
      close: hideClose ? null : closedBtn,
      className: `pb-0 text-${header.color} bg-${header.background}`
    },
    toggle,
    hide
  };
};

export default useModal;