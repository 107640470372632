export default function reducer(state = {
    competitiveInsightsItems: [],
    top5OpportunitiesData: [],
    categoriesOverviewData: [],
    manufacturerPurchasesData: [],
    competitivePurchasesData: [],
    detailsFilters: [],
    allFilters: [],
    top5OppsFilters: [],
    betterMatchData: [],
    competitiveListOptions: {
      sortBy: 'quantity',
      sortDesc: true
    },
    manufacturerListOptions: {
      sortBy: 'quantity',
      sortDesc: true
    },
    isLoading: true,
    isLoadingTop5: true,
    isLoadingCategoriesOverview: true,
    isLoadingMfrPurchases: true,
    isLoadingCompetitivePurchases: true
    }, action) {
      switch(action.type) {
        case 'SET_CI_DATA':
          return {
            ...state,
            competitiveInsightsItems: action.payload,
            isLoading: false
          }
        case 'CLEAR_CI_DATA':
          return {
            ...state,
            competitiveInsightsItems: [],
            top5OpportunitiesData: [],
            categoriesOverviewData: [],
            manufacturerPurchasesData: [],
            competitivePurchasesData: [],
            betterMatchData: [],
            detailsFilters: [],
            allFilters: [],
            top5OppsFilters: [],
            isLoading: true,
            isLoadingTop5: true,
            isLoadingCategoriesOverview: true,
            isLoadingMfrPurchases: true,
            isLoadingCompetitivePurchases: true
          }
        case 'SET_CITOP5_DATA':
          return {
            ...state,
            top5OpportunitiesData: action.payload,
            isLoadingTop5: false
          }
        case 'SET_CICATEGORIES_DATA':
          return {
            ...state,
            categoriesOverviewData: action.payload,
            isLoadingCategoriesOverview: false
          }
        case 'SET_CI_MFR_PURCHASES_DATA':
            return {
              ...state,
              manufacturerPurchasesData: action.payload,
              isLoadingMfrPurchases: false
            }
        case 'SET_CI_COMPS_PURCHASES_DATA':
            return {
              ...state,
              competitivePurchasesData: action.payload,
              isLoadingCompetitivePurchases: false
            }
        case 'SET_CI_DETAILS_FILTERS':
            return {
              ...state,
              detailsFilters: action.payload
            }
        case 'SET_CI_ALL_FILTERS':
          return {
            ...state,
            allFilters: action.payload
          }
        case 'SET_CI_TOP_5_OPPS_FILTERS':
          return {
            ...state,
            top5OppsFilters: action.payload
          }
        case 'SET_CI_BETTER_MATCH_DATA':
          return {
            ...state,
            betterMatchData: action.payload
          }
        case 'CLEAR_CI_BETTER_MATCH_DATA':
        return {
          ...state,
          betterMatchData: []
        }
        case 'SET_COMPETITIVE_DETAILS_SORT_BY':
        return {
          ...state,
          competitiveListOptions: {
            ...state.competitiveListOptions,
            sortBy: action.payload.key,
            sortDesc: action.payload.order
          }
        }
        case 'SET_MANUFACTURER_DETAILS_SORT_BY':
          return {
            ...state,
            manufacturerListOptions: {
              ...state.manufacturerListOptions,
              sortBy: action.payload.key,
              sortDesc: action.payload.order
            }
          }
        case 'LOADING':
          return {
            ...state,
            isLoading: true,
            isLoadingCompetitivePurchases: true,
            isLoadingMfrPurchases: true
          }
        case 'LOADING_TOP_5_OPPS':
          return {
            ...state,
            isLoadingTop5: true
          }
        default:
          return state;
      };
  };