import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import HeaderLogo from '../../sharedComponents/Header/HeaderLogo';
import { Container, Col, Row } from 'reactstrap';
import ResetUserPasswordForm from '../../sharedComponents/Forms/ResetUserPassword/ResetUserPasswordForm';

const ChangePasswordToken = () => {
  const logo = localStorage.getItem('branding') === "ARROWSTREAM" ? './arrowstreamLogoWhite.png' : process.env.REACT_APP_COMPANY_LOGO;
  const token = useParams()['*'];

  return (
    <Fragment>
      <HeaderLogo logo={logo} />
      <Container fluid className='flex-grow-1'>
        <Row className='justify-content-center'>
          <Col style={{maxWidth: '400px'}}>
            <Row className='py-5'>
              <Col className='text-center text-size-36 main-header'>Change Password</Col>
            </Row>
            <ResetUserPasswordForm token={token} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ChangePasswordToken;