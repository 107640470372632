import React from 'react';
import useModal from '../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ChangePassword from '../ChangePassword/ChangePassword';


const ChangePasswordModal = ({id}) => {
  const { modalProps, headerProps, bodyProps } = useModal(id, {isMenu: true, isFullscreen: true});
  return (
   <Modal  {...modalProps}>
      <ModalHeader {...headerProps}>Change Password</ModalHeader>
      <ModalBody {...bodyProps} >
        <ChangePassword />
      </ModalBody>
    </Modal>
  )
}

export default ChangePasswordModal;
