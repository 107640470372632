import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { IoIosArrowForward } from "react-icons/io";
import { isMobile, isTablet } from 'react-device-detect';
import FiltersCard from '../../AppMain/DataSuite/DataSuiteCards/FiltersCard';
import useDataSuite from '../../AppMain/DataSuite/useDataSuite';

const DataSuiteDetailsSlideDrawer = (props) => {
  const dispatch = useDispatch();
  const enabled = useSelector(({ui}) => ui.datasuiteWidgetDetailsSlideDrawer);
  const widgetDetailsDrawerContents = useSelector(({datasuite}) => datasuite.widgetDetailsDrawer)
  const { clearDetailsFilter } = useDataSuite.useDataSuite();

  const closeDetailsDrawer = () => {
    dispatch(slideDrawer.subtractDatasuiteDetails());
    clearDetailsFilter();
  }

  return (
    <div className={((isMobile &! isTablet) ? "ds-side-drawer-mobile" : "ds-side-drawer") + (enabled ? ' open' : '') + ' ' + props.className}>
        <Row className="m-3">
            <Col className="w-fc dflex text-size-24 ps-0">
                <IoIosArrowForward style={{color: 'black', position: 'relative'}} title='Hide Details Panel' className="clickable mb-1" onClick={() => closeDetailsDrawer()}></IoIosArrowForward>
            </Col>
            <Col className='ps-1 my-auto'>
                <div className="text-black main-header text-size-20">{widgetDetailsDrawerContents.title}</div>
            </Col>
        </Row>
        <Row className='w-100 ms-0'>
          <div style={{width: '100%', height: '1px', background: '#D7D8D9'}}></div>
        </Row>
        <Row>
          <FiltersCard parentElement="Details" />
        </Row>
        {widgetDetailsDrawerContents.drawerContents}
    </div>
  );
}

export default DataSuiteDetailsSlideDrawer;