import moment from "moment";

const dashFromDate = moment([moment().year(), moment().month(), 1]).subtract(13, 'month').format('YYYY-MM-DD');
const ciFromDate = moment([moment().year(), moment().month(), 1]).subtract(5, 'month').format('YYYY-MM-DD');
const oppsFromDate = moment([moment().year() - 2, 0, 1]).format('YYYY-MM-DD');
const dataSuiteFromDate = moment([moment().year(), moment().month(), 1]).subtract(13, 'month').format('YYYY-MM-DD');
const dataSuiteDetailsFromDate = moment([moment().year(), moment().month(), 1]).subtract(13, 'month').format('YYYY-MM-DD');
const dataSuiteExportFromDate = moment([moment().year(), moment().month(), 1]).subtract(13, 'month').format('YYYY-MM-DD');
const currentDate = moment([moment().year(), moment().month(), moment().date()]).format('YYYY-MM-DD');

export default function reducer(state = {
  dashMinDate: dashFromDate,
  dashFromDate: dashFromDate,
  dashToDate: currentDate,
  dashDateString: '',
  ciMinDate: ciFromDate,
  ciFromDate: ciFromDate,
  ciToDate: currentDate,
  oppsMinDate: oppsFromDate,
  oppsFromDate: oppsFromDate,
  oppsToDate: currentDate,
  datasuiteMinDate: dataSuiteFromDate,
  datasuiteFromDate: dataSuiteFromDate,
  datasuiteToDate: currentDate,
  datasuiteDateString: '',
  datasuiteDetailsMinDate: dataSuiteDetailsFromDate,
  datasuiteDetailsFromDate: dataSuiteDetailsFromDate,
  datasuiteDetailsToDate: currentDate,
  datasuiteDetailsDateString: '',
  datasuiteExportMinDate: dataSuiteExportFromDate,
  datasuiteExportFromDate: dataSuiteExportFromDate,
  datasuiteExportToDate: currentDate,
  datasuiteExportDateString: '',
  datasuiteCompExportMinDate: dataSuiteExportFromDate,
  datasuiteCompExportFromDate: dataSuiteExportFromDate,
  datasuiteCompExportToDate: currentDate,
  datasuiteCompExportDateString: '',
  }, action) {
    switch(action.type) {
      case 'SET_DASH_TO_DATE':
        return {
          ...state,
          dashToDate: action.payload
        };
      case 'SET_DASH_FROM_DATE':
        return {
          ...state,
          dashFromDate: action.payload
        };
      case 'SET_DASH_DATE_STRING':
        return {
          ...state,
          dashDateString: action.payload
        }; 
      case 'SET_CI_TO_DATE':
        return {
          ...state,
          ciToDate: action.payload
        };
      case 'SET_CI_FROM_DATE':
        return {
          ...state,
          ciFromDate: action.payload
        };
        case 'SET_OPPS_TO_DATE':
        return {
          ...state,
          oppsToDate: action.payload
        };
      case 'SET_OPPS_FROM_DATE':
        return {
          ...state,
          oppsFromDate: action.payload
        };
      case 'SET_DATASUITE_TO_DATE':
        return {
          ...state,
          datasuiteToDate: action.payload,
          datasuiteDetailsToDate: action.payload
        };
      case 'SET_DATASUITE_FROM_DATE':
        return {
          ...state,
          datasuiteFromDate: action.payload,
          datasuiteDetailsFromDate: action.payload
        };
      case 'SET_DATASUITE_DATE_STRING':
        return {
          ...state,
          datasuiteDateString: action.payload,
          datasuiteDetailsDateString: action.payload
        }; 
      case 'SET_DATASUITE_DETAILS_TO_DATE':
        return {
          ...state,
          datasuiteDetailsToDate: action.payload
        };
      case 'SET_DATASUITE_DETAILS_FROM_DATE':
        return {
          ...state,
          datasuiteDetailsFromDate: action.payload
        };
      case 'SET_DATASUITE_DETAILS_DATE_STRING':
        return {
          ...state,
          datasuiteDetailsDateString: action.payload
        }; 
      case 'SET_DATASUITE_EXPORT_TO_DATE':
        return {
          ...state,
          datasuiteExportToDate: action.payload
        };
      case 'SET_DATASUITE_EXPORT_FROM_DATE':
        return {
          ...state,
          datasuiteExportFromDate: action.payload
        };
      case 'SET_DATASUITE_EXPORT_DATE_STRING':
        return {
          ...state,
          datasuiteExportDateString: action.payload
        }; 
      case 'SET_COMP_DATASUITE_EXPORT_TO_DATE':
        return {
          ...state,
          datasuiteCompExportToDate: action.payload
        };
      case 'SET_COMP_DATASUITE_EXPORT_FROM_DATE':
        return {
          ...state,
          datasuiteCompExportFromDate: action.payload
        };
      case 'SET_COMP_DATASUITE_EXPORT_DATE_STRING':
        return {
          ...state,
          datasuiteCompExportDateString: action.payload
        }; 
      default:
        return state;
    };
};
