import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'reactstrap';
import { dataLoading } from '../../reducers/OpportunitiesReducer/OpportunitiesActions';
import useMarketingCampaigns from './useMarketingCampaigns';
import OpportunitiesTables from '../Opportunities/OpportunitiesTables';
import useOpportunities from '../Opportunities/useOpportunities';
import MarketingCampaignsOverview from './MarketingCampaignsOverview';
import MarketingCampaignsStatus from './MarketingCampaignsStatus';

const MarketingCampaigns = () => {
    const dispatch = useDispatch;
    dispatch(dataLoading);
    const { handleClickOverviewNav, handleClickStatusNav,setCampaignManagementTab } = useMarketingCampaigns.useMarketingCampaigns();
    const isLoading = useSelector(({marketing}) => marketing.isLoading);
    const { setAllFilters } = useOpportunities.useOpportunities();
    const tabOverview = useSelector(({ui}) => ui.oppsOverviewTab);
    const tabStatus = useSelector(({ui}) => ui.oppsStatusTab);
    const cmLinkSelectedClass = localStorage.getItem('branding') === "ARROWSTREAM" ? 'asCmLinkSelected' : 'cmLinkSelected';

    const initializePage = () => {
        setAllFilters();
        setCampaignManagementTab();
    };

    // eslint-disable-next-line
    useEffect(initializePage, []);

    if (isLoading) {
        return (
            <div className='pt-3 mx-0 h-100 marketingCampaignsContainer'>
                <OpportunitiesTables />
                <Row className="px-3">
                    <Card className="dashboard-card pt-3" style={{height: '64vh'}}>
                        <Row className='mx-0 mb-2' style={{borderBottom: '4px solid lightgray'}}>
                            <Container id='subheader-container' className='align-items-left' fluid>
                                <Row className='clickable align-items-left'>
                                    <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabOverview ? 'text-black' : 'text-gray')} id={tabOverview ? cmLinkSelectedClass : ''} onClick={() => handleClickOverviewNav()}>
                                        <span className='campaign-management-nav-label'>CAMPAIGN OVERVIEW</span>
                                    </Col>
                                    <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabStatus ? 'text-black' : 'text-gray')} id={tabStatus ? cmLinkSelectedClass : ''} onClick={() => handleClickStatusNav()}>
                                        <span className='campaign-management-nav-label'>OPPORTUNITY STATUS</span>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                        { tabOverview &&
                            <MarketingCampaignsOverview />
                        }
                        { tabStatus &&
                            <MarketingCampaignsStatus />
                        }
                    </Card>
                </Row>
            </div>
        );
    }
    return (
        <div className='pt-3 mx-0 marketingCampaignsContainer'>
            <OpportunitiesTables />
            <Row className="px-3">
                <Card className="dashboard-card pt-3" style={{height: '64vh'}}>
                    <Row className='mx-0 mb-2' style={{borderBottom: '4px solid lightgray'}}>
                        <Container id='subheader-container' className='align-items-left' fluid>
                            <Row className='clickable align-items-left'>
                                <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabOverview ? 'text-black' : 'text-gray')} id={tabOverview ? cmLinkSelectedClass : ''} onClick={() => handleClickOverviewNav()}>
                                    <span className='campaign-management-nav-label'>CAMPAIGN OVERVIEW</span>
                                </Col>
                                <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabStatus ? 'text-black' : 'text-gray')} id={tabStatus ? cmLinkSelectedClass : ''} onClick={() => handleClickStatusNav()}>
                                    <span className='campaign-management-nav-label'>OPPORTUNITY STATUS</span>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                    {tabOverview &&
                        <MarketingCampaignsOverview />
                    }
                    { tabStatus &&
                        <MarketingCampaignsStatus />
                    }
                </Card>
            </Row>
        </div>
    );
}

export default MarketingCampaigns;