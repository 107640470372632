import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';

const ChangeApps = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboardAccess = useSelector(({user}) => user.dashboard);
    const chainData = useSelector(({user}) => user.chainData);
    const emergingData = useSelector(({user}) => user.emergingData);
    const salesStreamAccess = chainData || emergingData;

    const applicationsList = [
        {name: 'Manufacturer Portal', brand: 'BEP', logo: './bepLogo.png', display: dashboardAccess},
        {name: 'SalesStream', brand: 'ARROWSTREAM', logo: './arrowstreamLogo.png', display: salesStreamAccess}
    ];

    const changeBrand = (brand) => {
        if((brand === 'BEP') && dashboardAccess) {
        localStorage.setItem('branding', 'BEP');
        navigate('/dashboard');
        } else if((brand === 'ARROWSTREAM') && salesStreamAccess) {
        localStorage.setItem('branding', 'ARROWSTREAM');
        navigate('/salesstream');
        } else {
        navigate('/');
        }
        dispatch(slideDrawer.subtractAppsMenu());
        dispatch(slideDrawer.subtractMobileMenu());
    };

    const applicationMap = applicationsList.map((application) => {
        return (
            <Card className='changeApplicationCard' style={{display: application.display ? 'block' : 'none', height: '56px'}} onClick={() => changeBrand(application.brand)}>
                <Row className='mx-0' style={{height: '100%', width: '100%'}}>
                    <Col className='col-4 mx-0 px-auto' style={{alignContent: 'center', textAlign: 'center'}}>
                        <img src={application.logo} alt='Company Icon' className='company-icon mx-auto' style={{maxWidth: '100%'}}/>
                    </Col>
                    <Col className='w-fc' style={{padding: '0px', alignContent: 'center'}}>
                        <div style={{height: '80%', width: '1px', background: 'darkgray'}}></div>
                    </Col>
                    <Col className='col-6 mx-0' style={{alignContent: 'center'}}>
                        <div>{application.name}</div>
                    </Col>
                </Row>
            </Card>
        )
    });

    return (
    <Col style={{height: '100vh'}}>
        { isMobile ? 
        <Fragment>
            <div style={{height: '100%'}}>
            <Row className='d-flex mt-2 justify-content-center'>
                <Col className='col-12'>
                    {applicationMap}
                </Col>
            </Row>
            </div> 
        </Fragment>
        : 
        <Col style={{height: '100%'}}>
            <Row className='d-flex mt-2 justify-content-center'>
                <Col className='col-12'>
                    {applicationMap}
                </Col>
            </Row>
        </Col>
        }
        </Col>
    )
}

export default ChangeApps;
