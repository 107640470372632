import React from 'react';
import { Group, ScrollArea } from '@mantine/core';
import { LinksGroup } from './NavBarLinksGroup.tsx';
import useTopBar from './useTopBar';
import classes from './TopBar.module.css'
import { isMobile, isMobileOnly } from 'react-device-detect';
import { slideDrawer } from '../../reducers/UIReducer/UIActions.js';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowBack, IoMdApps } from 'react-icons/io';

const TopBar = () => {
  const logoLocation = localStorage.getItem('branding') === 'ARROWSTREAM' ? './arrowstreamLogo.png' : './bepLogo.png'
  const { links, bottomLinks } = useTopBar.useLinks();
  const appsMenuOpen = useSelector(({ui}) => ui.appsMenuSlideDrawer);

  const toggleAppsMenu = () => {
    if(!appsMenuOpen) {
      dispatch(slideDrawer.addAppsMenu());
    } else {
      dispatch(slideDrawer.subtractAppsMenu());
    }
  }

  const toplinksMap = links.map((item) => <LinksGroup {...item} key={item.label} />);
  const bottomLinksMap = bottomLinks.map((item) => <LinksGroup {...item} key={item.label} />);
  const dispatch = useDispatch();

  return (
    <nav className=  {(isMobileOnly ? classes.navbarmobile : classes.navbar)} style={{paddingTop: '0px'}}>
        {isMobile ? (
          <div className={classes.header} style={{height: '56px', padding: '0px'}}>
            <Group style={{display: 'flex', justifyContent: 'left', position: 'relative'}}>
              <div onClick={() => toggleAppsMenu()} className={localStorage.getItem('branding') === "ARROWSTREAM" ? 'arrowstreamGradient p-2' : 'beplGradient p-2'} style={{backgroundColor: 'url(#icon-gradient)', height: '56px', width: '56px', cursor: 'pointer'}}>
                <IoMdApps size={36} style={{margin: '2px'}} color='white'/>
              </div>
              <div style={{height: '56px', width: '160px', alignContent: 'center'}}>
                <img src={logoLocation} alt='Company Icon' className='company-icon'/>
              </div>
              <IoIosArrowBack style={{color: 'black', position: 'absolute', right: '0', fontSize: '38px'}} className="clickable" onClick={() => dispatch(slideDrawer.subtractMobileMenu())}></IoIosArrowBack> 
            </Group>
          </div>
          ):(
            <div className={classes.header} style={{height: '56px', width: '250px', padding: '0px'}}>
            <Group justify="start">  
              <div onClick={() => toggleAppsMenu()} className={localStorage.getItem('branding') === "ARROWSTREAM" ? 'arrowstreamGradient p-2' : 'beplGradient p-2'} style={{backgroundColor: 'url(#icon-gradient)', height: '56px', width: '56px', cursor: 'pointer'}}>
                <IoMdApps size={36} style={{margin: '2px'}} color='white'/>
              </div>
              <div style={{height: '56px', width: '160px', alignContent: 'center'}}>
                <img src={logoLocation} alt='Company Icon' className='company-icon'/>
              </div>
            </Group>
          </div>
          )
        }

      <ScrollArea className={classes.links}>
        <div className={classes.linksInner}onClick={() => dispatch(slideDrawer.subtractMobileMenu()) }>{toplinksMap}</div>
      </ScrollArea>

      <div className={classes.linksBottom}>{bottomLinksMap}</div>
    </nav>
  );
};


export default TopBar;