import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Table, Column, AutoSizer } from 'react-virtualized';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { Chart as ChartJS, registerables } from 'chart.js';
import Title from '../../../sharedComponents/Title/Title';
import useDataSuite from '../useDataSuite';
import { FaExpand, FaTrash } from 'react-icons/fa';
import { BsPencilSquare } from 'react-icons/bs';
import { IoMdInformationCircle } from "react-icons/io";
import { Tooltip } from "@mui/material";
import formatters from '../../../config/formatters';
import { isEqual } from "lodash";
import useDataSuiteWidgets from './useDataSuiteWidgets';
import EmptyDataMessage from '../../../sharedComponents/ErrorMessages/EmptyDataMessage';
import EmptySearchResultsMessage from '../../../sharedComponents/ErrorMessages/EmptySearchResultsMessage';
import { Theme } from '../../../config/constants';

ChartJS.register(...registerables);

const SalesVoidOpportunityWidget = (props) => {
    const { w, i } = props;
    const {  onClickWidgetDrawerOpen, onClickDeleteWidget, onClickWidgetDetailsDrawerOpen } = useDataSuite.useWidgetControls();
    const { widgetDetailsContent, widgetTitle, salesVoidOpportunityTableData, salesVoidOpportunityDetailsTableData, filtersEnabled } = useDataSuiteWidgets.useSalesVoidOpportunityWidget();
    const detailsDrawerTitle = useSelector(({datasuite})=> datasuite.widgetDetailsDrawer.title);
    const detailsDrawerOpen = useSelector(({ui}) => ui.datasuiteWidgetDetailsSlideDrawer);
    const widgetOptionsOpen = useSelector(({ui}) => ui.slideDrawer);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const dateString = useSelector(({dates}) => dates.datasuiteDateString);
    const detailsDateString = useSelector(({dates}) => dates.datasuiteDetailsDateString);
    const isLoading = useSelector(({datasuite}) => datasuite.isLoading.salesVoidLoading);
    const detailsIsLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.salesVoidLoading);
    const { getSalesVoidOpportunity } = useDataSuite.useDataSuite();
    const [isWidgetHovered, setIsWidgetHovered] = useState(false);

    const prevFilters = useRef(null);
    const prevDate = useRef(null);

    const handleMouseEnter = () => {
        setIsWidgetHovered(true);
    };
    const handleMouseLeave = () => {
        setIsWidgetHovered(false);
    };
    const { numberFormatter, percentFormatter } = formatters();

    useEffect(() => {
        if (isEqual(selectedFilters, prevFilters.current) && isEqual(dateString, prevDate.current)) {
            getSalesVoidOpportunity(true);
        } else {
            getSalesVoidOpportunity(false);   
        }
        prevFilters.current = selectedFilters;
        prevDate.current = dateString;

    // eslint-disable-next-line
    }, [selectedFilters, selectedDetailsFilters, dateString, detailsDateString]);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === widgetTitle)) {onClickWidgetDetailsDrawerOpen(widgetTitle, widgetDetailsContent());}}, [salesVoidOpportunityDetailsTableData, detailsIsLoading]);

    if(isLoading || salesVoidOpportunityTableData.length <= 0) {
        return (
            <Col className='w-100 h-100' style={{minHeight: '315px', position: 'relative'}}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Row className='d-flex align-items-center'>
                    <Col className='w-fc pr-0'>
                        <div className='d-flex align-items-center px-0'>
                        <Title title={widgetTitle}/>
                        <Tooltip title='Displays operator purchases from competing manufacturers in your categories. Operator is a current customer and you will be able to see your % of case qty compared to competitive MFRs. By default listed from lowest to highest %. Helpful for identifying new opportunities with operators who are not currently your direct partners.' arrow>
                            <div className='pt-3 pb-2'>
                            <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                            </div>
                        </Tooltip>
                        </div>
                    </Col>
                    {(widgetOptionsOpen || isWidgetHovered)  && ( <>
                        <Col className='w-fc px-1 pt-3 pb-2'>
                            <Button 
                                block 
                                className='py-1 button-label replaceWidgtButton' 
                                size='sm' 
                                color='blueFilter'
                                onClick={() => onClickWidgetDrawerOpen("Replace Widget", true, i)}>
                                <BsPencilSquare color='white' /> 
                            </Button>
                        </Col>
                        <Col className='w-fc px-1 pt-3 pb-2'>
                            <Button
                                block 
                                className='py-1 button-label deleteWidgetButton' 
                                size='sm' 
                                color='redLight'
                                onClick={() => onClickDeleteWidget(w)}>
                                <FaTrash color='white' />
                            </Button>
                        </Col>
                    </>
                    )}
                    <Col className={`w-fc me-3 ms-auto px-1 pt-3 pb-2`}>
                        <Button
                            block 
                            className='py-1 button-label expandWidgetButton' 
                            size='sm' 
                            outline={true}
                            color={Theme().colors.accentName}
                            onClick={() => onClickWidgetDetailsDrawerOpen(widgetTitle, widgetDetailsContent())}>
                            <FaExpand color={Theme().colors.accentName} /> Expand
                        </Button>
                    </Col>
                </Row>
                <Row className="mx-auto"style={{height: '70%'}}>
                    <Col className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                        {isLoading &&
                        <Spinner style={{marginLeft:'50%'}} color='beplBlue' animation="border" />
                        }
                        {(!isLoading && (salesVoidOpportunityTableData.length <= 0) && filtersEnabled) &&
                        <EmptySearchResultsMessage />
                        }
                        {(!isLoading && (salesVoidOpportunityTableData.length <= 0) && !filtersEnabled) &&
                        <EmptyDataMessage />
                        }
                    </Col>
                </Row>
            </Col>
        );
    }
    else return (
        <Col onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'relative'}} >
            <Row className='d-flex align-items-center'>
                <Col className='w-fc pr-0'>
                    <div className='d-flex align-items-center px-0'>
                    <Title title={widgetTitle}/>
                    <Tooltip title='Displays operator purchases from competing manufacturers in your categories. Operator is a current customer and you will be able to see your % of case qty compared to competitive MFRs. By default listed from lowest to highest %. Helpful for identifying new opportunities with operators who are not currently your direct partners.' arrow>
                        <div className='pt-3 pb-2'>
                        <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                        </div>
                    </Tooltip>
                    </div>
                </Col>
                {(widgetOptionsOpen || isWidgetHovered)  && ( <>
                    <Col className='w-fc px-1 pt-3 pb-2'>
                        <Button 
                            block 
                            className='py-1 button-label replaceWidgetButton' 
                            size='sm' 
                            color='blueFilter'
                            onClick={() => onClickWidgetDrawerOpen("Replace Widget", true, i)}>
                            <BsPencilSquare color='white' /> 
                        </Button>
                    </Col>
                    <Col className='w-fc px-1 pt-3 pb-2'>
                        <Button
                            block 
                            className='py-1 button-label deleteWidgetButton' 
                            size='sm' 
                            color='redLight'
                            onClick={() => onClickDeleteWidget(w)}>
                            <FaTrash color='white' />
                        </Button>
                    </Col>
                </>
                )}
                <Col className={`w-fc me-3 ms-auto px-1 pt-3 pb-2`}>
                <Button
                    block
                    className={`py-1 button-label expandWidgetButton`}
                    size='sm'
                    outline={true}
                    color={Theme().colors.accentName}
                    onClick={() => onClickWidgetDetailsDrawerOpen(widgetTitle, widgetDetailsContent())}>
                    <FaExpand color={Theme().colors.accentName} /> Expand
                </Button>
                </Col>
            </Row>
            <Row className="w-100 mb-3 mx-0" style={{minHeight: '315px'}}>
                <Col className="col-12 text-size-14 p-0">
                    <Row style={{overflowY: 'auto', height: '100%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table salesVoidOpportunityTable border-grayLight" id="salesVoidOpportunityTable"
                                    width={width * 0.97}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={salesVoidOpportunityTableData.length}
                                    rowGetter={({ index }) => salesVoidOpportunityTableData[index]}
                                >
                                    <Column
                                    label='Location Name'
                                    dataKey='location_name'
                                    width={width * 0.3}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Your Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Competitor Cases'
                                    dataKey='total_cases_comp'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.10}
                                    label='Your %'
                                    dataKey='mfr_percent'
                                    cellRenderer={({cellData}) => cellData < 0.01 ? "<1%" : percentFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='% of Competitors'
                                    dataKey='comp_percent'
                                    cellRenderer={({cellData}) => (cellData > 0.99 && cellData < 1.0) ? ">99%" : percentFormatter.format(cellData)}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
    
}

export default SalesVoidOpportunityWidget;