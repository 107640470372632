import React, { Fragment } from 'react';
import { Form, FormGroup, Label, Button, Row, Col, Input } from 'reactstrap';
import Select, { createFilter } from 'react-select';
import { selectStyles, Theme } from '../../config/constants';
import CustomOption from '../../sharedComponents/Helper/CustomOption';
import CustomMenuList from '../../sharedComponents/Helper/CustomMenuList';
import ChangeUserPassword from './ChangeUserPassword/ChangeUserPassword';

const AdminLoginForm = ({ userSelectProps, mfrSelectProps, dashboard, chainData, gpoData, onLogin, onLogout, onAdminEdit, setDashAccess, setChainData, setGpoData }) => {
  const userEditForm = userSelectProps?.value ?
    <Fragment>
      <ChangeUserPassword token={userSelectProps?.value?.value?.resetPasswordToken} />
      <Form className="text-size-14 py-3" onSubmit={onAdminEdit}>
        <FormGroup>
          <Row className="my-3">
            <Col className='text-size-20 main-header'>User Permissions</Col>
          </Row>
          <Row className="my-3">
            <Col className="col-6">
              <Row>
                <Label className='label-text-header form-label text-beplBlue m-2' for='dashboardCheckbox'>Dashboard (GPO Data Only)</Label>
                <Input
                  id="dashboardCheckbox"
                  name="dashboardCheckbox"
                  type='checkbox'
                  checked={dashboard}
                  onChange={setDashAccess}
                />
              </Row>
              <Row>
                <Label className='label-text-header form-label text-beplBlue m-2' for='chainCheckbox'>Data Suite: Chain Data</Label>
                <Input
                  id="chainCheckbox"
                  name="chainCheckbox"
                  type='checkbox'
                  checked={chainData}
                  onChange={setChainData}
                />
              </Row>
              <Row>
                <Label className='label-text-header form-label text-beplBlue m-2' for='gpoCheckbox'>Data Suite: GPO Data</Label>
                <Input
                  id="gpoCheckbox"
                  name="gpoCheckbox"
                  type='checkbox'
                  checked={gpoData}
                  onChange={setGpoData}
                />
              </Row>
            </Col>
            <Col className="col-6">
              <Button type='submit' block outline color={Theme().colors.accentName} className='py-2 button-label'>Update Permissions</Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </Fragment>
    : null;

  return (
    <>
      <Form className="text-size-14" onSubmit={onLogin}>
        <FormGroup>
          <Label className='label-text-header form-label text-beplBlue mb-2' for='manufacturer'>Manufacturer</Label>
          <Select
            captureMenuScroll={false}
            classNamePrefix="custom-select"
            filterOption={createFilter({ ignoreAccents: false })}
            components={{ Option: CustomOption, MenuList: CustomMenuList }}
            name='manufacturer'
            placeholder='Select Manufacturer'
            styles={selectStyles}
            {...mfrSelectProps} />
          <Label className='label-text-header form-label text-beplBlue my-2' for='user'>User (Optional)</Label>
          <Select
            captureMenuScroll={false}
            classNamePrefix="custom-select"
            filterOption={createFilter({ ignoreAccents: false })}
            components={{ Option: CustomOption, MenuList: CustomMenuList }}
            name='user'
            placeholder='Select User'
            styles={selectStyles}
            {...userSelectProps} />
        </FormGroup>
        <Row className="my-3">
          <Col className="col-6">
            <Button outline onClick={onLogout} block color={Theme().colors.accentName} className='py-2 col-12 button-label'>Log Out</Button>
          </Col>
          <Col className="col-6">
            <Button disabled={mfrSelectProps?.value === null} type='submit' block color={Theme().colors.accentName} className='py-2 col-12 button-label'>Log In</Button>
          </Col>
        </Row>
      </Form>
      {userEditForm}
    </>
  );
};

export default AdminLoginForm;