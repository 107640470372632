import { useSelector } from "react-redux";
import useDataSuite from "../useDataSuite";
import { Col, Row, Input, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { Table, Column, AutoSizer } from "react-virtualized";
import { Doughnut, Bar } from "react-chartjs-2";
import { BsCircleFill, BsSearch } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";
import { deprioritizedBrands, tableColors, Theme } from "../../../config/constants";
import formatter from "../../../config/formatters";
import CollapsibleDataTable from "../../../sharedComponents/Tables/CollapsibleDataTable";
import EmptySearchResultsMessage from "../../../sharedComponents/ErrorMessages/EmptySearchResultsMessage";
import partition from "../../../sharedComponents/Helper/arrayPartitioner";

const { numberFormatter, percentFormatter } = formatter();

const useCompetitiveInsightsWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.competitiveInsightsLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const competitiveInsightsTableData = useSelector(({datasuite}) => datasuite.widgetData.competitiveInsightsData);
    const competitiveInsightsDetailsTableData = useSelector(({datasuite}) => datasuite.widgetDetailsData.competitiveInsightsData);
    var competitiveInsightsRightTableData = [];
    const widgetTitle = "Competitive Insights";
    var competitiveInsightsTableLabels = competitiveInsightsTableData?.competitors?.slice(0,5).map((value, idx) => {
        return "Competitor " + (idx + 1);
    });
    var competitiveInsightsDetailsTableLabels = competitiveInsightsDetailsTableData?.competitors?.slice(0,5).map((value, idx) => {
        return "Competitor " + (idx + 1);
    });
    competitiveInsightsTableLabels?.unshift('Manufacturer');
    competitiveInsightsDetailsTableLabels?.unshift('Manufacturer');
    var competitiveInsightsRadialData = competitiveInsightsTableData?.competitors?.slice(0,5).map((value, idx) => {
        return value.total_cases;
    });
    var competitiveInsightsDetailsRadialData = competitiveInsightsDetailsTableData?.competitors?.slice(0,5).map((value, idx) => {
        return value.total_cases;
    });
    competitiveInsightsRadialData?.unshift(competitiveInsightsTableData?.manufacturer[0].total_cases);
    competitiveInsightsDetailsRadialData?.unshift(competitiveInsightsDetailsTableData?.manufacturer[0].total_cases);
    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true,
              xAlign: 'center',
              yAlign: 'center'
          },
        },
    };
    competitiveInsightsRightTableData = competitiveInsightsTableLabels?.map((value, idx) => {
        return {"manufacturer" : value, "total_cases" : competitiveInsightsRadialData[idx]};
    });
    const competitiveInsightsDetailsRightLabels = competitiveInsightsDetailsTableLabels?.map((value, idx) => {
        return (
            <Row key={idx} className="my-2">
                <Col className="col-1">
                    <FaCircle className='w-fc' color={tableColors[idx % 12]}/>
                </Col>
                <Col>
                    <div className="w-fc p-0" style={{fontWeight: '600', fontSize: '14px'}}>{value}</div>
                </Col>
                <Col>
                    <div className="w-fc p-0" style={{fontWeight: '600', fontSize: '14px'}}>{numberFormatter.format(competitiveInsightsDetailsRadialData[idx])}</div>
                </Col>
            </Row>
        );
    });
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if (competitiveInsightsDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 mb-3 mx-3">
                    <Row className="p-0" style={{height: '45%'}}>
                        <Col className="col-12 col-xl-8 p-0">
                            <Doughnut
                                data = {{
                                    labels: competitiveInsightsDetailsTableLabels, 
                                    datasets: [
                                        {
                                            label: '',
                                            data: competitiveInsightsDetailsRadialData,
                                            backgroundColor: tableColors
                                        }
                                    ]
                                }}
                                options = {tableOptions}
                            />
                        </Col>
                        <Col className='my-auto'>
                            {competitiveInsightsDetailsRightLabels}
                        </Col>
                    </Row>
                    <Row className="text-size-14 mx-2 w-100" style={{height: '34vh'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table opportunityByDistributorDetailsTable border-grayLight" id="opportunityByDistributorDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={competitiveInsightsDetailsTableData.competitors.length}
                                        rowGetter={({ index }) => competitiveInsightsDetailsTableData.competitors[index]}
                                    >
                                        <Column
                                        label='Manufacturer'
                                        dataKey='value'
                                        width={width * 0.9}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[(rowIndex + 1) % 12]} /> Competitor {rowIndex + 1}</span>)}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        }
    };
    const detailsContent = () => {
        if (competitiveInsightsDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 my-3 mx-3">
                    <Row className="text-size-14 mx-2 w-100" style={{height: '100%'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table opportunityByDistributorDetailsTable border-grayLight" id="opportunityByDistributorDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={competitiveInsightsDetailsTableData.competitors.length}
                                        rowGetter={({ index }) => competitiveInsightsDetailsTableData.competitors[index]}
                                    >
                                        <Column
                                        label='Manufacturer'
                                        dataKey='value'
                                        width={width * 0.8}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[(rowIndex + 1) % 12]} /> Competitor {rowIndex + 1}</span>)}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>  
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        tableOptions,
        competitiveInsightsRadialData,
        competitiveInsightsTableData,
        competitiveInsightsDetailsTableData,
        competitiveInsightsTableLabels,
        competitiveInsightsRightTableData
    };
} 

const useGrowthOpportunityWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.growthOpportunityLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails, onClickViewItemMatches } = useDataSuite.useDataSuite();
    const growthOpportunityTableDataRaw = useSelector(({datasuite}) => datasuite.widgetData.growthOpportunityData);
    const growthOpportunityDetailsTableDataRaw = useSelector(({datasuite}) => datasuite.widgetDetailsData.growthOpportunityData);
    const widgetTitle = 'Growth Opportunities';
    const [sortedDeprioritized, growthOpportunityTableData] = partition(growthOpportunityTableDataRaw, e => deprioritizedBrands.includes(e.location_name));
    const [sortedDetailsDeprioritized, growthOpportunityDetailsTableData] = partition(growthOpportunityDetailsTableDataRaw, e => deprioritizedBrands.includes(e.location_name));
    growthOpportunityTableData.push(...sortedDeprioritized);
    growthOpportunityDetailsTableData.push(...sortedDetailsDeprioritized);
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if (growthOpportunityDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 mx-3" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table growthOpportunitiesTable border-grayLight" id="growthOpportunitiesTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={growthOpportunityDetailsTableData.length}
                                    rowGetter={({ index }) => growthOpportunityDetailsTableData[index]}
                                >
                                    <Column
                                    label='Location Name'
                                    dataKey='location_name'
                                    width={width * 0.4}
                                    />
                                    <Column
                                    width={width * 0.4}
                                    label='Category'
                                    dataKey='category'
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.1}
                                    label=''
                                    dataKey='total_cases'
                                    cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.location_name)}>View Details</span>}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col> 
            )
        }
    };
    const tableColumns = [
        {
            label: "Location Name",
            dataKey: "location_name",
            width: "0.3",
            formatter: "none"
        }, 
        {
            label: "Category",
            dataKey: "category",
            width: "0.3",
            formatter: "none"
        },
        {
            label: "Total Cases",
            dataKey: "total_cases",
            width: "0.1",
            formatter: "number"
        },
        {
            label: "",
            dataKey: "total_cases",
            width: "0.3",
            formatter: "none",
            cellRenderer: ({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accentName}} onClick={() => onClickViewItemMatches(rowData.location_name, rowData.category)}>View Your Item Matches</span>
        }
    ]; 
    const detailsContent = () => {
        if(growthOpportunityDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="mt-3">
                    <CollapsibleDataTable list={growthOpportunityDetailsTableData} tableColumns={tableColumns} tableName={"growthOpportunitiesTable"} widgetTitle={widgetTitle} />
                </Col>
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        growthOpportunityTableData,
        growthOpportunityDetailsTableData
    };
}

const useLeakageWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.leakageLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'Leakage';
    const leakageTableDataRaw = useSelector(({datasuite}) => datasuite.widgetData.leakageData);
    const leakageDetailsTableDataRaw = useSelector(({datasuite}) => datasuite.widgetDetailsData.leakageData);
    const sortedTableData = leakageTableDataRaw?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedDetailsTableData = leakageDetailsTableDataRaw?.sort((a, b) => b.total_cases - a.total_cases);
    const [sortedDeprioritized, leakageTableData] = partition(sortedTableData, e => deprioritizedBrands.includes(e.location_name));
    const [sortedDetailsDeprioritized, leakageDetailsTableData] = partition(sortedDetailsTableData, e => deprioritizedBrands.includes(e.location_name));
    leakageTableData.push(...sortedDeprioritized);
    leakageDetailsTableData.push(...sortedDetailsDeprioritized);
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 mx-3" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table leakageTable border-grayLight" id="leakageTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={sortedDetailsTableData.length}
                                    rowGetter={({ index }) => sortedDetailsTableData[index]}
                                >
                                    <Column
                                    label='Location Name'
                                    dataKey='location_name'
                                    width={width * 0.4}
                                    />
                                    <Column
                                    width={width * 0.3}
                                    label='Category'
                                    dataKey='category'
                                    />
                                    <Column
                                    width={width * 0.1}
                                    label='Your Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Competitor Cases'
                                    dataKey='total_cases_comp'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.1}
                                    label=''
                                    dataKey='total_cases'
                                    cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.location_name, rowData.category)}>View Details</span>}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col>
            )
        }
    };
    const tableColumns = [
        {
            label: "Location Name",
            dataKey: "location_name",
            width: "0.4",
            formatter: "none"
        }, 
        {
            label: "Category",
            dataKey: "category",
            width: "0.3",
            formatter: "none"
        },
        {
            label: "Your Cases",
            dataKey: 'total_cases',
            width: '0.1',
            formatter: 'number'
        },
        {
            label: "Competitor Cases",
            dataKey: 'total_cases_comp',
            width: "0.2",
            formatter: "number"
        }
    ];  
    const detailsContent = () => {
        if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="mt-3">
                    <CollapsibleDataTable list={sortedDetailsTableData} tableColumns={tableColumns} tableName={"leakageTable"} widgetTitle={widgetTitle} />
                </Col>
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        leakageTableData,
        leakageDetailsTableData
    };
}

const useLostLocationsWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.lostLocationsLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'Lost Locations';
    const lostLocationsTableData = useSelector(({datasuite}) => datasuite.widgetData.lostLocationsData);
    const lostLocationsDetailsTableData = useSelector(({datasuite}) => datasuite.widgetDetailsData.lostLocationsData);
    const sortedTableData = lostLocationsTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedDetailsTableData = lostLocationsDetailsTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const detailsContent = () => {
        if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 mx-3 mt-3" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table lostLocationsTable border-grayLight" id="lostLocationsTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={sortedDetailsTableData.length}
                                    rowGetter={({ index }) => sortedDetailsTableData[index]}
                                >
                                    <Column
                                    label='Location'
                                    dataKey='location_name'
                                    width={width * 0.8}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col> 
            )
        }
    };
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 mx-3" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table lostLocationsTable border-grayLight" id="lostLocationsTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={sortedDetailsTableData.length}
                                    rowGetter={({ index }) => sortedDetailsTableData[index]}
                                >
                                    <Column
                                    label='Location'
                                    dataKey='location_name'
                                    width={width * 0.7}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.1}
                                    label=''
                                    dataKey='total_cases'
                                    cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.location_name)}>View Details</span>}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col>
            )
            
        } 
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        lostLocationsTableData,
        sortedTableData,
        sortedDetailsTableData
    };
}

const useNewLocationsWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.newLocationsLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'New Locations';
    const newLocationsTableData = useSelector(({datasuite}) => datasuite.widgetData.newLocationsData);
    const newLocationsDetailsTableData = useSelector(({datasuite}) => datasuite.widgetDetailsData.newLocationsData);
    const sortedTableData = newLocationsTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedDetailsTableData = newLocationsDetailsTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 p-0 mx-2" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table newLocationsTable border-grayLight" id="newLocationsTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={sortedDetailsTableData.length}
                                    rowGetter={({ index }) => sortedDetailsTableData[index]}
                                >
                                    <Column
                                    label='Location'
                                    dataKey='location_name'
                                    width={width * 0.7}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.1}
                                    label=''
                                    dataKey='total_cases'
                                    cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.location_name)}>View Details</span>}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col>
            )
        }
    };
    const tableColumns = [
        {
            label: "Location Name",
            dataKey: "location_name",
            width: "0.5",
            formatter: "none"
        }, 
        {
            label: "Total Cases",
            dataKey: "total_cases",
            width: "0.5",
            formatter: "number"
        }
    ];   
    const detailsContent = () => {
        if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="mt-3">
                    <CollapsibleDataTable list={sortedDetailsTableData} tableColumns={tableColumns} tableName={"newLocationsTable"} widgetTitle={widgetTitle} />
                </Col>
            )
        }  
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        newLocationsTableData,
        sortedTableData,
        sortedDetailsTableData
    };
}

const useOpportunityByDistributorWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.opportunityByDistributorLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'Opportunities By Distributor';
    const opportunityByDistributorTableData = useSelector(({datasuite}) => datasuite.widgetData.opportunityByDistributorData);
    const opportunityByDistributorDetailsTableData = useSelector(({datasuite}) => datasuite.widgetDetailsData.opportunityByDistributorData);
    const sortedTableData = opportunityByDistributorTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedDetailsTableData = opportunityByDistributorDetailsTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const opportunityByDistributorTableLabels = sortedTableData?.slice(0,9).map((value, idx) => {
        return value.distributor_name;
    });
    const opportunityByDistributorDetailsTableLabels = sortedDetailsTableData?.slice(0,19).map((value, idx) => {
        return value.distributor_name;
    });
    const opportunityByDistributorDetailsBarData = sortedDetailsTableData?.slice(0,19).map((value, idx) => {
        return value.total_cases;
    });
    const opportunityByDistributorBarData = sortedTableData?.slice(0,9).map((value, idx) => {
        return value.total_cases;
    });
    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Total Cases'
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true
          },
        },
    };
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 mb-3 mx-3">
                    <Row className="p-0" style={{height: '45%'}}>
                        <Bar
                            data = {{
                                labels: opportunityByDistributorDetailsTableLabels, 
                                datasets: [
                                    {
                                        label: '',
                                        data: opportunityByDistributorDetailsBarData,
                                        backgroundColor: tableColors
                                    }
                                ]
                            }}
                            options = {tableOptions}
                        />
                    </Row>
                    <Row className="text-size-14 mx-2 w-100" style={{height: '34vh'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table opportunityByDistributorDetailsTable border-grayLight" id="opportunityByDistributorDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={sortedDetailsTableData.length}
                                        rowGetter={({ index }) => sortedDetailsTableData[index]}
                                    >
                                        <Column
                                        label='Distributor Name'
                                        dataKey='distributor_name'
                                        width={width * 0.7}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[rowIndex % 12]} /> {cellData}</span>)}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                        <Column
                                        width={width * 0.1}
                                        label=''
                                        dataKey='total_cases'
                                        cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.distributor_name)}>View Details</span>}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        }
    };
    const detailsContent = () => {
        if(sortedDetailsTableData.length <=0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 my-3 mx-3">
                    <Row className="text-size-14 mx-2 w-100" style={{height: '100%'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table opportunityByDistributorDetailsTable border-grayLight" id="opportunityByDistributorDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={sortedDetailsTableData.length}
                                        rowGetter={({ index }) => sortedDetailsTableData[index]}
                                    >
                                        <Column
                                        label='Distributor Name'
                                        dataKey='distributor_name'
                                        width={width * 0.8}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        tableOptions,
        opportunityByDistributorTableLabels,
        opportunityByDistributorBarData,
        sortedTableData,
        sortedDetailsTableData
    };
}

const useOpportunityByStoreWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.opportunityByStoreLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'Opportunities By Store';
    const opportunityByStoreTableDataRaw = useSelector(({datasuite}) => datasuite.widgetData.opportunityByStoreData);
    const opportunityByStoreDetailsTableDataRaw = useSelector(({datasuite}) => datasuite.widgetDetailsData.opportunityByStoreData);
    const sortedTableData = opportunityByStoreTableDataRaw?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedDetailsTableData = opportunityByStoreDetailsTableDataRaw?.sort((a, b) => b.total_cases - a.total_cases);
    const [sortedDeprioritized, opportunityByStoreTableData] = partition(sortedTableData, e => deprioritizedBrands.includes(e.location_name));
    const [sortedDetailsDeprioritized, opportunityByStoreDetailsTableData] = partition(sortedDetailsTableData, e => deprioritizedBrands.includes(e.location_name));
    opportunityByStoreTableData.push(...sortedDeprioritized);
    opportunityByStoreDetailsTableData.push(...sortedDetailsDeprioritized);
    const opportunityByStoreTableLabels = opportunityByStoreTableData?.slice(0,9).map((value, idx) => {
        return value.location_name;
    });
    const opportunityByStoreDetailsTableLabels = opportunityByStoreDetailsTableData?.slice(0,19).map((value, idx) => {
        return value.location_name;
    });
    const opportunityByStoreBarData = opportunityByStoreTableData?.slice(0,9).map((value, idx) => {
        return value.total_cases;
    });
    const opportunityByStoreDetailsBarData = opportunityByStoreTableData?.slice(0,19).map((value, idx) => {
        return value.total_cases;
    });
    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Total Cases'
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true
          },
        },
    };
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(opportunityByStoreDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 mb-3 mx-3">
                    <Row className="p-0" style={{height: '45%'}}>
                        <Bar
                            data = {{
                                labels: opportunityByStoreDetailsTableLabels, 
                                datasets: [
                                    {
                                        label: '',
                                        data: opportunityByStoreDetailsBarData,
                                        backgroundColor: tableColors
                                    }
                                ]
                            }}
                            options = {tableOptions}
                        />
                    </Row>
                    <Row className="text-size-14 mx-2 w-100" style={{height: '34vh'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table opportunityByStoreDetailsTable border-grayLight" id="opportunityByStoreDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={opportunityByStoreDetailsTableData.length}
                                        rowGetter={({ index }) => opportunityByStoreDetailsTableData[index]}
                                    >
                                        <Column
                                        label='Location Name'
                                        dataKey='location_name'
                                        width={width * 0.7}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[rowIndex % 12]} /> {cellData}</span>)}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                        <Column
                                        width={width * 0.1}
                                        label=''
                                        dataKey='total_cases'
                                        cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.location_name)}>View Details</span>}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        }
    };
    const detailsContent = () => {
        if(opportunityByStoreDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 my-3 mx-3">
                    <Row className="text-size-14 mx-2 w-100" style={{height: '100%'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table opportunityByStoreDetailsTable border-grayLight" id="opportunityByStoreDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={opportunityByStoreDetailsTableData.length}
                                        rowGetter={({ index }) => opportunityByStoreDetailsTableData[index]}
                                    >
                                        <Column
                                        label='Location Name'
                                        dataKey='location_name'
                                        width={width * 0.8}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[rowIndex % 12]} /> {cellData}</span>)}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        } 
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        tableOptions,
        opportunityByStoreBarData,
        opportunityByStoreTableLabels,
        opportunityByStoreDetailsTableData
    };
}

const useSalesByCategoryWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.salesByCategoryLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const salesByCategoryTableData = useSelector(({datasuite}) => datasuite.widgetData.salesByCategoryData);
    const salesByCategoryDetailsTableData = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesByCategoryData);
    const widgetTitle = 'Sales By Category';
    const sortedTableData = salesByCategoryTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedDetailsTableData = salesByCategoryDetailsTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const salesByCategoryTableLabels = sortedTableData?.slice(0,9).map((value, idx) => {
        return value.category;
    });
    const salesByCategoryDetailsTableLabels = sortedDetailsTableData?.slice(0,19).map((value, idx) => {
        return value.category;
    });
    const salesByCategoryBarData = sortedTableData?.slice(0,9).map((value, idx) => {
        return value.total_cases;
    });
    const salesByCategoryDetailsBarData = sortedDetailsTableData?.slice(0,19).map((value, idx) => {
        return value.total_cases;
    });
    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Total Cases'
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true
          },
        },
    };
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 mb-3 mx-3">
                    <Row className="p-0" style={{height: '45%'}}>
                        <Bar
                            data = {{
                                labels: salesByCategoryDetailsTableLabels, 
                                datasets: [
                                    {
                                        label: '',
                                        data: salesByCategoryDetailsBarData,
                                        backgroundColor: tableColors
                                    }
                                ]
                            }}
                            options = {tableOptions}
                        />
                    </Row>
                    <Row className="text-size-14 mx-2 w-100" style={{height: '34vh'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table salesByCategoryDetailsTable border-grayLight" id="salesByCategoryDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={sortedDetailsTableData.length}
                                        rowGetter={({ index }) => sortedDetailsTableData[index]}
                                    >
                                        <Column
                                        label='Category'
                                        dataKey='category'
                                        width={width * 0.7}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[rowIndex % 12]} /> {cellData}</span>)}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                        <Column
                                        width={width * 0.1}
                                        label=''
                                        dataKey='total_cases'
                                        cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.category)}>View Details</span>}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        }
    };
    const tableColumns = [
        {
            label: "Category",
            dataKey: "category",
            width: "0.5",
            formatter: "none"
        },
        {
            label: "Total Cases",
            dataKey: "total_cases",
            width: "0.5",
            formatter: "number"
        }
    ]; 
    const detailsContent = () => {
        if(sortedDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="mt-3">
                    <CollapsibleDataTable list={sortedDetailsTableData} tableColumns={tableColumns} tableName={"salesByCategoryDetailsTable"} widgetTitle={widgetTitle} />
                </Col>
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        tableOptions,
        salesByCategoryBarData,
        salesByCategoryTableLabels,
        sortedDetailsTableData
    };
}

const useSalesByDistributorWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.salesByDistributorLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'Sales By Distributor';
    const salesByDistributorTableData = useSelector(({datasuite}) => datasuite.widgetData.salesByDistributorData);
    const salesByDistributorDetailsTableData = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesByDistributorData);
    const sortedTableData = salesByDistributorTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedTableDetailsData = salesByDistributorDetailsTableData?.sort((a, b) => b.total_cases - a.total_cases);
    const salesByDistributorTableLabels = sortedTableData?.slice(0,9).map((value, idx) => {
        return value.distributor_name;
    });
    const salesByDistributorDetailsTableLabels = sortedTableDetailsData?.slice(0,19).map((value, idx) => {
        return value.distributor_name;
    });
    const salesByDistributorBarData = sortedTableData?.slice(0,9).map((value, idx) => {
        return value.total_cases;
    });
    const salesByDistributorDetailsBarData = sortedTableDetailsData?.slice(0,19).map((value, idx) => {
        return value.total_cases;
    });
    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Total Cases'
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true
          },
        },
    };
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(sortedTableDetailsData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 mb-3 mx-3">
                    <Row className="p-0" style={{height: '45%'}}>
                        <Bar
                            data = {{
                                labels: salesByDistributorDetailsTableLabels, 
                                datasets: [
                                    {
                                        label: '',
                                        data: salesByDistributorDetailsBarData,
                                        backgroundColor: tableColors
                                    }
                                ]
                            }}
                            options = {tableOptions}
                        />
                    </Row>
                    <Row className="text-size-14 mx-2 w-100" style={{height: '34vh'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table salesByDistributorDetailsTable border-grayLight" id="salesByDistributorDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={sortedTableDetailsData.length}
                                        rowGetter={({ index }) => sortedTableDetailsData[index]}
                                    >
                                        <Column
                                        label='Distributor'
                                        dataKey='distributor_name'
                                        width={width * 0.7}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[rowIndex % 12]} /> {cellData}</span>)}
                                        />
                                        <Column
                                        width={width * 0.2}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                        <Column
                                        width={width * 0.1}
                                        label=''
                                        dataKey='total_cases'
                                        cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.distributor_name)}>View Details</span>}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        }   
    };
    const tableColumns = [
        {
            label: "Distributor",
            dataKey: "distributor_name",
            width: "0.5",
            formatter: "none"
        }, 
        {
            label: "Total Cases",
            dataKey: "total_cases",
            width: "0.1",
            formatter: "number"
        }
    ]; 
    const detailsContent = () => {
        if(sortedTableDetailsData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="mt-3">
                    <CollapsibleDataTable list={sortedTableDetailsData} tableColumns={tableColumns} tableName={"salesByDistributorDetailsTable"} widgetTitle={widgetTitle} />
                </Col>
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        tableOptions,
        salesByDistributorBarData,
        salesByDistributorTableLabels,
        sortedTableDetailsData
    };
}

const useSalesByOperatorWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.salesByOperatorLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'Sales By Operator';
    const salesByOperatorTableDataRaw = useSelector(({datasuite}) => datasuite.widgetData.salesByOperatorData);
    const salesByOperatorDetailsTableDataRaw = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesByOperatorData);
    const sortedTableData = salesByOperatorTableDataRaw?.sort((a, b) => b.total_cases - a.total_cases);
    const sortedDetailsTableData = salesByOperatorDetailsTableDataRaw?.sort((a, b) => b.total_cases - a.total_cases);
    const [sortedDeprioritized, salesByOperatorTableData] = partition(sortedTableData, e => deprioritizedBrands.includes(e.location_name));
    const [sortedDetailsDeprioritized, salesByOperatorDetailsTableData] = partition(sortedDetailsTableData, e => deprioritizedBrands.includes(e.location_name));
    salesByOperatorTableData.push(...sortedDeprioritized);
    salesByOperatorDetailsTableData.push(...sortedDetailsDeprioritized);
    const salesByOperatorTableLabels = salesByOperatorTableData?.slice(0,10).map((value, idx) => {
        return value.location_name;
    });
    const salesByOperatorBarData = salesByOperatorTableData?.slice(0,10).map((value, idx) => {
        return value.total_cases;
    });
    const salesByOperatorDetailsTableLabels = salesByOperatorDetailsTableData?.slice(0,20).map((value, idx) => {
        return value.location_name;
    });
    const salesByOperatorDetailsBarData = salesByOperatorDetailsTableData?.slice(0,20).map((value, idx) => {
        return value.total_cases;
    });
    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Case Quantity'
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false
          },
          tooltip: {
              enabled: true
          },
        },
    };
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(salesByOperatorDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="h-100 mb-3 mx-3">
                    <Row className="p-0" style={{height: '45vh'}}>
                        <Bar
                            data = {{
                                labels: salesByOperatorDetailsTableLabels, 
                                datasets: [
                                    {
                                        label: '',
                                        data: salesByOperatorDetailsBarData,
                                        backgroundColor: tableColors
                                    }
                                ]
                            }}
                            options = {tableOptions}
                        />
                    </Row>
                    <Row className="text-size-14 mx-2 w-100" style={{height: '34vh'}}>
                        <Col className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                            <AutoSizer>
                                {({width, height}) => (
                                    <Table className="table salesByOperatorTable border-grayLight" id="salesByOperatorDetailsTable"
                                        width={width}
                                        height={height}
                                        headerHeight={34.5}
                                        rowHeight={38}
                                        rowCount={salesByOperatorDetailsTableData.length}
                                        rowGetter={({ index }) => salesByOperatorDetailsTableData[index]}
                                    >
                                        <Column
                                        label='Location Name'
                                        dataKey='location_name'
                                        width={width * 0.4}
                                        cellRenderer={({cellData, rowIndex}) => (<span><BsCircleFill color={tableColors[rowIndex % 12]} /> {cellData}</span>)}
                                        />
                                        <Column
                                        width={width * 0.4}
                                        label='Category'
                                        dataKey='category'
                                        />
                                        <Column
                                        width={width * 0.1}
                                        label='Total Cases'
                                        dataKey='total_cases'
                                        cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                        />
                                        <Column
                                        width={width * 0.1}
                                        label=''
                                        dataKey='total_cases'
                                        cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.location_name)}>View Details</span>}
                                        />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            )
        }
    };
    const tableColumns = [
        {
            label: "Location Name",
            dataKey: "location_name",
            width: "0.4",
            formatter: "none"
        }, 
        {
            label: "Category",
            dataKey: "category",
            width: "0.4",
            formatter: "none"
        },
        {
            label: "Total Cases",
            dataKey: "total_cases",
            width: "0.1",
            formatter: "number"
        }
    ]; 
    const detailsContent = () => {
        if(salesByOperatorDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="mt-3">
                    <CollapsibleDataTable list={salesByOperatorDetailsTableData} tableColumns={tableColumns} tableName={"salesByOperatorDetailsTable"} widgetTitle={widgetTitle} />
                </Col>
            )
        }  
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle,
        salesByOperatorTableData,
        salesByOperatorTableLabels,
        salesByOperatorBarData,
        tableOptions,
        salesByOperatorDetailsTableData
    };
}

const useSalesVoidOpportunityWidget = () => {
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.salesVoidLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const { onClickViewDetails } = useDataSuite.useDataSuite();
    const widgetTitle = 'Sales Void Opportunities';
    const salesVoidOpportunityTableDataRaw = useSelector(({datasuite}) => datasuite.widgetData.salesVoidData);
    const salesVoidOpportunityDetailsTableDataRaw = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesVoidData);
    const sortedTableData = salesVoidOpportunityTableDataRaw?.sort((a, b) => a.total_cases - b.total_cases);
    const sortedDetailsTableData = salesVoidOpportunityDetailsTableDataRaw?.sort((a, b) => a.total_cases - b.total_cases);
    const [sortedDeprioritized, salesVoidOpportunityTableData] = partition(sortedTableData, e => deprioritizedBrands.includes(e.location_name));
    const [sortedDetailsDeprioritized, salesVoidOpportunityDetailsTableData] = partition(sortedDetailsTableData, e => deprioritizedBrands.includes(e.location_name));
    salesVoidOpportunityTableData.push(...sortedDeprioritized);
    salesVoidOpportunityDetailsTableData.push(...sortedDetailsDeprioritized);
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(salesVoidOpportunityDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 mx-2" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table salesVoidOpportunityTable border-grayLight" id="salesVoidOpportunityTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={salesVoidOpportunityDetailsTableData.length}
                                    rowGetter={({ index }) => salesVoidOpportunityDetailsTableData[index]}
                                >
                                    <Column
                                    label='Location Name'
                                    dataKey='location_name'
                                    width={width * 0.3}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Your Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Competitor Cases'
                                    dataKey='total_cases_comp'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.1}
                                    label='Your %'
                                    dataKey='mfr_percent'
                                    cellRenderer={({cellData}) => cellData < 0.01 ? "<1%" : percentFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='% of Competitors'
                                    dataKey='comp_percent'
                                    cellRenderer={({cellData}) => (cellData > 0.99 && cellData < 1.0) ? ">99%" : percentFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.1}
                                    label=''
                                    dataKey='total_cases'
                                    cellRenderer={({cellData, rowData}) => <span className='clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, rowData.location_name)}>View Details</span>}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col>
            )
        }        
    };
    const tableColumns = [
        {
            label: "Location Name",
            dataKey: "location_name",
            width: "0.3",
            formatter: "none"
        }, 
        {
            label: "Your Cases",
            dataKey: "total_cases",
            width: "0.2",
            formatter: "number"
        },
        {
            label: "Competitor Cases",
            dataKey: "total_cases_comp",
            width: "0.2",
            formatter: "number"
        },
        {
            label: "Your %",
            dataKey: "mfr_percent",
            width: "0.15",
            formatter: "percentage",
        },
        {
            label: "% of Competitors",
            dataKey: "comp_percent",
            width: "0.15",
            formatter: "percentage"
        }
    ]; 
    const detailsContent = () => {
        if(salesVoidOpportunityDetailsTableData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="mt-3">
                    <CollapsibleDataTable list={salesVoidOpportunityDetailsTableData} tableColumns={tableColumns} tableName={"salesVoidOpportunityTable"} widgetTitle={widgetTitle} />
                </Col> 
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        filtersEnabled,
        widgetTitle, 
        salesVoidOpportunityTableData,
        salesVoidOpportunityDetailsTableData
    };
}

const useTargetsByCategoryWidget = () => {
    const { onClickViewDetails, filterTargetsByCategoryDetailsTable } = useDataSuite.useDataSuite();
    const widgetTitle = 'Targets By Category';
    const targetsByCategoryTableData = useSelector(({datasuite}) => datasuite.widgetData.targetsByCategoryData);
    const targetsByCategoryDetailsTableData = useSelector(({datasuite}) => datasuite.widgetDetailsData.targetsByCategoryData);
    var targetsByCategoryMap = targetsByCategoryTableData?.map((data, idx) =>{
        return (
            <tr className='tr' key={`${idx}`}>
                <td className='td'>{data?.category}</td>
                <td className='td'>{numberFormatter.format(data?.total_cases)}</td>
                <td className='td'>{numberFormatter.format(data?.total_cases_comp)}</td>
            </tr>
        )
    });
    var targetsByCategoryExpandedMap = targetsByCategoryDetailsTableData?.map((data, idx) =>{
        return (
            <tr className='tr' key={`${idx}`}>
                <td className='td'>{data?.category}</td>
                <td className='td'>{numberFormatter.format(data?.total_cases)}</td>
                <td className='td'>{numberFormatter.format(data?.total_cases_comp)}</td>
                <td className='td clickable' style={{color: Theme().colors.accent}} onClick={() => onClickViewDetails(widgetTitle, data?.category)}>View Details</td>
            </tr>
        )
    });
    var targetsByCategoryDetailsMap = targetsByCategoryDetailsTableData?.map((data, idx) =>{
        return (
            <tr className='tr' key={`${idx}`}>
                <td className='td'>{data?.category}</td>
                <td className='td'>{numberFormatter.format(data?.total_cases)}</td>
                <td className='td'>{numberFormatter.format(data?.total_cases_comp)}</td>
            </tr>
        )
    });
    const widgetDetailsContent = (
        <Col>
            <Row className='mb-2 mx-3'>
                <InputGroup size='sm'>
                    <InputGroupText>
                        <BsSearch/>
                    </InputGroupText>
                    <Input className='text-input' id="targetsByCategoryDetailsSearchInput" placeholder='Search...' onChange={() => filterTargetsByCategoryDetailsTable()}/> 
                </InputGroup>
            </Row>
            <Row className="w-100">
                <Col className="text-size-14 ms-3 me-2">
                    <Row className='p-0 m-0' style={{overflowY: 'auto', height: '81vh'}}>
                        <div>
                            <table className="table targetsByCategoryDetailsTable border-grayLight" id="targetsByCategoryDetailsTable">
                                <thead>
                                <tr>
                                    <th scope="col" >CATEGORY</th>
                                    <th scope="col" >TOTAL CASES</th>
                                    <th scope="col" >COMPETITOR CASES</th>
                                    <th scope="col" ></th>
                                </tr>
                                </thead>
                                {(targetsByCategoryDetailsTableData.length > 0) &&
                                <tbody>
                                    {targetsByCategoryExpandedMap}
                                </tbody>
                                }
                            </table>
                            {(targetsByCategoryDetailsTableData.length <= 0) && 
                            <div className='text-size-20' style={{textAlign: 'center'}}>No Data To Display.</div>
                            }
                        </div> 
                    </Row>
                </Col>
            </Row>
        </Col> 
    );
    const detailsContent = (
        <Col>
            <Row className='mb-2 mx-3 mt-3'>
                <InputGroup size='sm'>
                    <InputGroupText>
                        <BsSearch/>
                    </InputGroupText>
                    <Input className='text-input' id="targetsByCategoryDetailsSearchInput" placeholder='Search...' onChange={() => filterTargetsByCategoryDetailsTable()}/> 
                </InputGroup>
            </Row>
            <Row className="w-100">
                <Col className="text-size-14 ms-3 me-2">
                    <Row className='p-0 m-0' style={{overflowY: 'auto', height: '75vh'}}>
                        <div>
                            <table className="table targetsByCategoryDetailsTable border-grayLight" id="targetsByCategoryDetailsTable">
                                <thead>
                                <tr>
                                    <th scope="col" >CATEGORY</th>
                                    <th scope="col" >TOTAL CASES</th>
                                    <th scope="col" >COMPETITOR CASES</th>
                                </tr>
                                </thead>
                                {(targetsByCategoryDetailsTableData.length > 0) &&
                                <tbody>
                                    {targetsByCategoryDetailsMap}
                                </tbody>
                                }
                            </table>
                            {(targetsByCategoryDetailsTableData.length <= 0) && 
                            <div className='text-size-20' style={{textAlign: 'center'}}>No Data To Display.</div>
                            }
                        </div> 
                    </Row>
                </Col>
            </Row>
        </Col> 
    );
    return {
        widgetDetailsContent,
        detailsContent,
        widgetTitle,
        targetsByCategoryTableData,
        targetsByCategoryDetailsTableData,
        targetsByCategoryMap
    };
}

const useTopProductsWidget = () => {
    const topProductsDetailsData = useSelector(({datasuite}) => datasuite.widgetDetailsData.topProductsData);
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.topProductsLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const widgetTitle = 'Top Products';
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(topProductsDetailsData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 mx-2" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table topProductsTable border-grayLight" id="topProductsTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={topProductsDetailsData?.length}
                                    rowGetter={({ index }) => topProductsDetailsData[index]}
                                >
                                    <Column
                                    label='MIN'
                                    dataKey='min'
                                    width={width * 0.4}
                                    />
                                    <Column
                                    width={width * 0.4}
                                    label='Description'
                                    dataKey='description'
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='% of Sales'
                                    dataKey='sales_percent'
                                    cellRenderer={({cellData}) => percentFormatter.format(cellData)}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col> 
            )
        }
    };
    const detailsContent = () => {
        if(topProductsDetailsData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 my-3 mx-3" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table topProductsTable border-grayLight" id="topProductsTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={topProductsDetailsData?.length}
                                    rowGetter={({ index }) => topProductsDetailsData[index]}
                                >
                                    <Column
                                    label='MIN'
                                    dataKey='min'
                                    width={width * 0.4}
                                    />
                                    <Column
                                    width={width * 0.4}
                                    label='Description'
                                    dataKey='description'
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='% of Sales'
                                    dataKey='sales_percent'
                                    cellRenderer={({cellData}) => percentFormatter.format(cellData)}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col> 
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        widgetTitle,
        filtersEnabled
    };
}

const useTopCategoriesWidget = () => {
    const topCategoriesDetailsData = useSelector(({datasuite}) => datasuite.widgetDetailsData.topCategoriesData);
    const isLoading = useSelector(({datasuite}) => datasuite.detailsIsLoading.topCategoriesLoading);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const filtersEnabled = (selectedFilters.categories.length > 0 || selectedFilters.distributors.length > 0 || selectedFilters.locations.length > 0 || selectedFilters.segments.length > 0);
    const detailsFiltersEnabled = (selectedDetailsFilters.includedSearch.length > 0 || selectedDetailsFilters.excludedSearch.length > 0 || selectedDetailsFilters.categories.length > 0 || selectedDetailsFilters.distributors.length > 0 || selectedDetailsFilters.locations.length > 0 || selectedDetailsFilters.segments.length > 0);
    const widgetTitle = 'Top Categories';
    const widgetDetailsContent = () => {
        if (isLoading) {
            return (
                <Spinner style={{marginLeft:'50%', marginTop: '30%'}} color='beplBlue' animation="border" />
            )
        } else if(topCategoriesDetailsData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 mx-2" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table topCategoriesTable border-grayLight" id="topCategoriesTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={topCategoriesDetailsData?.length}
                                    rowGetter={({ index }) => topCategoriesDetailsData[index]}
                                >
                                    <Column
                                    label='Category'
                                    dataKey='category'
                                    width={width * 0.4}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='% of Sales'
                                    dataKey='sales_percent'
                                    cellRenderer={({cellData}) => percentFormatter.format(cellData)}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col> 
            )
        }
    };
    const detailsContent = () => {
        if(topCategoriesDetailsData.length <= 0 && detailsFiltersEnabled) {
            return (
                <EmptySearchResultsMessage />
            )
        } else {
            return (
                <Col className="text-size-14 my-3 mx-3" style={{height: '79vh'}}>
                    <Row className='p-0 m-0' style={{height: '100%', width: '99%'}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <Table className="table topCategoriesTable border-grayLight" id="topCategoriesTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={topCategoriesDetailsData?.length}
                                    rowGetter={({ index }) => topCategoriesDetailsData[index]}
                                >
                                    <Column
                                    label='Category'
                                    dataKey='category'
                                    width={width * 0.4}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='Total Cases'
                                    dataKey='total_cases'
                                    cellRenderer={({cellData}) => numberFormatter.format(cellData)}
                                    />
                                    <Column
                                    width={width * 0.2}
                                    label='% of Sales'
                                    dataKey='sales_percent'
                                    cellRenderer={({cellData}) => percentFormatter.format(cellData)}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </Row>
                </Col> 
            )
        }
    };
    return {
        widgetDetailsContent,
        detailsContent,
        widgetTitle,
        filtersEnabled
    };
}

// eslint-disable-next-line
export default { useCompetitiveInsightsWidget, useGrowthOpportunityWidget, useLeakageWidget, useLostLocationsWidget, useNewLocationsWidget,
                 useOpportunityByDistributorWidget, useOpportunityByStoreWidget, useSalesByCategoryWidget, useSalesByDistributorWidget,
                 useSalesByOperatorWidget, useSalesVoidOpportunityWidget, useTargetsByCategoryWidget, useTopProductsWidget, useTopCategoriesWidget };