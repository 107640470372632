import React from "react";
import { Col, Row, Container } from "reactstrap";
import { useSelector } from "react-redux";
import useDashboard from "../useDashboard";
import TopCategoriesTable from "../DashboardTables/TopCategoriesTable";
import NewLocationsTable from "../DashboardTables/NewLocationsTable";
import TopProductsTable from "../DashboardTables/TopProductsTable";
import LostLocationsTable from "../DashboardTables/LostLocationsTable";

const ProductsLocationsCard = () => {
    const { handleClickTopCategoriesTab, handleClickTopProductsTab, handleClickNewLocationsTab, handleClickLostLocationsTab} = useDashboard.useDashboard();
    const topProductsTab = useSelector(({ui}) => ui.topProductsTab);
    const topCategoriesTab = useSelector(({ui}) => ui.topCategoriesTab);
    const newLocationsTab = useSelector(({ui}) => ui.newLocationsTab);
    const lostLocationsTab = useSelector(({ui}) => ui.lostLocationsTab);
    const topProductsLinkSelectedClass = localStorage.getItem('branding') === "ARROWSTREAM" ? 'asTopCatsProdsLinkSelected' : 'topCatsProdsLinkSelected';
    const locationsLinkSelectedClass = localStorage.getItem('branding') === "ARROWSTREAM" ? 'asLocationsLinkSelected' : 'locationsLinkSelected';

    return (
        <Row className="h-100 py-3">
            <Col className="w-50 pe-3 h-100" style={{borderRight: '1px lightgray solid'}}>
                <Row className='mx-0 mb-2' style={{borderBottom: '4px solid lightgray'}}>
                    <Container id='subheader-container' className='align-items-left' fluid>
                        <Row className='clickable align-items-left'>
                            <Col className={'categoriesProductstLink w-fc position-relative h-100 pb-1 ' + (topCategoriesTab ? 'text-black' : 'text-gray')} id={topCategoriesTab ? topProductsLinkSelectedClass : ''} onClick={() => handleClickTopCategoriesTab()}>
                                <span className='dashboard-nav-label'>TOP CATEGORIES</span>
                            </Col>
                            <Col className={'categoriesProductsLink w-fc position-relative h-100 pb-1 ' + (topProductsTab ? 'text-black' : 'text-gray')} id={topProductsTab ? topProductsLinkSelectedClass : ''} onClick={() => handleClickTopProductsTab()}>
                                <span className='dashboard-nav-label'>TOP PRODUCTS</span>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row style={{height: '85%'}}>
                    { topCategoriesTab &&
                        <TopCategoriesTable />
                    }
                    { topProductsTab &&
                        <TopProductsTable />
                    }
                </Row>
            </Col>
            <Col className="w-50 pe-3 h-100">
                <Row className='mx-0 mb-2' style={{borderBottom: '4px solid lightgray'}}>
                    <Container id='subheader-container' className='align-items-left' fluid>
                        <Row className='clickable align-items-left'>
                            <Col className={'locationsLink w-fc position-relative h-100 pb-1 ' + (newLocationsTab ? 'text-black' : 'text-gray')} id={newLocationsTab ? locationsLinkSelectedClass : ''} onClick={() => handleClickNewLocationsTab()}>
                                <span className='dashboard-nav-label'>NEW LOCATIONS</span>
                            </Col>
                            <Col className={'locationsLink w-fc position-relative h-100 pb-1 ' + (lostLocationsTab ? 'text-black' : 'text-gray')} id={lostLocationsTab ? locationsLinkSelectedClass : ''} onClick={() => handleClickLostLocationsTab()}>
                                <span className='dashboard-nav-label'>LOST LOCATIONS</span>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row style={{height: '85%'}}>
                    { newLocationsTab && 
                        <NewLocationsTable />
                    }
                    { lostLocationsTab &&
                        <LostLocationsTable />
                    }
                </Row>
            </Col>
        </Row>
    )   
}

export default ProductsLocationsCard;