import { uiChartNames, uiTabNames } from "../../config/constants";

export const setDashboardData = (totalVolume, competitorVolume, totalRebates, 
  annualizedRebates, lostItemCategories, lostItemDescriptions) => {
  return {
    type: 'SET_DASHBOARD_DATA',
    payload: {
      totalVolume,
      competitorVolume,
      totalRebates,
      annualizedRebates,
      lostItemCategories,
      lostItemDescriptions
    }
  }
};

export const setDashboardHighlights = (data) => {
  return {
    type: 'SET_DASHBOARD_HIGHLIGHTS',
    payload: {
      data
    }
  }
};

export const setDashboardNewLocations = (data) => {
  return {
    type: 'SET_DASHBOARD_NEW_LOCATIONS',
    payload: {
      data
    }
  }
};

export const setDashboardLostLocations = (data) => {
  return {
    type: 'SET_DASHBOARD_LOST_LOCATIONS',
    payload: {
      data
    }
  }
};

export const setDashboardTopCategories = (data) => {
  return {
    type: 'SET_DASHBOARD_TOP_CATEGORIES',
    payload: {
      data
    }
  }
};

export const setDashboardTopProducts = (data) => {
  return {
    type: 'SET_DASHBOARD_TOP_PRODUCTS',
    payload: {
      data
    }
  }
};

export const setDistributorBreakdownChartData = (data) => {
  return {
    type: 'SET_DASHBOARD_DISTRIBUTOR_BREAKDOWN_CHART_DATA',
    payload: {
      data
    }
  }
};

export const setCompetitiveInsightsChartData = (data) => {
  return {
    type: 'SET_COMPETITIVE_INSIGHTS_CHART_DATA',
    payload: {
      data
    }
  }
};

export const setBEPDistributorListData = (data) => {
  return {
    type: 'SET_BEP_DISTRIBUTOR_LIST_DATA',
    payload: {
      data
    }
  }
};

export const clearDashboardData = () => {
  return {
    type: 'CLEAR_DASHBOARD_DATA'
  }
};

export const setTableDataLoading = (tableName, isLoading) => {
  switch (tableName) {
    case (uiTabNames.topCategories) : {
      return {
        type: 'SET_TOP_CATEGORIES_LOADING',
        payload: {
          isLoading
        }
      }
    }
    case (uiTabNames.topProducts) : {
      return {
        type: 'SET_TOP_PRODUCTS_LOADING',
        payload: {
          isLoading
        }
      }
    }
    case (uiTabNames.newLocations) : {
      return {
        type: 'SET_NEW_LOCATIONS_LOADING',
        payload: {
          isLoading
        }
      }
    }
    case (uiTabNames.lostLocations) : {
      return {
        type: 'SET_LOST_LOCATIONS_LOADING',
        payload: {
          isLoading
        }
      }
    }
    default : {
      console.log('The ' + tableName + ' tab does not exist.');
      break;
    }
  }
};

export const setChartDataLoading = (chartName, isLoading) => {
  switch (chartName) {
    case (uiChartNames.totalOpportunitiesTable) : {
      return {
        type: 'SET_TOTAL_OPPORTUNITIES_CHART_LOADING',
        payload: {
          isLoading
        }
      }
    }
    case (uiChartNames.competitiveInsightsTable) : {
      return {
        type: 'SET_COMPETITIVE_INSIGHTS_CHART_LOADING',
        payload: {
          isLoading
        }
      }
    }
    case (uiChartNames.distributorBreakdownTable) : {
      return {
        type: 'SET_DISTRIBUTOR_BREAKDOWN_LOADING',
        payload: {
          isLoading
        }
      }
    }
    default : {
      console.log('The ' + chartName + ' chart does not exist.');
      break;
    }
  }
};

export const setBepDistributorListLoading = (isLoading) => {
  return {
    type: 'SET_BEP_DISTRIBUTOR_LIST_LOADING',
    payload: {
      isLoading
    }
  }
}

export const setHighlightsLoading = (isLoading) => {
  return {
    type: 'SET_HIGHLIGHTS_LOADING',
    payload: {
      isLoading
    }
  }
}

export const setDashboardDrawerContents = (title, contents) => {
  return {
    type: 'SET_DASHBOARD_SLIDE_DRAWER_CONTENTS',
    payload: {
      title: title,
      contents: contents
    }
  }
}

export const setSelectedClientManagerFilter = (data) => {
  return {
    type: 'SET_OPPORTUNITIES_CLIENTMANAGER_FILTER',
    payload: data
  }
}

export const setSelectedOpportunitySourceFilter = (data) => {
  return {
    type: 'SET_OPPORTUNITIES_SOURCE_FILTER',
    payload: data
  }
}

export const setSelectedOpportunityStatusFilter = (data) => {
  return {
    type: 'SET_OPPORTUNITIES_STATUS_FILTER',
    payload: data
  }
}

export const dataLoading = () => {
  return {
    type: 'LOADING'
  }
};