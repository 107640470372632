const sortArrayByKey = (array, key, isDecending) => {
  return array.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    if (!isDecending) {
      if (x === 'N/A' || x === null) {
        return 1;
      }
      if (y === 'N/A' || y === null) {
        return -1;
      }
      return ((x < y) ? -1 : ((x > y) ? 1 : 0)); 
    } 
    else {
      if (x === 'N/A' || x === null) {
        return 1;
      }
      if (y === 'N/A' || y === null) {
        return -1;
      }
      return ((x > y) ? -1 : ((x > y) ? 1 : 0));
    }
  });
};

export default sortArrayByKey;