import React from 'react';
import { Col, Row } from 'reactstrap';
import ResetUserPasswordForm from '../../../sharedComponents/Forms/ResetUserPassword/ResetUserPasswordForm';


const ChangeUserPassword = ({ token }) => {
    return (
        <Row className="mt-5">
            <Col className='col-12 my-3 text-size-20 main-header'>Change User Password</Col>
            <Col>
                <ResetUserPasswordForm token={token} adminUser={true} />
            </Col>
        </Row>
    );
};

export default ChangeUserPassword;