import { useDispatch } from "react-redux";
import marketingApi from "../../api/marketingApi";
import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";
import { disableEditDetailsState, enableEditDetailsState, setMarketingCampaignsData } from "../../reducers/MarketingCampaignsReducer/MarketingCampaignsActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { campaignManagementNavigationBar, linkNavigationBar } from "../../reducers/UIReducer/UIActions";
import { uiTabNames } from "../../config/constants";
import { stringify } from 'csv-stringify';
import fileDownload from 'js-file-download';
import { setSelectedClientManagerFilter, setSelectedOpportunitySourceFilter, setSelectedOpportunityStatusFilter } from "../../reducers/DashboardReducer/DashboardActions";

const useMarketingCampaigns = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getMarketingCampaigns = () => {
        const params = {
            "manufacturerPlatformId": LocalStorage.manufacturerData.get().platform_id,
        };
        marketingApi.campaigns.post(params).then(({data}) => {
            dispatch(setMarketingCampaignsData(data));
        }).catch(err => {
            console.log(err);
        });
    };

    const setCampaignManagementTab = () => {
        dispatch(linkNavigationBar.setTab(uiTabNames.campaignManagement));
    }

    const updateMarketingCampaign = (campaignDetails) => {
        const params = {
            "manufacturerPlatformId" : LocalStorage.manufacturerData.get().platform_id,
            "manufacturerAgreement": campaignDetails.manufacturerAgreement,
            "itemBrand": campaignDetails.itemBrand,
            "products": campaignDetails.products,
            "CTA": campaignDetails.cta,
            "objective": campaignDetails.objective,
            "audience": campaignDetails.audience,
            "takeawaysAndLearning": campaignDetails.takeawaysAndLearning,
            "questions": campaignDetails.questions,
            "campaignAssets": campaignDetails.campaignAssets
        }
        //TODO put marketing campaign updates here
        marketingApi.updateCampaign.post(params).then(({data}) => {
            toast.info("Successfully updated campaign.");
        }).catch(err => {
            console.error(err);
            toast.error('Something went wrong updating campaign.');
        });
    };

    const handleClickViewCampaign = (idx) => {
        navigate("/campaignmanagement/" + idx);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClickDetailsHeader= () => {
        navigate("/campaignmanagement");
    };

    const handleClickEditDetails = () => {
        dispatch(enableEditDetailsState());
    };

    const handleClickOverviewNav = () => {
        dispatch(campaignManagementNavigationBar.setTab(uiTabNames.opportunityOverview));
    };

    const handleClickStatusNav = () => {
        dispatch(campaignManagementNavigationBar.setTab(uiTabNames.opportunityStatus));
    };

    const handleClickViewOppsGenerated = (campaign) => {
        navigate({pathname: '/campaignmanagement', state: {'source': campaign.campaignsource, 'campaignName': campaign.campaignName}});
        dispatch(campaignManagementNavigationBar.setTab(uiTabNames.opportunityStatus));
    };

    const handleClickSaveEditDetails = (campaignDetails) => {
        campaignDetails.itemBrand = document.getElementById('itemBrandInput').value;
        campaignDetails.products = document.getElementById('productsInput').value;
        campaignDetails.CTA = document.getElementById('ctaInput').value;
        campaignDetails.objective = document.getElementById('objectiveInput').value;
        campaignDetails.audience = document.getElementById('audienceInput').value;
        updateMarketingCampaign(campaignDetails);
        dispatch(disableEditDetailsState());
    };

    const handleClickDiscardEditDetails = (campaignDetails) => {
        document.getElementById('itemBrandInput').value = campaignDetails.itemBrand;
        document.getElementById('productsInput').value = campaignDetails.products;
        document.getElementById('ctaInput').value = campaignDetails.CTA;
        document.getElementById('objectiveInput').value = campaignDetails.objective;
        document.getElementById('audienceInput').value = campaignDetails.audience;
        dispatch(disableEditDetailsState());
    };

    const handleClickUpdateLearnings = (campaignDetails) => {
        const learningsTakeawaysInputValue = document.getElementById("learningsTakeawaysInput").value;
        const questionsInputValue = document.getElementById("questionsInput").value;
        campaignDetails.takeawaysAndLearning = learningsTakeawaysInputValue;
        campaignDetails.questions = questionsInputValue;
        updateMarketingCampaign(campaignDetails);
    };

    const handleClickExport = (campaign, mfrName) => {
        if (campaign) {
            let csvData = [];
            // CSV column headers
            csvData.push([
                'Start Date', 
                'Manufacturer', 
                'Item Brand', 
                'Products', 
                'CTAs', 
                'Objective', 
                'Audience', 
                'LearningsTakeaways', 
                'Questions', 
                'Opportunities', 
                'Engagements', 
                'Impressions', 
                'Assets'
            ]);

            // CSV data row
            csvData.push([
                campaign.campaignStartDate,
                mfrName,
                campaign.itemBrand,
                campaign.products,
                campaign.CTA,
                campaign.objective,
                campaign.audience,
                campaign.takewaysAndLearning,
                campaign.questions,
                campaign.opportunitiesGenerated,
                campaign.totalEngagements,
                campaign.totalImpressions,
                campaign.campaignAssets
            ]);

            // Start the Download
            stringify(csvData, (err, output) => {
                fileDownload(output, `${campaign.campaignName}.csv`);
            });
        }
    };

    const mapMarketingCampaigns = (marketingCampaignsData) => {
        const marketingCampaignsMap = marketingCampaignsData?.marketingCampaigns?.map((data, idx) =>{
            return (
                <tr key={`${idx}`}>
                    <td>{data?.campaignName}</td>
                    <td>{data?.campaignStartDate}</td>
                </tr>
            )
        });
        return marketingCampaignsMap;
    } 

    const mapMarketingCampaignsMobile = (marketingCampaignsData) => {
        const marketingCampaignsMap = marketingCampaignsData?.marketingCampaigns?.map((data, idx) =>{
            return (
                <tr key={`${idx}`} onClick={() => handleClickViewCampaign(idx)}>
                    <td>{data?.campaignName}</td>
                    <td>{data?.campaignStartDate}</td>
                </tr>
            )
        });
        return marketingCampaignsMap;
    } 

    const filterCampaignOverviewTable = () => {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("campaignOverviewSearchInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("marketingCampaignsTable");
        tr = table.getElementsByTagName("tr");
      
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1 || filter === '') {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }
        }
    }

    const filterOpportunityStatusTable = () => {
        // Declare variables
        var input, filter, table, tr, td1, td2, td3, td4, i, txtValue1, txtValue2, txtValue3, txtValue4;
        input = document.getElementById("opportunityStatusSearchInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("opportunitiesTable");
        tr = table.getElementsByTagName("tr");
      
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          td1 = tr[i].getElementsByTagName("td")[0];
          td2 = tr[i].getElementsByTagName("td")[1];
          td3 = tr[i].getElementsByTagName("td")[2];
          td4 = tr[i].getElementsByTagName("td")[3];
          if (td1 || td2 || td3 || td4) {
            txtValue1 = td1.textContent || td1.innerText;
            txtValue2 = td2.textContent || td2.innerText;
            txtValue3 = td3.textContent || td3.innerText;
            txtValue4 = td4.textContent || td4.innerText;
            if (txtValue1.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1 || txtValue3.toUpperCase().indexOf(filter) > -1 || txtValue4.toUpperCase().indexOf(filter) > -1 || filter === '') {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }

    const clearOpportunityFilters = () => {
        dispatch(setSelectedClientManagerFilter([]));
        dispatch(setSelectedOpportunitySourceFilter([]));
        dispatch(setSelectedOpportunityStatusFilter([]));
    }

    const setSelectedClientManager = (selectedClientManager) => {
        const selectedClientManagerMap = selectedClientManager?.map((data) => {
            return data.value;
        });
        if (selectedClientManagerMap) {
            dispatch(setSelectedClientManagerFilter(selectedClientManagerMap));  
        } else {
            dispatch(setSelectedClientManagerFilter([])); 
        }
    }

    const setSelectedSource = (selectedSource) => {
        const selectedSourceMap = selectedSource?.map((data) => {
            return data.value;
        });
        if (selectedSourceMap) {
            dispatch(setSelectedOpportunitySourceFilter(selectedSourceMap));  
        } else {
            dispatch(setSelectedOpportunitySourceFilter([]));
        }
    }

    const setSelectedStatus = (selectedStatus) => {
        const selectedStatusMap = selectedStatus?.map((data) => {
            return data.value;
        });
        if (selectedStatusMap) {
            dispatch(setSelectedOpportunityStatusFilter(selectedStatusMap));
        } else {
            dispatch(setSelectedOpportunityStatusFilter([]));
        }
    }

    return {
        getMarketingCampaigns,
        mapMarketingCampaigns,
        mapMarketingCampaignsMobile,
        handleClickDetailsHeader,
        updateMarketingCampaign,
        handleClickEditDetails,
        handleClickUpdateLearnings,
        handleClickSaveEditDetails,
        handleClickDiscardEditDetails,
        handleClickStatusNav,
        handleClickOverviewNav,
        handleClickExport,
        handleClickViewOppsGenerated,
        setCampaignManagementTab,
        filterCampaignOverviewTable,
        filterOpportunityStatusTable,
        clearOpportunityFilters,
        setSelectedClientManager,
        setSelectedSource,
        setSelectedStatus
    }
};
  
  // eslint-disable-next-line
  export default { useMarketingCampaigns };