import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";

const userData = LocalStorage.userData.get();
const manufacturerData = LocalStorage.manufacturerData.get();
const isLogin = LocalStorage.authKey.get() ? ( userData ? true : false) : false

export default function reducer(state = {
  isLogin: isLogin,
  id: isLogin ? userData.id : null,
  email: isLogin ? userData.email : null,
  name: isLogin ? userData.name || userData.contactName : null,
  displayIntel: userData ? userData.displayIntel : null,
  userRol: [],
  locationList: [],
  locationChecked: [],
  merchantsAll: [],
  distributorsList: [],
  manufacturerList: manufacturerData,
  fullManufacturersList: [],
  searchLocation: '',
  activeDistributor: null,
  activeDistributorApply: null,
  dataSuiteWidgets: [],
  isAdmin: false,
  chainData: userData ? userData.user?.chainData === true : false,
  emergingData: userData ? userData.user?.emergingData === true : false,
  notifications: [],
  dashboard: userData ? userData.user?.dashboard === true : false,
}, action) {
  switch(action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        ...action.payload,
        isLogin: true,
      };
    case 'SET_MANUFACTURER_ID_DATA':
      return {
        ...state,
        manufacturerList: action.payload
      };
    case 'SET_FULL_MANUFACTURERS_LIST_DATA':
      return {
        ...state,
        fullManufacturersList: action.payload
      };
    case 'CLEAR_USER_DATA':
      return {
        isLogin: false,
        id: null,
        email: null,
        name: null,
        activeDistributor: null,
        activeDistributorApply: null,
        distributorsList: [],
        fullManufacturersList: [],
        isAdmin: false,
        searchLocation: '',
        notifications: []
      };
    case 'SET_USER_ROL_DATA':
      return {
        ...state,
        userRol: action.payload,
      };
    case 'CLEAR_SEARCH_LOCATION':
      return {
        ...state,
        searchLocation: ''
      };
    case 'SET_SEARCH_LOCATION':
      return {
        ...state,
        searchLocation: action.payload
      };
    case 'SET_LOCATION_CHECKED':
      return {
        ...state,
        locationChecked: state.locationChecked.some(m => m === action.payload) ? state.locationChecked.filter(m => m !== action.payload) : [...state.locationChecked, action.payload]
    };
    case 'SET_LOCATION_CHECKED_ALL':
      return {
        ...state,
        locationChecked: action.payload
    };
    case 'SET_ACTIVE_DISTRIBUTOR':
      return {
        ...state,
        activeDistributor: action.payload,
        activeDistributorApply: state.activeDistributorApply === null ? action.payload : state.activeDistributorApply
      };
    case 'APPLY_ACTIVE_DISTRIBUTOR':
    case 'APPLY_INTEL_SEARCH':
      return {
        ...state,
        activeDistributorApply: state.activeDistributor
      }
    case 'SET_DISTRIBUTORS_LIST':
      return {
        ...state,
        distributorsList: action.payload
      };
    case 'SET_MERCHANTS_ALL':
      return {
        ...state,
        merchantsAll: action.payload
      };
    case 'SET_ADMIN':
      return {
        ...state,
        isAdmin: action.payload
      };
    case 'SET_DATASUITE_SELECTED_WIDGETS':
      return {
        ...state,
        dataSuiteWidgets: action.payload
      };
    case 'SET_DATA_SETS':
      return {
        ...state,
        chainData: action.payload.chainData === true,
        emergingData: action.payload.gpoData === true
      };
    case 'SET_DASHBOARD_ACCESS':
      return {
        ...state,
        dashboard: action.payload === true,
      }
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload
      };
    default:
      return state;
  };
};