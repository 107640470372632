import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback, Button, FormText, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import authApi from '../../../api/authApi';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../AlertModal/AlertModal';
import { spinner } from '../../../reducers/UIReducer/UIActions';
import useResetUserPasswordForm from './useResetUserPasswordForm';
import { Theme } from '../../../config/constants';

const ResetUserPasswordForm = ({ token, adminUser = false }) => {
  const {
    validate, password, passwordRepeat
  } = useResetUserPasswordForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      const params = {
        password: password.value,
        newPassword: passwordRepeat.value,
      };
      dispatch(spinner.add());
      authApi.resetPassword(token).post(params).then(({ data }) => {
        dispatch(spinner.subtract());
        const modalProps = {
          title: adminUser ? 'User password has been changed successfully.' : 'Your password has been changed successfully.',
          ...(adminUser ? {} : { buttonClick: () => navigate('/login') })
        };
        dispatch(showModal(AlertModal, modalProps));
      }).catch(err => {
        dispatch(spinner.subtract());
        let msg = 'Something went wrong';
        if (err?.data?.message) {
          msg = err.data.message;
        }
        const modalProps = {
          title: msg
        };
        dispatch(showModal(AlertModal, modalProps));
        setApiError(msg);
      });
    }
    console.log(errors);
  };

  const goToLogin = () => {
    navigate('login');
  };

  return (
    <Form className="text-size-14" onSubmit={onSubmit}>
      <FormGroup>
        <Label className='label-text-header text-beplBlue form-label mb-2' for='password'>Create Password *</Label>
        <Input className='mb-2' type='password' {...password} placeholder='Password' />
        <FormFeedback>{password.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header text-beplBlue form-label mb-2' for='passwordRepeat'>Confirm Password *</Label>
        <Input className='mb-3' type='password' {...passwordRepeat} placeholder='Repeat Password' />
        <FormFeedback>{passwordRepeat.message}</FormFeedback>
      </FormGroup>
      <Button type='submit' block color={Theme().colors.accentName} className='py-2 w-100'>Change Password</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      {!adminUser &&
        <Row className='mb-5'>
          <Col onClick={goToLogin} className='mt-3 text-center clickable' style={{ color: Theme().colors.accent }}>Already have an account? Login</Col>
        </Row>
      }
    </Form>
  );
};

export default ResetUserPasswordForm;