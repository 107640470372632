  export const setOpportunitiesData = (data) => {
    return {
      type: 'SET_OPPORTUNITIES_DATA',
      payload: {
        data
      }
    }
  };

  export const setOpportunitiesSortBy = ( key, order) => {
    return {
      type: 'SET_OPPORTUNITIES_SORT_BY',
      payload: {
        key: key,
        order
      }
    }
  };

  export const setOppsFilters = (data) => {
    return {
      type: 'SET_OPPORTUNITIES_FILTERS_DATA',
      payload: {
        data
      }
    }
  };

  export const setAllOppsFilters = (data) => {
    return {
      type: 'SET_OPPORTUNITIES_ALL_FILTERS_DATA',
      payload: {
        data
      }
    }
  };

  export const setOpportunitiesTableData = (data) => {
    return {
      type: 'SET_OPPORTUNITIES_TABLE_DATA',
      payload: {
        data
      }
    }
  };

  export const clearOpportunititesData = () => {
    return {
      type: 'CLEAR_OPPORTUNITIES_DATA'
    }
  };
  
  export const dataLoading = () => {
    return {
      type: 'LOADING'
    }
  };