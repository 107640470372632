import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Input, InputGroup, InputGroupText, Spinner } from 'reactstrap';
import { TiArrowUnsorted } from 'react-icons/ti';
import { BsSearch } from 'react-icons/bs';
import { dataLoading } from '../../reducers/OpportunitiesReducer/OpportunitiesActions';
import MarketingCampaignsRow from './MarketingCampaignsRow';
import useMarketingCampaigns from './useMarketingCampaigns';
import { useEffect } from 'react';

const MarketingCampaignsOverview = () => {
    const dispatch = useDispatch;
    dispatch(dataLoading);
    const isLoading = useSelector(({marketing}) => marketing.isLoading);
    const { filterCampaignOverviewTable,  getMarketingCampaigns } = useMarketingCampaigns.useMarketingCampaigns();

    const initializePage = () => {
        getMarketingCampaigns();
    };

    // eslint-disable-next-line
    useEffect(initializePage, []);

    if (isLoading) {
        return (
            <Col className='mx-3 w-100'>
                <Row className='ms-0 ps-0'>
                    <Col className='col-2 px-0'>
                        <InputGroup className='ps-0 mb-2' size='md' style={{height: '38px'}}>
                            <InputGroupText>
                                <BsSearch/>
                            </InputGroupText>
                            <Input className='text-input' id="campaignOverviewSearchInput" placeholder='Search...'/> 
                        </InputGroup>
                    </Col>
                </Row>
                <Row className='w-100' style={{overflowY: 'auto'}}>
                    <table className="table marketingCampaignsTable border-grayLight" id="marketingCampaignsTable">
                        <thead>
                        <tr>
                            <th className="clickable" scope="col" >CAMPAIGN NAME <TiArrowUnsorted /></th>
                            <th className="clickable" scope="col" >DATE CREATED <TiArrowUnsorted /></th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                    </table>
                </Row>
                <Row>
                    <div className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                        <Spinner color='beplBlue' animation="border" />
                    </div>
                </Row>
            </Col>
        );
    }
    return (
        <Col className='mx-3 w-100' style={{height:'100%'}}>
            <Row className='ms-0 ps-0'>
                <Col className='col-2 px-0'>
                    <InputGroup className='ps-0 mb-2' size='md' style={{height: '38px'}}>
                        <InputGroupText>
                            <BsSearch/>
                        </InputGroupText>
                        <Input className='text-input' id="campaignOverviewSearchInput" placeholder='Search...' onChange={() => filterCampaignOverviewTable()}/> 
                    </InputGroup>
                </Col>
            </Row>
            <Row className='w-100' style={{overflowY: 'auto', maxHeight: '84%'}}>
                <table className="table marketingCampaignsTable border-grayLight" id="marketingCampaignsTable">
                    <thead>
                    <tr>
                        <th className="clickable" scope="col" >CAMPAIGN NAME <TiArrowUnsorted /></th>
                        <th className="clickable" scope="col" >DATE CREATED <TiArrowUnsorted /></th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <MarketingCampaignsRow />
                </table>
            </Row>
        </Col>
        
    );
}

export default MarketingCampaignsOverview;