import React from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile, isMobileOnly } from 'react-device-detect';
import useSettings from './useSettings';
import ChangePassword from './ChangePassword/ChangePassword';
import UsersRoles from './UsersRoles/UsersRoles';
import useUserRoles from '../Settings/UsersRoles/useUsersRoles';
import Profile from './Profile/Profile';

const Settings = () => {
  const { clickTabRol, clickTabPassword, tabPassword, tabRol } = useSettings.useUser();
  const userRolesProps = useUserRoles.useApi();
  const profileLinkSelectedClass = localStorage.getItem('branding') === "ARROWSTREAM" ? 'asProfileLinkSelected' : 'profileLinkSelected';

  return (
   <Row className='mx-0 settingsContainer' style={{height: '100vh'}}> 
      <div className='px-0'>
        <Row className={(isMobile ? "text-size-14" : "'text-size-20'")}  style={{borderBottom:'4px solid lightgray'}} >
          <Col id={(tabPassword ? 'profileLinkSelected' : '')} className={'w-fc clickable'} onClick={clickTabPassword}>
            <Row className='py-2 px-3'>
              <span>Profile</span>
            </Row>
          </Col>
          <Col id={(tabRol ? profileLinkSelectedClass : '')} className={'w-fc clickable'} onClick={clickTabRol}>
            <Row className='py-2 px-3'>
              <span>Users & Roles</span>
            </Row>
          </Col>
        </Row>
        { tabPassword &&
          <Col className='col-12'>
            <Profile />
            <Row className={(isMobileOnly ? 'px-2 mb-2' :'px-5 mb-2')}>
              <span className={(isMobile ? "text-size-18 main-header" : "text-size-20 main-header")} >Change Password</span>
            </Row>
            <ChangePassword />
          </Col>
        }
        { tabRol &&
          <UsersRoles {...userRolesProps} />
        }
      </div>
    
    </Row>
  );
}

export default Settings;
