const initialState = {
  widgetDrawer: {
    title: '',
    replaceMode: false,
    replaceIndex: null
  },
  widgetDetailsDrawer: {
    title: '',
    drawerContents: null
  },
  highlights: {
    topOperator: {},
    trendingSales: {},
    trendingCategory: {},
    biggestGrowthOpportunity: {}
  },
  allFilters: [],
  selectedFilters: {
    chainData: false,
    gpoData: false,
    categories: [],
    distributors: [],
    locations: [],
    segments: []
  },
  selectedDetailsFilters: {
    chainData: false,
    gpoData: false,
    includedSearch: [],
    excludedSearch: [],
    categories: [],
    distributors: [],
    locations: [],
    segments: []
  },
  exportDetailsFilters: {
    chainData: false,
    gpoData: false,
    categories: [],
    distributors: [],
    locations: [],
    segments: []
  },
  compExportDetailsFilters: {
    chainData: false,
    gpoData: false,
    categories: [],
    locations: [],
    segments: []
  },
  defaultDatasetsLoaded: false,
  itemLevelDescriptionData: [],
  itemLevelDescriptionLoading: false,
  itemMatchData: [],
  itemMatchLoading: false,
  widgets: [],
  widgetData: {
    salesByOperatorData: [],
    salesByCategoryData: [],
    salesByDistributorData: [],
    opportunityByDistributorData: [],
    opportunityByStoreData: [],
    newLocationsData: [],
    lostLocationsData: [],
    salesVoidData: [],
    growthOpportunityData: [],
    leakageData: [],
    targetsByCategoryData: [],
    competitiveInsightsData: [],
    topProductsData: [],
    topCategoriesData: []
  },
  widgetDetailsData: {
    salesByOperatorData: [],
    salesByCategoryData: [],
    salesByDistributorData: [],
    opportunityByDistributorData: [],
    opportunityByStoreData: [],
    newLocationsData: [],
    lostLocationsData: [],
    salesVoidData: [],
    growthOpportunityData: [],
    leakageData: [],
    targetsByCategoryData: [],
    competitiveInsightsData: [],
    topProductsData: [],
    topCategoriesData: []
  },
  isLoading: {
    salesByOperatorLoading: false,
    salesByCategoryLoading: false,
    salesByDistributorLoading: false,
    opportunityByDistributorLoading: false,
    opportunityByStoreLoading: false,
    newLocationsLoading: false,
    lostLocationsLoading: false,
    salesVoidLoading: false,
    growthOpportunityLoading: false,
    leakageLoading: false,
    targetsByCategoryLoading: false,
    competitiveInsightsLoading: false,
    topProductsLoading: false,
    topCategoriesLoading: false,
    detailsPageLoading: false
  },
  detailsIsLoading: {
    salesByOperatorLoading: false,
    salesByCategoryLoading: false,
    salesByDistributorLoading: false,
    opportunityByDistributorLoading: false,
    opportunityByStoreLoading: false,
    newLocationsLoading: false,
    lostLocationsLoading: false,
    salesVoidLoading: false,
    growthOpportunityLoading: false,
    leakageLoading: false,
    targetsByCategoryLoading: false,
    competitiveInsightsLoading: false,
    topProductsLoading: false,
    topCategoriesLoading: false,
  }
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_DATASUITE_SLIDE_DRAWER_CONTENTS' :
      return {
        ...state,
        widgetDrawer: {
          title: action.payload.title,
          replaceMode: action.payload.replaceMode,
          replaceIndex: action.payload.replaceIndex
        }
      }
    case 'SET_DATASUITE_DETAILS_SLIDE_DRAWER_CONTENTS' :
      return {
        ...state,
        widgetDetailsDrawer: {
          title: action.payload.title,
          drawerContents: action.payload.drawerContents
        }
      }
    case 'SET_DATASUITE_WIDGETS' :
      return {
        ...state,
        widgets: action.payload
      }
    case 'SET_DATASUITE_HIGHLIGHTS_TOPOPERATOR' :
      return {
        ...state,
        highlights: {
          ...state.highlights,
          topOperator: action.payload
        }
      }
    case 'SET_DATASUITE_HIGHLIGHTS_TRENDINGSALES' :
      return {
        ...state,
        highlights: {
          ...state.highlights,
          trendingSales: action.payload
        }
      }
    case 'SET_DATASUITE_HIGHLIGHTS_TRENDINGCATEGORY' :
      return {
        ...state,
        highlights: {
          ...state.highlights,
          trendingCategory: action.payload
        }
      }
    case 'SET_DATASUITE_HIGHLIGHTS_BIGGESTGROWTHOPPORTUNITY' :
      return {
        ...state,
        highlights: {
          ...state.highlights,
          biggestGrowthOpportunity: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_SALESBYOPERATOR' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          salesByOperatorData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_SALESBYOPERATOR_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          salesByOperatorData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_SALESBYCATEGORY' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          salesByCategoryData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_SALESBYCATEGORY_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          salesByCategoryData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_GROWTHOPPORTUNITY' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          growthOpportunityData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_GROWTHOPPORTUNITY_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          growthOpportunityData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_LEAKAGE' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          leakageData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_LEAKAGE_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          leakageData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_TARGETSBYCATEGORY' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          targetsByCategoryData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_TARGETSBYCATEGORY_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          targetsByCategoryData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_COMPETITIVEINSIGHTS' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          competitiveInsightsData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_COMPETITIVEINSIGHTS_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          competitiveInsightsData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_SALESBYDISTRIBUTOR' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          salesByDistributorData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_SALESBYDISTRIBUTOR_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          salesByDistributorData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYDISTRIBUTOR' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          opportunityByDistributorData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYDISTRIBUTOR_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          opportunityByDistributorData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYSTORE' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          opportunityByStoreData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYSTORE_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          opportunityByStoreData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_NEWLOCATIONS' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          newLocationsData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_NEWLOCATIONS_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          newLocationsData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_LOSTLOCATIONS' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          lostLocationsData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_LOSTLOCATIONS_DETAILS' :
    return {
      ...state,
      widgetDetailsData: {
        ...state.widgetDetailsData,
        lostLocationsData: action.payload
      }
    }
    case 'SET_DATASUITE_WIDGETS_SALESVOIDOPPORTUNITY' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          salesVoidData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_SALESVOIDOPPORTUNITY_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          salesVoidData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_TOPPRODUCTS' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          topProductsData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_TOPPRODUCTS_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          topProductsData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_TOPCATEGORIES' :
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          topCategoriesData: action.payload
        }
      }
    case 'SET_DATASUITE_WIDGETS_TOPCATEGORIES_DETAILS' :
      return {
        ...state,
        widgetDetailsData: {
          ...state.widgetDetailsData,
          topCategoriesData: action.payload
        }
      }
    case 'SET_DATASUITE_ALL_FILTERS' :
      return {
        ...state,
        allFilters: action.payload
      }
    case 'SET_DATASUITE_DATASETS_FILTER' :
      return {
        ...state,
        defaultDatasetsLoaded: true,
        selectedFilters: {
          ...state.selectedFilters,
          chainData: action.payload.chainData,
          gpoData: action.payload.gpoData
        }
      }
    case 'SET_DATASUITE_CATEGORIES_FILTER' :
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          categories: action.payload
        }
      }
    case 'SET_DATASUITE_DISTRIBUTORS_FILTER' :
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          distributors: action.payload
        }
      }
    case 'SET_DATASUITE_LOCATIONS_FILTER' :
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          locations: action.payload
        }
      }
    case 'SET_DATASUITE_SEGMENTS_FILTER' :
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          segments: action.payload
        }
      }
    case 'SET_DETAILS_DATASETS_FILTER' :
      return {
        ...state,
        defaultDatasetsLoaded: true,
        selectedDetailsFilters: {
          ...state.selectedDetailsFilters,
          chainData: action.payload.chainData,
          gpoData: action.payload.gpoData
        }
      }
    case 'SET_DETAILS_CATEGORIES_FILTER' :
      return {
        ...state,
        selectedDetailsFilters: {
          ...state.selectedDetailsFilters,
          categories: action.payload
        }
      }
    case 'SET_DETAILS_INCLUDEDSEARCH_FILTER' :
      return {
        ...state,
        selectedDetailsFilters: {
          ...state.selectedDetailsFilters,
          includedSearch: action.payload
        }
      }
    case 'SET_DETAILS_EXCLUDEDSEARCH_FILTER' :
      return {
        ...state,
        selectedDetailsFilters: {
          ...state.selectedDetailsFilters,
          excludedSearch: action.payload
        }
      }
    case 'SET_DETAILS_DISTRIBUTORS_FILTER' :
      return {
        ...state,
        selectedDetailsFilters: {
          ...state.selectedDetailsFilters,
          distributors: action.payload
        }
      }
    case 'SET_DETAILS_LOCATIONS_FILTER' :
      return {
        ...state,
        selectedDetailsFilters: {
          ...state.selectedDetailsFilters,
          locations: action.payload
        }
      }
    case 'SET_DETAILS_SEGMENTS_FILTER' :
      return {
        ...state,
        selectedDetailsFilters: {
          ...state.selectedDetailsFilters,
          segments: action.payload
        }
      }
    case 'SET_EXPORT_DATASETS_FILTER' :
      return {
        ...state,
        defaultDatasetsLoaded: true,
        exportDetailsFilters: {
          ...state.exportDetailsFilters,
          chainData: action.payload.chainData,
          gpoData: action.payload.gpoData
        }
      }
    case 'SET_EXPORT_CATEGORIES_FILTER' :
      return {
        ...state,
        exportDetailsFilters: {
          ...state.exportDetailsFilters,
          categories: action.payload
        }
      }
    case 'SET_EXPORT_DISTRIBUTORS_FILTER' :
      return {
        ...state,
        exportDetailsFilters: {
          ...state.exportDetailsFilters,
          distributors: action.payload
        }
      }
    case 'SET_EXPORT_LOCATIONS_FILTER' :
      return {
        ...state,
        exportDetailsFilters: {
          ...state.exportDetailsFilters,
          locations: action.payload
        }
      }
    case 'SET_EXPORT_SEGMENTS_FILTER' :
      return {
        ...state,
        exportDetailsFilters: {
          ...state.exportDetailsFilters,
          segments: action.payload
        }
      }
    case 'SET_COMP_EXPORT_DATASETS_FILTER' :
      return {
        ...state,
        defaultDatasetsLoaded: true,
        compExportDetailsFilters: {
          ...state.compExportDetailsFilters,
          chainData: action.payload.chainData,
          gpoData: action.payload.gpoData
        }
      }
    case 'SET_COMP_EXPORT_CATEGORIES_FILTER' :
      return {
        ...state,
        compExportDetailsFilters: {
          ...state.compExportDetailsFilters,
          categories: action.payload
        }
      }
    case 'SET_COMP_EXPORT_LOCATIONS_FILTER' :
      return {
        ...state,
        compExportDetailsFilters: {
          ...state.compExportDetailsFilters,
          locations: action.payload
        }
      }
    case 'SET_COMP_EXPORT_SEGMENTS_FILTER' :
      return {
        ...state,
        compExportDetailsFilters: {
          ...state.compExportDetailsFilters,
          segments: action.payload
        }
      }
    case 'SET_SALESBYOPERATOR_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          salesByOperatorLoading: action.payload
        }
      }
    case 'SET_SALESBYCATEGORY_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          salesByCategoryLoading: action.payload
        }
      }
    case 'SET_GROWTHOPPORTUNITY_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          growthOpportunityLoading: action.payload
        }
      }
    case 'SET_LEAKAGE_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          leakageLoading: action.payload
        }
      }
    case 'SET_TARGETSBYCATEGORY_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          targetsByCategoryLoading: action.payload
        }
      }
    case 'SET_COMPETITIVEINSIGHTS_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          competitiveInsightsLoading: action.payload
        }
      }
    case 'SET_SALESBYDISTRIBUTOR_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          salesByDistributorLoading: action.payload
        }
      }
    case 'SET_OPPORTUNITYBYDISTRIBUTOR_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          opportunityByDistributorLoading: action.payload
        }
      }
    case 'SET_OPPORTUNITYBYSTORE_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          opportunityByStoreLoading: action.payload
        }
      }
    case 'SET_NEWLOCATIONS_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          newLocationsLoading: action.payload
        }
      }
    case 'SET_LOSTLOCATIONS_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          lostLocationsLoading: action.payload
        }
      }
    case 'SET_SALESVOID_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          salesVoidLoading: action.payload
        }
      }
    case 'SET_TOPPRODUCTS_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          topProductsLoading: action.payload
        }
      }
    case 'SET_TOPCATEGORIES_ISLOADNG':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          topCategoriesLoading: action.payload
        }
      }
    case 'SET_ITEM_LEVEL_DESCRIPTION_DATA': 
      return {
        ...state,
        itemLevelDescriptionData: action.payload
      }
    case 'SET_ITEM_LEVEL_DESCRIPTION_LOADING':
      return {
        ...state,
        itemLevelDescriptionLoading: action.payload
      }
    case 'SET_ITEM_MATCH_DATA': 
      return {
        ...state,
        itemMatchData: action.payload
      }
    case 'SET_ITEM_MATCH_LOADING':
      return {
        ...state,
        itemMatchLoading: action.payload
      }
    case 'SET_DETAILS_PAGE_LOADING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          detailsPageLoading: action.payload
        }
      }
    case 'SET_SALESBYOPERATORDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          salesByOperatorLoading: action.payload
        }
      }
    case 'SET_SALESBYCATEGORYDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          salesByCategoryLoading: action.payload
        }
      }
    case 'SET_GROWTHOPPORTUNITYDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          growthOpportunityLoading: action.payload
        }
      }
    case 'SET_LEAKAGEDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          leakageLoading: action.payload
        }
      }
    case 'SET_TARGETSBYCATEGORYDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          targetsByCategoryLoading: action.payload
        }
      }
    case 'SET_COMPETITIVEINSIGHTSDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          competitiveInsightsLoading: action.payload
        }
      }
    case 'SET_SALESBYDISTRIBUTORDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          salesByDistributorLoading: action.payload
        }
      }
    case 'SET_OPPORTUNITYBYDISTRIBUTORDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          opportunityByDistributorLoading: action.payload
        }
      }
    case 'SET_OPPORTUNITYBYSTOREDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          opportunityByStoreLoading: action.payload
        }
      }
    case 'SET_NEWLOCATIONSDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          newLocationsLoading: action.payload
        }
      }
    case 'SET_LOSTLOCATIONSDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          lostLocationsLoading: action.payload
        }
      }
    case 'SET_SALESVOIDDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          salesVoidLoading: action.payload
        }
      }
    case 'SET_TOPPRODUCTSDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          topProductsLoading: action.payload
        }
      }
    case 'SET_TOPCATEGORIESDETAILS_ISLOADNG':
      return {
        ...state,
        detailsIsLoading: {
          ...state.detailsIsLoading,
          topCategoriesLoading: action.payload
        }
      }
    case 'CLEAR_DATASUITE_HIGHLIGHTS' :
      return {
        ...state,
        highlights: {
          topOperator: {},
          trendingSales: {},
          trendingCategory: {},
        },
        allFilters: [],
        selectedFilters: {
          chainData: false,
          gpoData: false,
          categories: [],
          distributors: [],
          locations: [],
          segments: []
        },
        selectedDetailsFilters: {
          chainData: false,
          gpoData: false,
          includedSearch: [],
          excludedSearch: [],
          categories: [],
          distributors: [],
          locations: [],
          segments: []
        },
        widgetData: {
          salesByOperatorData: [],
          salesByCategoryData: [],
          salesByDistributorData: [],
          opportunityByDistributorData: [],
          opportunityByStoreData: [],
          newLocationsData: [],
          lostLocationsData: [],
          salesVoidData: [],
          growthOpportunityData: [],
          leakageData: [],
          targetsByCategoryData: [],
          competitiveInsightsData: [],
          topProductsData: [],
          topCategoriesData: [],
        },
        widgetDetailsData: {
          salesByOperatorData: [],
          salesByCategoryData: [],
          salesByDistributorData: [],
          opportunityByDistributorData: [],
          opportunityByStoreData: [],
          newLocationsData: [],
          lostLocationsData: [],
          salesVoidData: [],
          growthOpportunityData: [],
          leakageData: [],
          targetsByCategoryData: [],
          competitiveInsightsData: [],
          topProductsData: [],
          topCategoriesData: [],
        },
        exportDetailsFilters: {
          chainData: false,
          gpoData: false,
          categories: [],
          distributors: [],
          locations: [],
          segments: []
        },
        compExportDetailsFilters: {
          chainData: false,
          gpoData: false,
          categories: [],
          locations: [],
          segments: []
        },
        itemLevelDescriptionData: [],
        itemMatchData: []
      }
    case 'CLEAR_DATASUITE' :
      return initialState;
    default:
      return state;
  };
};
