import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { Chart as ChartJS, registerables } from 'chart.js';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { FaCircle } from '../../../node_modules/react-icons/fa';
import { Tooltip } from '@mui/material';
import { IoMdInformationCircle } from 'react-icons/io';
import Title from '../../sharedComponents/Title/Title';
import useOpportunities from '../Opportunities/useOpportunities';
import getWelcomeName from '../../sharedComponents/WelcomeName/getWelcomeName';
import { Theme } from '../../config/constants';
import { BsFillGridFill } from 'react-icons/bs';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { HiOutlineMenu } from "react-icons/hi";
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import MobileMenuSlideDrawer from '../../sharedComponents/SlideDrawer/MobileMenuSlideDrawer';


ChartJS.register(...registerables);

const OpportunitiesTables = () => {
    const opportunitiesTableData = useSelector(({opportunities}) => opportunities.opportunitiesTableData);
    const isLoading = useSelector(({opportunities}) => opportunities.isLoading);
    const { getManufacturerAgreements } = useOpportunities.useOpportunities();
    const totalOppsTableLabels = ['Active Opportunity', 'Won', 'Won Pending Validation', 'Identified'];
    const [ totalOppsData, setTotalOppsData ] = useState([]);
    const [ oppsGeneratedData, setOppsGeneratedData ] = useState([]);
    const totalOppsTableColors = [Theme().colors.accent, 'rgba(0, 157, 154)', 'rgba(250, 144, 22)', 'rgba(49, 65, 117)'];
    const oppsGeneratedTableColors = ['rgba(192, 75, 123)', 'rgba(48, 62, 111)', 'rgba(127, 24, 189)', 'rgba(22, 186, 197)'];
    const oppsGeneratedTableLabels = ['Marketing Generated', 'Customer Request', 'Manufacturer Generated', 'Other'];
    const marketingGeneratedTooltip = "Includes: Custom Email Campaigns, Marketing Events, MFR Mailing Sample Campaigns, MFR Spotlights, Newsletters, Social Media, and Webinars";
    const customerGeneratedTooltip = "Includes: Platform Portal";
    const manufacturerGeneratedTooltip = "Includes: Manufacturer Portal and MFR Lead List";
    const otherGeneratedTooltip = "Includes: Client Manager Requests, Business Reviews, Comparisons, and Strategic MFR Targets";
    const oppsGeneratedTooltips = [marketingGeneratedTooltip, customerGeneratedTooltip, manufacturerGeneratedTooltip, otherGeneratedTooltip];
    var showWonOpps = [false, false, false, false, false];
    var mfrName = getWelcomeName() || 'Admin';

    for (var i = 0; i < opportunitiesTableData?.data?.topFiveOpportunitiesTable?.length; i++) {
        showWonOpps[i] = true;
    }

    const initializePage = () => {
        getManufacturerAgreements([]);
    }

    // eslint-disable-next-line
    useEffect(initializePage, []);

    // eslint-disable-next-line
    useEffect(() => {setupDataTables()}, [opportunitiesTableData]);
    
    const setupDataTables = () => {
        if(!isLoading && opportunitiesTableData?.data?.totalOpportunitiesTable && opportunitiesTableData?.data?.opportunitiesGeneratedTable) {
            setTotalOppsData([opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[0]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[1]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[2]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[3]]]);
            setOppsGeneratedData([opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[0]], opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[1]], opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[2]], opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[3]]])
            var totalOppsTableCanvas = document.getElementById("totalOppsTableCanvas");
            var totalOppsChart = ChartJS.getChart("totalOppsTableCanvas");
            var oppsGeneratedTableCanvas = document.getElementById("oppsGeneratedTableCanvas");
            var oppsGeneratedChart = ChartJS.getChart("oppsGeneratedTableCanvas");
            if(totalOppsChart !== undefined) {
                totalOppsChart.destroy();
            }
            // eslint-disable-next-line
            var totalOppsTable = new ChartJS( totalOppsTableCanvas ,{
                type: 'bar',
                data: {
                    labels: totalOppsTableLabels, 
                    datasets: [
                        {
                            data: [opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[0]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[1]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[2]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[3]]],
                            backgroundColor: totalOppsTableColors
                        }
                    ]
                },
                options: tableOptions
            });
            if(oppsGeneratedChart !== undefined) {
                oppsGeneratedChart.destroy();
            }
            // eslint-disable-next-line
            var oppsGeneratedTable = new ChartJS( oppsGeneratedTableCanvas ,{
                type: 'bar',
                data: {
                    labels: oppsGeneratedTableLabels, 
                    datasets: [
                        {
                            data: [opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[0]], opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[1]], opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[2]], opportunitiesTableData?.data?.opportunitiesGeneratedTable[oppsGeneratedTableLabels[3]]],
                            backgroundColor: oppsGeneratedTableColors
                        }
                    ]
                },
                options: tableOptions
            });
        }
    };

    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true,
              xAlign: 'center',
              yAlign: 'center'
          },
        },
    };
    const dispatch = useDispatch();
    const menuOpen = useSelector(({ui}) => ui.mobileMenuSlideDrawer);

    if(isLoading) {
        return (
            <Col className='ps-0'>
                <Col className=' d-flex justify-content-between align-items-end' >
                    {isMobile ? 
                   <Col className='ps-0 d-flex justify-content-between align-items-end' >
                   <div className={(isMobileOnly ? 'main-header text-size-24 mt-2':'main-header text-size-28 mt-2') } style={{ fontWeight: '700', letterSpacing: '.0025em', lineHeight: '28px', marginLeft: '-2px', marginRight: '-16px'}}>
                     <HiOutlineMenu
                       className='me-2'
                       onClick={() => dispatch(slideDrawer.addMobileMenu())}
                       isActive={menuOpen}
                       style={{
                         verticalAlign: 'middle',
                         marginBottom: '7px',
                         fontSize: '32px',
                       }}
                     />
                     <BsFillGridFill
                       className='me-1'
                       style={{
                         verticalAlign: 'middle',
                         marginBottom: '7px',
                         fill: 'url(#icon-gradient)',
                       }}
                     />
                     Campaign Management
                   </div>
                   <svg width='24px' height='24px'>
                     <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                       <stop stopColor={Theme().colors.gradientColorOne} offset="0%" />
                       <stop stopColor={Theme().colors.gradientColorTwo} offset="100%" />
                     </linearGradient>
                   </svg>
                   <MobileMenuSlideDrawer/>
                 </Col>
                    : 
                    <Col className='ps-0 d-flex justify-content-between align-items-end' >
                    <div className='main-header text-size-32 'style={{fontWeight: '700', letterSpacing:'.0025em', lineHeight: '38px'}}>
                    <BsFillGridFill
                        className='me-1'
                        style={{
                            verticalAlign: 'middle',
                            marginBottom: '4px',
                            fill: 'url(#icon-gradient)',
                            marginLeft: '5px'
                        }}
                        />
                    Campaign Management 
                    </div>    
                    <svg width= '26px' height='26px'>
                        <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop stopColor={Theme().colors.gradientColorTwo} offset="0%" />
                            <stop stopColor={Theme().colors.gradientColorOne} offset="100%" />
                        </linearGradient>
                    </svg>
                    <div className='main-header text-size-20 align-self-end' style={{marginRight:'5px', lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em' }}>Welcome, {mfrName}</div>
                </Col>
                    }
                
            </Col>
            <hr className='line' style={{marginLeft: '5px', marginRight:'5px', border: '1px solid grey'}}></hr>
                <Row className='card m-1' style={{minHeight: "340px", flexDirection: 'row'}} id='opportunityTableCardsRow'>
                    <Col className="col-xxl-6">
                        <div className='d-flex align-items-center px-0'>
                            <Title title='Total Opportunities' />
                            <Tooltip title='We show opportunities that have engagement. These may include opportunities driven by our internal team, email campaigns where the operator has engaged and other marketing campaigns. ' arrow>
                                <div className='pt-3 pb-2'>
                                <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </div>
                        <Row style={{width: '95%'}} className="mx-auto">
                            <div className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                                <Spinner color='beplBlue' animation="border" />
                            </div>
                        </Row>
                    </Col>
                    <Col className="col-xxl-6" style={{borderLeft: '#DFDFDF 1px solid'}}>
                        <Title title='Opportunities Generated' />
                        <Row style={{width: '95%'}} className="mx-auto">
                            <div className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                                <Spinner color='beplBlue' animation="border" />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    }
    else return (
        <Col className='ps-0'>
            <Col className=' d-flex justify-content-between align-items-end' >
                {isMobile ?
                    <Col className='ps-0 d-flex justify-content-between align-items-end' >
                        <div className={(isMobileOnly ? 'main-header text-size-24 mt-2' : 'main-header text-size-28 mt-2')} style={{ fontWeight: '700', letterSpacing: '.0025em', lineHeight: '28px', marginLeft: '-2px', marginRight: '-16px' }}>
                            <HiOutlineMenu
                                className='me-2'
                                onClick={() => dispatch(slideDrawer.addMobileMenu())}
                                isActive={menuOpen}
                                style={{
                                    verticalAlign: 'middle',
                                    marginBottom: '7px',
                                    fontSize: '32px',
                                }}
                            />
                            <BsFillGridFill
                                className='me-1'
                                style={{
                                    verticalAlign: 'middle',
                                    marginBottom: '7px',
                                    fill: 'url(#icon-gradient)',
                                }}
                            />
                            Campaign Management
                        </div>
                        <svg width='24px' height='24px'>
                            <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop stopColor={Theme().colors.gradientColorTwo} offset="0%" />
                                <stop stopColor={Theme().colors.gradientColorOne} offset="100%" />
                            </linearGradient>
                        </svg>
                        {isMobileOnly ?
                            <div></div>
                            :
                            <row className='main-header text-size-16 align-self-end' style={{marginRight:'3px', lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em', marginBottom: '7px'}}>Welcome, {mfrName}</row>
                        }
                        <MobileMenuSlideDrawer />
                    </Col>
                    : 
                    <Col className='ps-0 d-flex justify-content-between align-items-end' >
                    <div className='main-header text-size-32 'style={{fontWeight: '700', letterSpacing:'.0025em', lineHeight: '38px'}}>
                    <BsFillGridFill
                        className='me-1'
                        style={{
                            verticalAlign: 'middle',
                            marginBottom: '4px',
                            fill: 'url(#icon-gradient)',
                            marginLeft: '5px'
                        }}
                        />
                    Campaign Management 
                    </div>    
                    <svg width= '26px' height='26px'>
                        <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop stopColor={Theme().colors.gradientColorTwo} offset="0%" />
                            <stop stopColor={Theme().colors.gradientColorOne} offset="100%" />
                        </linearGradient>
                    </svg>
                    <div className='main-header text-size-20 align-self-end' style={{marginRight:'5px', lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em' }}>Welcome, {mfrName}</div>
                </Col>
                    }
                
            </Col>
            <hr className='line' style={{marginLeft: '5px', marginRight:'5px', border: '1px solid grey'}}></hr>

            <Row className='card m-1' style={{minHeight: "340px", flexDirection: 'row'}} id='opportunityTableCardsRow'>
                <Col className="col-xxl-6">
                    <div className='d-flex align-items-center px-0'>
                        <Title title='Total Opportunities' />
                        <Tooltip title='We show opportunities that have engagement. These may include opportunities driven by our internal team, email campaigns where the operator has engaged and other marketing campaigns. ' arrow>
                            <div className='pt-3 pb-2'>
                            <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                            </div>
                        </Tooltip>
                    </div>
                    <Row className="w-100 mb-3 mx-0" style={{minHeight: '260px'}}>
                        <Col className="col-12 col-xl-6 p-0">
                            <canvas id="totalOppsTableCanvas"/>
                        </Col>
                        <Col className="col-12 col-xl-6 text-size-14 p-0" style={{marginTop: '3%'}}>
                            <AutoSizer>
                            {({width, height}) => (
                                <Table className="table totalOpportunitiesTable border-grayLight" id="totalOpportunitiesTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={totalOppsData.length}
                                    rowGetter={({ index }) => totalOppsData[index]}
                                >
                                    <Column
                                    label='Opportunity Status'
                                    dataKey='totalOppsData'
                                    width={width * 0.7}
                                    cellRenderer={({cellData, rowIndex}) => (<span><FaCircle className='w-fc my-auto' style={{color: totalOppsTableColors[rowIndex%4]}}/> {totalOppsTableLabels[rowIndex%4]}</span>)}
                                    />
                                    <Column
                                    width={width * 0.3}
                                    label='Opportunities'
                                    dataKey='totalOppsData'
                                    cellRenderer={({cellData, rowIndex}) => (<span>{totalOppsData[rowIndex]}</span>)}
                                    />
                                </Table>
                            )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-xxl-6" style={{borderLeft: '#DFDFDF 1px solid'}}>
                    <Title title='Opportunities Generated' />
                    <Row className="w-100 mb-3 mx-0" style={{minHeight: '260px'}}>
                        <Col className="col-12 col-xl-6 p-0">
                            <canvas id="oppsGeneratedTableCanvas"/>
                        </Col>
                        <Col className="col-12 col-xl-6 text-size-14 p-0" style={{marginTop: '3%'}}>
                            <AutoSizer>
                            {({width, height}) => (
                                <Table className="table totalOpportunitiesTable border-grayLight" id="totalOpportunitiesTable"
                                    width={width}
                                    height={height}
                                    headerHeight={34.5}
                                    rowHeight={38}
                                    rowCount={oppsGeneratedData.length}
                                    rowGetter={({ index }) => oppsGeneratedData[index]}
                                >
                                    <Column
                                    label='Opportunity Type'
                                    dataKey='totalOppsData'
                                    width={width * 0.7}
                                    cellRenderer={({cellData, rowIndex}) => (<Tooltip title={oppsGeneratedTooltips[rowIndex%4]} placement="top" arrow><span><FaCircle className='w-fc my-auto' style={{color: oppsGeneratedTableColors[rowIndex%4]}}/><IoMdInformationCircle style={{fontSize: "16px"}} /> {oppsGeneratedTableLabels[rowIndex%4]}</span></Tooltip>)}
                                    />
                                    <Column
                                    width={width * 0.3}
                                    label='Opportunities'
                                    dataKey='totalOppsData'
                                    cellRenderer={({cellData, rowIndex}) => (<span>{oppsGeneratedData[rowIndex]}</span>)}
                                    />
                                </Table>
                            )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
    
};

export default OpportunitiesTables;
