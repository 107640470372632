import moment from 'moment';
import React from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { Theme } from '../../config/constants';

const formatTimeDifference = (created_at) => {
    if (!created_at || !moment(created_at).isValid()) return 'Unknown time';
    const currentDateTime = moment();
    const notificationDateTime = moment(created_at);
    const timeDifference = currentDateTime.diff(notificationDateTime, 'seconds');
    
    if (timeDifference < 60) return `${timeDifference}s ago`;
    if (timeDifference < 3600) return `${Math.floor(timeDifference / 60)}m ago`;
    if (timeDifference < 86400) return `${Math.floor(timeDifference / 3600)}h ago`;
    if (timeDifference < 172800) return 'Yesterday';
    if (timeDifference < 604800) return `${Math.floor(timeDifference / 86400)}d ago`;
    if (timeDifference < 1209600) return 'Last week';
    if (timeDifference < 2419200) return `${Math.floor(timeDifference / 604800)} weeks ago`;
    if (timeDifference < 4838400) return 'Last month';
    return moment(created_at).format('DD/MM/YYYY');
};

const Notifications = () => {
    const notificationsList = useSelector(({ user }) => user.notifications) || [];

    const notificationsMap = notificationsList.map((notification) => {
        const created_at = notification.created_at ?? null;
        const body = notification.body ?? 'No message available';
        const timeDifferenceString = formatTimeDifference(created_at);

        return (
            <Row key={notification.id} className='py-3' style={{ borderBottom: '1px solid lightgray' }}>
                <Col className='w-fc' style={{ paddingTop: '2px' }}>
                    <BsCircleFill className='text-size-12' color='#49A4DA' />
                </Col>
                <Col>
                    <Row className='text-size-14 py-1' style={{ fontWeight: '600' }}>
                        {body}
                    </Row>
                    <Row className='text-size-12'>
                        {timeDifferenceString}
                    </Row>
                </Col>
                <Col className='w-fc py-1'>
                    <Button
                        outline
                        block
                        className='button-label'
                        size='sm'
                        color={Theme().colors.accentName}
                        style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                        View
                    </Button>
                </Col>
            </Row>
        );
    });

    return (
        <Row className='w-100 h-100 mx-0 px-0'>
            <Col className='w-100 h-100'>
                {notificationsMap}
            </Col>
        </Row>
    );
};

export default Notifications;