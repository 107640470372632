import React from 'react';
import { useSelector } from 'react-redux';
import useOpportunities from './useOpportunities';

const OpportunitiesRowMobile = () => {
    const opportunitiesList = useSelector(({opportunities}) => opportunities.opportunitiesItems);
    const { mapOpportunitiesMobile } = useOpportunities.useOpportunities();
    const opportunitiesMap = mapOpportunitiesMobile(opportunitiesList);

    if(opportunitiesList?.data) {
        return (
            <tbody>
                {opportunitiesMap}
            </tbody>
    );
    }
    else return (
        <tbody></tbody>
    );
    
};

export default OpportunitiesRowMobile;