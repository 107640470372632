import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TiArrowUnsorted } from 'react-icons/ti';
import { dataLoading } from '../../reducers/OpportunitiesReducer/OpportunitiesActions';
import { Row, Card, Spinner, Button, Col } from 'reactstrap';
import OpportunitiesRow from '../Opportunities/OpportunitiesRow';
import useOpportunities from '../Opportunities/useOpportunities';
import { isMobile, isTablet } from 'react-device-detect';
import OpportunitiesRowMobile from '../Opportunities/OpportunitiesRowMobile';
import { useEffect } from 'react';
import MarketingCampaignsFiltersCard from './MarketingCampaignsFiltersCard';
import { Theme } from '../../config/constants';

const MarketingCampaignsStatus = () => {
    const dispatch = useDispatch;
    dispatch(dataLoading);
    const isLoading = useSelector(({opportunities}) => opportunities.isLoading);
    const opportunitiesList = useSelector(({opportunities}) => opportunities.opportunitiesItems);
    const opportunitiesFilters = useSelector(({opportunities}) => opportunities.opportunitiesFilterData);
    const { onClickClientManager, onClickMfrGrowthSpecialist, onClickOpportunityStatus, onClickCustomer, onClickDateCreated, handleClickSeeCompInsights, onClickContactAcctManager, getManufacturerAgreements } = useOpportunities.useOpportunities();

    const initializePage = () => {
        getManufacturerAgreements([]);
    };

    // eslint-disable-next-line
    useEffect(initializePage, []);

    const oppsFiltersLength = () => {
        var numberOfFilters = 0;
        if(opportunitiesFilters?.data){
            if(opportunitiesFilters.data.oppSourceStrings?.length > 0) {
                numberOfFilters = numberOfFilters + opportunitiesFilters.data.oppSourceStrings.length;
            }
            if(opportunitiesFilters.data.status?.length > 0) {
                numberOfFilters = numberOfFilters + opportunitiesFilters.data.status.length;
            }
        }
        return numberOfFilters;
    };

    const filterLength = oppsFiltersLength();

    if (isLoading) {
        return (
            <Col className='mx-3'>
                <Row>
                    <MarketingCampaignsFiltersCard />
                </Row>
                <Row style={{overflowY: 'auto'}}>
                    <table className="table opportunitiesTable border-grayLight" id="opportunitiesTable">
                        <thead>
                        {(isMobile && !isTablet) &&
                            <tr>
                            <th className="clickable" scope="col" onClick={() => onClickCustomer()}>CUSTOMER <TiArrowUnsorted /></th>
                            <th className="clickable" scope="col" onClick={() => onClickOpportunityStatus()}>STATUS <TiArrowUnsorted /></th>
                            <th scope="col"></th>
                            </tr>
                        }
                        {!(isMobile && !isTablet) &&
                            <tr>
                                <th className="clickable" scope="col" onClick={() => onClickCustomer()}>CUSTOMER <TiArrowUnsorted /></th>
                                <th className="clickable" scope="col" onClick={() => onClickMfrGrowthSpecialist()}>MFR GROWTH SPECIALIST <TiArrowUnsorted /></th>
                                <th className="clickable" scope="col" onClick={() => onClickClientManager()}>CLIENT MANAGER <TiArrowUnsorted /></th>
                                <th scope="col">OPPORTUNITY SOURCE</th>
                                <th className="clickable" scope="col" onClick={() => onClickDateCreated()}>CREATION DATE <TiArrowUnsorted /></th>
                                <th className="clickable" scope="col" onClick={() => onClickOpportunityStatus()}>OPPORTUNITY STATUS <TiArrowUnsorted /></th>
                                <th scope="col"></th>
                            </tr>
                        }
                        </thead>
                    </table>
                </Row>
                <Row>
                    <div className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                        <Spinner color='beplBlue' animation="border" />
                    </div>
                </Row>
            </Col>
        );
    }
    if(!isLoading && opportunitiesList?.data?.length === 0 && filterLength === 0) {
        return (
                <Col className="mx-auto mt-5 w-100 h-100">
                    <Row>
                        <MarketingCampaignsFiltersCard />
                    </Row>
                    <Row className="mx-auto my-3 w-fc">
                        <div className="text-beplBlue main-text-header text-size-24" style={{fontWeight: '600'}}>No Opportunities to Display</div>
                    </Row>
                    <Row className="d-flex justify-content-center" style={{height: '40vh'}}>
                        <Col className="col-4">
                            <Card className="h-100">
                                <Row className="w-fc mx-auto mt-5">
                                    <div className="text-beplBlue main-text-header text-size-24" style={{fontWeight: '600', textAlign: 'center'}}>Add Competitive Purchases Opportunities</div>
                                </Row>
                                <Row className="w-fc mx-auto my-auto">
                                    <div className="text-beplBlue main-text-header text-size-16" style={{textAlign: 'center'}}>To add opportunities, view competitive purchases and click learn more.</div>
                                </Row>
                                <Row className="w-fc mx-auto mb-5">
                                    <Button 
                                        block 
                                        className='py-2 button-label' 
                                        size='sm' 
                                        onClick = {() => handleClickSeeCompInsights()}
                                        color={Theme().colors.accentName}>VIEW COMPETITIVE PURCHASES
                                    </Button>
                                </Row>
                            </Card>
                        </Col>
                        <Col className="col-4">
                            <Card className="h-100">
                            <Row className="w-fc mx-auto mt-5">
                                    <div className="text-beplBlue main-text-header text-size-24" style={{fontWeight: '600', textAlign: 'center'}}>Connect with your Account Manager</div>
                                </Row>
                                <Row className="w-fc mx-4 my-auto">
                                    <div className="text-beplBlue main-text-header text-size-16" style={{textAlign: 'center'}}>There are plenty of ways to generate opportunities with BEP operators! Connect with your Account Manager to learn more.</div>
                                </Row>
                                <Row className="w-fc mx-auto mb-5">
                                    <Button 
                                        block 
                                        className='py-2 button-label' 
                                        size='sm' 
                                        onClick={() => onClickContactAcctManager()}
                                        color={Theme().colors.accentName}>EXPLORE OPPORTUNITIES WITH ACCOUNT MANAGER
                                    </Button>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
        )
    }
    return (
        <Col className='mx-3' style={{height:'100%'}}>
            <Row>
                <MarketingCampaignsFiltersCard />
            </Row>
            <Row style={{overflowY: 'auto', height:'84%'}}>
                <table className="table opportunitiesTable border-grayLight" id="opportunitiesTable">
                    <thead>
                        {(isMobile && !isTablet) &&
                            <tr>
                            <th className="clickable" scope="col" onClick={() => onClickCustomer()}>CUSTOMER <TiArrowUnsorted /></th>
                            <th className="clickable" scope="col" onClick={() => onClickOpportunityStatus()}>STATUS <TiArrowUnsorted /></th>
                            <th scope="col"></th>
                            </tr>
                        }
                        {!(isMobile && !isTablet) &&
                            <tr>
                                <th className="clickable" scope="col" onClick={() => onClickCustomer()}>CUSTOMER <TiArrowUnsorted /></th>
                                <th className="clickable" scope="col" onClick={() => onClickMfrGrowthSpecialist()}>MFR GROWTH SPECIALIST <TiArrowUnsorted /></th>
                                <th className="clickable" scope="col" onClick={() => onClickClientManager()}>CLIENT MANAGER <TiArrowUnsorted /></th>
                                <th scope="col">OPPORTUNITY SOURCE</th>
                                <th className="clickable" scope="col" onClick={() => onClickDateCreated()}>CREATION DATE <TiArrowUnsorted /></th>
                                <th className="clickable" scope="col" onClick={() => onClickOpportunityStatus()}>OPPORTUNITY STATUS <TiArrowUnsorted /></th>
                                <th scope="col"></th>
                            </tr>
                        }
                    </thead>
                    {(isMobile && !isTablet) &&
                        <OpportunitiesRowMobile />
                    }
                    {!(isMobile && !isTablet) &&
                        <OpportunitiesRow />
                    }
                </table>
                <div className="text-size-16 mx-auto" style={{display: (opportunitiesList?.data?.length === 0 ? "block" : "none"), textAlign:"center", color: Theme().colors.accent}}>No opportunities to display.</div>
            </Row>
        </Col>
    );
}

export default MarketingCampaignsStatus;