import Axios from "axios";

const url = '/distributor';

const dashboardApi = {
  get: () => {
    return Axios.get(`${url}`);
  },
  downloadCsv: {
    get: (id) => {
        return Axios.get(`${url + "/" + id}`);
    }
  }
};

export default dashboardApi;