import React, { useState } from 'react';
import { Row, Col, Input, Button, FormText, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import useLoginForm from './useLoginForm';
import { useDispatch } from 'react-redux';
import { If } from 'react-if';
import authApi from '../../api/authApi';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import useLogin from '../../sharedComponents/useLogin/useLogin';
import { spinner } from '../../reducers/UIReducer/UIActions';
import { FullStory } from '@fullstory/browser';
import { v4 as uuidv4 } from 'uuid';
import { withMsal } from '@azure/msal-react';
import useMsal from '../../sharedComponents/useMsal/useMsal';
import { BsMicrosoft } from 'react-icons/bs';
import { redirect } from 'react-router-dom/dist';


const LoginForm = (props) => {
  const { email, password, validate } = useLoginForm();
  const [apiError, setApiError] = useState('');
  const { login } = useLogin();
  const dispatch = useDispatch();
  const { loginPopup } = useMsal();

  const onLogin = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      const params = {
        email: email.value,
        password: password.value
      };
      dispatch(spinner.add());
      authApi.login.post(params).then(({data}) => {
        dispatch(spinner.subtract());
        login(data);
        const uuid = uuidv4();
        FullStory.identify(uuid, {displayName: data.user.first_name + ' ' + data.user.last_name, email: data.user.email});
      }).catch(err => {
        console.log(err);
        dispatch(spinner.subtract());
        let msg = `Something went wrong`;
        if (err) {
          if (err.data && err.data.message) {
            msg = err.data.message;
          }
        }
        const modalProps = {
          title: 'Log In Error',
          fontSize: '16px',
          message: 'Email or password does not match or account is not active',
          FiAlignJustify: 'center',
          buttonColor: 'beplMagenta',
          buttonText: 'Close',

          buttonClick: () => {
            redirect('/login');
          }                        
        };                
        dispatch(showModal(AlertModal, modalProps));
        setApiError(msg);
      });
    }
  };

  const goToSignUp = () => {
    redirect('/signup');
  };

  const goToResendActivationEmail = () => {
    redirect('/resend-activation-email');
  };

  const goToForgotPassword = () => {
    redirect('/forgot-password');
  };

  return (
    <Form className="text-size-14" onSubmit={onLogin}>
      <If condition={props.activateMsg}>
        <FormGroup>
          <FormText color='primary'>Account Already being Activated. Please Login.</FormText>
        </FormGroup>
      </If>
      <FormGroup className='field' style={{minHeight: '60px'}}>
        <Input {...email} placeholder='Email' type='text' className='form-field' />
        <Label className='label-text-header form-label login-label' for='email' style={{fontSize: '14px'}}>Email</Label>
        <FormFeedback  style={{marginTop: '-10px'}}>{email.message}</FormFeedback>
      </FormGroup>
      <FormGroup className='field' style={{minHeight: '60px'}}>
        <Input {...password} type='password' className='form-field' placeholder='Password'/>
        <Label className='label-text-header form-label login-label' for='password'style={{fontSize: '14px'}}>Password</Label>
        <FormFeedback style={{marginTop: '-10px'}}>{password.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Button type='submit' block color='beplMagenta' className='mx-auto col-6 mb-2 button-label'>Log In</Button>
      </FormGroup>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <FormGroup className='mb-3'>
        <Col onClick={goToForgotPassword} className='text-beplMagenta form-label text-center clickable'>Forgot Password?</Col>
        <Col onClick={goToResendActivationEmail} className='text-beplMagenta form-label text-center clickable'>Resend Activation Email</Col>
      </FormGroup>
      <Row className='my-2'>
        <Col className='my-auto' style={{height: '2px', background: 'darkGray'}}></Col>
        <Col className='col-2 form-label text-grayDark text-size-14 text-center p-1 m-0'>OR</Col>
        <Col className='my-auto' style={{height: '2px', background: 'darkGray'}}></Col>
      </Row>
      <FormGroup className='mt-1 mb-1'>
        <Button block color='blue' onClick={() => loginPopup(props.msalContext.instance, false)} className='mx-auto col-12 mt-2 mb-3 button-label'><BsMicrosoft className='mx-2 my-auto'/>Log In with Microsoft</Button>
        <Col className='form-label text-center text-grayDark my-2'>Don't have an account? <span onClick={goToSignUp} className='text-beplMagenta form-label text-center clickable'>Sign Up</span></Col>
      </FormGroup>
    </Form>
  );
};

export default withMsal(LoginForm);