import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Logout from './Logout/Logout';
import Help from './Help/Help';
import MarketingCampaigns from './MarketingCampaigns/MarketingCampaigns';
import MarketingCampaignsDetails from './MarketingCampaigns/MarketingCampaignDetails/MarketingCampaignsDetails';
import DataSuite from './DataSuite/DataSuite';
import DataSuiteDetails from './DataSuite/DataSuiteDetails';
import GrowthOpportunities from './DataSuite/GrowthOpportunities';
import { toast } from 'react-toastify';

const AppMainRouter = () => {
  const chainData = useSelector(({user}) => user.chainData);
  const emergingData = useSelector(({user}) => user.emergingData);
  const dashboard = useSelector(({user}) => user.dashboard);
  const isArrowstream = localStorage.getItem('branding') === 'ARROWSTREAM';
  const isBEP = localStorage.getItem('branding') === 'BEP';
  const [dashboardAccess, setDashboardAccess] = useState(false);
  const [salesStreamAccess, setSalesStreamAccess] = useState(false);

  useEffect(() => {
    if (dashboard) {
      setDashboardAccess(true);
    }
    if (chainData || emergingData) {
      setSalesStreamAccess(true);
    }
    if (!dashboard && (!chainData || !emergingData)) {
      toast.error('You do not have access to this portal.');
    }
  },[chainData, emergingData, dashboard])

  if (dashboardAccess && salesStreamAccess) {
    return (
      <Routes>
        {isBEP && <Route exact path='/dashboard' element={<Dashboard />} />}
        <Route exact path='/logout' element={<Logout />} />
        <Route exact path='/help' element={<Help />} />
        {isBEP && <Route exact path='/campaignmanagement' element={<MarketingCampaigns />} />}
        {isBEP && <Route path='/campaignmanagement/:id' element={<MarketingCampaignsDetails />} />}
        {isArrowstream && <Route exact path='/salesstream' element={<DataSuite />} />}
        {isArrowstream && <Route exact path='/salesstream/details' element={<DataSuiteDetails />} />}
        {isArrowstream && <Route exact path='/growthopportunities' element={<GrowthOpportunities />} />}
        {isArrowstream && <Route path="*" element={<Navigate to='/salesstream' replace={true} />} />}
        {isBEP && <Route path="*" element={<Navigate to='/dashboard' replace={true} />} />}
      </Routes>
    )
  } 

  else if (dashboardAccess && !salesStreamAccess) {
    return (
      <Routes>
        {isBEP && <Route exact path='/dashboard' element={<Dashboard />} />}
        <Route exact path='/logout' element={<Logout />} />
        <Route exact path='/help' element={<Help />} />
        {isBEP && <Route exact path='/campaignmanagement' element={<MarketingCampaigns />} />}
        {isBEP && <Route path='/campaignmanagement/:id' element={<MarketingCampaignsDetails />} />}
        {isBEP && <Route path="*" element={<Navigate to='/dashboard' replace={true} />} />}
      </Routes>
    )
  }

  else if (!dashboardAccess && salesStreamAccess) {
    return (
      <Routes>
        <Route exact path='/logout' element={<Logout />} />
        <Route exact path='/help' element={<Help />} />
        {isArrowstream && <Route exact path='/salesstream' element={<DataSuite />} />}
        {isArrowstream && <Route exact path='/salesstream/details' element={<DataSuiteDetails />} />}
        {isArrowstream && <Route exact path='/growthopportunities' element={<GrowthOpportunities />} />}
        {isArrowstream && <Route path="*" element={<Navigate to='/salesstream' replace={true} />} />}
      </Routes>
    )
  }

  else {
    return (
      <Routes>
        <Route exact path='/logout' element={<Logout />} />
      </Routes>
    )
  }
};

export default AppMainRouter;