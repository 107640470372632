import React, { useEffect } from 'react';
import { Row, Col, Button, Card } from 'reactstrap';
import SlideDrawer from '../../sharedComponents/SlideDrawer/SlideDrawer';
import useDataSuite from './useDataSuite';
import { useSelector } from 'react-redux';
import { HiViewGridAdd } from 'react-icons/hi';
import FiltersCard from './DataSuiteCards/FiltersCard';
import GrowthOpportunityWidget from './Widgets/GrowthOpportunityWidget';
import { FaTrash } from 'react-icons/fa';
import CompetitiveInsightsWidget from './Widgets/CompetitiveInsightsWidget';
import TargetsByCategoryWidget from './Widgets/TargetsByCategoryWidget';
import LeakageWidget from './Widgets/LeakageWidget';
import OpportunityByDistributorWidget from './Widgets/OpportunityByDistributorWidget';
import OpportunityByStoreWidget from './Widgets/OpportunityByStoreWidget';
import SalesVoidOpportunityWidget from './Widgets/SalesVoidOpportunityWidget';
import LostLocationsWidget from './Widgets/LostLocationsWidget';
import { Theme } from '../../config/constants';
import { BsFillBarChartFill } from 'react-icons/bs';
import DataSuiteDetailsSlideDrawer from '../../sharedComponents/SlideDrawer/DataSuiteDetailsSlideDrawer';
import getWelcomeName from '../../sharedComponents/WelcomeName/getWelcomeName';
import GrowthOpportunitiesHighlightsCard from './DataSuiteCards/GrowthOpportunitiesHighlightsCard';

const GrowthOpportunities = () => {
  const widgetDrawer = useSelector(({datasuite}) => datasuite.widgetDrawer);
  const widgets = useSelector(({datasuite}) => datasuite.widgets);
  const selectedWidgets = useSelector(({user}) => user.dataSuiteWidgets);
  var mfrName = getWelcomeName() || 'Admin';

  const { 
    getWidgetCatalog, 
    getUserWidgetList, 
    onClickWidgetDrawerOpen,
    onClickAddWidget,
    onClickReplaceWidget,
    onClickDeleteWidget
  } = useDataSuite.useWidgetControls();

  useEffect(() => {
    getWidgetCatalog();
    getUserWidgetList();
  // eslint-disable-next-line
  }, []);

  // TODO: add actual widgets when they're ready
  const widgetElements = selectedWidgets?.filter(widget => widget.page === 2).map((w, i) => {
    switch (w.name) {
      case ("Growth Opportunities"):
        {return (
        <Card className='col-6' key={`widget-container-${w.id}`}>
          <GrowthOpportunityWidget w={w} i={i}/>
        </Card>);}
      case ("Leakage"):
        {return (
        <Card className='col-6' key={`widget-container-${w.id}`}>
            <LeakageWidget w={w} i={i}/>
        </Card>);}
      case ("Competitive Insights"):
        {return (
        <Card className='col-6' key={`widget-container-${w.id}`}>
          <CompetitiveInsightsWidget w={w} i={i}/>
        </Card>);}
      case ("Targets By Category"):
        {return (
        <Card key={`widget-container-${w.id}`}>
          <TargetsByCategoryWidget w={w} i={i}/>
        </Card>);}
      case ("Opportunity By Distributor"):
        {return (
        <Card className='col-6' key={`widget-container-${w.id}`}>
            <OpportunityByDistributorWidget w={w} i={i}/>
        </Card>);}
      case ("Opportunity By Store"):
        {return (
        <Card className='col-6' key={`widget-container-${w.id}`}>
            <OpportunityByStoreWidget w={w} i={i}/>
        </Card>);}
      case ("Sales Void Opportunity"):
        {return (
        <Card className='col-6' key={`widget-container-${w.id}`}>
            <SalesVoidOpportunityWidget w={w} i={i}/>
        </Card>);}
      case ("Lost Locations"):
        {return (
        <Card className='col-6' key={`widget-container-${w.id}`}>
            <LostLocationsWidget w={w} i={i}/>
        </Card>);}
      default: {
        return (
        <Card key={`widget-container-${w.id}`}>
          <div>Unrecognized widget.
          <Button
              block 
              className='py-1 button-label deleteWidgetButton' 
              size='sm' 
              color='redLight'
              onClick={() => onClickDeleteWidget(w)}>
              <FaTrash color='white' />
          </Button>
          </div>
        </Card>);
      }
    }
  });

  // find all widgets that are not in 'selectedWidgets'
  const unselectedWidgets = widgets.filter((w) => {
    return selectedWidgets ? selectedWidgets.findIndex((sel) => sel.id === w.id) === -1 : false;
  });

  const widgetDrawerElements = unselectedWidgets?.filter(widget => widget.page === 2).map((w) => {
    return (
        <Card className='m-2' key={`widget-preview-${w.id}`}>
        <Row className='p-2'>
            <Col className='d-flex align-items-center text-black main-header text-size-20'>
            {w.name}
            </Col>
            <Col>
            <Button 
                className='button-label'
                onClick={() => widgetDrawer.replaceMode ? onClickReplaceWidget(w, widgetDrawer.replaceIndex) : onClickAddWidget(w)}
                block
                color={Theme().colors.accentName}
                size='sm'
            >
                <HiViewGridAdd /> {widgetDrawer.replaceMode ? 'Replace Widget' : 'Add Widget'}
            </Button>
            </Col>
        </Row>
        <Row className='p-2'>
            <Col>
            <img src={w.path} alt='Widget Preview' />
            </Col>
        </Row>
        </Card>
    );
 });

  const widgetDrawerContainer = <div style={{overflowY: 'auto', height: '95%'}}>{widgetDrawerElements?.length > 0 ? widgetDrawerElements : (<div className='mt-3' style={{width: '40vw', textAlign: 'center'}}>No more widgets to add.</div>)}</div>;

  return (
    <Col className='px-3 py-3'>
      <Col className='d-flex justify-content-between align-items-end' >
        <div className='main-header text-size-32 ' style={{fontWeight: '700', letterSpacing:'.0025em', lineHeight: '38px'}}>
          <BsFillBarChartFill
              className='me-1'
              style={{
                verticalAlign: 'middle',
                marginBottom: '4px',
                fill: 'url(#icon-gradient)' 
              }}
            />
          SalesStream 
        </div>    
        <svg width= '26px' height='26px'>
          <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop stopColor={Theme().colors.gradientColorTwo} offset="0%" />
            <stop stopColor={Theme().colors.gradientColorOne} offset="100%" />
          </linearGradient>
        </svg>
        <div className='main-header text-size-20 align-self-end' style={{lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em' }}>Welcome, {mfrName}</div>
      </Col>
      <div className='main-header text-size-20 align-self-end' style={{lineHeight:'23px', fontWeight:'400',letterSpacing:'.0025em' }}>Growth Opportunities</div>
      <hr className='line'></hr>
      <Row className='label-text text-size-14 d-flex align-items-center mx-auto pb-2'>
        <Col className='ps-0'>
          <div className='main-header text-size-20' style={{lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em' }}>Today's Highlights</div>
        </Col>
      </Row>
      <Row xs={1} md={1} lg={1} xl={1} className='label-text text-size-14 d-flex align-items-center card-row g-2'>
        <Card className='px-3'>
          <GrowthOpportunitiesHighlightsCard />
        </Card>
      </Row>
      <Row className='label-text text-size-14 d-flex align-items-center mx-auto pb-2'>
        <Col className='ps-0'>
          <div className='main-header text-size-24'>Data Insights</div>
        </Col>
      </Row>
      <Row xs={1} md={1} lg={1} xl={1} className='label-text text-size-14 d-flex align-items-center card-row g-2'>
        <Card className='px-0' id="dataSuiteFiltersCard" style={{height: '120px'}}>
          <FiltersCard />
        </Card>
      </Row>
      <Row className='px-2'>
        {widgetElements}
      </Row>
      <Row>
        <Col className='w-100 px-0'>
          <Button 
            className='my-2 py-2 button-label text-size-20' 
            style={{width: '100%', color: '#49A4DA', border: '2px dashed #49A4DA', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)', borderRadius: '8px'}}
            onClick={() => onClickWidgetDrawerOpen("Add Widget")}
            block 
            size='sm' 
          >
            <Row>
              <Col><HiViewGridAdd /></Col>
            </Row>
            <Row>
              <Col>Add Widgets</Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <SlideDrawer title={widgetDrawer.title} className="dataSuiteSlideDrawer" drawerContents={widgetDrawerContainer} />
      <DataSuiteDetailsSlideDrawer title={"N/A"} className="dataSuiteDetailsSlideDrawer" drawerContents={""}/>
    </Col>
  )
}

export default GrowthOpportunities;
