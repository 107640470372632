import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import HeaderLogo from '../../sharedComponents/Header/HeaderLogo';
import SignUpForm from './SignUpForm';

const SignUp = () => {
  const logo = localStorage.getItem('branding') === "ARROWSTREAM" ? './arrowstreamLogoWhite.png' : process.env.REACT_APP_COMPANY_LOGO;

  return (
    <Fragment>
      <HeaderLogo logo={logo} />
      <Container fluid className='flex-grow-1'>
        <Row className='justify-content-center' >
          <Col style={{maxWidth: '400px'}}>
            <Row className='py-5'>
              <Col className='text-center text-size-36 main-header'>Create Account</Col>
            </Row>
            <SignUpForm />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SignUp;