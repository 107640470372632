import React, { useEffect } from 'react';
import Container from 'reactstrap/lib/Container';
import { useDispatch } from 'react-redux';
import { clearUserData } from '../../reducers/UserReducer/UserActions';
import { clearCompetitiveInsightsData } from '../../reducers/CompetitiveInsightsReducer/CompetitiveInsightsActions';
import { clearOpportunititesData } from '../../reducers/OpportunitiesReducer/OpportunitiesActions';
import { clearMarketingCampaignsData } from '../../reducers/MarketingCampaignsReducer/MarketingCampaignsActions';
import { clearDataSuiteHighlights , clearDataSuite} from '../../reducers/DatasuiteReducer/DataSuiteActions';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearUserData());
    localStorage.clear();
    dispatch(slideDrawer.subtract());
    dispatch(slideDrawer.subtractDatasuiteDetails());
    dispatch(slideDrawer.subtractProfile());
    dispatch(slideDrawer.subtractComp());
    dispatch(slideDrawer.subtractHelp());
    dispatch(slideDrawer.subtractMfr());
    dispatch(slideDrawer.subtractOpp());
    dispatch(slideDrawer.subtractMobileMenu());
    dispatch(clearUserData());
    dispatch(clearCompetitiveInsightsData());
    dispatch(clearOpportunititesData());
    dispatch(clearMarketingCampaignsData());
    dispatch(clearDataSuiteHighlights());
    dispatch(clearDataSuite());
    navigate('/login');
    window.location.reload();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      Logging out...
    </Container>
  );
};

export default Logout;