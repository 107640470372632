import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Theme } from '../../config/constants';

const AgreementStatusIndicator = ({...props}) => {
  const status = props.status;
  switch (status){
    case 'Lost':
        return(
            <div><FaCircle color='#CF3F3F'/><span style={{'color':'#CF3F3F'}}> Lost</span></div>
        )
    case 'Closed Lost':
        return(
            <div><FaCircle color='#CF3F3F'/><span style={{'color':'#CF3F3F'}}> Closed Lost</span></div>
        )
    case 'Won':
        return(
            <div><FaCircle color='#009D9A'/><span style={{'color':'#009D9A'}}> Won</span></div>
        )
    case 'Won Pending Validation':
        return(
            <div><FaCircle color='#FA9016'/><span style={{'color':'#FA9016'}}> Won Pending Validation</span></div>
        )
    case 'Closed Pending':
        return(
            <div><FaCircle color='#DDC734'/><span style={{'color':'#DDC734'}}> Closed Pending</span></div>
        )
    case 'Active Opportunity':
        return(
            <div><FaCircle style={{color: Theme().colors.accent}}/><span style={{color: Theme().colors.accent}}> Active Opportunity</span></div>
        )
    case 'Identified':
        return(
            <div><FaCircle className="text-beplBlue" /><span className="text-beplBlue"> Opportunity Identified</span></div>
        )
    case 'Pending':
        return(
            <div><FaCircle color='#DDC734'/><span style={{'color':'#DDC734'}}> Pending</span></div>
        )
    default:
        return (
            <div>{status}</div>
        );
   }; 
};


export default AgreementStatusIndicator;