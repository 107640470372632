import React from "react";
import { Col, Row, Container, Card } from "reactstrap";
import { useSelector } from "react-redux";
import useDashboard from "../useDashboard";
import TopCategoriesTable from "../DashboardTables/TopCategoriesTable";
import NewLocationsTable from "../DashboardTables/NewLocationsTable";
import TopProductsTable from "../DashboardTables/TopProductsTable";
import LostLocationsTable from "../DashboardTables/LostLocationsTable";

const ProductsLocationsCardMobile = () => {
    const { handleClickTopCategoriesTab, handleClickTopProductsTab, handleClickNewLocationsTab, handleClickLostLocationsTab} = useDashboard.useDashboard();
    const topProductsTab = useSelector(({ui}) => ui.topProductsTab);
    const topCategoriesTab = useSelector(({ui}) => ui.topCategoriesTab);
    const newLocationsTab = useSelector(({ui}) => ui.newLocationsTab);
    const lostLocationsTab = useSelector(({ui}) => ui.lostLocationsTab);

    if (window.innerWidth < 1024) {
        return (
            <Col className="h-100 py-2">
                <Card className='px-2 py-1' style={{height: '400px'}}>
                    <Col className=" pe-1 ps-1 h-100" >
                        <Row className='mx-0 mb-2 my-2' style={{ borderBottom: '4px solid lightgray' }}>
                            <Container id='subheader-container' className='align-items-left' fluid>
                                <Row className='clickable align-items-left'>
                                    <Col className={'categoriesProductstLink w-fc position-relative h-100 ' + (topCategoriesTab ? 'text-black' : 'text-gray')} id={topCategoriesTab ? 'topCatsProdsLinkSelected' : ''} onClick={() => handleClickTopCategoriesTab()}>
                                        <span className=' text-size-16 '>TOP CATEGORIES</span>
                                    </Col>
                                    <Col className={'categoriesProductsLink w-fc position-relative h-100 ' + (topProductsTab ? 'text-black' : 'text-gray')} id={topProductsTab ? 'topCatsProdsLinkSelected' : ''} onClick={() => handleClickTopProductsTab()}>
                                        <span className=' text-size-16 '>TOP PRODUCTS</span>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                        <Row style={{ height: '90%' }}>
                            {topCategoriesTab &&
                                <TopCategoriesTable />
                            }
                            {topProductsTab &&
                                <TopProductsTable />
                            }
                        </Row>
                    </Col>
                </Card>
                <Card className='px-2 py-1' style={{height: '400px'}}>
                    <Col className=" pe-1 h-100">
                        <Row className='mx-0 mb-2 my-2' style={{ borderBottom: '4px solid lightgray' }}>
                            <Container id='subheader-container' className='align-items-left' fluid>
                                <Row className='clickable align-items-left'>
                                    <Col className={'locationsLink w-fc position-relative h-100 ' + (newLocationsTab ? 'text-black' : 'text-gray')} id={newLocationsTab ? 'locationsLinkSelected' : ''} onClick={() => handleClickNewLocationsTab()}>
                                        <span className='text-size-16'>NEW LOCATIONS</span>
                                    </Col>
                                    <Col className={'locationsLink w-fc position-relative h-100 ' + (lostLocationsTab ? 'text-black' : 'text-gray')} id={lostLocationsTab ? 'locationsLinkSelected' : ''} onClick={() => handleClickLostLocationsTab()}>
                                        <span className='text-size-16'>LOST LOCATIONS</span>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                        <Row style={{ height: '85%' }}>
                            {newLocationsTab &&
                                <NewLocationsTable />
                            }
                            {lostLocationsTab &&
                                <LostLocationsTable />
                            }
                        </Row>
                    </Col>
                </Card>
            </Col>
        )
    }
    else return (
        <Card className='dashboard-card px-2'>
        <Row className="h-100 py-2">
            <Col className="w-50 pe-3 h-100" style={{borderRight: '1px lightgray solid'}}>
                <Row className='mx-0 mb-2' style={{borderBottom: '4px solid lightgray'}}>
                    <Container id='subheader-container' className='align-items-left' fluid>
                        <Row className='clickable align-items-left'>
                            <Col className={'categoriesProductstLink w-fc position-relative h-100 ' + (topCategoriesTab ? 'text-black' : 'text-gray')} id={topCategoriesTab ? 'topCatsProdsLinkSelected' : ''} onClick={() => handleClickTopCategoriesTab()}>
                                <span className='dashboard-nav-label'>TOP CATEGORIES</span>
                            </Col>
                            <Col className={'categoriesProductsLink w-fc position-relative h-100 ' + (topProductsTab ? 'text-black' : 'text-gray')} id={topProductsTab ? 'topCatsProdsLinkSelected' : ''} onClick={() => handleClickTopProductsTab()}>
                                <span className='dashboard-nav-label'>TOP PRODUCTS</span>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row style={{height: '85%'}}>
                    { topCategoriesTab &&
                        <TopCategoriesTable />
                    }
                    { topProductsTab &&
                        <TopProductsTable />
                    }
                </Row>
            </Col>
            <Col className="w-50 pe-3 h-100">
                <Row className='mx-0 mb-2' style={{borderBottom: '4px solid lightgray'}}>
                    <Container id='subheader-container' className='align-items-left' fluid>
                        <Row className='clickable align-items-left'>
                            <Col className={'locationsLink w-fc position-relative h-100 ' + (newLocationsTab ? 'text-black' : 'text-gray')} id={newLocationsTab ? 'locationsLinkSelected' : ''} onClick={() => handleClickNewLocationsTab()}>
                                <span className='dashboard-nav-label'>NEW LOCATIONS</span>
                            </Col>
                            <Col className={'locationsLink w-fc position-relative h-100 ' + (lostLocationsTab ? 'text-black' : 'text-gray')} id={lostLocationsTab ? 'locationsLinkSelected' : ''} onClick={() => handleClickLostLocationsTab()}>
                                <span className='dashboard-nav-label'>LOST LOCATIONS</span>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row style={{height: '85%'}}>
                    { newLocationsTab && 
                        <NewLocationsTable />
                    }
                    { lostLocationsTab &&
                        <LostLocationsTable />
                    }
                </Row>
            </Col>
        </Row>
        </Card>
    )   
}


export default ProductsLocationsCardMobile;