import moment from "moment";

const fromDate = new Date(moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'));
const toDate = new Date(moment().add(1, 'day').format('YYYY-MM-DD'));

export default function reducer(state = {
  merchants: [],
  merchantsList: [],
  merchantsLoaded: false,
  fromDate: fromDate,
  toDate: toDate,
  fromDateApply: fromDate,
  toDateApply: toDate,
  search: '',
  searchApply: '',
  allLoaded: false,
  gridOptions: {
    page: 1
  },
  listOptions: {
    page: 1,
    sortBy: 'totalVolume',
    sortDesc: true
  },
  detailOptions: {
    distributorSort: {
      sortBy: 'quantity',
      sortDesc: true
    },
    competitiveSort: {
      sortBy: 'quantity',
      sortDesc: true
    }
  }
  }, action) {
    switch(action.type) {
      case 'SET_DETAIL_SORT':
        return {
          ...state,
          detailOptions: {
            ...state.detailOptions,
            [action.payload.view]: {
              sortBy: action.payload.key,
              sortDesc: action.payload.desc === undefined ? (action.payload.key === state.detailOptions[action.payload.view].sortBy ? !state.detailOptions[action.payload.view].sortDesc : true) : action.payload.desc
            }
          }
        }
      case 'APPLY_INTEL_SEARCH':
        return {
          ...state,
          searchApply: state.search,
          fromDateApply: state.fromDate,
          toDateApply: state.toDate,
          gridOptions: {
            page: 1
          },
          listOptions: {
            ...state.listOptions,
            page: 1
          }
        };
      case 'SET_GRID_PAGE':
        return {
          ...state,
          gridOptions: {
            page: action.payload
          }
        };
      case 'SET_LIST_PAGE':
        return {
          ...state,
          listOptions: {
            ...state.listOptions,
            page: action.payload
          }
        };
      case 'SET_LIST_SORT_BY':
        return {
          ...state,
          listOptions: {
            ...state.listOptions,
            sortBy: action.payload,
            sortDesc: action.payload === state.listOptions.sortBy ? !state.listOptions.sortDesc : true
          },
        };
      case 'SET_LIST_SORT_BY_DESC':
        return {
          ...state,
          listOptions: {
            ...state.listOptions,
            sortBy: action.payload.key,
            sortDesc: action.payload.desc
          },
        };
      case 'CLEAR_INTEL_SEARCH':
        return {
          ...state,
          search: '',
          searchApply: '',
          gridOptions: state.searchApply !== '' ? {
            page: 1
          } : state.gridOptions,
          listOptions: state.searchApply !== '' ? {
            ...state.listOptions,
            page: 1
          } : state.listOptions
        };
      case 'SET_INTEL_SEARCH':
        return {
          ...state,
          search: action.payload,
        };
      case 'SET_INTEL_SEARCHS_ONLY':
        return {
          ...state,
          search: action.payload,
          searchApply: action.payload
        };
      case 'CLEAR_INTEL_MERCHANTS':
        return {
          ...state,
          merchantsLoaded: false,
          merchants: [],
          allLoaded: false
        };
      case 'SET_INTEL_MERCHANTS':
        return {
          ...state,
          merchantsLoaded: true,
          merchantsList: action.payload,
          merchants: action.payload.map(m => {
            return {
              ...m,
              loading: true,
              data: [],
              noData: true,
              totalVolume: 0,
              competitorVolume: 0,
              competitorVolumePerc: 0,
              distributorVolume: 0,
              distributorVolumePerc: 0,
              dcn: ''
            }
          })
        };
      case 'SET_INTEL_MERCHANT_DATA':
        return {
          ...state,
          allLoaded: action.payload.finish,
          merchants: state.merchants.map(m => {
            if (m.id !== action.payload.id) return m;
            const totalVolume = action.payload.data.reduce((prev, cur) => { return prev + cur.volume}, 0);
            const competitorVolume = action.payload.data
              .filter(d => d.competitor === true)
              .reduce((prev, cur) => { return prev + cur.volume}, 0);
            const competitorVolumePerc = action.payload.data
              .filter(d => d.competitor === true)
              .reduce((prev, cur) => { return prev + cur.percentage/100}, 0);
            const distributorVolume = action.payload.data
              .filter(d => d.competitor === false)
              .reduce((prev, cur) => { return prev + cur.volume}, 0);
            const distributorVolumePerc = action.payload.data
              .filter(d => d.competitor === false)
              .reduce((prev, cur) => { return prev + cur.percentage/100}, 0);
            const preferredDist = action.payload.data.find(d => !d.competitor);
            const dcn = preferredDist ? preferredDist.dcn : '';
            const noData = action.payload.data.length === 0 || action.payload.data.every(d => d.volume === 0);
            return {
              ...m,
              loading: false,
              data: action.payload.data,
              noData,
              totalVolume,
              competitorVolume,
              competitorVolumePerc,
              distributorVolume,
              distributorVolumePerc,
              dcn
            }
          })
        };
      case 'CLEAR_INTEL_MERCHANT_DATA':
        return {
          ...state,
          allLoaded: false,
          gridOptions: {
            page: 1
          },
          listOptions: {
            ...state.listOptions,
            page: 1
          },
          merchants: state.merchants.map(m => {
            return {
              ...m,
              loading: true,
              totalVolume: 0,
              competitorVolume: 0,
              competitorVolumePerc: 0,
              distributorVolume: 0,
              distributorVolumePerc: 0,
              data: [],
              noData: true,
              dcn: ''
            };
          })
        };
      case 'SET_INTEL_TO_DATE':
        return {
          ...state,
          toDate: action.payload
        };
      case 'SET_INTEL_FROM_DATE':
        return {
          ...state,
          fromDate: action.payload
        };
      default:
        return state;
    };
};
