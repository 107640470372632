import React, { useState } from 'react';
import { Row, Col, Input, Button, FormText, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import useForgotPasswordForm from './useForgotPasswordForm';
import { useNavigate } from 'react-router-dom';
import authApi from '../../api/authApi';
import { useDispatch } from 'react-redux';
import { spinner } from '../../reducers/UIReducer/UIActions';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { Theme } from '../../config/constants';

const ForgotPasswordForm = () => {
  const { email, validate } = useForgotPasswordForm();
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submit = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      dispatch(spinner.add());
      const params = {
        email: email.value,
        siteUrl: window.location.origin,
      };
      authApi.requestPasswordReset.post(params).then(({data}) => {
        dispatch(spinner.subtract());
        dispatch(showModal(AlertModal, {title: 'Please check your email we send you a link to reset your password'}));
      }).catch(err => {
        dispatch(spinner.subtract());
        let msg = 'Something went wrong';
        if (err.data) {
          msg = (err.data.message && err.data.message.keys) ? err.data.message : 'Email not found'
        }
        setApiError(msg);
      })
    }
  };

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <Form className="text-size-14" onSubmit={submit}>
      <FormGroup>
        <Label className='label-text-header text-beplBlue form-label mb-2' for='email'>Email</Label>
        <Input {...email} />
        <FormFeedback>{email.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Button type='submit' block color={Theme().colors.accentName} className='py-2 mt-3 col-12 button-label'>Submit</Button>
      <Row className='mb-5'>
        <Col onClick={goToLogin} className='form-label mt-3 text-center clickable' style={{color: Theme().colors.accent}}>Back to Login</Col>
      </Row>
    </Form>
  );
};

export default ForgotPasswordForm;