import Axios from "axios";

const url = '/marketing';

const marketingApi = {
  campaigns : {
    post: (params) => {
      return Axios.post(`${url}/campaigns`, params)
    }
  },
  updateCampaign : {
    post : (params) => {
      return Axios.post(`${url}/update-campaign`, params)
    }
  }
};

export default marketingApi;