export const setDashToDate = (date) => {
  return {
    type: 'SET_DASH_TO_DATE',
    payload: date
  }
};

export const setDashFromDate = (date) => {
  return {
    type: 'SET_DASH_FROM_DATE',
    payload: date
  }
};

export const setDashDateString = (date) => {
  return {
    type: 'SET_DASH_DATE_STRING',
    payload: date
  }
};

export const setCiToDate = (date) => {
  return {
    type: 'SET_CI_TO_DATE',
    payload: date
  }
};

export const setCiFromDate = (date) => {
  return {
    type: 'SET_CI_FROM_DATE',
    payload: date
  }
};

export const setOppsToDate = (date) => {
  return {
    type: 'SET_OPPS_TO_DATE',
    payload: date
  }
};

export const setOppsFromDate = (date) => {
  return {
    type: 'SET_OPPS_FROM_DATE',
    payload: date
  }
};

export const setDsFromDate = (date) => {
  return {
    type: 'SET_DATASUITE_FROM_DATE',
    payload: date
  }
}

export const setDsToDate = (date) => {
  return {
    type: 'SET_DATASUITE_TO_DATE',
    payload: date
  }
}

export const setDsDateString = (string) => {
  return {
    type: 'SET_DATASUITE_DATE_STRING',
    payload: string
  }
}

export const setDsDetailsFromDate = (date) => {
  return {
    type: 'SET_DATASUITE_DETAILS_FROM_DATE',
    payload: date
  }
}

export const setDsDetailsToDate = (date) => {
  return {
    type: 'SET_DATASUITE_DETAILS_TO_DATE',
    payload: date
  }
}

export const setDsDetailsDateString = (string) => {
  return {
    type: 'SET_DATASUITE_DETAILS_DATE_STRING',
    payload: string
  }
}

export const setDsExportFromDate = (date) => {
  return {
    type: 'SET_DATASUITE_EXPORT_FROM_DATE',
    payload: date
  }
}

export const setDsExportToDate = (date) => {
  return {
    type: 'SET_DATASUITE_EXPORT_TO_DATE',
    payload: date
  }
}

export const setDsExportDateString = (string) => {
  return {
    type: 'SET_DATASUITE_EXPORT_DATE_STRING',
    payload: string
  }
}

export const setDsCompExportFromDate = (date) => {
  return {
    type: 'SET_COMP_DATASUITE_EXPORT_FROM_DATE',
    payload: date
  }
}

export const setDsCompExportToDate = (date) => {
  return {
    type: 'SET_COMP_DATASUITE_EXPORT_TO_DATE',
    payload: date
  }
}

export const setDsCompExportDateString = (string) => {
  return {
    type: 'SET_COMP_DATASUITE_EXPORT_DATE_STRING',
    payload: string
  }
}