import React  from 'react';
import { Row, Input, Button, Label, Form, FormGroup, FormFeedback } from 'reactstrap';
import useChangePassword from './useChangePassword';
import { Theme } from '../../../config/constants';
import { isMobile, isMobileOnly } from 'react-device-detect';

const ChangePassword = () => {
  const { changePassword } = useChangePassword.useApi();
  const  { currentPassword, password,  passwordRepeat, validate} = useChangePassword.useFormChangePassword();
  const passwordParams = {
    currentPassword: currentPassword.value,
    password: password.value,
    passwordRepeat: passwordRepeat.value,
    validate
  }
  return (
      <Form className= {(isMobileOnly ? 'px-2 text-size-12 line-height-14' : 'px-5 text-size-12 line-height-14')} >
        <FormGroup >
          <Label className='label-text-header mb-2'>Current Password</Label>
          <Input {...currentPassword} type='password' placeholder='Type Current Password' />
          <FormFeedback>{currentPassword.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className={(isMobile ? 'label-text-header mb-2 mt-2'  : 'label-text-header mb-2 mt-3' )}>New Password</Label>
          <Input {...password} type='password' placeholder='Type Password'/>
          <FormFeedback>{password.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className={(isMobile ? 'label-text-header mb-2 mt-2'  : 'label-text-header mb-2 mt-3' )}>Confirm Password</Label>
          <Input {...passwordRepeat} type='password' placeholder='Type Password Again'/>
          <FormFeedback>{passwordRepeat.message}</FormFeedback>
        </FormGroup>
        <Row className='py-4 w-fc mx-auto'>
          <Button onClick={()=>changePassword(passwordParams)} block color={Theme().colors.accentName} className='py-2 px-5 button-label'>Save</Button>
        </Row>
      </Form>
  )
}

export default ChangePassword;
