import React, { useEffect, useState } from 'react';
import { Row, Col, ModalHeader, ModalBody, Modal, ModalFooter, Button, Card } from 'reactstrap';
import useDashboard from './useDashboard';
import { mfrPlatformIdToDmId, Theme } from '../../config/constants';
import moment from 'moment';
import DateRangePicker from '../../sharedComponents/DateRangePicker/DateRangePicker';
import { useSelector, useDispatch } from 'react-redux';
import LocalStorage from '../../sharedComponents/LocalStorage/LocalStorage';
import HighlightsCard from './DashboardCards/HighlightsCard';
import { BsFillGridFill } from 'react-icons/bs';
import { isMobileOnly } from "react-device-detect";
import { HiOutlineMenu } from "react-icons/hi";
import { BsCalendarEvent } from "react-icons/bs";
import { IoMdInformationCircle } from "react-icons/io";
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import MobileMenuSlideDrawer from '../../sharedComponents/SlideDrawer/MobileMenuSlideDrawer';
import ProductsLocationsCardMobile from './DashboardCards/ProductsLocationsCardMobile.js';
import DashboardChartsCardMobile from './DashboardCards/DashboardChartsCardMobile.js';
import BEPDistributorListCard from './DashboardCards/BEPDistributorListCard.js';



const DashboardMobile = () => {
  const dispatch = useDispatch();
  const menuOpen = useSelector(({ui}) => ui.mobileMenuSlideDrawer);

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const toggleInfoModal = () => {
    setInfoModalOpen(!infoModalOpen);
  }

  const {
    fromDate, toDate, onApplySearch, onChangeDashboardDates
  } = useDashboard.useDates();
  const { setDashboardTab } = useDashboard.useDashboard();
  const minDate = useSelector(({ dates }) => dates.dashMinDate);
  const dashDateString = useSelector(({ dates }) => dates.dashDateString);
  var mfrName = 'Admin';
  if (mfrPlatformIdToDmId[LocalStorage.manufacturerData.get().platform_id]?.mfrName) {
    mfrName = mfrPlatformIdToDmId[LocalStorage.manufacturerData.get().platform_id].mfrName;
  }

  const initializePage = () => {
    setDashboardTab();
  };
  useEffect(initializePage
    //  eslint-disable-next-line  
    , []);

  //  eslint-disable-next-line  
  useEffect(() => onApplySearch(), [dashDateString]);
  if (isMobileOnly) {
    return (
      <div className='bg-transparent pt-3 px-3 summaryContainer'>
        <header style={{ backgroundColor: '#f8f8f8' }}>
          <Col className='ps-0 d-flex justify-content-between align-items-center' >
            <HiOutlineMenu
              className='me-1'
              onClick={() => dispatch(slideDrawer.addMobileMenu())}
              isActive={menuOpen}
              style={{
                verticalAlign: 'middle',
                marginBottom: '7px',
                fontSize: '38px',
              }}
            />
            

            <div className='main-header text-size-28 ' style={{ fontWeight: '700', letterSpacing: '.0025em', lineHeight: '8px' }}>
              <BsFillGridFill
                className='me-1'
                style={{
                  verticalAlign: 'middle',
                  marginBottom: '7px',
                  fill: 'url(#icon-gradient)'
                }}
              />
              Dashboard
              <svg width='26px' height='26px'>
                <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop stopColor={Theme().colors.gradientColorOne} offset="0%" />
                  <stop stopColor={Theme().colors.gradientColorTwo} offset="100%" />
                </linearGradient>
              </svg>
            </div>

            <div>
              <BsCalendarEvent
                className='me-1 '
                style={{
                  verticalAlign: 'middle',
                  marginBottom: '7px',
                  fontSize: '28px',
                }}
                onClick={toggleModal}
              />
            </div>
            <Modal isOpen={modalOpen} toggle={toggleModal} style={{ height: '90%' }}>
              <ModalHeader>
                Select Date Range
              </ModalHeader>
              <ModalBody>
                <DateRangePicker
                  id='csvDateSearch'
                  defaultStartDate={moment(fromDate).format('MM/DD/YYYY')}
                  defaultEndDate={moment(toDate).format('MM/DD/YYYY')}
                  minDate={moment(minDate).format('MM/DD/YYYY')}
                  maxDate={moment().format('MM/DD/YYYY')}
                  callback={(start, end) => onChangeDashboardDates(start, end)}
                  ranges={{
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'Last 2 Months': [moment().subtract(2, 'month'), moment()],
                    'Last Year': [moment().subtract(1, 'year'), moment()]
                  }
                  }
                />
              </ModalBody>
            </Modal>
          </Col>
          
          <hr className='line' style={{ border: '1px solid grey', marginTop: '0px' }}></hr>
        </header>

        <Col className='label-text text-size-14 d-flex align-items-center pb-0 justify-content-between '>
          <div className='main-header text-size-16' style={{ lineHeight: '23px', fontWeight: '700', letterSpacing: '.0025em' }}>Today's Highlights
            <IoMdInformationCircle className="text-beplMagenta text-size-18 mx-1" onClick={toggleInfoModal} />
          </div>
          <Modal isOpen={infoModalOpen} toggle={toggleInfoModal} style={{ height: '90%' }}>
            <ModalHeader >
              Today's Highlights
            </ModalHeader>
            <ModalBody>
              <div className="mx-3">
                <Row><p><b>Total Volume:</b> This section will show you your total volume, direct volume or BEPL volume within the timeframe you select from the date filters.</p></Row>
                <Row><p><b>Locations:</b>  Your total locations (ie, individual store locations) within the selected date range.</p></Row>
                <Row><p><b>Cases:</b>  Your total cases across all categories in the selected date range</p></Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleInfoModal} block className='py-2' style={{ float: 'right', backgroundColor: '#CF3F7C', color: 'white' }} >Close</Button>
            </ModalFooter>
          </Modal>
        </Col>
        <HighlightsCard />
        <MobileMenuSlideDrawer />
        <ProductsLocationsCardMobile />
        <DashboardChartsCardMobile />
        <Card className='dashboard-card px-3' style={{ minHeight: '450px' }}>
          <BEPDistributorListCard />
        </Card>
        <Row>
          <Col className=' w-fc ms-auto me-0'>
            <div className='text-beplBlue' style={{ 'display': (mfrName ? 'block' : 'none') }}>*All data is for {mfrName}</div>
          </Col>
        </Row>
      </div>

    )
  } else {
    return (
      <div className='bg-transparent pt-3 px-3 summaryContainer'>
        <Col className='ps-0 d-flex justify-content-between align-items-end' >
          <div className='main-header text-size-32 mt-2' style={{ fontWeight: '700', letterSpacing: '.0025em', lineHeight: '28px' }}>
            <HiOutlineMenu
              className='me-3'
              onClick={() => dispatch(slideDrawer.addMobileMenu())}
              isActive={menuOpen}
              style={{
                verticalAlign: 'middle',
                marginBottom: '7px',
                fontSize: '38px',
              }}
            />
            <BsFillGridFill
              className='me-1'
              style={{
                verticalAlign: 'middle',
                marginBottom: '7px',
                fill: 'url(#icon-gradient)',
                fontSize: '30px'
              }}
            />
            Dashboard
          </div>
          <svg width='24px' height='24px'>
            <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop stopColor={Theme().colors.gradientColorOne} offset="0%" />
              <stop stopColor={Theme().color.gradientColorTwo} offset="100%" />
            </linearGradient>
          </svg>
          <div className='main-header text-size-20 align-self-end my-2' style={{ lineHeight: '23px', fontWeight: '700', letterSpacing: '.0025em' }}>Welcome, {mfrName}</div>
        </Col>
        <hr className='line my-2' style={{ border: '1px solid grey' }}></hr>
        <Row className='label-text text-size-14  mx-auto pb-2' style={{ justifyContent: 'right', alignContent: 'right' }}>
          <Col className='col-auto ps-0 pe-0'>
            <Row>
              <div className='text-size-12'>Select Date Range</div>
            </Row>
            <Row>
              <DateRangePicker
                id='csvDateSearch'
                defaultStartDate={moment(fromDate).format('MM/DD/YYYY')}
                defaultEndDate={moment(toDate).format('MM/DD/YYYY')}
                minDate={moment(minDate).format('MM/DD/YYYY')}
                maxDate={moment().format('MM/DD/YYYY')}
                callback={(start, end) => onChangeDashboardDates(start, end)}
                ranges={{
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'Last 2 Months': [moment().subtract(2, 'month'), moment()],
                  'Last Year': [moment().subtract(1, 'year'), moment()]
                }
                }
              />
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '-40px' }}>
          <HighlightsCard />
        </Row>
        <MobileMenuSlideDrawer />
        <ProductsLocationsCardMobile />
        <DashboardChartsCardMobile />
        <Card className='dashboard-card px-3' style={{ minHeight: '450px' }}>
          <BEPDistributorListCard />
        </Card>
        <Row>
          <Col className=' w-fc ms-auto me-0'>
            <div className='text-beplBlue' style={{ 'display': (mfrName ? 'block' : 'none') }}>*All data is for {mfrName}</div>
          </Col>
        </Row>
      </div>
    )
  }
};

export default DashboardMobile;