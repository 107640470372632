import React from 'react';
import useModal from '../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col } from 'reactstrap';
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';
import useUserRoles from '../UsersRoles/useUsersRoles';

const UsersRolesModal = ({id}) => {
  const { modalProps, headerProps } = useModal(id, {isMenu: true, isFullscreen: true});
  const userRolesProps = useUserRoles.useApi();
  const maxHeight = (isMobileOnly ? '450px' : (isTablet ? '800px' : '400px'))
  return (
    <Modal  {...modalProps}>
      <ModalHeader {...headerProps} >Users and Roles</ModalHeader>
      <ModalBody className={isMobile ? 'px-2' : ''} >
        <Col style={{maxHeight: maxHeight, overflowX:'hidden', overflowY:'auto'}}>
          {userRolesProps.userMap}
        </Col>
      </ModalBody>
      <ModalFooter className='justify-content-center'>
        <Col className='pl-0'>
          <Button block className='py-2 button-label' color='primary' onClick={()=> userRolesProps.userInvite(false) }>Invite User</Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default UsersRolesModal;
