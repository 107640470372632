import useForm from "../../sharedComponents/useForm/useForm"
import Joi from "joi";

const initialState = {
  platformId: '',
  manufacturerName: '',
  contactName: '',
  title: '',
  email: '',
  password: '',
  passwordRepeat: ''
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  };
};

const platformIdValidation = (value, helpers) => {
  const pattern = /^MA-[0-9]{7}/g;
  const test = pattern.test(value);

  if (!test) {
    return helpers.error('any.invalid');
  }

  return value;
};

const schema = Joi.object({
  platformId: Joi.string().custom(platformIdValidation).required().label('Manufacturer Platform Id'),
  manufacturerName: Joi.string().min(3).required().label('Manufacturer Name'),
  contactName: Joi.string().min(3).required().label('Contact Name'),
  title: Joi.string().min(3).required().label('Title/Role'),
  email: Joi.string().email({tlds: {allow: false}}).required().label('Email'),
  password: Joi.string().min(6).max(20).required().label('Create Password'),
  passwordRepeat:  Joi.string().valid(Joi.ref('password')).messages({'any.only': `Passwords don't match`}),
});

const useSignUpForm = () => {
  let { form, validate } = useForm(reducer, initialState, schema);

  const showPasswordRequirements = () => {
    const passwordRequirements = document.getElementById("passwordRequirements");
    passwordRequirements.style.display = 'block';
  };

  const hidePasswordRequirements = () => {
    const passwordRequirements = document.getElementById("passwordRequirements");
    passwordRequirements.style.display = 'none';
  };

  return {
    ...form,
    validate,
    showPasswordRequirements,
    hidePasswordRequirements
  };
};

export default useSignUpForm;