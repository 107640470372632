import { loginRequest } from "../../config/msAuthConfig";
import authApi from "../../api/authApi";
import useLogin from "../useLogin/useLogin";
import { setToken } from "../../config/msAuthConfig";
import { toast } from "react-toastify";

function useMsal() {
  const { login } = useLogin();
  const loginPopup = (msalInstance, switchingMfr) => {
    msalInstance.loginPopup(loginRequest).then(res => {
      const params = {
        accessToken: res.accessToken,
        tenantId: res.tenantId,
        tokenType: res.tokenType,
        name: res.account && res.account.name,
        email: res.account && res.account.username,
        switchingMfr
      };

      authApi.mslogin.post(params)
        .then(({ data }) => {
          let result = data;
          setToken(result.authKey);
          login(result);
        }).catch(error => {
          if (error) {
            console.log(error);
            let errorMessage = error.message;
            alert(errorMessage);
          }
        });
    }).catch(error => {
      if(error) {
        console.log(error);
        toast.error(error.message);
      }
    });
  }
  return {
    loginPopup
  }
}
export default useMsal