import { useDispatch } from "react-redux";
import { setDashboardNewLocations, setDashboardTopCategories, setDashboardTopProducts, setDashboardLostLocations, setDashboardDrawerContents } from "../../../reducers/DashboardReducer/DashboardActions";
import { slideDrawer } from "../../../reducers/UIReducer/UIActions";

const useCategoriesTable = () => {
    const dispatch = useDispatch();

    const sortByVolume = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.total_volume > b.total_volume) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardTopCategories(newList));
    };

    const sortByCategory = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.category < b.category) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardTopCategories(newList));
    };

    const sortBySales = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.sales_percent > b.sales_percent) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardTopCategories(newList));
    };

    const onSelectChange = (value, list) => {
        switch (value) {
            case ('Total Volume'): {
                sortByVolume(list);
                break;
            }
            case ('Category'): {
                sortByCategory(list);
                break;
            }
            case ('% of Sales'): {
                sortBySales(list);
                break;
            }
            default: {
                sortByVolume(list);
                break;
            }
        }
    }

    const onClickSeeMore = (title, contents) => {
        dispatch(setDashboardDrawerContents(title, contents));
        dispatch(slideDrawer.add());
    }
    
    return {
        onSelectChange,
        onClickSeeMore
    }
}

const useProductsTable = () => {
    const dispatch = useDispatch();

    const sortByMin = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.min < b.min) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardTopProducts(newList));
    };

    const sortByVolume = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.total_volume > b.total_volume) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardTopProducts(newList));
    };

    const sortByDescription = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.description < b.description) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardTopProducts(newList));
    };

    const sortBySales = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.sales_percent > b.sales_percent) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardTopProducts(newList));
    };

    const onSelectChange = (value, list) => {
        switch (value) {
            case ('MIN'): {
                sortByMin(list);
                break;
            }
            case ('Description'): {
                sortByDescription(list);
                break;
            }
            case ('Total Volume'): {
                sortByVolume(list);
                break;
            }
            case ('% of Sales'): {
                sortBySales(list);
                break;
            }
            default: {
                sortByVolume(list);
                break;
            }
        }
    }

    const onClickSeeMore = (title, contents) => {
        dispatch(setDashboardDrawerContents(title, contents));
        dispatch(slideDrawer.add());
    }
    
    return {
        onSelectChange,
        onClickSeeMore
    }
}

const useNewLocationsTable = () => {
    const dispatch = useDispatch();

    const sortByVolume = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.total_volume > b.total_volume) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardNewLocations(newList));
    };

    const sortByLocations = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.location_name < b.location_name) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardNewLocations(newList));
    };

    const sortByCases = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.total_cases > b.total_cases) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardNewLocations(newList));
    };

    const onSelectChange = (value, list) => {
        switch (value) {
            case ('Locations'): {
                sortByLocations(list);
                break;
            }
            case ('Total Volume'): {
                sortByVolume(list);
                break;
            }
            case ('Cases'): {
                sortByCases(list);
                break;
            }
            default: {
                sortByVolume(list);
                break;
            }
        }
    }

    const onClickSeeMore = (title, contents) => {
        dispatch(setDashboardDrawerContents(title, contents));
        dispatch(slideDrawer.add());
    }
    
    return {
        onSelectChange,
        onClickSeeMore
    }
}

const useLostLocationsTable = () => {
    const dispatch = useDispatch();

    const sortByVolume = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.total_volume > b.total_volume) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardLostLocations(newList));
    };

    const sortByLocations = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.location_name < b.location_name) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardLostLocations(newList));
    };

    const sortByCases = (list) => {
        const newList = [...list]?.sort((a,b) => {
            if (a.total_cases > b.total_cases) {
                return -1;
            }
            return 1;
        });
        dispatch(setDashboardLostLocations(newList));
    };

    const onSelectChange = (value, list) => {
        switch (value) {
            case ('Locations'): {
                sortByLocations(list);
                break;
            }
            case ('Total Volume'): {
                sortByVolume(list);
                break;
            }
            case ('Cases'): {
                sortByCases(list);
                break;
            }
            default: {
                sortByVolume(list);
                break;
            }
        }
    }

    const onClickSeeMore = (title, contents) => {
        dispatch(setDashboardDrawerContents(title, contents));
        dispatch(slideDrawer.add());
    }
    
    return {
        onSelectChange,
        onClickSeeMore
    }
}

// eslint-disable-next-line
export default { useCategoriesTable, useProductsTable, useNewLocationsTable, useLostLocationsTable };