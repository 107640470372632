
import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { showModal } from '../../../reducers/ModalsReducer/ModalsActions';
import { setUserRolData, clearSearchLocation,  setSearchLocation, setLocationChecked, setLocationCheckedAll } from '../../../reducers/UserReducer/UserActions';
import AlertModal from '../../../sharedComponents/AlertModal/AlertModal';
import Joi from '@hapi/joi';
import useForm from '../../../sharedComponents/useForm/useForm';
import InviteUserModal from '../Modals/InviteUserModal';
import UsersRolesRow from '../UsersRoles/UsersRolesRow';
import userApi from '../../../api/userApi';
import merchantApi from '../../../api/merchantApi';
import LocalStorage from '../../../sharedComponents/LocalStorage/LocalStorage';
import { Theme } from '../../../config/constants';

const initialState = {
  contactName: '',
  email: '',
  rol: { label: 'Marketing', value: 1 }
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field] : action.value
  };
};

const useApi = () => {
  const dispatch = useDispatch();
  const userRol = useSelector(({user}) => user.userRol);

  const getUsers = () => {
    if(LocalStorage.userData.get().user.internalAdmin === true) {
      const params = {
        manufacturer : LocalStorage.manufacturerData.get()
      };
      userApi.post(params).then(({data}) => {
        dispatch(setUserRolData(data))
      }).catch(err => {
        toast.error('Something went wrong updating the user');
        console.error(err);
      });
    } else {
        userApi.post().then(({data}) => {
        dispatch(setUserRolData(data))
      }).catch(err => {
        toast.error('Something went wrong updating the user');
        console.error(err);
      });
    }
  }

  const userDelete = (user) => {
    const currentUser = JSON.parse(localStorage.getItem('userData'));
    const title= 'Delete User';
    if(currentUser.email === user.email) {
      dispatch(showModal(AlertModal, {title, message: `You can't delete your own user`, buttonText:`GOT IT`}));
    } else {
      const modalProps = {
        title,
        message: `Are you sure you want to delete ${user.first_name + " " + user.last_name}? This user will immediately lose access.`,
        showTwoButtons: true,
        buttonLeftText: 'Cancel',
        buttonRightText: 'Delete',
        buttonRightColor: Theme().colors.accentName,
        buttonLeftColor: Theme().colors.accentName,
        buttonLeftOutline: true,
        buttonRightClick: () => deleteUser(user.id),
        user
      }
      dispatch(showModal(AlertModal, modalProps));
    }
  }

  const deleteUser = (id, params) => {
    userApi.userInvite.delete(id, params).then(({data}) => {
      toast.success('User deleted!');
      getUsers();
    }).catch(err => {
      toast.error('Something went wrong deleting user.');
      console.error(err);
      toast.error('Something went wrong deleting user');
    });
  }

  const userInvite = (user) => {
    const modalProps = {
      isEdit: user ? true : false, 
      title: user ? 'Edit User' : 'Invite User',
      user,
      size:'sm',
      getUsers
    };
    if(modalProps.isEdit) {
      merchantApi.available.get(user.id).then(({data}) => {
        if(data.length > 0) {
          const check = data.map(data => data.id)
          dispatch(setLocationCheckedAll(check));
        }
        dispatch(showModal(InviteUserModal, modalProps));
      }).catch(err => {
        console.error(err);
        toast.error('Something went wrong inviting user');
      });
    } else {
      dispatch(setLocationCheckedAll([]));
      dispatch(showModal(InviteUserModal, modalProps));
    }
  }

  const userMap = userRol?.map((list, i) => (
    <UsersRolesRow  key={`user-${i}`} data={list} userInvite={userInvite} userDelete={userDelete} />
  ));

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  return { userInvite, userDelete, userMap }
}

const useUser = (props) => {
  const locations = props.locations;
  const getUsers = props.getUsers;
  const dispatch = useDispatch();
  const [checkAll, setCheckAll] = useState(false);
  const [checkIntel, setCheckIntel] = useState(props.user.displayIntel);
  const searchLocation = useSelector(({user}) => user.searchLocation);
  const locationChecked = useSelector(({user}) => user.locationChecked);
  const merchants = useSelector(({intel}) => intel.merchants);

  const onChange = (e) => {
    dispatch(setSearchLocation(e.target.value));
  };

  const clearValue = () => {
    dispatch(clearSearchLocation());
  };

  const checkLocation = (oLocation) => {
    dispatch(setLocationChecked(oLocation.id));
  }

  useEffect(() => {
    const dataLoc = locationChecked.filter(d => merchants.some(m => m.id === d));
   if(dataLoc.length === merchants.length) {
    setCheckAll(true);
   } else {
    setCheckAll(false);
   }
  //eslint-disable-next-line
  }, [locationChecked])

  const checkLocationAll = (checkAll) => {
    setCheckAll(!checkAll)
    if(!checkAll) {
      const loc = locations.map(loc => {
        return loc.id
      });
      dispatch(setLocationCheckedAll(loc));
    } else {
      dispatch(setLocationCheckedAll([]));
    }
  }

  const onCheckAllowIntel = (displayIntel) => {
    setCheckIntel(!displayIntel)
  }

  const userSave = (user) => {
    const errors = user.validate();
    if (!errors) {
      const params = {
        contactName: user.contactName,
        email: user.email,
        title: user.rol,
        siteUrl: window.location.origin,
        displayIntel: checkIntel ? true : false
      };
      if(user.isEdit) {
        userApi.userInvite.patch(user.id, params).then(({data}) => {
          toast.success('User invited!');
          getUsers();
          user.toggle();
        }).catch(err => { 
          toast.error('Something went wrong inviting user.');
          user.toggle();
          let msg = `Something went wrong`;
          if (err) {
            if (err.data && err.data.message) {
              msg = err.data.message;
            }
            dispatch(showModal(AlertModal, {title: msg}));
          }
        })
      } else if(LocalStorage.userData.get().user.internalAdmin === true) {
        const userObj = params;
        const mfrObj = LocalStorage.manufacturerData.get();
        const paramsExt = { ...userObj, "manufacturer": mfrObj };
        userApi.adminInvite.post(paramsExt).then(({data}) => {
          toast.success('User invited!');
          getUsers();
          user.toggle();
        }).catch(err => { 
          toast.error('Something went wrong inviting user.');
          user.toggle();
          let msg = `Something went wrong`;
          if (err) {
            if (err.data && err.data.message) {
              msg = err.data.message;
            }
            dispatch(showModal(AlertModal, {title: msg}));
          }
        })
      } else {
        userApi.userInvite.post(params).then(({data}) => {
          toast.success('User invited!');
          getUsers();
          user.toggle();
        }).catch(err => { 
          toast.error('Something went wrong inviting user.');
          user.toggle();
          let msg = `Something went wrong`;
          if (err) {
            if (err.data && err.data.message) {
              msg = err.data.message;
            }
            dispatch(showModal(AlertModal, {title: msg}));
          }
        })
      }
    }
  }

  return { 
    userSave, 
    checkLocationAll, 
    checkLocation, 
    checkAll,
    onCheckAllowIntel,
    checkIntel,
    search: {
      onChange: onChange,
      value: searchLocation,
      clearValue: clearValue
    }
  }

}


const schema = Joi.object({
  contactName: Joi.string().min(3).required(),
  email: Joi.string().min(3).required(),
  rol: Joi.required()
});

const useUserForm = (isEdit, initValues) => {
  const { form, validate, dispatch } = useForm(reducer, initialState, schema);
  useEffect(() => {
    if (isEdit) {
      dispatch({field: 'contactName', value: initValues.contactName});
      dispatch({field: 'email', value: initValues.email});
      dispatch({field: 'rol', value: { label : initValues.title || '' } });
    }
    //eslint-disable-next-line
  }, []);

  form.rol.onChange = (valueSelected) => {
    dispatch({field: 'rol', value: valueSelected})
  }
 
  return {
    ...form,
    validate
  }
}

// eslint-disable-next-line
export default {
  useApi,
  useUserForm,
  useUser
} 
