import { BsSimSlash } from "react-icons/bs";

const EmptyDataMessage = () => {
    return (
        <div className="h-100 w-100" style={{display: 'inline-flex'}}>
            <div className="mx-auto my-auto w-50">
                <div className="mx-auto w-fc mb-2"><BsSimSlash className="text-size-36"/></div>
                <div className='mx-auto text-size-20 w-fc mb-1' style={{textAlign: 'center'}}>No Data To Display</div>
                <div className="mx-auto text-size-16 w-fc" style={{textAlign: 'center'}}>There is no data for this widget, please try again later.</div>
            </div>   
        </div>
    );
}

export default EmptyDataMessage;