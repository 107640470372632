import Axios from "axios";

const url='/user';

const userApi = {
  post: (params) => {
    return Axios.post(`${url}/all`, params);
  },
  changePassword: {
    post: (params) => {
      return Axios.post(`${url}/change-password`, params);
    }
  },
  userInvite: {
    get: () => {
      return Axios.get(`${url}`);
    },
    post: (params) => {
      return Axios.post(`${url}/invite`, params);
    },
    patch: (id, params) => {
      return Axios.patch(`${url}/${id}`, params);
    },
    delete: (id) => {
      return Axios.delete(`${url}/${id}`);
    }
  },
  adminInvite: {
    post: (params) => {
      return Axios.post(`${url}/admin-invite`, params);
    }
  },
  adminUserLogin: {
    post: (params) => {
      return Axios.post(`${url}/admin-user-login`, params);
    }
  },
  currentDistributor: {
    get: () => {
      return Axios.get(`${url}/current-distributor`);
    }
  },
  activeDistributor: (distributorId) => {
    const post = (params) => {
      return Axios.post(`${url}/active-distributor/${distributorId}`, params);
    };

    return {
      post
    };
  },
  permissions: {
    post: (params) => {
      return Axios.post(`${url}/permissions`, params);
    }
  }
};

export default userApi