import Axios from "axios";

const url = '/dashboard';

const dashboardApi = {
  get: (params) => {
    return Axios.get(`${url}`, {
      params: params
    });
  },
  highlights: {
    post: (params) => {
      return Axios.post(`${url + '/highlights'}`, params);
    }
  },
  newLocations: {
    post: (params) => {
      return Axios.post(`${url + '/locations/new'}`, params);
    }
  },
  lostLocations: {
    post: (params) => {
      return Axios.post(`${url + '/locations/lost'}`, params);
    }
  },
  topCategories: {
    post: (params) => {
      return Axios.post(`${url + '/categories'}`, params);
    }
  },
  topProducts: {
    post: (params) => {
      return Axios.post(`${url + '/products'}`, params);
    }
  },
  charts: {
    distributorBreakdown: {
      post: (params) => {
        return Axios.post(`${url + '/distributor'}`, params)
      },
      list: {
        post: (params) => {
          return Axios.post(`${url + '/distributor/list'}`, params);
        }
      }
    },
    competitiveInsights: {
      post: (params) => {
        return Axios.post(`${url + '/insights'}`, params);
      }
    }
  }
};

export default dashboardApi;