import React, { useRef } from "react";
import { Col, Row, Button } from "reactstrap";
import moment from "moment";
import { FaFilter } from "react-icons/fa";
import ReactSelect, { createFilter, components } from "react-select";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import useExportFiltersCard from "./useExportFiltersCard";
import { useEffect, useState } from "react";
import { selectStyles, Theme } from "../../../config/constants";
import CustomMenuList from "../../Helper/CustomMenuList";
import { BsCaretDownFill } from "react-icons/bs";
import useDataSuite from "../../../AppMain/DataSuite/useDataSuite";
import { setDataSetsExportFilter, setCompDataSetsExportFilter } from "../../../reducers/DatasuiteReducer/DataSuiteActions";

const ExportFiltersCard = (props) => {
    const exportFromDate = useSelector(({dates}) => dates.datasuiteExportFromDate);
    const exportToDate = useSelector(({dates}) => dates.datasuiteExportToDate);
    const exportMinDate = useSelector(({dates}) => dates.datasuiteExportMinDate);
    const compExportFromDate = useSelector(({dates}) => dates.datasuiteCompExportFromDate);
    const compExportToDate = useSelector(({dates}) => dates.datasuiteCompExportToDate);
    const compExportMinDate = useSelector(({dates}) => dates.datasuiteCompExportMinDate);
    const allFilters = useSelector(({datasuite}) => datasuite.allFilters);
    const exportButtonActive = useSelector(({ui}) => ui.exportButtonActive);
    const selectedExportFilters = useSelector(({datasuite}) => datasuite.exportDetailsFilters);
    const selectedCompExportFilters = useSelector(({datasuite}) => datasuite.compExportDetailsFilters);
    const { getFilters } = useDataSuite.useDataSuite();
    const { changeExportDates, setExportCategories, setExportDistributors, setExportLocations, setExportSegments, setExportDataSets, handleClickExportButton, 
            setCompExportCategories, setCompExportDataSets, setCompExportLocations, setCompExportSegments, changeCompExportDates } = useExportFiltersCard.useExportFiltersCard();
    const chainData = useSelector(({user}) => user.chainData);
    const emergingData = useSelector(({user}) => user.emergingData);

    const dateRef = useRef();
    const dispatch = useDispatch();

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };
    
    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
          values = `${values.length} Selected`;
        }
        return (
          <components.ValueContainer {...props}>
            {values}
            {input}
          </components.ValueContainer>
        );
      };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              <input className="me-2" type="checkbox" checked={isSelected} disabled={isDisabled} />
              {children}
            </components.Option>
        );
      };


    const categoriesOptionsMap = allFilters?.categories?.sort().map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const selectedCategoriesDetailsMap = selectedExportFilters?.categories?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedOperatorsDetailsMap = selectedExportFilters?.locations?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedCompCategoriesDetailsMap = selectedCompExportFilters?.categories?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedCompOperatorsDetailsMap = selectedCompExportFilters?.locations?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const distributorOptionsMap = allFilters?.distributors?.sort().map((data) => {
        return (
            {value: data, label: data}
        );
    });


    const selectedDistributorsDetailsMap = selectedExportFilters?.distributors?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const segmentOptionsMap = allFilters?.segments?.sort().map((data) => {
        const segmentLabel = data.split('_').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
        return (
            {value: data, label: segmentLabel}
        );
    });

    const selectedSegmentsDetailsMap = selectedExportFilters?.segments?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedCompSegmentsDetailsMap = selectedCompExportFilters?.segments?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    // const selectedOperatorNames = selectedOperatorsDetailsMap?.map(loc => loc.value);
    // const selectedSegments = selectedSegmentsDetailsMap?.map(segment => segment.value);

    // Show all locations if no segment is selected
    let filteredLocations = allFilters?.locations;

    // if (selectedSegments?.length > 0) {
    //     if (selectedOperatorNames?.length > 0) {
    //         // Show 1.) locations in the selected segments 2.) selected locations
    //         filteredLocations = allFilters?.locations?.filter((loc) => 
    //             selectedSegments.includes(loc.market_segment) || selectedOperatorNames.includes(loc.location_name)
    //         );
    //     }
    //     else {
    //         // Show only locations in the selected segments
    //         filteredLocations = allFilters?.locations?.filter((loc) => 
    //             selectedSegments.includes(loc.market_segment)
    //         );
    //     }
    // }

    const operatorOptionsMap = filteredLocations?.sort((a, b) => 
        a.toLowerCase() > b.toLowerCase() ? 1 : -1)
        .map((data) => {
            return (
                {value: data, label: data}
            );
    });

    const datasetOptions = [
        {
            value: "Chain Data", 
            label: "Chain Data",
            isDisabled: !chainData
        },
        {
            value: "Emerging Chain Data", 
            label: "Emerging Chain Data",
            isDisabled: !emergingData
        }
    ];

    const selectedDatasetsMap = [];
    if (selectedExportFilters?.chainData) {
        selectedDatasetsMap.push(datasetOptions[0]);
    }
    if (selectedExportFilters?.gpoData) {
        selectedDatasetsMap.push(datasetOptions[1]);
    }

    const selectedCompDatasetsMap = [];
    if (selectedCompExportFilters?.chainData) {
        selectedCompDatasetsMap.push(datasetOptions[0]);
    }
    if (selectedCompExportFilters?.gpoData) {
        selectedCompDatasetsMap.push(datasetOptions[1]);
    }

    const initializePage = () => {
        getFilters();
        dispatch(setDataSetsExportFilter(chainData, emergingData));
        dispatch(setCompDataSetsExportFilter(chainData, emergingData));
    }

    // eslint-disable-next-line
    useEffect(initializePage, []);
    
    // The daterange component we use is uncontrolled, so we must save a ref
    // to this component and update the selected values on our own. This is 
    // only applicable to the details date range as its value can be updated by
    // other components.
    useEffect(() => {
        dateRef.current?.setStartDate(moment(exportFromDate).format('MM/DD/YYYY'));
        dateRef.current?.setEndDate(moment(exportToDate).format('MM/DD/YYYY'));
    }, [exportFromDate, exportToDate]);

    return (
        <Col className="h-100">
            <Row id={`exportFiltersMain`} className="w-100 mx-0" style={{background: 'white', zIndex: '2', position: 'relative', borderRadius: '5px', height: (selectedExportFilters?.includedSearch?.length > 0 || selectedExportFilters?.excludedSearch?.length > 0) ? '160px' : '118px'}}>
                <Col>
                    <Row>
                        <Col>
                            <div className="main-header text-size-20 mt-3">Download Your Sales Data</div>
                        </Col>
                    </Row>
                    <Row className="px-3" style={{position: 'relative'}}>
                        <Col className="card px-3" style={{height: '35%'}}>
                            <Row className="pt-2">
                                <div className="main-header text-size-20" style={{color: '#00A6CC'}}><FaFilter fontSize={'15px'} style={{marginBottom: '5px'}}/> Filters</div>
                            </Row>
                            <Row className="my-2" fluid="true">
                                <Col className="pb-3 col-6">
                                    <DateRangePicker 
                                        childRef={dateRef}
                                        id={`exportDateSearch`}
                                        defaultStartDate={moment(exportFromDate)} 
                                        defaultEndDate={moment(exportToDate)} 
                                        minDate={moment(exportMinDate)} 
                                        maxDate={moment().format('MM/DD/YYYY')} 
                                        callback={(start, end) => changeExportDates(start, end)}
                                        ranges={{
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'Last 2 Months': [moment().subtract(2, 'month'), moment()],
                                        'Last Year': [moment().subtract(1, 'year'), moment()]
                                        }
                                        }
                                    />
                                </Col>
                                <Col className="pb-3 col-6">
                                    <ReactSelect
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        name='dataSet'
                                        styles={selectStyles} 
                                        options = {datasetOptions}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator}}
                                        onChange = {(e) => setExportDataSets(e)}
                                        value = {selectedDatasetsMap}
                                        placeholder='Select Data Set'
                                    />
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator }} 
                                        name='category' 
                                        styles={selectStyles} 
                                        options = {categoriesOptionsMap}
                                        onChange = {(e) => setExportCategories(e)}
                                        value = {selectedCategoriesDetailsMap}
                                        placeholder='Select Category'
                                    />
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                                        name='operator' 
                                        styles={selectStyles} 
                                        options = {operatorOptionsMap}
                                        onChange = {(e) => setExportLocations(e)}
                                        value = {selectedOperatorsDetailsMap}
                                        placeholder='Select Operator'
                                    />
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        name='distributor' 
                                        styles={selectStyles} 
                                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                                        options = {distributorOptionsMap}
                                        onChange = {(e) => setExportDistributors(e)}
                                        value = {selectedDistributorsDetailsMap}
                                        placeholder='Select Distributor'
                                    />           
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        isDisabled={true}
                                        name='location' 
                                        styles={selectStyles} 
                                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                                        placeholder='Select Distribution Center Location'
                                    />           
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        name='segment' 
                                        styles={selectStyles} 
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator }}
                                        options = {segmentOptionsMap}
                                        onChange = {(e) => setExportSegments(e)}
                                        value = {selectedSegmentsDetailsMap}
                                        placeholder='Select Customer Segment'
                                    />           
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mx-2 my-2">
                        <Button 
                            block 
                            className='py-1 button-label exportWidgetButton' 
                            size='md' 
                            outline={false}
                            color={Theme().colors.accentName}
                            onClick={() => handleClickExportButton(props.title, false)}
                            disabled={!exportButtonActive}
                        > Export Your Data As CSV
                        </Button>
                    </Row>
                    <Row className="pt-3">
                        <Col>
                            <div style={{height: "1px", width: "100%", background: "lightgray"}}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="main-header text-size-20 mt-3">Download Competitive Data</div>
                        </Col>
                    </Row>
                    <Row className="px-3" style={{position: 'relative'}}>
                        <Col className="card px-3" style={{height: '35%'}}>
                            <Row className="pt-2">
                                <div className="main-header text-size-20" style={{color: '#00A6CC'}}><FaFilter fontSize={'15px'} style={{marginBottom: '5px'}}/> Required Filters for Competitive Data</div>
                            </Row>
                            <Row className="my-2" fluid="true">
                                <Col className="pb-3 col-6">
                                    <DateRangePicker 
                                        childRef={dateRef}
                                        id={`compExportDateSearch`}
                                        defaultStartDate={moment(compExportFromDate)} 
                                        defaultEndDate={moment(compExportToDate)} 
                                        minDate={moment(compExportMinDate)} 
                                        maxDate={moment().format('MM/DD/YYYY')} 
                                        callback={(start, end) => changeCompExportDates(start, end)}
                                        ranges={{
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'Last 2 Months': [moment().subtract(2, 'month'), moment()],
                                        'Last Year': [moment().subtract(1, 'year'), moment()]
                                        }
                                        }
                                    />
                                </Col>
                                <Col className="pb-3 col-6">
                                    <ReactSelect
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        name='dataSet'
                                        styles={selectStyles} 
                                        options = {datasetOptions}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator}}
                                        onChange = {(e) => setCompExportDataSets(e)}
                                        value = {selectedCompDatasetsMap}
                                        placeholder='Select Data Set'
                                    />
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator }} 
                                        name='category' 
                                        styles={selectStyles} 
                                        options = {categoriesOptionsMap}
                                        onChange = {(e) => setCompExportCategories(e)}
                                        value = {selectedCompCategoriesDetailsMap}
                                        placeholder='Select Category'
                                    />
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                                        name='operator' 
                                        styles={selectStyles} 
                                        options = {operatorOptionsMap}
                                        onChange = {(e) => setCompExportLocations(e)}
                                        value = {selectedCompOperatorsDetailsMap}
                                        placeholder='Select Operator'
                                    />
                                </Col>
                                <Col className="py-3 col-6">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        name='segment' 
                                        styles={selectStyles} 
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator }}
                                        options = {segmentOptionsMap}
                                        onChange = {(e) => setCompExportSegments(e)}
                                        value = {selectedCompSegmentsDetailsMap}
                                        placeholder='Select Customer Segment'
                                    />           
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mx-2 my-2">
                        <Button 
                            block 
                            className='py-1 button-label exportWidgetButton' 
                            size='md' 
                            outline={false}
                            color={Theme().colors.accentName}
                            onClick={() => handleClickExportButton(props.title, true)}
                            disabled={!exportButtonActive}
                        > Export Competitive Data As CSV
                        </Button>
                    </Row>
                    <Row className="pt-3">
                        <Col>
                            <div style={{height: "1px", width: "100%", background: "lightgray"}}></div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )   
}

export default ExportFiltersCard;