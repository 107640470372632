import React from 'react';
import { Row, Col } from 'reactstrap';
import { Theme } from '../../config/constants';

const Title = (props) => {
  return (
    <Row className="px-3 pt-3 pb-2">
        <Col className='w-fc p-0' style={{alignSelf: 'center'}}>
            <div style={{height: '20px', width: '4px', backgroundColor: Theme().colors.accent}}></div>
        </Col>
        <Col className="main-header my-auto text-size-20 col-11" style={{paddingLeft: '4px', whiteSpace: 'nowrap'}}><div>{props.title}</div></Col>   
    </Row>
  );
};

export default Title;