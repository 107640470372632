import React from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button } from "reactstrap";
import { IoMdInformationCircle } from "react-icons/io";
import { Tooltip } from "@mui/material";
import useDataSuite from "../useDataSuite";
import { useEffect } from "react";
import { AiOutlineRise, AiOutlineFall } from "react-icons/ai";
import formatters from "../../../config/formatters";
import useDataSuiteWidgets from "../Widgets/useDataSuiteWidgets";
import { Theme } from "../../../config/constants";

const GrowthOpportunitiesHighlightsCard = () => {
    const { getHighlights, getCategoryTrendingReport, getComplianceLeakageReport, getLostOperatorTrendingReport, getTopOperatorReport, getLostLocations, getSalesByOperator, getSalesByCategory, getLeakage } = useDataSuite.useDataSuite();
    const salesByOperatorData = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesByOperatorData);
    const salesByCategoryData = useSelector(({datasuite}) => datasuite.widgetDetailsData.salesByCategoryData);
    const lostLocationsData = useSelector(({datasuite}) => datasuite.widgetDetailsData.lostLocationsData);
    const leakageData = useSelector(({datasuite}) => datasuite.widgetDetailsData.leakageData);
    const detailsDrawerOpen = useSelector(({ui}) => ui.datasuiteWidgetDetailsSlideDrawer);
    const detailsDrawerTitle = useSelector(({datasuite})=> datasuite.widgetDetailsDrawer.title);
    const { widgetDetailsContent : salesByOperatorDetailsContent } = useDataSuiteWidgets.useSalesByOperatorWidget();
    const { widgetDetailsContent : leakageDetailsContent } = useDataSuiteWidgets.useLeakageWidget();
    const { widgetDetailsContent : lostLocationsDetailsContent } = useDataSuiteWidgets.useLostLocationsWidget();
    const { widgetDetailsContent : salesByCategoryWidgetDetailsContent } = useDataSuiteWidgets.useSalesByCategoryWidget();
    const highlights = useSelector(({datasuite}) => datasuite.highlights);
    const { numberFormatter} = formatters();
    const percentFormatter = Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 2,
    });

    const initializePage = () => {
        getHighlights();  
        if (salesByOperatorData.length <= 0) {
            getSalesByOperator(true);
        }
        if (salesByCategoryData.length <= 0) {
            getSalesByCategory(true);
        }
        if (lostLocationsData.length <= 0) {
            getLostLocations(true);
        }
        if (leakageData.length <= 0) {
            getLeakage(true);
        }
    }

    // eslint-disable-next-line
    useEffect(initializePage, []);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "Sales By Operator")) {getTopOperatorReport(salesByOperatorDetailsContent());}}, [salesByOperatorData]);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "Sales By Category")) {getCategoryTrendingReport(salesByCategoryDetailsContent());}}, [salesByCategoryData]);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "Lost Locations")) {getLostOperatorTrendingReport(lostLocationsDetailsContent());}}, [lostLocationsData]);

    // eslint-disable-next-line
    useEffect(() => {if(detailsDrawerOpen && (detailsDrawerTitle === "Leakage")) {getComplianceLeakageReport(leakageDetailsContent());}}, [leakageData]);

    return (
        <Col className="py-2">
            <Row className="py-2" style={{height: '100%', paddingBottom: '55px'}}>
                <Col className='col-3 px-3' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">BIGGEST GROWTH OPPORTUNITY</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='This is the operator purchasing the most in cases from your highest volume category from competitive manufacturers.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2" style={{paddingBottom: '55px'}}>
                        <Col>
                            <Row>
                                <Col style={{height: '48px'}}>
                                    {!highlights?.biggestGrowthOpportunity?.location_name && 
                                    <div className="w-100 h-100 animated-background" ></div>
                                    }  
                                    <div id="highlightsLocationName" title={highlights?.biggestGrowthOpportunity?.location_name} className="main-header text-size-32 w-100" style={{overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap'}}>{highlights?.biggestGrowthOpportunity?.location_name}</div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="main-header text-gray text-size-12">Purchasing Above 40% Competitive Volume</div>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getTopOperatorReport(salesByOperatorDetailsContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
                <Col className='col-3 px-3' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">YOUR TRENDING CATEGORY</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='This displays the category with the largest positive or negative shift in total case quantity over the last 3 months.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2 px-3" style={{paddingBottom: '55px'}}>
                        <Col className="col-9 px-0">
                            <Row>
                                {!highlights?.trendingCategory?.trend && 
                                <div className="animated-background" style={{height: '48px', width: '100px', marginBottom:'1px'}}></div> 
                                }
                                {highlights?.trendingCategory?.trend &&
                                <div className={"main-header text-size-32 " + (highlights?.trendingCategory?.trend > 0 ? 'text-green' : 'text-red')}>{percentFormatter.format(highlights?.trendingCategory?.trend)}</div>
                                }
                            </Row>
                            <Row>
                                {!highlights?.trendingCategory?.category && 
                                <div className="animated-background" style={{height: '18px', width: '150px'}}></div> 
                                }
                                <div className="main-header text-gray text-size-12" style={{textTransform: 'capitalize'}}>{highlights?.trendingCategory?.category}</div>
                            </Row>
                        </Col>
                        <Col className="col-3 p-0">
                            {(highlights?.trendingCategory?.trend > 0) &&
                            <AiOutlineRise size={'74px'} color='#00A86B'/>
                            }
                            {(highlights?.trendingCategory?.trend <= 0) &&
                            <AiOutlineFall size={'74px'} color='#DC3545'/>
                            }
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getCategoryTrendingReport(salesByCategoryWidgetDetailsContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
                <Col className='col-3 px-3' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">WIN BACK</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='This displays total number of operators who stopped purchasing YTD who had purchased in the previous year.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2" style={{paddingBottom: '55px'}}>
                        <Col className="col-9 ps-3">
                            <Row>
                                <Col className="w-fc pe-0">
                                    {(!highlights?.trendingSales?.newLocationCount && !highlights?.trendingSales?.lostLocationCount) && 
                                    <div className="animated-background" style={{height: '48px', width: '48px', marginBottom: '1px'}}></div>
                                    }
                                    {(highlights?.trendingSales?.newLocationCount >= highlights?.trendingSales?.lostLocationCount) &&
                                    <div className="main-header text-green text-size-32">{numberFormatter.format(highlights?.trendingSales?.newLocationCount)}</div>
                                    }
                                    {(highlights?.trendingSales?.newLocationCount < highlights?.trendingSales?.lostLocationCount) &&
                                    <div className="main-header text-red text-size-32">{numberFormatter.format(highlights?.trendingSales?.lostLocationCount)}</div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {(!highlights?.trendingSales?.newLocationCount && !highlights?.trendingSales?.lostLocationCount) &&
                                    <div className="animated-background" style={{height: '18px', width: '150px'}}></div> 
                                    }
                                    {(highlights?.trendingSales?.newLocationCount >= highlights?.trendingSales?.lostLocationCount) &&
                                        <div className="main-header text-gray text-size-12">Locations Added This Month</div>
                                    }
                                    {(highlights?.trendingSales?.newLocationCount < highlights?.trendingSales?.lostLocationCount) &&
                                        <div className="main-header text-gray text-size-12">Locations Lost This Month</div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-3 p-0">
                            {(highlights?.trendingSales?.newLocationCount >= highlights?.trendingSales?.lostLocationCount) &&
                                <AiOutlineRise size={'74px'} color='#00A86B'/>
                            }
                            {(highlights?.trendingSales?.newLocationCount < highlights?.trendingSales?.lostLocationCount) &&
                                <AiOutlineFall size={'74px'} color='#DC3545'/>
                            }
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getLostOperatorTrendingReport(lostLocationsDetailsContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
                <Col className='col-3 px-3'>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">LEAKAGE & COMPLIANCE</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='View report to learn more about your leakage and compliance across categories.' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="pt-2" style={{paddingBottom: '55px'}}>
                        <Col>
                            <AiOutlineRise size={'74px'} color='#00A86B'/>
                        </Col>
                    </Row>
                    <Row style={{height: '55px', position: 'absolute', bottom: '10px'}}>
                        <Button 
                            block 
                            className='py-2 mt-3 mx-2 button-label' 
                            size='sm' 
                            style={{width: '120px'}}
                            color={Theme().colors.accentName}
                            onClick={() => {getComplianceLeakageReport(leakageDetailsContent())}}
                        >View Report</Button>
                    </Row>
                </Col>
            </Row>
        </Col>
    )   
}

export default GrowthOpportunitiesHighlightsCard;