import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { setDataSetsExportFilter, setCategoriesExportFilter, setDistributorsExportFilter, setLocationsExportFilter, setSegmentsExportFilter, setCompCategoriesExportFilter, setCompDataSetsExportFilter,
         setCompLocationsExportFilter, setCompSegmentsExportFilter } from "../../../reducers/DatasuiteReducer/DataSuiteActions";
import { setDsExportFromDate, setDsExportToDate, setDsExportDateString, setDsCompExportDateString, setDsCompExportFromDate, setDsCompExportToDate } from "../../../reducers/DateReducer/DateActions";
import LocalStorage from "../../LocalStorage/LocalStorage";
import datasuiteApi from "../../../api/datasuiteApi";
import fileDownload from "js-file-download";
import { setExportButtonActive } from "../../../reducers/UIReducer/UIActions";

const useExportFiltersCard = () => {
    const dispatch = useDispatch();
    const mfrId = LocalStorage.manufacturerData.get().platform_id;
    const selectedExportFilters = useSelector(({datasuite}) => datasuite.exportDetailsFilters);
    const exportFromDate = useSelector(({dates}) => dates.datasuiteExportFromDate);
    const exportToDate = useSelector(({dates}) => dates.datasuiteExportToDate);
    const selectedCompExportFilters = useSelector(({datasuite}) => datasuite.compExportDetailsFilters);
    const compExportFromDate = useSelector(({dates}) => dates.datasuiteCompExportFromDate);
    const compExportToDate = useSelector(({dates}) => dates.datasuiteCompExportToDate);

    const setExportDataSets = (datasets) => {
        const chainData = datasets.findIndex((ds) => ds.value === "Chain Data") > -1;
        const gpoData = datasets.findIndex((ds) => ds.value === "Emerging Chain Data") > -1;
        dispatch(setDataSetsExportFilter(chainData, gpoData));
      }

    const changeExportDates = (fromDate, toDate) => {
        const dateString = moment(fromDate).format('YYYY-MM-DD') + " - " + moment(toDate).format('YYYY-MM-DD');
        dispatch(setDsExportFromDate(fromDate));
        dispatch(setDsExportToDate(toDate));
        dispatch(setDsExportDateString(dateString));
      }

    const setExportCategories = (categories) => {
        if(categories?.length > 0) {
          }
          if(categories) {
            const mappedCategories = categories.map(category => category.value);
            dispatch(setCategoriesExportFilter(mappedCategories));
          } else {
            dispatch(setCategoriesExportFilter([]));
          } 
    }

    const setExportLocations = (locations) => {
        if(locations?.length > 0) {
          }
          if(locations) {
            const mappedLocations = locations.map(location => location.value);
            dispatch(setLocationsExportFilter(mappedLocations));
          } else {
            dispatch(setLocationsExportFilter([]));
          }
    }

    const setExportDistributors = (distributors) => {
        if(distributors?.length > 0) {
          }
          if(distributors) {
            const mappedDistributors = distributors.map(distributor => distributor.value);
            dispatch(setDistributorsExportFilter(mappedDistributors));
          } else {
            dispatch(setDistributorsExportFilter([]));
          }
    } 

    const setExportSegments = (segments) => {
        if(segments?.length > 0) {
          }
          if(segments) {
            const mappedSegments = segments.map(segment => segment.value);
            dispatch(setSegmentsExportFilter(mappedSegments));
          } else {
            dispatch(setSegmentsExportFilter([]));
          }
    }

    const setCompExportDataSets = (datasets) => {
        const chainData = datasets.findIndex((ds) => ds.value === "Chain Data") > -1;
        const gpoData = datasets.findIndex((ds) => ds.value === "Emerging Chain Data") > -1;
        dispatch(setCompDataSetsExportFilter(chainData, gpoData));
      }

    const changeCompExportDates = (fromDate, toDate) => {
        const dateString = moment(fromDate).format('YYYY-MM-DD') + " - " + moment(toDate).format('YYYY-MM-DD');
        dispatch(setDsCompExportFromDate(fromDate));
        dispatch(setDsCompExportToDate(toDate));
        dispatch(setDsCompExportDateString(dateString));
      }

    const setCompExportCategories = (categories) => {
        if(categories?.length > 0) {
          }
          if(categories) {
            const mappedCategories = categories.map(category => category.value);
            dispatch(setCompCategoriesExportFilter(mappedCategories));
          } else {
            dispatch(setCompCategoriesExportFilter([]));
          } 
    }

    const setCompExportLocations = (locations) => {
        if(locations?.length > 0) {
          }
          if(locations) {
            const mappedLocations = locations.map(location => location.value);
            dispatch(setCompLocationsExportFilter(mappedLocations));
          } else {
            dispatch(setCompLocationsExportFilter([]));
          }
    }

    const setCompExportSegments = (segments) => {
        if(segments?.length > 0) {
          }
          if(segments) {
            const mappedSegments = segments.map(segment => segment.value);
            dispatch(setCompSegmentsExportFilter(mappedSegments));
          } else {
            dispatch(setCompSegmentsExportFilter([]));
          }
    }

    const handleClickExportButton = (widgetName, includeCompetitiveData) => {
        dispatch(setExportButtonActive(false));
        var params = {};
        if (includeCompetitiveData) {
            params = {
                "manufacturerPlatformId": mfrId,
                "chainData": selectedCompExportFilters?.chainData,
                "emergingData": selectedCompExportFilters?.gpoData,
                "startDate": compExportFromDate,
                "endDate": compExportToDate,
                "categories": selectedCompExportFilters?.categories,
                "distributors": selectedCompExportFilters?.distributors,
                "locations": selectedCompExportFilters?.locations,
                "segments": selectedCompExportFilters?.segments
            }
            if (selectedCompExportFilters?.categories?.length === 0 && selectedCompExportFilters?.locations?.length === 0) {
                toast.error("A Category or Operator filter is required for Competitive Data.");
                dispatch(setExportButtonActive(true));
            } else {
                const loadingToast = toast.loading("Fetching data. This may take a moment...");
                datasuiteApi.export.competitorItems.post(params).then((response) => {
                    toast.update(loadingToast, { render: "Downloading CSV file..", type: "success", isLoading: false, autoClose: "10000"});
                    fileDownload(response.data, widgetName.replace(/\s+/g, '') + "CompetitiveExport_" + moment().format('DD-MM-YYYY_hmmss').toString() + ".csv"); 
                    dispatch(setExportButtonActive(true));
                }).catch((error) => {
                    toast.update(loadingToast, { render: "No data found. Try changing your selected filters and try again.", type: "warn", isLoading: false, autoClose: "10000"});
                    dispatch(setExportButtonActive(true));
                });
            }
        } else {
            params = {
                "manufacturerPlatformId": mfrId,
                "chainData": selectedExportFilters?.chainData,
                "emergingData": selectedExportFilters?.gpoData,
                "startDate": exportFromDate,
                "endDate": exportToDate,
                "categories": selectedExportFilters?.categories,
                "distributors": selectedExportFilters?.distributors,
                "locations": selectedExportFilters?.locations,
                "segments": selectedExportFilters?.segments
            }
            const loadingToast = toast.loading("Fetching data. This may take a moment...");
            datasuiteApi.export.manufacturerItems.post(params).then((response) => {
                toast.update(loadingToast, { render: "Downloading CSV file..", type: "success", isLoading: false, autoClose: "10000"});
                fileDownload(response.data, widgetName.replace(/\s+/g, '') + "MFRExport_" + moment().format('DD-MM-YYYY_hmmss').toString() + ".csv"); 
                dispatch(setExportButtonActive(true));
            }).catch((error) => {
                toast.update(loadingToast, { render: "No data found. Try changing your selected filters and try again.", type: "warn", isLoading: false, autoClose: "10000"});
                dispatch(setExportButtonActive(true));
            });
        }
    }

    return {
        changeExportDates,
        setExportCategories,
        setExportDataSets,
        setExportLocations,
        setExportDistributors,
        setExportSegments,
        setCompExportCategories,
        setCompExportDataSets,
        setCompExportLocations,
        setCompExportSegments,
        changeCompExportDates,
        handleClickExportButton
    }
}

// eslint-disable-next-line
export default { useExportFiltersCard };