import LocalStorage from '../LocalStorage/LocalStorage';

const getWelcomeName = () => {
  const userData = LocalStorage.userData.get();
  const manufacturerData = LocalStorage.manufacturerData.get();
  if (!userData || !manufacturerData) return 'Admin';

  const user = userData.user;
  return user.internalAdmin ? manufacturerData.nickname : `${user.first_name} ${user.last_name}`;
};

export default getWelcomeName;
