import React, { Fragment } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { useLocation } from 'react-router-dom/dist';
import LoginFormMobile from './LoginFormMobile';

const LoginMobile = () => {
  document.body.style.backgroundImage = "linear-gradient(#0F1740, #CF3F7C)";
  document.body.style.backgroundSize = "200vw 150vh";
  document.body.style.backgroundRepeat = "no-repeat";

  const location = useLocation();
  return (
    <Fragment>
      <img src={'https://rebate-portal.s3.amazonaws.com/lops/be-white.png'}  className='company-logo mx-auto d-block mt-4' alt= 'logo'/>
      <Container fluid className='flex-grow-1' style={{marginTop: '30px'}}>
        <Card className='mx-auto px-3' style={{width: '85vw', maxHeight: '90vh', maxWidth:'500px', backgroundColor: 'rgb(217, 217, 217, .9)'}} >
          <Row className='justify-content-center'>
            <Col style={{maxWidth: '350px'}}>
              <Row className='p-3'>
                <Col className='text-center text-size-28 main-header'>Login</Col>
              </Row>
              <LoginFormMobile activateMsg={location.state && location.state.activate ? true : false}  />
            </Col>
          </Row>
        </Card>
      </Container>
    </Fragment>
  );
};

export default LoginMobile;