import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback, Button, FormText, Row, Col } from 'reactstrap';
import useSignUpForm from './useSignUpForm';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import authApi from '../../api/authApi';
import { useDispatch } from 'react-redux';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { spinner } from '../../reducers/UIReducer/UIActions';
import { Theme } from '../../config/constants';

const SignUpForm = () => {
  const {
    validate, platformId, manufacturerName, contactName,
    title, email, password, passwordRepeat, showPasswordRequirements,
    hidePasswordRequirements
  } = useSignUpForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState('');

  const signUp = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      const params = {
        manufacturerPlatformId: platformId.value,
        manufacturerName: manufacturerName.value,
        contactName: contactName.value,
        title: title.value,
        email: email.value,
        password: password.value,
        repeatPassword: passwordRepeat.value,
        siteUrl: window.location.origin
      };
      dispatch(spinner.add());
      authApi.signUp.post(params).then(({data}) => {
        dispatch(spinner.subtract());
        const modalProps = {
          title: `Thank you ${contactName.value}!`,
          message: `Your information for ${contactName.value} has been submitted successfully. Once we have processed your information, we will send an activation link to your email that you can use to login.`,
          buttonText: 'Return to Login',
          buttonClick: () => navigate('/login'),
        };
        dispatch(showModal(AlertModal, modalProps));
      }).catch(err => {
        dispatch(spinner.subtract());
        let msg = 'Something went wrong';
        if (err.data) {
          if (err.data.errors && err.data.errors.message) {
            msg = err.data.errors.message;
          }
        }
        const modalProps = {
          title: msg
        };
        dispatch(showModal(AlertModal, modalProps));
        setApiError(msg);
      });
    }
  };

  const goToLogin = () => {
    navigate('login');
  };

  return (
    <Form className="text-size-14" onSubmit={signUp}>
      <FormGroup>
        <Label className='label-text-header form-label text-beplBlue mb-2' for='platformId'>Manufacturer Platform ID</Label>
        <InputMask mask='MA-9999999' {...platformId}>
          {(inputProps) => <Input {...inputProps} placeholder='MA-1234567' />}
        </InputMask>
        <FormFeedback>{platformId.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header form-label text-beplBlue mb-2 mt-3' for='manufacturerName'>Manufacturer Name</Label>
        <Input {...manufacturerName} placeholder='ACME Foods Inc.' />
        <FormFeedback>{manufacturerName.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header form-label text-beplBlue mb-2 mt-3' for='contactName'>Contact Name</Label>
        <Input {...contactName} placeholder='J. Doe' />
        <FormFeedback>{contactName.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header form-label text-beplBlue mb-2 mt-3' for='title'>Title/Role</Label>
        <Input {...title} placeholder='Production Manager' />
        <FormFeedback>{title.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header form-label text-beplBlue mb-2 mt-3' for='email'>Email</Label>
        <Input {...email} placeholder='jDoe@email.com' />
        <FormFeedback>{email.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header form-label text-beplBlue mb-2 mt-3' for='password'>Create Password</Label>
        <Input type='password' {...password} placeholder='Password123' onFocus={() => showPasswordRequirements()} onBlur={() => hidePasswordRequirements()} />
        <div className="mt-1" id="passwordRequirements" style={{display: 'none', color: Theme().colors.accent}}>Password Requirements:<br/>- Length between 3 and 30 characters<br/>- Only letter and numbers<br/>- No special characters (like $,@,#)<br/></div>
        <FormFeedback>{password.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header form-label text-beplBlue mb-2 mt-3' for='passwordRepeat'>Confirm Password</Label>
        <Input type='password' {...passwordRepeat} placeholder='Password123' />
        <FormFeedback>{passwordRepeat.message}</FormFeedback>
      </FormGroup>
      <Button type='submit' block style={{color: Theme().colors.accent}} className='py-2 col-12 my-3 button-label'>Sign Up</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Row className='mb-5'>
        <Col onClick={goToLogin} className='form-label text-center clickable' style={{color: Theme().colors.accent}}>Already have an account? Login</Col>
      </Row>
    </Form>
  );
};

export default SignUpForm;