import React, { useEffect } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { Chart as ChartJS, registerables } from 'chart.js';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { FaCircle } from 'react-icons/fa';

ChartJS.register(...registerables);

const MarketingCampaignDetailsTables = (props) => {
    const marketingDetailsTableLabels = ['Opportunities Generated', 'Total Engagements', 'Total Impressions'];
    const tableColors = ['rgba(49, 65, 117)', 'rgba(207, 63, 124)', 'rgba(22, 186, 197)'];
    const campaign = props.campaign;
    const marketingDetailsTableData = [campaign.opportunitiesGenerated, campaign.totalEngagements, campaign.totalImpressions];

    // eslint-disable-next-line
    useEffect(() => {setupDataTables()}, [campaign]);
    
    const setupDataTables = () => {
        if(marketingDetailsTableData) {
            var marketingDetailsTableCanvas = document.getElementById("marketingDetailsTableCanvas");
            var marketingDetailsChart = ChartJS.getChart("marketingDetailsTableCanvas");
            if(marketingDetailsChart !== undefined) {
                marketingDetailsChart.destroy();
            }
            // eslint-disable-next-line
            var marketingDetailsTable = new ChartJS( marketingDetailsTableCanvas ,{
                type: 'doughnut',
                data: {
                    labels: marketingDetailsTableLabels, 
                    datasets: [
                        {
                            data: [marketingDetailsTableData[0], marketingDetailsTableData[1], marketingDetailsTableData[2]],
                            backgroundColor: tableColors
                        }
                    ]
                },
                options: tableOptions
            });
        }
    };

    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true,
              xAlign: 'center',
              yAlign: 'center'
          },
        },
    };

    if(!marketingDetailsTableData) {
        return (
            <div className="mb-1">
                <Row style={{width: '95%'}} className="mx-auto marketingDetailsTableCard">
                    <div className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                        <Spinner animation="border" />
                    </div>
                </Row>
            </div>
        );
    }
    else return (
        <Col style={{height: '100%'}}>
            <Row className="w-100 my-auto marketingDetailsTableCard" style={{overflow: 'hidden', marginTop: '10%'}}>
                <Col className="col-1 p-0"></Col>
                <Col className="col-5 p-0">
                    <canvas id="marketingDetailsTableCanvas"/>
                </Col>
                <Col className="col-1 p-0"></Col>
                <Col className="col-5 text-size-14 p-0" style={{marginTop: '7%'}}>
                    <AutoSizer>
                        {({width, height}) => (
                            <Table className="table campaignStatsTable border-grayLight" id="campaignStatsTable"
                                width={width}
                                height={height}
                                headerHeight={34.5}
                                rowHeight={38}
                                rowCount={marketingDetailsTableData.length}
                                rowGetter={({ index }) => marketingDetailsTableData[index]}
                            >
                                <Column
                                label='Stat Type'
                                dataKey='marketingDetailsTableData'
                                width={width * 0.7}
                                cellRenderer={({cellData, rowIndex}) => (<span><FaCircle className='w-fc my-auto' style={{color: tableColors[rowIndex]}}/> {marketingDetailsTableLabels[rowIndex]}</span>)}
                                />
                                <Column
                                width={width * 0.3}
                                label='Total'
                                dataKey='marketingDetailsTableData'
                                cellRenderer={({cellData, rowIndex}) => (<span>{marketingDetailsTableData[rowIndex]}</span>)}
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </Col>
            </Row>
        </Col>
    );
    
};

export default MarketingCampaignDetailsTables;
