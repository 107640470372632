export default function reducer(state = {
    marketingCampaignsData: [],
    editDetailsEnabled: false,
    isLoading: false
    }, action) {
      switch(action.type) {
        case 'SET_MARKETING_CAMPAIGNS_DATA':
          return {
            ...state,
            marketingCampaignsData: action.payload,
            isLoading: false
          }
        case 'CLEAR_MARKETING_CAMPAIGNS_DATA':
          return {
            ...state,
            marketingCampaignsData: []
          }
        case 'DISABLE_EDIT_DETAILS' :
          return {
            ...state,
            editDetailsEnabled: false
          }
        case 'ENABLE_EDIT_DETAILS' :
          return {
            ...state,
            editDetailsEnabled: true
          }
        case 'LOADING':
          return {
            ...state,
            isLoading: true
          }
        default:
          return state;
      };
  };
  