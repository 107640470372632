import React from "react";
import { Col, Row, Button } from "reactstrap";
import CompetitiveInsightsChart from "../DashboardCharts/CompetitiveInsightsChart";
import DashboardDistributorBreakdownChart from "../DashboardCharts/DashboardDistributorBreakdownChart";
import DashboardTotalOpportunititesChart from "../DashboardCharts/DashboardTotalOpportunitiesChart";
import useDashboard from "../useDashboard";
import { Theme } from "../../../config/constants";

const DashboardChartsCard = () => {
    const { handleClickViewCampaignManagement } = useDashboard.useDashboard();

    return (
        <Col>
            <Row className="m-0">
                <Col className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <DashboardDistributorBreakdownChart />
                </Col>
                <Col className="w-fc m-0 p-0 pt-2">
                    <div style={{width: '1px', height: '380px', background: 'lightgray'}}></div>
                </Col>
                <Col>
                    <CompetitiveInsightsChart />
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <div style={{width: '100%', height: '1px', background: 'lightgray'}}></div>
            </Row>
            <Row className="m-0">
                <Col className="col-xxl-6 col-xl-6">
                    <DashboardTotalOpportunititesChart />
                </Col>
                <Col className="w-fc m-0 p-0 pb-2">
                    <div style={{width: '1px', height: '380px', background: 'lightgray'}}></div>
                </Col>
                <Col className="my-auto w-100 d-flex flex-column justify-content-center h-50" style={{minHeight: '350px'}}>
                    <Row className="mx-auto w-fc">
                        <img src={'./vectorcharts.png'} alt='Fake Charts and Graphs' style={{height: '59px', width: '211px'}}/>
                    </Row>
                    <Row className="mx-auto w-fc">
                        <div className="main-header text-size-18">Want more Campaign Insights?</div>
                    </Row>
                    <Row className="mx-auto w-fc">
                        <div className="text-grayText text-center">Access our Campaign Management page for full insights on your campaigns.</div>
                    </Row>
                    <Row className="mx-auto my-1 w-fc">
                        <Button 
                            block 
                            className='py-2 button-label' 
                            size='sm' 
                            style={{width: '250px'}}
                            onClick={() => handleClickViewCampaignManagement()}
                            color={Theme().colors.accentName}>View Campaign Management
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Col>
    )   
}

export default DashboardChartsCard;