import React from 'react';
import { NavLink } from '@mantine/core';
import { Theme } from '../../config/constants';

interface LinksGroupProps {
  icon: React.FC<any>;
  label: string;
  initiallyOpened?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  link?: string;
  onclick?: () => void;
  links?: { label: string; link: string; icon: React.FC<any>; isActive?:boolean; onclick?: () => void}[];
}

export function LinksGroup({ icon: Icon, label, link, links, isActive, isDisabled, onclick }: LinksGroupProps) {
  const items = links?.map((link) => {
    return (
      <NavLink label={link.label} href={link.link} key={link.label} active={link.isActive} variant="subtle" color={Theme().colors.accent} onClick={link.onclick}/>
    )
  });

  return (
    <NavLink
      href={link}
      label={label}
      leftSection={<Icon size="1rem" stroke={1.5} />}
      childrenOffset={28}
      active={isActive}
      color={Theme().colors.accent}
      defaultOpened={isActive}
      opened={isActive}
      onClick={onclick}
      disabled={isDisabled}
    >
      {items}
    </NavLink>
  );
}