import React, { useState } from 'react';
import { Row, Col, Input, Button, FormText, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import useResendActivationEmailForm from './useResendActivationEmailForm';
import { useNavigate } from 'react-router-dom';
import authApi from '../../api/authApi';
import { useDispatch } from 'react-redux';
import { spinner } from '../../reducers/UIReducer/UIActions';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { Theme } from '../../config/constants';

const ResendActivationEmailForm = () => {
  const { email, validate } = useResendActivationEmailForm();
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submit = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      dispatch(spinner.add());
      const params = {
          siteUrl: window.location.origin,
          email: email.value
      };
      authApi.resendActivationEmail.post(params).then(({data}) => {
        dispatch(spinner.subtract());
        dispatch(showModal(AlertModal, {title: 'Please check your email, we sent you a link to activate your account'}));
      }).catch(err => {
        dispatch(spinner.subtract());
        if (err.data) {
          if (err.data.message[1] === undefined) {
            dispatch(showModal(AlertModal, {title: 'Something went wrong'}));
          }
          else {
            dispatch(showModal(AlertModal, {title: err.data.message}));
          }
        }
      })
    }
  };

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <Form className="text-size-14" onSubmit={submit}>
      <FormGroup>
        <Label className='label-text-header text-beplBlue form-label mb-2' for='email'>Email</Label>
        <Input {...email} />
        <FormFeedback>{email.message}</FormFeedback>
      </FormGroup>
      <Button type='submit' block color={Theme().colors.accentName} className='mx-auto col-12 m-3 py-2 button-label'>Submit</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Row className='mb-5'>
        <Col onClick={goToLogin} className='form-label text-center clickable' style={{color: Theme().colors.accent}}>Back to Login</Col>
      </Row>
    </Form>
  );
};

export default ResendActivationEmailForm;