import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsChatLeftDotsFill, BsFillBarChartFill, BsFillGridFill, BsPersonCircle, BsQuestionCircle } from 'react-icons/bs';
import { isMobile, isTablet } from 'react-device-detect';
import { setAdmin } from '../../reducers/UserReducer/UserActions';
import { clearCompetitiveInsightsData } from '../../reducers/CompetitiveInsightsReducer/CompetitiveInsightsActions';
import { linkNavigationBar, slideDrawer } from '../../reducers/UIReducer/UIActions';
import { FiLogOut } from 'react-icons/fi';
import useDataSuite from '../../AppMain/DataSuite/useDataSuite';

const useLinks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profileOpen = useSelector(({ui}) => ui.profileSlideDrawer);
  //const notificationsOpen = useSelector(({ui}) => ui.notificationsSlideDrawer);
  const helpOpen = useSelector(({ui}) => ui.helpSlideDrawer);
  const chainData = useSelector(({user}) => user.chainData);
  const emergingData = useSelector(({user}) => user.emergingData);
  const detailsDrawerOpen = useSelector(({ui}) => ui.datasuiteWidgetDetailsSlideDrawer);
  const { pathname } = location;
  const [chatbotEnabled, setChatbotEnabled] = useState(false);
  const { clearDetailsFilter } = useDataSuite.useDataSuite();

  const dataSuiteAccess = chainData || emergingData;

  const closeDetailsDrawer = () => {
    if (detailsDrawerOpen) {
      dispatch(slideDrawer.subtractDatasuiteDetails());
      clearDetailsFilter();
    }
  }

  const openProfile = () => {
    dispatch(slideDrawer.addProfile());
    dispatch(slideDrawer.subtractHelp());
    dispatch(slideDrawer.subtractNotifications());
  }

  const openHelp = () => {
    dispatch(slideDrawer.addHelp());
    dispatch(slideDrawer.subtractProfile());
    dispatch(slideDrawer.subtractNotifications());
  }

  // const openNotifications = () => {
  //   dispatch(slideDrawer.addNotifications());
  //   dispatch(slideDrawer.subtractProfile());
  //   dispatch(slideDrawer.subtractHelp());
  // }

  const goToSettings = () => {
    navigate('/settings');
  };

  const goToChangeManufacturer = () => {
    dispatch(clearCompetitiveInsightsData());
    dispatch(setAdmin(true));
    navigate('/admin-login');
  };

  const goToHelp = () => {
    navigate('/help');
  };

  const logOut = () => {
    navigate('/logout');
  };

  const moveNavBar = () => {
    if(!(isMobile && !isTablet)){
      const tabSelected = document.getElementById('linkSelected');
      if(tabSelected) {
        dispatch(linkNavigationBar.setLink(tabSelected.clientWidth, tabSelected.getBoundingClientRect().left));
      }
    }
  };

  const links = [];

  if(localStorage.getItem('branding') === 'BEP') {
    links.push({
      label: 'Dashboard',
      icon: () => { return (<BsFillGridFill/>) },
      isActive: (pathname === '/dashboard' || pathname.substring(0,19) === '/campaignmanagement') && !profileOpen,
      link: '/#/dashboard',
      links: [
        { label: 'Dashboard',
          isActive: pathname === '/dashboard' && !profileOpen,
          link: '/#/dashboard' 
        },
        { label: 'Campaign Management',
          isActive: pathname.substring(0,19) === '/campaignmanagement' && !profileOpen,
          link: '/#/campaignmanagement' 
        },
      ],
    })
  }

  if(localStorage.getItem('branding') === 'ARROWSTREAM') {
    links.push({
      label: 'SalesStream',
      icon: () => { return (<BsFillBarChartFill/>)},
      isActive: (pathname.substring(0,12) === '/salesstream' || pathname.substring(0,20) === '/growthopportunities') && !profileOpen,
      link: '/salesstream',
      isDisabled: !dataSuiteAccess,
      links: [
        { label: "Where You're Winning",
          isActive: pathname.substring(0,12) === '/salesstream' && !profileOpen,
          link: '/#/salesstream',
          onclick: () => closeDetailsDrawer()
        },
        { label: "Growth Opportunities",
          isActive: pathname.substring(0,20) === '/growthopportunities' && !profileOpen,
          link: '/#/growthopportunities',
          onclick: () => closeDetailsDrawer()
        },
      ],
    })
  }

  const handleChatbotClose = (event) => {
    if (event.data === 'closeChatbotWindow') {
      toggleChatbot();
    }
  }

  window.addEventListener('message', handleChatbotClose);

  const toggleChatbot = () => {
    var chatbotIframe = document.getElementById("chatbotIframe");
    if(chatbotEnabled) {
      chatbotIframe && (chatbotIframe.style.visibility = "hidden");
      setChatbotEnabled(false);
    } else if (!chatbotEnabled) {
      chatbotIframe && (chatbotIframe.style.visibility = "visible");
      setChatbotEnabled(true);
    } else {
      //Do nothing
    }
  }
  
  const bottomLinks = [
    // {
    //   label: 'Notifications',
    //   icon: () => { return (<BsBell/>) },
    //   isActive:  notificationsOpen,
    //   onclick: () => openNotifications(),
    // },
    {
      label: 'Profile',
      icon: () => { return (<BsPersonCircle/>) },
      isActive: profileOpen,
      onclick: () => openProfile(),
    },
    {
      label: 'Help',
      icon: () => { return (<BsQuestionCircle/>) },
      isActive: helpOpen,
      onclick: () => openHelp(),
    },
    {
      label: 'Logout',
      icon: () => { return (<FiLogOut/>) },
      isActive: pathname === '/logout',
      link: '/#/logout',
    },
  ];

  if (localStorage.getItem('branding') === 'BEP') {
    bottomLinks.unshift({
      label: 'Chat with Erni (Beta)',
      icon: () => { return (<BsChatLeftDotsFill/>) },
      onclick: () => toggleChatbot(),
    });
  }
  
  return {
    goToSettings, goToChangeManufacturer, logOut, goToHelp, moveNavBar,
    links: links,
    bottomLinks: bottomLinks,
  };
};


// eslint-disable-next-line
export default {
  useLinks
};
