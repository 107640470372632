import React, { Fragment } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { FaHandshake } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';
import LocalStorage from '../../sharedComponents/LocalStorage/LocalStorage';



const Help = () => {


  const onClickSupport = () => {
    window.open('https://diningalliance.atlassian.net/servicedesk/customer/portal/31', '_blank');
  };

  const onClickContactMfrTeam = () => {
    toast.info("Opening email to contact your representative");
    const manufacturerData = LocalStorage.manufacturerData.get();
    const userData = LocalStorage.userData.get();
    var email =  'MFRrelations@buyersedgeplatform.com';
    var subject = 'Request from ' + manufacturerData.nickname;
    var emailBody = 'Hello, this is ' + userData.user.first_name + ' ' + userData.user.last_name + ' from ' + manufacturerData.nickname + ' requesting assistance on The Peak.';
    document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
  };

  return (
   <Col style={{height: '100vh'}}>
    { isMobile ? 
      <Fragment>
        <div style={{height: '100%'}}>
          <Row className='d-flex mt-5 justify-content-center'>
            <Col className='w-fc'>
              <div className='main-header text-size-24 justify-content-center'>How Can We Help?</div>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col className='d-flex mt-3 justify-content-center'>
              <Card className='w-100 py-3 clickable' style={{border: 'solid 1px lightgrey', textAlign: 'center', backgroundColor: '#F5E6ED'}} onClick={onClickSupport}>
                <div className='my-auto'>
                <FaHandshake className='mt-2 text-size-36 mx-auto' style={{color: '#CF3F7C'}} />
                <div className='my-2 text-black main-header text-size-18 mx-auto'>Support</div>
                <div className='mb-2'>Questions about the portal?<br/>Running into tech issues?<br/>Contact us here.</div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex mt-3 justify-content-center'>
              <Card className='w-100 py-3 clickable' style={{border: 'solid 1px lightgrey', textAlign: 'center', backgroundColor: '#F5E6ED'}} onClick={onClickContactMfrTeam}>
                <div className='my-auto'>
                <FiMail className='mt-2 text-size-36 mx-auto' style={{color: '#CF3F7C'}}/>
                <div className='my-2 text-black main-header text-size-18 mx-auto'>Connect with Our<br/>Manufacturer Relations Team</div>
                <div className='mb-2'>Have questions about the data shown here?<br/>Want to learn more about open opportunities?<br/>Email a member of our Manufacturer Relations team here.</div>
                </div>
              </Card>
            </Col>
          </Row>
        </div> 
      </Fragment>
      : 
      <Col style={{height: '100%'}}>
        <Row className='d-flex mt-5 justify-content-center'>
          <Col className='w-fc'>
            <div className='main-header text-size-24'>How Can We Help?</div>
          </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
          <Col className='d-flex mt-3 justify-content-end'>
            <Card className='w-100 px-2 py-3 clickable' style={{border: 'solid 1px lightgrey', textAlign: 'center'}} onClick={onClickSupport}>
              <div className='my-auto'>
              <FaHandshake className='mt-2 text-size-36 mx-auto' />
              <div className='my-2 text-black main-header text-size-20 mx-auto'>Support</div>
              <div className='mb-2'>Questions about the portal?<br/>Running into tech issues?<br/>Contact us here.</div>
              </div>
            </Card>
          </Col>
          <Col className='d-flex mt-3 justify-content-start'>
            <Card className='w-100 px-2 py-3 clickable' style={{border: 'solid 1px lightgrey', textAlign: 'center'}} onClick={onClickContactMfrTeam}>
              <div className='my-auto'>
              <FiMail className='mt-2 text-size-36 mx-auto' />
              <div className='my-2 text-black main-header text-size-20 mx-auto'>Connect with Our<br/>Manufacturer Relations Team</div>
              <div className='mb-2'>Have questions about the data shown here?<br/>Want to learn more about open opportunities?<br/>Email a member of our Manufacturer Relations team here.</div>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    }
    </Col>
  )
}

export default Help;
