export const setDatasuiteDrawerContents = (title, replaceMode, replaceIndex) => {
  return {
    type: 'SET_DATASUITE_SLIDE_DRAWER_CONTENTS',
    payload: {
      title: title,
      replaceMode: replaceMode,
      replaceIndex: replaceIndex
    }
  }
}

export const setDatasuiteDetailsDrawerContents = (title, drawerContents) => {
  return {
    type: 'SET_DATASUITE_DETAILS_SLIDE_DRAWER_CONTENTS',
    payload: {
      title: title,
      drawerContents: drawerContents
    }
  }
}

export const setWidgetCatalog = (widgets) => {
  return {
    type: 'SET_DATASUITE_WIDGETS',
    payload: widgets
  }
}

export const setTopOperator = (operator) => {
  return {
    type: 'SET_DATASUITE_HIGHLIGHTS_TOPOPERATOR',
    payload: operator
  }
}

export const setTrendingSales = (sales) => {
  return {
    type: 'SET_DATASUITE_HIGHLIGHTS_TRENDINGSALES',
    payload: sales
  }
}

export const setTrendingCategory = (category) => {
  return {
    type: 'SET_DATASUITE_HIGHLIGHTS_TRENDINGCATEGORY',
    payload: category
  }
}

export const setBiggestGrowthOpportunity = (opportunity) => {
  return {
    type: 'SET_DATASUITE_HIGHLIGHTS_BIGGESTGROWTHOPPORTUNITY',
    payload: opportunity
  }
}

export const setDataSuiteAllFilters = (filters) => {
  return {
    type: 'SET_DATASUITE_ALL_FILTERS',
    payload: filters
  }
}

export const setSalesByOperator = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESBYOPERATOR',
    payload: data
  }
}

export const setSalesByOperatorDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESBYOPERATOR_DETAILS',
    payload: data
  }
}

export const setSalesByCategory = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESBYCATEGORY',
    payload: data
  }
}

export const setSalesByCategoryDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESBYCATEGORY_DETAILS',
    payload: data
  }
}

export const setGrowthOpportunity = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_GROWTHOPPORTUNITY',
    payload: data
  }
}

export const setGrowthOpportunityDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_GROWTHOPPORTUNITY_DETAILS',
    payload: data
  }
}

export const setLeakage = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_LEAKAGE',
    payload: data
  }
}

export const setLeakageDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_LEAKAGE_DETAILS',
    payload: data
  }
}

export const setTargetsByCategory = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_TARGETSBYCATEGORY',
    payload: data
  }
}

export const setTargetsByCategoryDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_TARGETSBYCATEGORY_DETAILS',
    payload: data
  }
}

export const setCompetitiveInsights = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_COMPETITIVEINSIGHTS',
    payload: data
  }
}

export const setCompetitiveInsightsDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_COMPETITIVEINSIGHTS_DETAILS',
    payload: data
  }
}

export const setSalesByDistributor = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESBYDISTRIBUTOR',
    payload: data
  }
}

export const setSalesByDistributorDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESBYDISTRIBUTOR_DETAILS',
    payload: data
  }
}

export const setOpportunityByDistributor = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYDISTRIBUTOR',
    payload: data
  }
}

export const setOpportunityByDistributorDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYDISTRIBUTOR_DETAILS',
    payload: data
  }
}

export const setOpportunityByStore = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYSTORE',
    payload: data
  }
}

export const setOpportunityByStoreDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_OPPORTUNITYBYSTORE_DETAILS',
    payload: data
  }
}

export const setNewLocations = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_NEWLOCATIONS',
    payload: data
  }
}

export const setNewLocationsDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_NEWLOCATIONS_DETAILS',
    payload: data
  }
}

export const setLostLocations = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_LOSTLOCATIONS',
    payload: data
  }
}

export const setLostLocationsDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_LOSTLOCATIONS_DETAILS',
    payload: data
  }
}

export const setSalesVoidOpportunity = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESVOIDOPPORTUNITY',
    payload: data
  }
}

export const setSalesVoidOpportunityDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_SALESVOIDOPPORTUNITY_DETAILS',
    payload: data
  }
}

export const setTopProducts = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_TOPPRODUCTS',
    payload: data
  }
}

export const setTopProductsDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_TOPPRODUCTS_DETAILS',
    payload: data
  }
}

export const setTopCategories = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_TOPCATEGORIES',
    payload: data
  }
}

export const setTopCategoriesDetails = (data) => {
  return {
    type: 'SET_DATASUITE_WIDGETS_TOPCATEGORIES_DETAILS',
    payload: data
  }
}

export const setDataSetsFilter = (chainData, gpoData) => {
  return {
    type: 'SET_DATASUITE_DATASETS_FILTER',
    payload: { chainData, gpoData }
  }
}

export const setCategoriesFilter = (categories) => {
  return {
    type: 'SET_DATASUITE_CATEGORIES_FILTER',
    payload: categories
  }
}

export const setDistributorsFilter = (distributors) => {
  return {
    type: 'SET_DATASUITE_DISTRIBUTORS_FILTER',
    payload: distributors
  }
}

export const setLocationsFilter = (locations) => {
  return {
    type: 'SET_DATASUITE_LOCATIONS_FILTER',
    payload: locations
  }
}

export const setSegmentsFilter = (segments) => {
  return {
    type: 'SET_DATASUITE_SEGMENTS_FILTER',
    payload: segments
  }
}

export const setIncludedSearchDetailsFilter = (data) => {
  return {
    type: 'SET_DETAILS_INCLUDEDSEARCH_FILTER',
    payload: data
  }
}

export const setExcludedSearchDetailsFilter = (data) => {
  return {
    type: 'SET_DETAILS_EXCLUDEDSEARCH_FILTER',
    payload: data
  }
}

export const setDataSetsDetailsFilter = (chainData, gpoData) => {
  return {
    type: 'SET_DETAILS_DATASETS_FILTER',
    payload: { chainData, gpoData }
  }
}

export const setCategoriesDetailsFilter = (categories) => {
  return {
    type: 'SET_DETAILS_CATEGORIES_FILTER',
    payload: categories
  }
}

export const setDistributorsDetailsFilter = (distributors) => {
  return {
    type: 'SET_DETAILS_DISTRIBUTORS_FILTER',
    payload: distributors
  }
}

export const setLocationsDetailsFilter = (locations) => {
  return {
    type: 'SET_DETAILS_LOCATIONS_FILTER',
    payload: locations
  }
}

export const setSegmentsDetailsFilter = (segments) => {
  return {
    type: 'SET_DETAILS_SEGMENTS_FILTER',
    payload: segments
  }
}

export const setDataSetsExportFilter = (chainData, gpoData) => {
  return {
    type: 'SET_EXPORT_DATASETS_FILTER',
    payload: { chainData, gpoData }
  }
}

export const setCategoriesExportFilter = (categories) => {
  return {
    type: 'SET_EXPORT_CATEGORIES_FILTER',
    payload: categories
  }
}

export const setDistributorsExportFilter = (distributors) => {
  return {
    type: 'SET_EXPORT_DISTRIBUTORS_FILTER',
    payload: distributors
  }
}

export const setLocationsExportFilter = (locations) => {
  return {
    type: 'SET_EXPORT_LOCATIONS_FILTER',
    payload: locations
  }
}

export const setSegmentsExportFilter = (segments) => {
  return {
    type: 'SET_EXPORT_SEGMENTS_FILTER',
    payload: segments
  }
}

export const setCompDataSetsExportFilter = (chainData, gpoData) => {
  return {
    type: 'SET_COMP_EXPORT_DATASETS_FILTER',
    payload: { chainData, gpoData }
  }
}

export const setCompCategoriesExportFilter = (categories) => {
  return {
    type: 'SET_COMP_EXPORT_CATEGORIES_FILTER',
    payload: categories
  }
}

export const setCompLocationsExportFilter = (locations) => {
  return {
    type: 'SET_COMP_EXPORT_LOCATIONS_FILTER',
    payload: locations
  }
}

export const setCompSegmentsExportFilter = (segments) => {
  return {
    type: 'SET_COMP_EXPORT_SEGMENTS_FILTER',
    payload: segments
  }
}

export const setSalesByOperatorLoading = (boolean) => {
  return {
    type: 'SET_SALESBYOPERATOR_ISLOADNG',
    payload: boolean
  }
}

export const setSalesByCategoryLoading = (boolean) => {
  return {
    type: 'SET_SALESBYCATEGORY_ISLOADNG',
    payload: boolean
  }
}

export const setGrowthOpportunityLoading = (boolean) => {
  return {
    type: 'SET_GROWTHOPPORTUNITY_ISLOADNG',
    payload: boolean
  }
}

export const setLeakageLoading = (boolean) => {
  return {
    type: 'SET_LEAKAGE_ISLOADNG',
    payload: boolean
  }
}

export const setTargetsByCategoryLoading = (boolean) => {
  return {
    type: 'SET_TARGETSBYCATEGORY_ISLOADNG',
    payload: boolean
  }
}

export const setCompetitiveInsightsLoading = (boolean) => {
  return {
    type: 'SET_COMPETITIVEINSIGHTS_ISLOADNG',
    payload: boolean
  }
}

export const setSalesByDistributorLoading = (boolean) => {
  return {
    type: 'SET_SALESBYDISTRIBUTOR_ISLOADNG',
    payload: boolean
  }
}

export const setOpportunityByDistributorLoading = (boolean) => {
  return {
    type: 'SET_OPPORTUNITYBYDISTRIBUTOR_ISLOADNG',
    payload: boolean
  }
}

export const setOpportunityByStoreLoading = (boolean) => {
  return {
    type: 'SET_OPPORTUNITYBYSTORE_ISLOADNG',
    payload: boolean
  }
}

export const setNewLocationsLoading = (boolean) => {
  return {
    type: 'SET_NEWLOCATIONS_ISLOADNG',
    payload: boolean
  }
}

export const setLostLocationsLoading = (boolean) => {
  return {
    type: 'SET_LOSTLOCATIONS_ISLOADNG',
    payload: boolean
  }
}

export const setSalesVoidOpportunityLoading = (boolean) => {
  return {
    type: 'SET_SALESVOID_ISLOADNG',
    payload: boolean
  }
}

export const setTopProductsLoading = (boolean) => {
  return {
    type: 'SET_TOPPRODUCTS_ISLOADNG',
    payload: boolean
  }
}

export const setTopCategoriesLoading = (boolean) => {
  return {
    type: 'SET_TOPCATEGORIES_ISLOADNG',
    payload: boolean
  }
}

export const setSalesByOperatorDetailsLoading = (boolean) => {
  return {
    type: 'SET_SALESBYOPERATORDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setSalesByCategoryDetailsLoading = (boolean) => {
  return {
    type: 'SET_SALESBYCATEGORYDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setGrowthOpportunityDetailsLoading = (boolean) => {
  return {
    type: 'SET_GROWTHOPPORTUNITYDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setLeakageDetailsLoading = (boolean) => {
  return {
    type: 'SET_LEAKAGEDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setTargetsByCategoryDetailsLoading = (boolean) => {
  return {
    type: 'SET_TARGETSBYCATEGORYDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setCompetitiveInsightsDetailsLoading = (boolean) => {
  return {
    type: 'SET_COMPETITIVEINSIGHTSDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setSalesByDistributorDetailsLoading = (boolean) => {
  return {
    type: 'SET_SALESBYDISTRIBUTORDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setOpportunityByDistributorDetailsLoading = (boolean) => {
  return {
    type: 'SET_OPPORTUNITYBYDISTRIBUTORDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setOpportunityByStoreDetailsLoading = (boolean) => {
  return {
    type: 'SET_OPPORTUNITYBYSTOREDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setNewLocationsDetailsLoading = (boolean) => {
  return {
    type: 'SET_NEWLOCATIONSDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setLostLocationsDetailsLoading = (boolean) => {
  return {
    type: 'SET_LOSTLOCATIONSDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setSalesVoidOpportunityDetailsLoading = (boolean) => {
  return {
    type: 'SET_SALESVOIDDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setTopProductsDetailsLoading = (boolean) => {
  return {
    type: 'SET_TOPPRODUCTSDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setTopCategoriesDetailsLoading = (boolean) => {
  return {
    type: 'SET_TOPCATEGORIESDETAILS_ISLOADNG',
    payload: boolean
  }
}

export const setItemLevelDescriptionData = (data) => {
  return {
    type: 'SET_ITEM_LEVEL_DESCRIPTION_DATA',
    payload: data
  }
}

export const setItemLevelDescriptionLoading = (boolean) => {
  return {
    type: 'SET_ITEM_LEVEL_DESCRIPTION_LOADING',
    payload: boolean
  }
}

export const setItemMatchData = (data) => {
  return {
    type: 'SET_ITEM_MATCH_DATA',
    payload: data
  }
}

export const setItemMatchLoading = (boolean) => {
  return {
    type: 'SET_ITEM_MATCH_LOADING',
    payload: boolean
  }
}

export const setDetailsPageLoading = (boolean) => {
  return {
    type: 'SET_DETAILS_PAGE_LOADING',
    payload: boolean
  }
}

export const clearDataSuiteHighlights = () => {
  return {
    type: 'CLEAR_DATASUITE_HIGHLIGHTS'
  }
}
export const clearDataSuite = () => {
  return {
    type: 'CLEAR_DATASUITE'
  }
}