import Axios from "axios";

const url = '/datasuite';

const datasuiteApi = {
  highlights: {
    topOperator: {
        post: (params) => {
            return Axios.post(`${url + '/highlights/toplocations'}`, params); 
        },
        report: {
            post: (params) => {
                return Axios.post(`${url + '/details/toplocations'}`, params);
            }
        }
    },
    trendingSales: {
        post: (params) => {
            return Axios.post(`${url + '/highlights/locations'}`, params); 
        },
        report: {
            post: (params) => {
                return Axios.post(`${url + '/details/locations'}`, params);
            }
        }
    },
    trendingCategory: {
        post: (params) => {
            return Axios.post(`${url + '/highlights/categories'}`, params); 
        },
        report: {
            post: (params) => {
                return Axios.post(`${url + '/details/categories'}`, params);
            }
        }
    },
    growthOpportunity: {
        post: (params) => {
            return Axios.post(`${url + '/highlights/growthOpportunity'}`, params);
        },
        report: {
            post: (params) => {
                return Axios.post(`${url + '/details/growthOpportunity'}`, params);
            }
        }
    }
  },
  filters: {
    post: (params) => {
        return Axios.post(`${url + '/filters'}`, params);
    }
  },
  widgets: {
    salesByOperator: {
        post: (params) => {
            return Axios.post(`${url + '/details/locations'}`, params);
        }
    },
    salesByCategory: {
        post: (params) => {
            return Axios.post(`${url + '/details/categories'}`, params);
        }
    },
    salesByDistributor: {
        post: (params) => {
            return Axios.post(`${url + '/details/distributors'}`, params);
        }
    },
    opportunityByStore: {
        post: (params) => {
            return Axios.post(`${url + '/details/locations'}`, params);
        }
    },
    newLocations: {
        post: (params) => {
            return Axios.post(`${url + '/details/newlocations'}`, params);
        }
    },
    lostLocations: {
        post: (params) => {
            return Axios.post(`${url + '/details/lostlocations'}`, params);
        }
    },
    salesVoid: {
        post: (params) => {
            return Axios.post(`${url + '/details/salesvoid'}`, params);
        }
    },
    growthOpportunity: {
        post: (params) => {
            return Axios.post(`${url + '/details/growthOpportunity'}`, params);
        }
    },
    leakage: {
        post: (params) => {
            return Axios.post(`${url + '/details/leakage'}`, params);
        }
    },
    targetsByCategory: {
        post: (params) => {
            return Axios.post(`${url + '/details/targetcategories'}`, params);
        }
    },
    competitiveInsights: {
        post: (params) => {
            return Axios.post(`${url + '/details/insights'}`, params);
        }
    },
    itemLevelDescriptions: {
        post: (params) => {
            return Axios.post(`${url + '/details/mfritems'}`, params);
        }
    },
    topProducts: {
        post: (params) => {
            return Axios.post(`${url + '/details/productVolume'}`, params);
        }
    },
    topCategories: {
        post: (params) => {
            return Axios.post(`${url + '/details/categoryVolume'}`, params);
        }
    }
  },
  export: {
    manufacturerItems: {
        post: (params) => {
            return Axios.post(`${url + '/export/mfritems'}`, params);
        }
    },
    competitorItems: {
        post: (params) => {
            return Axios.post(`${url + '/export/mfrcompitems'}`, params);
        }
    }
  },
  itemMatch: {
    post: (params) => {
        return Axios.post(`${url + '/details/matches'}`, params);
    }
  }
};

export default datasuiteApi;