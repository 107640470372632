import React from 'react';
import Router from  './Router';
import './App.scss';
import useAxios from '../sharedComponents/useAxios/useAxios';
import { Container } from 'reactstrap';
import Spinner from '../sharedComponents/Spinner/Spinner';
import Modals from '../sharedComponents/Modals/Modals';
import { MantineProvider } from '@mantine/core';
import { Buffer } from 'buffer';

function App() {
  window.Buffer = Buffer;
  const ready = useAxios();
  if (!ready) {
    return (
      <Container />
    );
  }
  return (
    <MantineProvider>
      <Router />
      <Modals />
      <Spinner />
    </MantineProvider>
  );
};

export default App;
