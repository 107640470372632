import React from 'react';
import TopBar from '../TopBar/TopBar';

const Header = () => {

  return (
    <TopBar />
  );
};

export default Header;