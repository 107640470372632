import React, { useEffect } from 'react';
import { Col, Row, Spinner, Input, InputGroupText, InputGroup } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FaDownload } from 'react-icons/fa';
import DateRangePicker from '../../../sharedComponents/DateRangePicker/DateRangePicker';
import Title from '../../../sharedComponents/Title/Title';
import useDashboard from '../useDashboard';
import { BsSearch } from 'react-icons/bs';
import moment from 'moment';
import EmptyDataMessage from '../../../sharedComponents/ErrorMessages/EmptyDataMessage';
import { Theme } from '../../../config/constants';
import { isMobileOnly } from 'react-device-detect';

const BEPDistributorListCard = () => {
    const isLoading = useSelector(({dashboard}) => dashboard.bepDistributorListIsLoading);
    const bepDistributorListData = useSelector(({dashboard}) => dashboard.bepDistributorListData);
    const { getBEPDistributorListData, onClickDownloadCsv, onChangeCsvDateFilter } = useDashboard.useDates();
    const { filterCsvTable } = useDashboard.useDashboard();

    const initializePage = () => {
        getBEPDistributorListData();
    }

    useEffect(initializePage
        //  eslint-disable-next-line  
      , []);

    const bepDistributorListMap = bepDistributorListData?.sort((a, b) => b.id - a.id).map((data, idx) =>{
        const dateSplit = data.date.split('-');
        const formattedTitle = `BEP_Distributor_List_${dateSplit[0].length <= 1 ? '0' + dateSplit[0] : dateSplit[0]}_${dateSplit[1]}`;
        return (
            <tr key={`${idx}`}>
                <td>{formattedTitle}</td>
                {isMobileOnly ? (
                    <>
                    <td>{(dateSplit[0].length <= 1 ? '0' + dateSplit[0] : dateSplit[0]) + '-' + (dateSplit[1] % 1000)}</td>
                    <td className='clickable' style={{color: Theme().colors.accent}} align='right' onClick={() => onClickDownloadCsv(formattedTitle)}><FaDownload /></td>
                    </>
                ):(
                    <>
                    <td>{(dateSplit[0].length <= 1 ? '0' + dateSplit[0] : dateSplit[0]) + '-' + dateSplit[1]}</td>
                    <td className='clickable' style={{color: Theme().colors.accent}} align='right' onClick={() => onClickDownloadCsv(formattedTitle)}><FaDownload /> Download</td>
                    </>
                )}
            </tr>
        )
    });

    if (isLoading || bepDistributorListMap.length <= 0) {
        return (
            <Col style={{ height: '100%' }}>
                <Title title='BEP Distributor List' />
                <Row className='mb-2'>
                    <Col className='col-4 pe-1'>
                        <InputGroup size='sm'>
                            <InputGroupText>
                                <BsSearch/>
                            </InputGroupText>
                            <Input className='text-input' id="csvSearchInput" placeholder='Search...'/> 
                        </InputGroup>
                    </Col>
                    <Col className='w-fc'>
                        <DateRangePicker 
                            id='csvDateSearch' 
                            defaultStartDate={moment().subtract(2, 'year').format('MM/DD/YYYY')} 
                            defaultEndDate={moment().format('MM/DD/YYYY')} 
                            minDate={moment().subtract(2, 'year').format('MM/DD/YYYY')} 
                            maxDate={moment().format('MM/DD/YYYY')} 
                            callback={() => onChangeCsvDateFilter()}
                        />
                    </Col>
                </Row>
                <Row style={{height: '75%', overflowY: 'auto'}}>
                    <div>
                        {isLoading &&
                        <div className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                            <Spinner color='beplBlue' animation="border" />
                        </div>
                        }
                        {!isLoading &&
                        <EmptyDataMessage />
                        }
                    </div>
                </Row>
            </Col>
        );
    }
    return (
        <Col style={{ height: '100%' }}>
            <Title title='BEP Distributor List' />
            <Row className='mb-2'>
                <Col className='col-4 pe-1'>
                    <InputGroup size='sm'>
                        <InputGroupText>
                            <BsSearch/>
                        </InputGroupText>
                        <Input className='text-input' id="csvSearchInput" placeholder='Search...' onChange={() => filterCsvTable()}/> 
                    </InputGroup>
                </Col>
                <Col className='w-fc'>
                    <DateRangePicker 
                        id='csvDateSearch' 
                        defaultStartDate={moment().subtract(2, 'year').format('MM/DD/YYYY')} 
                        defaultEndDate={moment().format('MM/DD/YYYY')} 
                        minDate={moment().subtract(2, 'year').format('MM/DD/YYYY')} 
                        maxDate={moment().format('MM/DD/YYYY')} 
                        callback={(start, end) => onChangeCsvDateFilter(start, end)} 
                        ranges={{
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'Last 2 Months': [moment().subtract(2, 'month'), moment()],
                            'Last Year': [moment().subtract(1, 'year'), moment()],
                            'Last Two Years': [moment().subtract(2, 'year'), moment()]
                            }
                        }
                    />
                </Col>
            </Row>
            <Row style={{height: '75%', overflowY: 'auto'}}>
                <div>
                    <table className="table topCategoriesTable border-grayLight" id="distributorCsvTable">
                        <thead>
                        <tr>
                            <th scope="col" >FILE NAME</th>
                            <th scope="col" >DATE CREATED</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                            {bepDistributorListMap}
                        </tbody>
                    </table>
                </div>
            </Row>
        </Col>
    );
}

export default BEPDistributorListCard;