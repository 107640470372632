import React, {Fragment} from 'react';
import { Row, Col } from 'reactstrap';
import { BsTrash } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';

const UsersRolesRow = (props) => {
  const { userDelete } = props;
  const user  = props.data;
  return (
    <Row className={'py-2 ' + (isMobile ? ' border-bottom' : 'text-size-14 line-height-16')}>
        <Col className={isMobile ? 'py-1 col-9': 'col-2'}>
          {user.first_name + " " + user.last_name}
        </Col>
        { isMobile && 
          <Col xs={3}>
            <Row>
              <Col className='w-fc clickable'>
                <BsTrash color='inherit' size={16} onClick={()=> userDelete(user)} />
              </Col>
            </Row>
          </Col>
        }
        <Col className={isMobile ? 'py-1 col-12': 'col-3'}>
          {user.email}
        </Col>
        <Col className={isMobile ? 'py-1 col-12': 'col-2'}>
          {user.title}
        </Col>
        { !isMobile &&
          <Fragment>
            <Col className='w-fc clickable'>
              <BsTrash color='inherit' size={16} onClick={()=> userDelete(user)} />
            </Col>
          </Fragment>
        }
      </Row>
  )
}

export default UsersRolesRow;
