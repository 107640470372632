import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { IoIosArrowForward } from "react-icons/io";
import { isMobile, isTablet } from 'react-device-detect';

const SlideDrawer = (props) => {
  const dispatch = useDispatch();
  const enabled = useSelector(({ui}) => ui.slideDrawer);

  return (
    <div className={((isMobile &! isTablet) ? "side-drawer-mobile" : "side-drawer") + (enabled ? ' open' : '') + ' ' + props.className}>
        <Row className="m-3">
            <Col className="w-fc dflex text-size-24 ps-0">
                <IoIosArrowForward style={{color: 'black', position: 'relative'}} className="clickable mb-1" onClick={() => dispatch(slideDrawer.subtract())}></IoIosArrowForward>
            </Col>
            <Col className='ps-1 my-auto'>
                <div className="text-black main-header text-size-20">{props.title}</div>
            </Col>
        </Row>
        <Row className='w-100 ms-0'>
          <div style={{width: '100%', height: '1px', background: '#D7D8D9'}}></div>
        </Row>
        {props.drawerContents}
    </div>
  );
}

export default SlideDrawer;