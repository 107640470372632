import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import useMarketingCampaigns from './useMarketingCampaigns';

const MarketingCampaignsRow = () => {
    const marketingCampaignsData = useSelector(({marketing}) => marketing.marketingCampaignsData);
    const { mapMarketingCampaigns, mapMarketingCampaignsMobile } = useMarketingCampaigns.useMarketingCampaigns();
    var marketingCampaignsMap = [];

    if(isMobile && !isTablet) {
        marketingCampaignsMap = mapMarketingCampaignsMobile(marketingCampaignsData);
    } else {
        marketingCampaignsMap = mapMarketingCampaigns(marketingCampaignsData);
    }

    return (
        <tbody>
            {marketingCampaignsMap}
        </tbody>
    );
    
};

export default MarketingCampaignsRow;