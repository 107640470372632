import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Theme } from '../../../config/constants';

const MarketingCampaignsDetailsAssets = (props) => {
  var assets = props.assets;
  if(typeof assets === 'string') {
    assets = [assets];
  }
  const assetsMap = assets?.map((link, i) => (
    <Fragment  key={`campaignAssets-${i}`}>
        <Row className='w-fc ms-4 my-2' href={link}>{link}</Row>
    </Fragment>
  ));
    
  return (
    <Col className='text-size-14 clickable align-items-left h-100 w-100' style={{color: Theme().colors.accent}}>
      {assetsMap}
    </Col>
  );
};


export default MarketingCampaignsDetailsAssets;