import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useOpportunities from './useOpportunities';

const OpportunitiesRow = () => {
    const location = useLocation();
    const opportunitiesList = useSelector(({opportunities}) => opportunities.opportunitiesItems);
    const { mapOpportunities } = useOpportunities.useOpportunities();
    const opportunitiesMap = mapOpportunities(opportunitiesList, location?.state?.source);

    if(opportunitiesMap) {
        return (
            <tbody>
                {opportunitiesMap}
            </tbody>
    );
    }
    else return (
        <div>No data to display.</div>
    );
    
};

export default OpportunitiesRow;