const formatters = () => {
    const usCurrencyFormatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });

    const numberFormatter = Intl.NumberFormat('en-US');

    const percentFormatter = Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 0,
    });

    return {
        usCurrencyFormatter,
        numberFormatter,
        percentFormatter
    };
}

export default formatters;