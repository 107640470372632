import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Theme } from '../../config/constants';

const AgreementStatusIndicatorMobile = ({...props}) => {
  const status = props.status;
  switch (status){
    case 'Lost':
        return(
            <div><FaCircle color='#CF3F3F'/></div>
        )
    case 'Closed Lost':
        return(
            <div><FaCircle color='#CF3F3F'/></div>
        )
    case 'Won':
        return(
            <div><FaCircle color='#009D9A'/></div>
        )
    case 'Won Pending Validation':
        return(
            <div><FaCircle color='#FA9016'/></div>
        )
    case 'Closed Pending':
        return(
            <div><FaCircle color='#DDC734'/></div>
        )
    case 'Active Opportunity':
        return(
            <div><FaCircle style={{color: Theme().colors.accent}}/></div>
        )
    case 'Identified':
        return(
            <div><FaCircle className="text-beplBlue"/></div>
        )
    case 'Pending':
        return(
            <div><FaCircle color='#DDC734'/></div>
        )
    default:
        return (
            <div>{status}</div>
        );
   }; 
};


export default AgreementStatusIndicatorMobile;