const authKey = {
  get: () => {
    const authKey = localStorage.getItem('authKey') ;
    return authKey ? authKey : null
  },
  set: (value) => {
    localStorage.setItem('authKey', value);
  }
};

const resetKey = {
  get: () => {
    const resetKey = localStorage.getItem('resetKey') ;
    return resetKey ? resetKey : null
  },
  set: (value) => {
    localStorage.setItem('resetKey', value);
  }
};

const userData = {
  get: () => {
    const userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null
  },
  set: (value) => {
    localStorage.setItem('userData', JSON.stringify(value));
  }
};

const manufacturerData = {
  get: () => {
    const manufacturerData = localStorage.getItem('manufacturerData');
    return manufacturerData ? JSON.parse(manufacturerData) : null
  },
  set: (value) => {
    localStorage.setItem('manufacturerData', JSON.stringify(value));
  }
};

const allManufacturerData = {
  get: () => {
    const allManufacturerData = localStorage.getItem('allManufacturerData');
    return allManufacturerData ? JSON.parse(allManufacturerData) : null
  },
  set: (value) => {
    localStorage.setItem('allManufacturerData', JSON.stringify(value));
  }
};

const tableauTicket = {
  get: () => {
    const tableauData = localStorage.getItem('tableauData');
    return tableauData ? JSON.parse(tableauData) : null
  },
  set: (value) => {
    localStorage.setItem('tableauData', JSON.stringify(value));
  }
};

const clear = () => {
  localStorage.removeItem('authKey');
  localStorage.removeItem('resetKey');
  localStorage.removeItem('userData');
  localStorage.removeItem('manufacturerData');
  localStorage.removeItem('allManufacturerData');
  localStorage.removeItem('tableauData');
};

const LocalStorage = {
  authKey,
  resetKey,
  userData,
  manufacturerData,
  allManufacturerData,
  tableauTicket,
  clear
};

export default LocalStorage;