import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button, Spinner, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AutoSizer, Table, Column } from "react-virtualized";
import useDataSuite from "../../AppMain/DataSuite/useDataSuite";
import useModal from "../useModal/useModal";
import formatters from "../../config/formatters";
import { Theme } from "../../config/constants";

const ItemMatchModal = ({ id, ...props }) => {
  const isLoading = useSelector(({ datasuite }) => datasuite.itemMatchLoading);
  const itemMatchData = useSelector(({ datasuite }) => datasuite.itemMatchData);
  const { getItemMatches, onClickItemMatchesExport } = useDataSuite.useDataSuite();
  const { modalProps, headerProps } = useModal(id, { hideClose: false, centered: true, header: { color: 'black', background: 'white' } });
  const { numberFormatter } = formatters();

  // eslint-disable-next-line
  useEffect(() => getItemMatches([props.location], [props.category]), []);

  if (isLoading || itemMatchData?.length <= 0) {
    return (
      <Modal {...modalProps} modalClassName="item-match-modal">
        <ModalHeader {...headerProps}>
          <Row className="w-fc ms-0">
            Matched Items for {props.location} - {props.category}
          </Row>
        </ModalHeader>
        <ModalBody >
          <Col className="mx-auto my-auto d-flex justify-content-center align-items-center" style={{ height: "100%", width: "100%" }}>
            {isLoading && <Spinner color='beplBlue' />}
            {(!isLoading && itemMatchData?.length <= 0) &&
              <div className='main-header' style={{ textAlign: 'center' }}>No data to display.</div>
            }
          </Col>
        </ModalBody>
      </Modal>
    );
  }
  else return (
    <Modal {...modalProps} modalClassName="item-match-modal">
      <ModalHeader {...headerProps}>
        <Row className="w-fc ms-0">
          Matched Items for {props.location} - {props.category}
        </Row>
      </ModalHeader>
      <ModalBody >
        <Col className='my-auto text-size-14' style={{ width: "100%" }}>
          <Row className="mx-0" style={{ height: "40px", width: "100%" }}>
            <Col className="text-size-16" style={{ fontWeight: '600', paddingTop: "4px" }}>
              {itemMatchData?.length === 1 &&
                <span>{itemMatchData?.length} Opportunity to View Below</span>
              }
              {itemMatchData?.length > 1 &&
                <span>{itemMatchData?.length} Opportunities to View Below</span>
              }
            </Col>
            <Col className="w-fc">
              <Button
                block
                className='py-1 button-label'
                size='sm'
                style={{ width: '80px' }}
                color={Theme().colors.accentName}
                onClick={() => onClickItemMatchesExport(itemMatchData, props.location, props.category)}>Export
              </Button>
            </Col>
          </Row>
          <div style={{ height: "400px", width: "100%", overflowY: "auto" }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table className="table itemMatchTable border-grayLight" id="itemMatchTable"
                  width={width}
                  height={height}
                  headerHeight={34.5}
                  rowHeight={38}
                  rowCount={itemMatchData.length}
                  rowGetter={({ index }) => itemMatchData[index]}
                >
                  <Column
                    label='MIN'
                    dataKey='match_min'
                    width={width * 0.15}
                  />
                  <Column
                    width={width * 0.4}
                    label='Description'
                    dataKey='match_description'
                  />
                  <Column
                    width={width * 0.25}
                    label='Category'
                    dataKey='match_category'
                    cellRenderer={({ cellData }) => props.category}
                  />
                  <Column
                    width={width * 0.15}
                    label='Pack Size'
                    dataKey='match_pack'
                  />
                  <Column
                    width={width * 0.1}
                    label='Cases'
                    dataKey='total_cases'
                    cellRenderer={({ cellData }) => numberFormatter.format(cellData)}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default ItemMatchModal;