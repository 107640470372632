import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner, Button } from 'reactstrap';
import ReactSelect, { components } from 'react-select';
import formatters from '../../../config/formatters';
import useDashboardTables from './useDashboardTables';
import EmptyDataMessage from '../../../sharedComponents/ErrorMessages/EmptyDataMessage';
import { selectStyles, Theme } from '../../../config/constants';
import { BsCaretDownFill } from 'react-icons/bs';

const TopProductsTable = () => {
    const isLoading = useSelector(({dashboard}) => dashboard.topProductsIsLoading);
    const topProductsData = useSelector(({dashboard}) => dashboard.topProducts);
    const { onSelectChange, onClickSeeMore } = useDashboardTables.useProductsTable();
    const { usCurrencyFormatter, percentFormatter, numberFormatter } = formatters();
    const topProductsOptions = [
        {value: 'Total Volume', label: 'Sort By: Total Volume ($)'},
        {value: 'MIN', label: 'Sort By: MIN'},
        {value: 'Description', label: 'Sort By: Description'},
        {value: 'Cases', label: 'Sort By: Cases'},
        {value: '% of Sales', label: 'Sort By: % of Sales'}
    ];

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              {children}
            </components.Option>
        );
      };

    const topProductsMap = topProductsData?.map((data, idx) =>{
        return (
            <tr key={`${idx}`}>
                <td>{data?.min}</td>
                <td>{data?.description}</td>
                <td>{usCurrencyFormatter.format(data?.total_volume)}</td>
                <td>{numberFormatter.format(data?.total_cases)}</td>
                <td>{percentFormatter.format(data?.sales_percent)}</td>
            </tr>
        )
    });

    const slideDrawerContents = (
        <div className='m-3' style={{height: '92vh', overflowY: 'auto'}}>
            <table className= {(window.innerWidth < 1024 ?  "table topCategoriesTable border-grayLight text-size-14":"table topCategoriesTable border-grayLight")} id="topCategoriesTable">
                <thead>
                <tr>
                    <th scope="col" >MIN</th>
                    <th scope="col" >DESCRIPTION</th>
                    <th scope='col' >TOTAL VOLUME ($)</th>
                    <th scope='col' >CASES</th>
                    <th scope="col" >% OF SALES</th>
                </tr>
                </thead>
                <tbody>
                    {topProductsMap}
                </tbody>
            </table>
        </div> 
    );

    if (isLoading || topProductsMap.length <= 0) {
        return (
            <Col>
                <Row>
                    <Col className={(window.innerWidth < 1024 ? "col-7" : "col-5")}>
                        <ReactSelect 
                            styles={selectStyles}
                            components={{DropdownIndicator, Option: InputOption}}
                            options = {topProductsOptions}
                            defaultValue = {topProductsOptions[0]}
                        />
                    </Col>
                    <Col className={(window.innerWidth < 1024 ? "col-4" : "")}></Col>
                    <Col>
                        <Button 
                              block 
                              outline={true}
                              style={{float: 'right', padding: '6px', fontSize: '14px'}} 
                              color={Theme().colors.accentName}>See More
                          </Button>
                    </Col>
                </Row>
                <Row>
                    <div style={{overflowY: 'auto', height: '100%'}}>
                        {isLoading &&
                        <div className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                            <Spinner color='beplBlue' animation="border" />
                        </div>
                        }
                        {!isLoading &&
                        <EmptyDataMessage />
                        }
                    </div> 
                </Row>
            </Col>
        );
    }
    return (
        <Col style={{height: '100%'}}>
            <Row className='pb-2'>
                <Col className={(window.innerWidth < 1024 ? "col-7" : "col-5")}>
                    <ReactSelect 
                        styles={selectStyles}
                        components={{DropdownIndicator, Option: InputOption}}
                        options = {topProductsOptions}
                        defaultValue = {topProductsOptions[0]}
                        onChange = {(e) => onSelectChange(e.value, topProductsData)}
                    />
                </Col>
                <Col className={(window.innerWidth < 1024 ? "col-1" : "")}></Col>
                <Col>
                    <Button 
                        block 
                        outline={true}
                        style={{float: 'right', padding: '6px', fontSize: '14px'}} 
                        color={Theme().colors.accentName}
                        onClick={() => onClickSeeMore('All Top Products', slideDrawerContents)}>See More
                    </Button>
                </Col>
            </Row>
            <Row style={{overflowY: 'auto', height: '83%'}}>
                <div>
                    <table className={(window.innerWidth < 1024 ?  "table topCategoriesTable border-grayLight text-size-14":"table topCategoriesTable border-grayLight")} id="topCategoriesTable">
                        <thead>
                        <tr>
                            <th scope="col" >MIN</th>
                            <th scope="col" >DESCRIPTION</th>
                            <th scope='col' >TOTAL VOLUME ($)</th>
                            <th scope='col' >CASES</th>
                            <th scope="col" >% OF SALES</th>
                        </tr>
                        </thead>
                        <tbody>
                            {topProductsMap}
                        </tbody>
                    </table>
                </div>  
            </Row>
        </Col>
    );
}

export default TopProductsTable;