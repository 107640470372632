import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import * as $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import LocalStorage from '../LocalStorage/LocalStorage';
import { isMobile } from 'react-device-detect';

const HeaderLogoLoggedIn = ({logo}) => {
  const [h, setH] = useState(0);
  const navigate = useNavigate();
  const manufacturerName = LocalStorage.manufacturerData.get().nickname;

  useEffect(() => {
    const h = $('#header-container').outerHeight();
    setH(h);
  }, [setH]);

  const goToHome = () => {
    navigate('/');
  };

  if (isMobile) {

  } else
  return (
    <div id='header-relative' style={{height: `${h}px`, zIndex: 5}}>
      <Container id='header-container' fluid className='bg-white p-2'>
        <Row>
          <Col className='text-center'>
            <img src={logo} height='25px' style={{float:'left', marginLeft:'1vw'}} alt='Company Logo' className='company-logo clickable' onClick={goToHome}/>
          </Col>
          <Col className='text-black text-end text-size-12 col-9 pt-1 pr-0'>
            {manufacturerName}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

HeaderLogoLoggedIn.propTypes = {
  logo: PropTypes.string.isRequired
};

export default HeaderLogoLoggedIn;