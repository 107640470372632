import React, { useState } from 'react';
import { Row, Col, Input, Button, FormText, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import useCreatePasswordForm from './useCreatePasswordForm';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../config/constants';

const LoginForm = () => {
  const { passwordRepeat, password, validate } = useCreatePasswordForm();
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  const save = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      setApiError('');
    }
  };

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <Form onSubmit={save}>
      <FormGroup>
        <Label className='label-text-header mb-2' for='password'>Create Password *</Label>
        <Input {...password} type='password' />
        <FormFeedback>{password.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header mt-3 mb-2' for='passwordRepeat'>Confirm Password *</Label>
        <Input {...passwordRepeat} type='password' />
        <FormFeedback>{passwordRepeat.message}</FormFeedback>
      </FormGroup>
      <Button type='submit' block color={Theme().colors.accentName} className='py-2 mt-3'>SAVE</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Row className='mb-5'>
        <Col onClick={goToLogin} className='text-center clickable' style={{color: Theme().colors.accent}}>Back to Login</Col>
      </Row>
    </Form>
  );
};

export default LoginForm;