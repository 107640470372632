export default function reducer(state = {
  totalVolume: 0,
  competitorVolume: 0,
  totalRebates: 0,
  annualizedRebates: 0,
  lostItemCategories: [],
  lostItemDescriptions: [],
  highlights: {
    total_volume: 0,
    total_locations: 0,
    total_cases: 0,
    yoy_growth: 0
  },
  slideDrawer: {
    title: '',
    contents: []
  },
  selectedFilters: {
    selectedClientManager: [],
    selectedSource: [],
    selectedStatus: []
  },
  newLocations: [],
  lostLocations: [],
  topCategories: [],
  topProducts: [],
  competitiveInsightsData: [],
  distributorBreakdownData: [],
  bepDistributorListData: [],
  isLoading: false,
  highlightsIsLoading: false,
  topProductIsLoading: false,
  topCategoriesIsLoading: false,
  newLocationsIsLoading: false,
  lostLocationsIsLoading: false,
  totalOpportunitiesChartIsLoading: false,
  competitiveInsightsChartIsLoading: false,
  distributorBreakdownChartIsLoading: false,
  bepDistributorListIsLoading: false,
  }, action) {
    switch(action.type) {
      case 'SET_DASHBOARD_DATA':
        return {
          ...state,
          totalVolume: action.payload.totalVolume,
          competitorVolume: action.payload.competitorVolume,
          totalRebates: action.payload.totalRebates,
          annualizedRebates: action.payload.annualizedRebates,
          lostItemCategories: action.payload.lostItemCategories,
          lostItemDescriptions: action.payload.lostItemDescriptions,
          isLoading: false
        }
      case 'SET_DASHBOARD_HIGHLIGHTS':
        return {
          ...state,
          highlights: action.payload.data
        }
      case 'SET_DASHBOARD_NEW_LOCATIONS':
        return {
          ...state,
          newLocations: action.payload.data
        }
      case 'SET_DASHBOARD_LOST_LOCATIONS':
        return {
          ...state,
          lostLocations: action.payload.data
        }
      case 'SET_DASHBOARD_TOP_CATEGORIES':
        return {
          ...state,
          topCategories: action.payload.data
        }
      case 'SET_DASHBOARD_TOP_PRODUCTS':
        return {
          ...state,
          topProducts: action.payload.data
        }
      case 'SET_DASHBOARD_DISTRIBUTOR_BREAKDOWN_CHART_DATA':
        return {
          ...state,
          distributorBreakdownData: action.payload.data
        }
      case 'SET_COMPETITIVE_INSIGHTS_CHART_DATA':
        return {
          ...state,
          competitiveInsightsData: action.payload.data
        }
      case 'SET_BEP_DISTRIBUTOR_LIST_DATA':
        return {
          ...state,
          bepDistributorListData: action.payload.data
        }
      case 'CLEAR_DASHBOARD_DATA':
        return {
          totalVolume: 0,
          competitorVolume: 0,
          totalRebates: 0,
          annualizedRebates: 0,
          lostItemCategories: [],
          lostItemDescriptions: [],
          highlights: {
            total_volume: 0,
            total_locations: 0,
            total_cases: 0,
            yoy_growth: 0
          },
          slideDrawer: {
            title: '',
            contents: []
          },
          selectedFilters: {
            selectedClientManager: [],
            selectedSource: [],
            selectedStatus: []
          },
          newLocations: [],
          lostLocations: [],
          topCategories: [],
          topProducts: [],
          competitiveInsightsData: [],
          distributorBreakdownData: [],
          bepDistributorListData: [],
          isLoading: false,
          highlightsIsLoading: false,
          topProductIsLoading: false,
          topCategoriesIsLoading: false,
          newLocationsIsLoading: false,
          lostLocationsIsLoading: false,
          totalOpportunitiesChartIsLoading: false,
          competitiveInsightsChartIsLoading: false,
          distributorBreakdownChartIsLoading: false,
          bepDistributorListIsLoading: false
        }
      case 'SET_OPPORTUNITIES_CLIENTMANAGER_FILTER':
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedClientManager: action.payload
          }
        }
      case 'SET_OPPORTUNITIES_SOURCE_FILTER':
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedSource: action.payload
          }
        }
      case 'SET_OPPORTUNITIES_STATUS_FILTER':
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            selectedStatus: action.payload
          }
        }
      case 'SET_HIGHLIGHTS_LOADING' :
        return {
          ...state,
          highlightsIsLoading: action.payload.isLoading
        } 
      case 'SET_TOP_CATEGORIES_LOADING' :
        return {
          ...state,
          topCategoriesIsLoading: action.payload.isLoading
        }
      case 'SET_TOP_PRODUCTS_LOADING' :
        return {
          ...state,
          topProductIsLoading: action.payload.isLoading
        }
      case 'SET_NEW_LOCATIONS_LOADING' :
        return {
          ...state,
          newLocationsIsLoading: action.payload.isLoading
        }
      case 'SET_LOST_LOCATIONS_LOADING' :
        return {
          ...state,
          lostLocationsIsLoading: action.payload.isLoading
        }
      case 'SET_TOTAL_OPPORTUNITIES_CHART_LOADING' :
        return {
          ...state,
          totalOpportunitiesChartIsLoading: action.payload.isLoading
        }
      case 'SET_COMPETITIVE_INSIGHTS_CHART_LOADING' :
        return {
          ...state,
          competitiveInsightsChartIsLoading: action.payload.isLoading
        }
      case 'SET_DISTRIBUTOR_BREAKDOWN_CHART_LOADING' :
        return {
          ...state,
          distributorBreakdownChartIsLoading: action.payload.isLoading
        }
      case 'SET_BEP_DISTRIBUTOR_LIST_LOADING' :
        return {
          ...state,
          bepDistributorListIsLoading: action.payload.isLoading
        }
      case 'SET_DASHBOARD_SLIDE_DRAWER_CONTENTS' :
        return {
          ...state,
          slideDrawer: {
            title: action.payload.title,
            contents: action.payload.contents
          }
        }
      case 'LOADING':
        return {
          ...state,
          isLoading: true
        }
      default:
        return state;
    };
};
