import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { IoIosArrowBack } from "react-icons/io";
import Settings from '../../AppMain/Settings/Settings';

const ProfileSlideDrawer = (props) => {
    const dispatch = useDispatch();
    const enabled = useSelector(({ ui }) => ui.profileSlideDrawer);

    return (
        <div className={(isMobileOnly ? "left-side-drawer-mobileonly" : (isMobile ? "left-side-drawer-mobile" : "left-side-drawer")) + (enabled ? " open" : "")}>
            {isMobileOnly ?
                <Row className="mx-0 mt-3" style={{ borderBottom: '1px solid lightgray', paddingBottom: '10px' }}>
                    <Col className='ms-2' style={{ alignSelf: 'center' }}>
                        <div className="text-black main-header text-size-20">Profile Settings</div>
                    </Col>
                    <Col className="w-fc me-0 text-size-32 pb-1" style={{ alignSelf: 'center', right: '0', position: 'absolute' }}>
                    <IoIosArrowBack style={{ color: 'black' }} className="clickable" onClick={() => dispatch(slideDrawer.subtractProfile())}></IoIosArrowBack>
                    </Col>
                </Row>
                :
                <Row className="mx-0 mt-2" style={{ borderBottom: '1px solid lightgray', paddingBottom: '10px' }}>
                    <Col className='ms-2' style={{ alignSelf: 'center' }}>
                        <div className="text-black main-header text-size-20">Profile Settings</div>
                    </Col>
                    <Col className="w-fc me-0 text-size-32 pb-1" style={{ alignSelf: 'center' }}>
                    <IoIosArrowBack style={{ color: 'black' }} className="clickable" onClick={() => dispatch(slideDrawer.subtractProfile())}></IoIosArrowBack>
                    </Col>
                </Row>
            }

            <Row className="mx-0 my-2">
                <Settings />
            </Row>
        </div>
    );
}

export default ProfileSlideDrawer;