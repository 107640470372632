import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import manufacturerApi from "../../api/manufacturerApi";
import { Row, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import { dataLoading, setAllOppsFilters, setOpportunitiesData, setOpportunitiesSortBy, setOppsFilters, setOpportunitiesTableData } from "../../reducers/OpportunitiesReducer/OpportunitiesActions";
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AgreementStatusIndicator from "../../sharedComponents/AgreementStatusIndicator/AgreementStatusIndicator";
import AgreementStatusIndicatorMobile from "../../sharedComponents/AgreementStatusIndicator/AgreementStatusIndicatorMobile";
import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";
import { slideDrawer } from "../../reducers/UIReducer/UIActions";
import OpportunitiesSeeDetailsModal from "./Modals/OpportunitiesSeeDetailsModal";
import { setOppsToDate, setOppsFromDate } from '../../reducers/DateReducer/DateActions';
import sortArrayByKey from  '../../sharedComponents/Helper/sortArrayByKey';
import { useNavigate } from "react-router-dom";
import { ciDetailsTabs } from '../../reducers/UIReducer/UIActions';
import { Theme } from "../../config/constants";

const useOpportunities = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const opportunitiesData = useSelector(({opportunities}) => opportunities.opportunitiesItems);
    const sortOpportunitiesState = useSelector(({opportunities}) => { return { sortBy: opportunities.opportunitiesListOptions.sortBy, sortDesc: opportunities.opportunitiesListOptions.sortDesc}});
    const opportunitiesFilters = useSelector(({opportunities}) => opportunities.opportunitiesFilterData);
    const allOpportunitiesFilters = useSelector(({opportunities}) => opportunities.opportunitiesAllFilterData);
    const selectedFilters = useSelector(({dashboard}) => dashboard.selectedFilters);
    const fromDate = useSelector(({dates}) => dates.oppsFromDate);
    const toDate = useSelector(({dates}) => dates.oppsToDate);

    const getManufacturerAgreements = (filters) => {
        const mfId = LocalStorage.manufacturerData.get().platform_id;
        const dmId = LocalStorage.manufacturerData.get().data_manager_id;
        dispatch(dataLoading(true));
        var oppTypeFilters = "";
        var oppStatusFilters = "";
        if(filters?.length !== 0) {
            if(filters?.oppSourceStrings) {
              oppTypeFilters = filters.oppSourceStrings;
            }
            if(filters?.status) {
              oppStatusFilters = filters.status;
            }
        } else {
          dispatch(setOppsFilters([]));
        }
        const params = {
            'manufacturerPlatformId': mfId,
            'dataManagerId': dmId,
            'startDate': fromDate,
            'endDate': toDate,
            'complianceActivity': oppTypeFilters,
            "statuses": oppStatusFilters
        };
        manufacturerApi.agreements.post(params).then(({data}) => {
            dispatch(setOpportunitiesData(data.data.agreements));
            dispatch(setOpportunitiesTableData(data.data.tableData));
        }).catch(err => {
            console.log(err);
            toast.error('Something went wrong loading your agreement data');
        });
    }

    useEffect(() => {
        if(opportunitiesData.data) {
          const sortedOpportunities = sortArrayByKey(opportunitiesData?.data, sortOpportunitiesState.sortBy, sortOpportunitiesState.sortDesc);
          dispatch(setOpportunitiesData(sortedOpportunities));
        }
        // eslint-disable-next-line
    }, [sortOpportunitiesState.sortBy, sortOpportunitiesState.sortDesc])

    const handleSeeDetails = (data) => {
        if(!data) {
          return;
        }
        const modalParams = {
            buttonRightText: 'Close', 
            buttonRightColor: Theme().colors.accentName,
            detailsData: data
          }
          dispatch(showModal(OpportunitiesSeeDetailsModal, modalParams));
    };

    const handleMobileOppRowClick = (idx, rowContents) => {
        rowContents = {
            ...rowContents,
            'index': idx
        };
        dispatch(slideDrawer.setOppData(rowContents));
        dispatch(slideDrawer.addOpp());
    }

    const oppSourceFilters = allOpportunitiesFilters?.data?.opportunitySource?.map((data, idx) => {
        if(opportunitiesFilters?.data?.oppSourceStrings?.includes(data.toString())){
          return(
            <li key={idx}>
              <label className="additionalFiltersContainer" >{data}
                <input defaultChecked='true' type="checkbox" className="oppSourceCheckbox"/>
                <span className="checkmark"></span>
              </label>
            </li>
          );
        } else {
          return(
            <li key={idx}>
              <label className="additionalFiltersContainer">{data}
                <input type="checkbox" className="oppSourceCheckbox"/>
                <span className="checkmark"></span>
              </label>
            </li>
          );
        }
      });

    const oppStatusFilters = allOpportunitiesFilters?.data?.opportunityStatus?.map((data, idx) => {
        if(opportunitiesFilters?.data?.status?.includes(data.toString())){
          return(
            <li key={idx}>
              <label className="additionalFiltersContainer" >{data}
                <input defaultChecked='true' type="checkbox" className="oppStatusCheckbox"/>
                <span className="checkmark"></span>
              </label>
            </li>
          );
        } else {
          return(
            <li key={idx}>
              <label className="additionalFiltersContainer">{data}
                <input type="checkbox" className="oppStatusCheckbox"/>
                <span className="checkmark"></span>
              </label>
            </li>
          );
        }
      });

    const oppSourceData = {
        "opportunitySource": [
            "Customer Generated",
            "Manufacturer Generated",
            "Marketing Generated",
            "Other"
        ]
    }; 

    const oppStatus = {
        "opportunityStatus": [
            "Active Opportunity",
            "Identified",
            "Won"
        ]
    }

    const setAllFilters = () => {
        const allFilters = {
            ...oppSourceData,
            ...oppStatus
        };
        dispatch(setAllOppsFilters(allFilters));
    }

    const handleSetFilters = () => {
        toast.info("Applying filters.");
        var oppSourceStrings = [];
        var oppStatusFilters = [];
        const oppSourceCheckboxes = document.getElementsByClassName("oppSourceCheckbox");
        for (var i = 0; i < oppSourceCheckboxes.length; i++) {
            if(oppSourceCheckboxes[i].checked) {
              oppSourceStrings.push(oppSourceCheckboxes[i].closest('label').innerText);
            }
        }
        const oppStatusCheckboxes = document.getElementsByClassName("oppStatusCheckbox");
        for (var j = 0; j < oppStatusCheckboxes.length; j++) {
            if(oppStatusCheckboxes[j].checked) {
              oppStatusFilters.push(oppStatusCheckboxes[j].closest('label').innerText);
            }
        }
        const params = {
            "oppSourceStrings": oppSourceStrings,
            "status": oppStatusFilters
          };
        dispatch(slideDrawer.subtract());
        dispatch(setOppsFilters(params));
        getManufacturerAgreements(fromDate, toDate, params);
    }

    const handleClearFilters = () => {
        dispatch(dataLoading());
        dispatch(slideDrawer.subtract());
        dispatch(setOppsFilters([]));
        getManufacturerAgreements(fromDate, toDate, []);
    }

    const oppTypeOnChange = (e) => {
        if(e.currentTarget.checked) {
            document.getElementById("oppSourceFiltersList").style.display= 'block';
          }
          else {
            document.getElementById("oppSourceFiltersList").style.display= 'none';
            const checkboxes = document.getElementsByClassName("oppSourceCheckbox");
            for (var i = 0; i < checkboxes.length; i++) {
              checkboxes[i].checked = false;
            }
          }
    }

    const oppStatusOnChange = (e) => {
        if(e.currentTarget.checked) {
            document.getElementById("oppStatusFiltersList").style.display= 'block';
          }
          else {
            document.getElementById("oppStatusFiltersList").style.display= 'none';
            const checkboxes = document.getElementsByClassName("oppStatusCheckbox");
            for (var i = 0; i < checkboxes.length; i++) {
              checkboxes[i].checked = false;
            }
          }
    }

    const oppsFiltersDrawerContents = (
        <Row className="mx-3" style={{overflowY: 'auto', height: '74%'}}>
          <Col className="h-fc" style={{overflowX: 'hidden'}}>
            <ul className="my-0" style={{listStyleType: 'none', paddingLeft: "0"}}>
            <li>
                <label className="additionalFiltersContainer">Opportunity Source
                    <input defaultChecked={(opportunitiesFilters?.data?.oppSourceStrings?.length > 0)} onChange={(e) => oppTypeOnChange(e)} type="checkbox"/>
                    <span className="checkmark"></span>
                </label>
            </li>
            <div id="oppSourceFiltersList" className="my-2" style={{display: (opportunitiesFilters?.data?.oppSourceStrings?.length > 0) ? 'block' : 'none', overflowY: "auto"}}>
                <ul className="m-2" style={{listStyleType: 'none', paddingLeft: "0"}}>
                    {oppSourceFilters}
                </ul>
            </div>
            <li>
                <label className="additionalFiltersContainer">Opportunity Status
                    <input defaultChecked={(opportunitiesFilters?.data?.status?.length > 0)} onChange={(e) => oppStatusOnChange(e)} type="checkbox"/>
                    <span className="checkmark"></span>
                </label>
            </li>
            <div id="oppStatusFiltersList" className="my-2" style={{display: (opportunitiesFilters?.data?.status?.length > 0) ? 'block' : 'none', overflowY: "auto"}}>
                <ul className="m-2" style={{listStyleType: 'none', paddingLeft: "0"}}>
                    {oppStatusFilters}
                </ul>
            </div>
            </ul>
            <Row className="mb-5 mx-1 mt-auto drawerButtons" style={{backgroundColor: 'white'}}>
              <Button 
                outline
                block 
                className='py-3 button-label col-5' 
                size='sm' 
                color={Theme().colors.accentName}
                onClick={handleClearFilters}>Clear Filters
              </Button>
              <div className="col-2"/>
              <Button 
                  block 
                  className='py-3 button-label col-5' 
                  size='sm' 
                  color={Theme().colors.accentName}
                  onClick={handleSetFilters}>Apply
              </Button>
            </Row>
          </Col>
        </Row>
      );

    const onClickFilters = () => {
        dispatch(slideDrawer.add());
    }

    const onClickToggleTables = () => {
        const toggleTableLabel = document.getElementById("toggleTableLabel");
        const label = toggleTableLabel.innerText;
        const opportunityTableCardsRow = document.getElementById("opportunityTableCardsRow");
        const opportunityTableCards = document.getElementsByClassName("opportunityTableCard");
        const toggleDashes = document.getElementsByClassName("toggleShowDashes");
        if(label === "Show Less") {
          for(var i = 0; i < opportunityTableCards.length; i++) {
            opportunityTableCards[i].style.display = 'none';
            toggleDashes[i].style.visibility = 'visible';
          }
          toggleTableLabel.innerText = "Show More";
          opportunityTableCardsRow.style.minHeight = "80px";
        }
        if(label === "Show More") {
          for(var j = 0; j < opportunityTableCards.length; j++) {
            opportunityTableCards[j].style.display = 'flex';
            toggleDashes[j].style.visibility = 'hidden';
          }
          toggleTableLabel.innerText = "Show Less";
          opportunityTableCardsRow.style.minHeight = "275px";
        }
    }
    
    const mapOpportunities = (opportunitiesList) => {
      var opportunities = opportunitiesList?.data;
      if(selectedFilters?.selectedClientManager?.length > 0) {
        opportunities = opportunities?.filter((data) => selectedFilters.selectedClientManager.includes(data.clientmanagername));
      }
      if(selectedFilters?.selectedSource?.length > 0) {
        opportunities = opportunities?.filter((data) => selectedFilters.selectedSource.includes(data.complianceactivity));
      }
      if(selectedFilters?.selectedStatus?.length > 0) {
        opportunities = opportunities?.filter((data) => selectedFilters.selectedStatus.includes(data.analysisstatus));
      }
      const opportunitiesMap = opportunities?.map((data, idx) => {
          return ( 
              <tr key={`${idx}`}>
                  <td>{data?.customer}</td>
                  <td>{data?.growthspecialistname}</td>
                  <td>{data?.clientmanagername}</td>
                  <td>{data?.complianceactivity}</td>
                  <td>{new Date(data?.createddate).toLocaleDateString("en-US")}</td>
                  <td><AgreementStatusIndicator status={data?.analysisstatus}/></td>
              </tr>
          ); 
      });   
      return opportunitiesMap;
    }

    const mapOpportunitiesMobile = (opportunitiesList) => {
        const opportunitiesMap = opportunitiesList?.data?.map((data, idx) => {
            return ( 
                <tr key={`${idx}`} onClick={() => handleMobileOppRowClick(idx, opportunitiesData.data[idx])}>
                    <td>{data?.customer}</td>
                    <td><AgreementStatusIndicatorMobile status={data?.analysisstatus}/></td>
                </tr>
            ); 
        });   
        return opportunitiesMap;
    }


    const onClickCustomer = () => {
        if(sortOpportunitiesState.sortBy === 'customer' && sortOpportunitiesState.sortDesc === true) {
            dispatch(setOpportunitiesSortBy('customer', false));
        } else {
            dispatch(setOpportunitiesSortBy('customer', true));
        }
    }

    const onClickMfrGrowthSpecialist = () => {
        if(sortOpportunitiesState.sortBy === 'growthspecialistname' && sortOpportunitiesState.sortDesc === false) {
            dispatch(setOpportunitiesSortBy('growthspecialistname', true));
        } else {
            dispatch(setOpportunitiesSortBy('growthspecialistname', false));
        }
    }

    const onClickClientManager = () => {
        if(sortOpportunitiesState.sortBy === 'clientmanagername' && sortOpportunitiesState.sortDesc === false) {
            dispatch(setOpportunitiesSortBy('clientmanagername', true));
        } else {
            dispatch(setOpportunitiesSortBy('clientmanagername', false));
        }
    }

    const onClickOpportunityStatus = () => {
        if(sortOpportunitiesState.sortBy === 'analysisstatus' && sortOpportunitiesState.sortDesc === false) {
            dispatch(setOpportunitiesSortBy('analysisstatus', true));
        } else {
            dispatch(setOpportunitiesSortBy('analysisstatus', false));
        }
    }

    const onClickDateCreated = () => {
        if(sortOpportunitiesState.sortBy === 'createddate' && sortOpportunitiesState.sortDesc === false) {
            dispatch(setOpportunitiesSortBy('createddate', true));
        } else {
            dispatch(setOpportunitiesSortBy('createddate', false));
        }
    }
    
    const onClickContactMfrRelations = (customerId) => {
        toast.info("Opening email to contact Manufacturer Relations Team");
        const manufacturerData = LocalStorage.manufacturerData.get();
        const userData = LocalStorage.userData.get();
        var email =  'matt.russo@buyersedgeplatform.com';
        var subject = 'Opportunity Request from ' + manufacturerData.nickname;
        var emailBody = 'Hello, this is ' + userData.user.first_name + ' ' + userData.user.last_name + ' from ' + manufacturerData.nickname + ' with a question about our opportunity with customer ' + customerId + '.';
        document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
    }

    const handleClickSeeCompInsights = () => {
      dispatch(ciDetailsTabs.setCompsPurchasesTab());
      navigate('/competitiveinsights/details');
    };

    const onClickContactAcctManager = () => {
      toast.info("Opening email to contact Account Manager");
      const manufacturerData = LocalStorage.manufacturerData.get();
      const userData = LocalStorage.userData.get();
      var email =  'MFRrelations@buyersedgeplatform.com';
      var subject = 'Generating Opportunities Request from ' + manufacturerData.nickname;
      var emailBody = 'Hello, this is ' + userData.user.first_name + ' ' + userData.user.last_name + ' from ' + manufacturerData.nickname + ' wanting to learn more about generating opportunities through The Peak.';
      document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
    };

    return {
        getManufacturerAgreements,
        mapOpportunities,
        mapOpportunitiesMobile,
        onClickCustomer,
        onClickClientManager,
        onClickMfrGrowthSpecialist,
        onClickOpportunityStatus,
        onClickContactMfrRelations,
        onClickFilters,
        oppsFiltersDrawerContents,
        setAllFilters, 
        onClickToggleTables, 
        handleSeeDetails,
        handleClickSeeCompInsights, 
        onClickContactAcctManager,
        onClickDateCreated
    }
};

const useDates = () => {
    const dispatch = useDispatch();
    const { getManufacturerAgreements } = useOpportunities();
    const fromDate = useSelector(({dates}) => dates.oppsFromDate);
    const toDate = useSelector(({dates}) => dates.oppsToDate);
    const opportunitiesFilters = useSelector(({opportunities}) => opportunities.opportunitiesFilterData);
  
    const onApplySearch = () => {
      toast.info("Applying date ranges.");
      dispatch(dataLoading());
      getManufacturerAgreements(fromDate, toDate, opportunitiesFilters.data);
    }
  
    return {
      setToDate: (d) => dispatch(setOppsToDate(d)),
      setFromDate: (d) => dispatch(setOppsFromDate(d)),
      toDate,
      fromDate,
      onApplySearch
    };
  }
  
  // eslint-disable-next-line
  export default { useOpportunities, useDates };