import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TopBar from '../TopBar/TopBar';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import HelpSlideDrawer from '../../sharedComponents/SlideDrawer/HelpSlideDrawer';
import ProfileSlideDrawer from './ProfileSlideDrawer';
import NotificationsSlideDrawer from './NotificationsSlideDrawer';
import { isMobileOnly } from 'react-device-detect';
import ChangeAppsSlideDrawer from './ChangeAppsSlideDrawer';

const MobileMenuSlideDrawer = () => {
    const dispatch = useDispatch();
    const enabled = useSelector(({ ui }) => ui.mobileMenuSlideDrawer);

    const handleClickOutside = (event) => {
        if (isMobileOnly) {
            if (enabled && !event.target.closest('.left-side-drawer-menu-mobileonly')) {
                dispatch(slideDrawer.subtractMobileMenu());
                dispatch(slideDrawer.subtractNotifications());
                dispatch(slideDrawer.subtractProfile());
                dispatch(slideDrawer.subtractHelp());
                dispatch(slideDrawer.subtractAppsMenu());
            }
        } else {
            if (enabled && !event.target.closest('.left-side-drawer-menu-mobile')) {
                dispatch(slideDrawer.subtractMobileMenu());
                dispatch(slideDrawer.subtractNotifications());
                dispatch(slideDrawer.subtractProfile());
                dispatch(slideDrawer.subtractHelp());
                dispatch(slideDrawer.subtractAppsMenu());
            }
        }
    };

    useEffect(() => {
        if (enabled) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, [enabled]);

    return (
        <div className={(isMobileOnly ? "left-side-drawer-menu-mobileonly" : "left-side-drawer-menu-mobile") + (enabled ? " open" : "")}>
            <TopBar/>
            <HelpSlideDrawer />
            <ProfileSlideDrawer />
            <NotificationsSlideDrawer />
            <ChangeAppsSlideDrawer />
        </div>
        
    );
}

export default MobileMenuSlideDrawer;