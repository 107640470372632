import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Card } from 'reactstrap'; 
import { Theme } from '../../config/constants';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { BsFillGridFill } from 'react-icons/bs';
import { isMobileOnly } from 'react-device-detect';
import { HiOutlineMenu } from "react-icons/hi";
import MobileMenuSlideDrawer from '../../sharedComponents/SlideDrawer/MobileMenuSlideDrawer';
import { mfrPlatformIdToDmId } from '../../config/constants';
import LocalStorage from '../../sharedComponents/LocalStorage/LocalStorage';

const MarketingCampaignsMobile = () => {
    const dispatch = useDispatch();
    const menuOpen = useSelector(({ ui }) => ui.mobileMenuSlideDrawer);
    var mfrName = 'Admin';
    if (mfrPlatformIdToDmId[LocalStorage.manufacturerData.get().platform_id]?.mfrName) {
      mfrName = mfrPlatformIdToDmId[LocalStorage.manufacturerData.get().platform_id].mfrName;
    }
    if (isMobileOnly) {
      return (
        <div className='bg-transparent pt-3 px-3 summaryContainer'>
          <header style={{ backgroundColor: '#f8f8f8' }}>
            <Col className='ps-0 d-flex justify-content-between align-items-center' >
              <HiOutlineMenu
                className='me-1'
                onClick={() => dispatch(slideDrawer.addMobileMenu())}
                isActive={menuOpen}
                style={{
                  verticalAlign: 'middle',
                  marginBottom: '7px',
                  fontSize: '38px',
                }}
              />
  
              <div className='main-header text-size-26 ' style={{ fontWeight: '700', letterSpacing: '.0025em', lineHeight: '8px' }}>
                <BsFillGridFill
                  className='me-1'
                  style={{
                    verticalAlign: 'middle',
                    marginBottom: '7px',
                    fill: 'url(#icon-gradient)'
                  }}
                />
                Campaign Management
                <svg width='26px' height='26px'>
                  <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop stopColor={Theme().colors.gradientColorOne} offset="0%" />
                    <stop stopColor={Theme().colors.gradientColorTwo} offset="100%" />
                  </linearGradient>
                </svg>
              </div>
              <div></div>
            </Col>
            <hr className='line' style={{ border: '1px solid grey', marginTop: '0px' }}></hr>
          </header>
          <MobileMenuSlideDrawer />
          <Col className='justify-content-center' style={{ margin: '40px' }}>
            <Card className=' text-size-20 pt-4 pb-4 px-4' style={{ textAlign: 'center' }}>This page is not optimized for mobile viewing. Please use the desktop site.</Card>
          </Col>
        </div>
      )
    } else return (
      <div className='bg-transparent pt-3 px-3 summaryContainer'>
        <Col className='ps-0 d-flex justify-content-between align-items-end' >
          <div className='main-header text-size-32 mt-2' style={{ fontWeight: '700', letterSpacing: '.0025em', lineHeight: '28px' }}>
            <HiOutlineMenu
              className='me-3'
              onClick={() => dispatch(slideDrawer.addMobileMenu())}
              isActive={menuOpen}
              style={{
                verticalAlign: 'middle',
                marginBottom: '7px',
                fontSize: '38px',
              }}
            />
            <BsFillGridFill
              className='me-1'
              style={{
                verticalAlign: 'middle',
                marginBottom: '7px',
                fill: 'url(#icon-gradient)',
                fontSize: '30px'
              }}
            />
            Campaign Management
          </div>
          <svg width='24px' height='24px'>
            <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop stopColor={Theme().colors.gradientColorOne} offset="0%" />
              <stop stopColor={Theme().colors.gradientColorTwo} offset="100%" />
            </linearGradient>
          </svg>
          <div className='main-header text-size-20 align-self-end my-2' style={{ lineHeight: '23px', fontWeight: '700', letterSpacing: '.0025em' }}>Welcome, {mfrName}</div>
        </Col>
        <hr className='line my-2' style={{ border: '1px solid grey' }}></hr>
        <MobileMenuSlideDrawer />
        <Col className='justify-content-center' style={{ margin: '120px' }}>
          <Card className=' text-size-20 pt-5 pb-5 px-4' style={{ textAlign: 'center' }}>This page is not optimized for mobile viewing. Please use the desktop site.</Card>
        </Col>
      </div>
    )
  }

export default MarketingCampaignsMobile;