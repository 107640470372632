import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Card, Col, Button, Spinner } from 'reactstrap';
import { dataLoading } from '../../../reducers/OpportunitiesReducer/OpportunitiesActions';
import MarketingCampaignDetailsTables from './MarketingCampaignsDetailsTables';
import useMarketingCampaigns from '../useMarketingCampaigns';
import LocalStorage from '../../../sharedComponents/LocalStorage/LocalStorage';
import { If, Then } from 'react-if';
import MarketingCampaignsDetailsAssets from './MarketingCampaignsDetailsAssets';
import { Theme } from '../../../config/constants';

const MarketingCampaignsDetails = () => {
    const dispatch = useDispatch;
    const isAdmin = LocalStorage.userData.get().user.internalAdmin === true;
    const { getMarketingCampaigns, handleClickEditDetails, handleClickUpdateLearnings, 
            handleClickSaveEditDetails, handleClickDiscardEditDetails, handleClickViewOppsGenerated,
            handleClickExport } = useMarketingCampaigns.useMarketingCampaigns();
    dispatch(dataLoading);
    const { handleClickDetailsHeader } = useMarketingCampaigns.useMarketingCampaigns();
    const { id } = useParams();
    const marketingCampaigns = useSelector(({marketing}) => marketing.marketingCampaignsData);
    const editDetailsEnabled = useSelector(({marketing}) => marketing.editDetailsEnabled);
    const manufacturerName = LocalStorage.manufacturerData.get().nickname;
    var marketingCampaign = [];
    if(marketingCampaigns.length === 0) {
        getMarketingCampaigns();
    }
    if(marketingCampaigns?.marketingCampaigns) {
        marketingCampaign = marketingCampaigns.marketingCampaigns[id];
    }
    const campaignName = marketingCampaign?.campaignName;

    return (
        <div className='pt-3 marketingCampaignsDetailsContainer'>
            <Row className='mx-1 flex align-items-center'>
                <Col className="px-1 pb-2 w-fc">
                    <div className="main-header text-size-16 clickable" style={{marginLeft:'8px', color: Theme().colors.accent}}onClick={() => handleClickDetailsHeader()}>Marketing Campaigns</div>
                </Col>
                <Col className="px-1 pb-2 w-fc">
                    <div className="main-header text-size-20">&#62;</div>
                </Col>
                <Col className="px-1 pb-2">
                    <div className="main-header text-size-16">{campaignName}</div>
                </Col>
                <Col className='w-fc pb-2 ml-auto'>
                    <Button 
                        block 
                        className='py-1 button-label' 
                        size='sm' 
                        style={{width: '80px'}}
                        onClick={() => handleClickExport(marketingCampaign, manufacturerName)}
                        color={Theme().colors.accentName}>Export
                    </Button>
                </Col>
            </Row>
            <Row className='mx-1'>
                <Col className="px-1">
                    <div className='main-header text-size-20' style={{marginLeft:'8px'}}>{campaignName}</div>
                </Col>
            </Row>
            <Row className="px-3">
                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                    <Card className="dashboard-card flex-fill py-3" style={{overflowY: 'auto', height: '39.5vh !important'}}>
                        <Row className='w-100'>
                            <Col className='w-fc ps-4'>
                                <div style={{height: '25px', width: '6px', backgroundColor: Theme().colors.accent}}></div>
                            </Col>
                            <Col className='mx-0 px-0 w-fc'>
                                <div className='main-header text-size-18'>Details</div>
                            </Col>
                            <Col className='mx-auto'></Col>
                            <If condition={isAdmin && editDetailsEnabled}>
                                <Then>
                                    <Col className='mr-0 px-1 w-fc'>
                                        <div>
                                            <Button 
                                                block 
                                                className='py-1 button-label' 
                                                size='sm' 
                                                style={{width: '75px'}}
                                                onClick={() => handleClickSaveEditDetails(marketingCampaign)} 
                                                color={Theme().colors.accentName}>Save
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col className='mr-0 pl-1 w-fc'>
                                        <div>
                                            <Button 
                                                block 
                                                className='py-1 button-label' 
                                                size='sm' 
                                                style={{width: '75px'}}
                                                onClick={() => handleClickDiscardEditDetails(marketingCampaign)} 
                                                color='beplBlue'>Cancel
                                            </Button>
                                        </div>
                                    </Col>
                                </Then>
                            </If>
                            <If condition={isAdmin && !editDetailsEnabled}>
                                <Then>
                                    <Col className='mr-0 w-fc'>
                                        <div>
                                            <Button 
                                                block 
                                                className='py-1 button-label' 
                                                size='sm' 
                                                style={{width: '75px'}}
                                                onClick={() => handleClickEditDetails()} 
                                                color={Theme().colors.accentName}>Edit
                                            </Button>
                                        </div>
                                    </Col>
                                </Then>
                            </If>
                        </Row>
                        {!campaignName &&
                            <Row className='w-100 my-auto'>
                                <Col className='col-4'></Col>
                                <Col className='col-4 d-flex justify-content-center'>
                                    <Spinner className='m-4' color='beplBlue' animation='border' />
                                </Col>
                                <Col className='col-4'></Col>
                            </Row>
                        }
                        {campaignName &&
                        <Row className='w-100' style={{overflowY: 'auto'}}>
                            <Col className='mx-2'>
                                <Row className='mx-4 mt-2'>
                                    <Col className='col-2 p-0'>
                                        <div className='float-end text-grayDark'>Manufacturer:</div>
                                    </Col>
                                    <Col className='col-10'>
                                        <input id='manufacturerInput' className='marketingCampaignDetailsCard w-100' readOnly={true} value={manufacturerName} />
                                    </Col>
                                </Row>
                                <Row className='mx-4'>
                                    <Col className='col-2 p-0'>
                                        <div className='float-end text-grayDark'>Item Brand:</div>
                                    </Col>
                                    <Col className='col-10'>
                                        <input id='itemBrandInput' className='marketingCampaignDetailsCard w-100' readOnly={editDetailsEnabled ? false : true} defaultValue={marketingCampaign?.itemBrand} />
                                    </Col>
                                </Row>
                                <Row className='mx-4'>
                                    <Col className='col-2 p-0'>
                                        <div className='float-end text-grayDark'>Products:</div>
                                    </Col>
                                    <Col className='col-10'>
                                        <input id='productsInput' className='marketingCampaignDetailsCard w-100' readOnly={editDetailsEnabled ? false : true} defaultValue={marketingCampaign?.products} />
                                    </Col>
                                </Row>
                                <Row className='mx-4'>
                                    <Col className='col-2 p-0'>
                                        <div className='float-end text-grayDark'>CTA(s):</div>
                                    </Col>
                                    <Col className='col-10'>
                                        <textarea id='ctaInput' className='marketingCampaignDetailsCard w-100' readOnly={editDetailsEnabled ? false : true} style={{'resize' : 'none'}} defaultValue={marketingCampaign?.CTA} />
                                    </Col>
                                </Row>
                                <Row className='mx-4'>
                                    <Col className='col-2 p-0'>
                                        <div className='float-end text-grayDark'>Objective:</div>
                                    </Col>
                                    <Col className='col-10'>
                                        <textarea id='objectiveInput' className='marketingCampaignDetailsCard w-100' readOnly={editDetailsEnabled ? false : true} style={{'resize' : 'none'}} defaultValue={marketingCampaign?.objective} />
                                    </Col>
                                </Row>
                                <Row className='mx-4'>
                                    <Col className='col-2 p-0'>
                                        <div className='float-end text-grayDark'>Audience:</div>
                                    </Col>
                                    <Col className='col-10'>
                                        <textarea id='audienceInput' className='marketingCampaignDetailsCard w-100' readOnly={editDetailsEnabled ? false : true} style={{'resize' : 'none'}} defaultValue={marketingCampaign?.audience} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        }
                    </Card>
                </Col>
                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                    <Card className="dashboard-card flex-fill py-3" style={{overflowY: 'auto', height: '39.5vh !important'}}>
                        <Row className='w-100'>
                            <Col className='w-fc ps-4'>
                                <div style={{height: '25px', width: '6px', backgroundColor: Theme().colors.accent}}></div>
                            </Col>
                            <Col className='mx-0 px-0 col-6'>
                                <div className='main-header text-size-18'>Learnings & Takeaways</div>
                            </Col>
                            <Col className='mx-auto'></Col>
                            <Col className='w-fc'>
                                <Button 
                                    block 
                                    className='py-1 button-label' 
                                    size='sm' 
                                    style={{width: '80px'}}
                                    onClick={() => handleClickUpdateLearnings(marketingCampaign)} 
                                    color={Theme().colors.accentName}>Update
                                </Button>
                            </Col>
                        </Row>
                        {!campaignName &&
                            <Row className='w-100 my-auto'>
                                <Col className='col-4'></Col>
                                <Col className='col-4 d-flex justify-content-center'>
                                    <Spinner className='m-4' color='beplBlue' animation='border' />
                                </Col>
                                <Col className='col-4'></Col>
                            </Row>
                        }
                        {campaignName &&
                        <Col style={{overflowY: 'auto'}}>
                            <Row className='w-100 my-1'>
                                <Col className='mx-4 w-fc text-grayDark'>
                                    <div>Important Learnings and Takeaways</div>
                                </Col>
                            </Row>
                            <Row className='w-100' style={{height: '39%'}}>
                                <Col className='mx-4'>
                                    <textarea 
                                        id='learningsTakeawaysInput'
                                        className='w-100 h-100 marketingCampaignLearningsCard' 
                                        style={{"resize": "none"}} 
                                        readOnly={isAdmin ? false : true}
                                        defaultValue={marketingCampaign?.takewaysAndLearning}
                                    />
                                </Col>
                            </Row>
                            <Row className='w-100 my-1'>
                                <Col className='mx-4 w-fc text-grayDark'>
                                    <div>Questions</div>
                                </Col>
                            </Row>
                            <Row className='w-100' style={{height: '39%'}}>
                                <Col className='mx-4'>
                                    <textarea 
                                        id='questionsInput'
                                        className='w-100 h-100 marketingCampaignLearningsCard' 
                                        style={{"resize": "none"}} 
                                        defaultValue={marketingCampaign?.questions}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        }
                    </Card>
                </Col>
            </Row>
            <Row className='px-3'>
                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                    <Card className="dashboard-card flex-fill py-3" style={{overflowY: 'none', height: '39.5vh !important'}}>
                        <Row className='w-100'>
                            <Col className='w-fc ps-4'>
                                <div style={{height: '25px', width: '6px', backgroundColor: Theme().colors.accent}}></div>
                            </Col>
                            <Col className='mx-0 px-0 w-fc'>
                                <div className='main-header text-size-18'>Recap & Stats</div>
                            </Col>
                        </Row>
                        {!campaignName &&
                            <Row className='w-100 my-auto'>
                                <Col className='col-4'></Col>
                                <Col className='col-4 d-flex justify-content-center'>
                                    <Spinner className='m-4' color='beplBlue' animation='border' />
                                </Col>
                                <Col className='col-4'></Col>
                            </Row>
                        }
                        {campaignName &&
                        <MarketingCampaignDetailsTables campaign={marketingCampaign} />
                        }
                        <Row className='d-flex flex-row-reverse'>
                            <Col className='w-fc me-3' style={{alignSelf: 'end'}}>
                                <Button 
                                    block 
                                    className='py-2 button-label' 
                                    size='sm' 
                                    style={{width: '350px'}}
                                    onClick={() => handleClickViewOppsGenerated(marketingCampaign)}
                                    color={Theme().colors.accentName}>View Campaign Opportunities Generated
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                    <Card className="dashboard-card flex-fill py-3" style={{overflowY: 'auto', height: '39.5vh !important'}}>
                        <Row className='w-100'>
                            <Col className='w-fc ps-4'>
                                <div style={{height: '25px', width: '6px', backgroundColor: Theme().colors.accent}}></div>
                            </Col>
                            <Col className='mx-0 px-0 w-fc'>
                                <div className='main-header text-size-18'>Campaign Assets</div>
                            </Col>
                        </Row>
                        {!campaignName &&
                        <Row className='w-100 my-auto' style={{height: 'inherit'}}>
                            <Col className='col-4'></Col>
                            <Col className='col-4 d-flex my-auto justify-content-center'>
                                <Spinner className='m-4' color='beplBlue' animation='border' />
                            </Col>
                            <Col className='col-4'></Col>
                        </Row>
                        }
                        {campaignName &&
                        <Row className='w-100' style={{overflowY: 'auto', height: 'inherit'}}>
                            <MarketingCampaignsDetailsAssets assets={marketingCampaign?.campaignAssets} />
                        </Row>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default MarketingCampaignsDetails;