import React from 'react';
import { InputGroup, InputGroupText } from 'reactstrap';
import { BsCalendarFill } from 'react-icons/bs';
import RangePicker from 'react-bootstrap-daterangepicker';

const DateRangePicker = (props) => {
  return (
    <InputGroup size='sm'>
        <InputGroupText className='pe-0'>
            <BsCalendarFill />
        </InputGroupText>
        <RangePicker
          initialSettings={{ startDate: props.defaultStartDate, 
                             endDate: props.defaultEndDate, 
                             minDate: props.minDate, 
                             maxDate: props.maxDate,
                             ranges: props.ranges
                            }}
          onCallback={props.callback}
          ref={props.childRef}
        >
            <input id={props.id} type="text" className="form-control date-picker-input" style={{fontSize: '12px', borderRadius: '0.2rem', borderLeft: 'none', padding: '0.35rem 0.5rem'}} />
        </RangePicker>
    </InputGroup>
  );
};

export default DateRangePicker;