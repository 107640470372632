

export default function reducer(state = {
  rebateList: [],
  rebateLoaded: false,
  manufacturerGroup: [],
  manufacturerChecked: [],
  categoryGroup: [],
  categoryChecked: [],
  search: '',
  searchManufacturer: '',
  searchCategory: '',
  rebateView: []
}, action) {
  switch(action.type) {
    case 'CLEAR_REBATE_SEARCH':
      return {
        ...state,
        search: ''
      };
    case 'CLEAR_REBATE_DATA':
      return {
        ...state,
        rebateLoaded: false,
        rebateList: []
      };
    case 'SET_REBATE_SEARCH':
      return {
        ...state,
        search: action.payload
      };
    case 'SET_REBATE_DATA':
      return {
        ...state,
        rebateLoaded: true,
        rebateList: action.payload.data,
        rebateView: []
      };
    case 'CLEAR_SEARCH_MANUFACTURER':
      return {
        ...state,
        searchManufacturer: ''
      };
    case 'SET_SEARCH_MANUFACTURER':
      return {
        ...state,
        searchManufacturer: action.payload
      };
    case 'SET_MANUFACTURER_DATA':
      return {
        ...state,
        manufacturerGroup: action.payload.data
      };
    case 'SET_MANUFACTURER_CHECKED':
      return {
        ...state,
        manufacturerChecked: state.manufacturerChecked.some(m => m === action.payload) ? state.manufacturerChecked.filter(m => m !== action.payload) : [...state.manufacturerChecked, action.payload]
      };
    case 'CLEAR_SEARCH_CATEGORY':
      return {
        ...state,
        searchCategory: ''
      };
    case 'SET_SEARCH_CATEGORY':
      return {
        ...state,
        searchCategory: action.payload
      };
    case 'SET_CATEGORY_DATA':
      return {
        ...state,
        categoryGroup: action.payload.data
      };
    case 'SET_CATEGORY_CHECKED':
      return {
        ...state,
        categoryChecked: state.categoryChecked.some(c => c === action.payload) ? state.categoryChecked.filter(c => c !== action.payload) : [...state.categoryChecked, action.payload]
      };
    case 'RESET_REBATE_FILTERS':
      return {
        ...state,
        manufacturerChecked: [],
        categoryChecked: []
      };
      case 'CLEAR_REBATE_VIEW':
        return {
          ...state,
          rebateView: []
        };
      case 'SET_REBATE_VIEW':
        return {
          ...state,
          rebateView: action.payload.data
        };
    default:
      return state;
  };
};