import { FaSearchMinus } from "react-icons/fa";

const EmptySearchResultsMessage = () => {
    return (
        <div className="h-100 w-100" style={{display: 'inline-flex'}}>
            <div className="mx-auto my-auto w-50">
                <div className="mx-auto w-fc mb-2"><FaSearchMinus className="text-size-36"/></div>
                <div className='mx-auto text-size-20 w-fc mb-1' style={{textAlign: 'center'}}>No Results To Display</div>
                <div className="mx-auto text-size-16 w-fc" style={{textAlign: 'center'}}>There are no results using the selected filters above.</div>
            </div> 
        </div>
        
    );
}

export default EmptySearchResultsMessage;