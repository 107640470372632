import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { selectStyles, Theme } from '../../../config/constants';
import useModal from '../../../sharedComponents/useModal/useModal';
import useUsersRoles from '../UsersRoles/useUsersRoles';
import Select from 'react-select';

const InviteUserModal = ({id, ...props}) => {
  const { modalProps, headerProps, bodyProps, toggle } = useModal(id, {isMenu: true, isFullscreen: true, header: {background: isMobile ? 'beplBlue' : 'white', color: isMobile ? 'white' : 'black'}});
  const { contactName, email, rol, validate } = useUsersRoles.useUserForm(props.isEdit, props.user);
  const { userSave } = useUsersRoles.useUser(props);
  const userParams = {
    id: props.user.id,
    isEdit: props.isEdit,
    contactName: contactName.value,
    email: email.value,
    rol: rol.value.label,
    validate,
    toggle
  }

  const options = [
    { value: 1, label: 'Marketing' },
    { value: 2, label: 'Field Sales' },
    { value: 3, label: 'Analyst' },
    { value: 4, label: 'Account Manager' },
    { value: 5, label: 'Portal Administrator' }
  ];

  return (
    <Modal {...modalProps}>
      <ModalHeader {...headerProps}>{props.title}</ModalHeader>
      <ModalBody {...bodyProps}>
        <Form className={isMobile ? '' : 'px-4'}>
          <FormGroup>
            <Label className='label-text-header my-2'>Name</Label>
            <Input placeholder='Type Full Name' {...contactName} />
          </FormGroup>
          <FormGroup>
            <Label className='label-text-header my-2' >Email</Label>
            <Input placeholder='Type Email Address' {...email} disabled={props.isEdit} />
          </FormGroup>
          <FormGroup>
            <Label className='label-text-header my-2'>Role</Label>
            <Select options={options} onChange={rol.onChange} value={rol.label} styles={selectStyles} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className='justify-content-center'>
        <Col className={isMobile ? 'px-1':'px-4'}>
          <Button onClick={()=> userSave(userParams)} block className='py-2 px-5 button-label' color={Theme().colors.accentName} style={{float: 'right'}}>Invite</Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default InviteUserModal;
