import React, { useRef } from "react";
import { Col, Row, Button, InputGroup, InputGroupText, Input } from "reactstrap";
import moment from "moment";
import { FaFilter, FaTimes } from "react-icons/fa";
import ReactSelect, { createFilter, components } from "react-select";
import DateRangePicker from "../../../sharedComponents/DateRangePicker/DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import useDataSuite from "../useDataSuite";
import { useEffect, useState } from "react";
import { selectStyles, Theme } from "../../../config/constants";
import CustomMenuList from "../../../sharedComponents/Helper/CustomMenuList";
import { BsCaretDownFill } from "react-icons/bs";
import { setDataSetsDetailsFilter, setDataSetsFilter } from "../../../reducers/DatasuiteReducer/DataSuiteActions";
import { BsSearch } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";

const FiltersCard = (props) => {
    const fromDate = useSelector(({dates}) => dates.datasuiteFromDate);
    const toDate = useSelector(({dates}) => dates.datasuiteToDate);
    const detailsFromDate = useSelector(({dates}) => dates.datasuiteDetailsFromDate);
    const detailsToDate = useSelector(({dates}) => dates.datasuiteDetailsToDate);
    const minDate = useSelector(({dates}) => dates.datasuiteMinDate);
    const detailsMinDate = useSelector(({dates}) => dates.datasuiteDetailsMinDate);
    const allFilters = useSelector(({datasuite}) => datasuite.allFilters);
    const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
    const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
    const { clickMoreFilters, filtersDrawerOpen, getFilters, setSelectedDataSets, setSelectedCategories, setSelectedDistributors, setSelectedLocations, setSelectedSegments, changeDataSuiteDates, 
            setIncludedSearchKeywords, setExcludedSearchKeywords, removeIncludedSearchKeyword, removeExcludedSearchKeyword, alignFiltersComponents } = useDataSuite.useDataSuite();
    const parentElement = props.parentElement ? props.parentElement : '';
    const chainData = useSelector(({user}) => user.chainData);
    const emergingData = useSelector(({user}) => user.emergingData);
    const widgetDetailsDrawerContents = useSelector(({datasuite}) => datasuite.widgetDetailsDrawer);
    const defaultDatasetsLoaded = useSelector(({datasuite}) => datasuite.defaultDatasetsLoaded);
    const [showClearButton, setShowClearButton] = useState(false);
    const [selectedDataSets] = useState(false);
    const detailView = parentElement === 'Details' || parentElement === 'DetailsPage';
    const dateRef = useRef();
    const searchOptionsRef = useRef();
    const dispatch = useDispatch();

    const topPositions = {
        '' : '44',
        'Details' : '88',
        'DetailsPage' : '200'
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };
    
    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
          values = `${values.length} Selected`;
        }
        return (
          <components.ValueContainer {...props}>
            {values}
            {input}
          </components.ValueContainer>
        );
      };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = "rgb(245, 214, 228)";
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              <input className="me-2" type="checkbox" checked={isSelected} disabled={isDisabled} />
              {children}
            </components.Option>
        );
      };

      const SearchInputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              {children}
            </components.Option>
        );
      }; 

    const removeIncludedSearchTag = (removedTag) => {
        removeIncludedSearchKeyword(removedTag);
    }

    const removeExcludedSearchTag = (removedTag) => {
        removeExcludedSearchKeyword(removedTag);
    }

    const includedSearchTagsMap = selectedDetailsFilters?.includedSearch?.map((tag, index) => {
        return (
            <div key={index} className="w-fc me-1 px-2 py-1" style={{background: "#49A4DA", color: "white", borderRadius: '5px'}}>
                {tag}
                <IoIosClose className="ms-1 clickable" fontSize={"24px"} onClick={() => removeIncludedSearchTag(tag)} />
            </div>
        );
    });

    const excludedSearchTagsMap = selectedDetailsFilters?.excludedSearch?.map((tag, index) => {
        return (
            <div key={index} className="w-fc me-1 px-2 py-1" style={{background: Theme().colors.accent, color: "white", borderRadius: '5px'}}>
                {tag}
                <IoIosClose className="ms-1 clickable" fontSize={"24px"} onClick={() => removeExcludedSearchTag(tag)} />
            </div>
        );
    });

    const categoriesOptionsMap = detailView ? 
    allFilters?.categories?.sort().sort((a,b) => selectedDetailsFilters?.categories?.includes(b) - selectedDetailsFilters?.categories?.includes(a)).map((data) => {
        return (
            {value: data, label: data}
        );
    }) :
    allFilters?.categories?.sort().sort((a,b) => selectedFilters?.categories?.includes(b) - selectedFilters?.categories?.includes(a)).map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const selectedCategoriesMap = selectedFilters?.categories?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedCategoriesDetailsMap = selectedDetailsFilters?.categories?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedOperatorsMap = selectedFilters?.locations?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedOperatorsDetailsMap = selectedDetailsFilters?.locations?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const distributorOptionsMap = detailView ? 
    allFilters?.distributors?.sort().sort((a,b) => selectedDetailsFilters?.distributors?.includes(b) - selectedDetailsFilters?.distributors?.includes(a)).map((data) => {
        return (
            {value: data, label: data}
        );
    }) :
    allFilters?.distributors?.sort().sort((a,b) => selectedFilters?.distributors?.includes(b) - selectedFilters?.distributors?.includes(a)).map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const selectedDistributorsMap = selectedFilters?.distributors?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedDistributorsDetailsMap = selectedDetailsFilters?.distributors?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const segmentOptionsMap = detailView ? allFilters?.segments?.sort().sort((a,b) => selectedDetailsFilters?.segments?.includes(b) - selectedDetailsFilters?.segments?.includes(a)).map((data) => {
        const segmentLabel = data.split('_').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
        return (
            {value: data, label: segmentLabel}
        );
    }) :
    allFilters?.segments?.sort().sort((a,b) => selectedFilters?.segments?.includes(b) - selectedFilters?.segments?.includes(a)).map((data) => {
        const segmentLabel = data.split('_').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
        return (
            {value: data, label: segmentLabel}
        );
    });
    
    const selectedSegmentsMap = selectedFilters?.segments?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    const selectedSegmentsDetailsMap = selectedDetailsFilters?.segments?.map((data) => {
        return (
            {value: data, label: data}
        )
    });

    //const selectedOperatorNames = detailView ? selectedOperatorsDetailsMap?.map(loc => loc.value) : selectedOperatorsMap?.map(loc => loc.value);
    //const selectedSegments = detailView ? selectedSegmentsDetailsMap?.map(segment => segment.value) : selectedSegmentsMap?.map(segment => segment.value);

    // // Show all locations if no segment is selected
    let filteredLocations = allFilters?.locations;

    // if (selectedSegments?.length > 0) {
    //     if (selectedOperatorNames?.length > 0) {
    //         // Show 1.) locations in the selected segments 2.) selected locations
    //         filteredLocations = allFilters?.locations?.filter((loc) => 
    //             selectedSegments.includes(loc.market_segment) || selectedOperatorNames.includes(loc.location_name)
    //         );
    //     }
    //     else {
    //         // Show only locations in the selected segments
    //         filteredLocations = allFilters?.locations?.filter((loc) => 
    //             selectedSegments.includes(loc.market_segment)
    //         );
    //     }
    // }

    const operatorOptionsMap = detailView ?  filteredLocations?.sort((a, b) => a.localeCompare(b)).sort((a,b) => selectedDetailsFilters?.locations?.includes(b) - selectedDetailsFilters?.locations?.includes(a))
        .map((data) => {
            return (
                {value: data, label: data}
            );
    }) :
    filteredLocations?.sort((a, b) => a.localeCompare(b)).sort((a,b) => selectedFilters?.locations?.includes(b) - selectedFilters?.locations?.includes(a))
        .map((data) => {
            return (
                {value: data, label: data}
            );
    });

    const datasetOptions = [
        {
            value: "Chain Data", 
            label: "Chain Data",
            isDisabled: !chainData
        },
        {
            value: "Emerging Chain Data", 
            label: "Emerging Chain Data",
            isDisabled: !emergingData
        }
    ];

    const searchOptions = [
        {
            value: "Include", 
            label: "Include",
            isDisabled: false
        },
        {
            value: "Exclude", 
            label: "Exclude",
            isDisabled: false
        }
    ];

    const selectedDatasetsMap = [];
    const selectedDatasetsDetailsMap = [];
    if (selectedFilters?.chainData) {
        selectedDatasetsMap.push(datasetOptions[0]);
    }
    if (selectedFilters?.gpoData) {
        selectedDatasetsMap.push(datasetOptions[1]);
    }
    if (selectedDetailsFilters?.chainData) {
        selectedDatasetsDetailsMap.push(datasetOptions[0]);
    }
    if (selectedDetailsFilters?.gpoData) {
        selectedDatasetsDetailsMap.push(datasetOptions[1]);
    }

    const initializePage = () => {
        getFilters();
        if (!defaultDatasetsLoaded) {
            dispatch(setDataSetsFilter(chainData, emergingData));
            dispatch(setDataSetsDetailsFilter(chainData, emergingData));
        }
    }

    const handleClearFilters = () => {
        setSelectedCategories();
        setSelectedLocations();
        setSelectedDistributors();
        setSelectedSegments();
        setExcludedSearchKeywords();
        setIncludedSearchKeywords();
      };

    const addSearchKeyword = (e) => {
        if(e.keyCode === 13) {
            if(searchOptionsRef?.current.state.selectValue[0].value === "Include") {
                setIncludedSearchKeywords(e.target.value, detailView);
                e.target.value="";
            }
            if(searchOptionsRef?.current.state.selectValue[0].value === "Exclude") {
                setExcludedSearchKeywords(e.target.value, detailView);
                e.target.value="";
            }
        }
    };

    // eslint-disable-next-line
    useEffect(initializePage, []);

    // eslint-disable-next-line
    useEffect(() => {if(parentElement === "DetailsPage") {alignFiltersComponents();}}, [selectedDetailsFilters]);

    useEffect(() => {
        const hasFilters = Object.values(selectedFilters).some((filter) => filter && filter.length > 0) || (selectedDataSets.length > 0 && selectedDataSets[0] !== null)
                            || Object.values(selectedDetailsFilters).some((filter) => filter && filter.length > 0);
        setShowClearButton(hasFilters);
    }, [selectedFilters, selectedDetailsFilters, selectedDataSets]);
    
    // The daterange component we use is uncontrolled, so we must save a ref
    // to this component and update the selected values on our own. This is 
    // only applicable to the details date range as its value can be updated by
    // other components.
    useEffect(() => {
        if (detailView) {
            dateRef.current?.setStartDate(moment(detailsFromDate).format('MM/DD/YYYY'));
            dateRef.current?.setEndDate(moment(detailsToDate).format('MM/DD/YYYY'));
        }
    }, [detailView, detailsFromDate, detailsToDate]);

    return (
        <Col className="h-100">
            <Row id={`filters${parentElement}Main`} className="w-100 mx-0" style={{ textWrap: 'nowrap', background: 'white', zIndex: '2', position: 'relative', borderRadius: '5px', height: (selectedDetailsFilters?.includedSearch?.length > 0 || selectedDetailsFilters?.excludedSearch?.length > 0) ? '160px' : '118px'}}>
                <Col>
                    <Row style={{zIndex: '2'}}>
                        <img src={'./FiltersBackground.png'} alt='' className='px-0' style={{position: 'absolute', top: '0px', right: '0px', height: (selectedDetailsFilters?.includedSearch?.length > 0 || selectedDetailsFilters?.excludedSearch?.length > 0) ? '160px'  : '118px', borderRadius: '5px', opacity: '40%'}}></img>
                    </Row>
                    <Row className="px-3 pt-2" style={{position: 'relative'}}>
                        <Col>
                            <Row>
                                <div className="main-header text-size-20" style={{color: '#00A6CC'}}><FaFilter fontSize={'15px'} style={{marginBottom: '5px'}}/> Filters</div>
                            </Row>
                            <Row>
                            {widgetDetailsDrawerContents.title === "Sales Void Opportunities" ? (
                                <Row>
                                    <div className="main-header text-size-14">Use the filters below to narrow your search. For best results in this report, select a category first.</div>
                                </Row>
                            ) : (
                                <Row>
                                    <div className="main-header text-size-14">Use the filters below to narrow your search.</div>
                                </Row>
                            )}
                            </Row>
                            <Row className="my-2">
                                {parentElement === "DetailsPage" && 
                                    <Col className="col-3">
                                        <InputGroup className='ps-0' size='md'>
                                            <InputGroupText>
                                                <BsSearch/>
                                            </InputGroupText>
                                            <Input className='text-input' id={`datasuite${parentElement}SearchInput`} placeholder='Search...' onKeyUp={(e) => addSearchKeyword(e)}/> 
                                            <ReactSelect
                                                ref={searchOptionsRef}
                                                captureMenuScroll={false}
                                                id={`datasuite${parentElement}SearchOptions`}
                                                classNamePrefix="custom-select "
                                                filterOption={createFilter({ ignoreAccents: false })} 
                                                hideSelectedOptions={false}
                                                name='searchChoice'
                                                styles={selectStyles} 
                                                options = {searchOptions}
                                                defaultValue={searchOptions[0]}
                                                components={{ ValueContainer, Option: SearchInputOption, DropdownIndicator}}
                                            />
                                        </InputGroup>
                                    </Col>
                                }
                                <Col className="col-2">
                                    <DateRangePicker 
                                        childRef={dateRef}
                                        id={`dataSuite${parentElement}DateSearch`}
                                        defaultStartDate={moment(detailView ? detailsFromDate : fromDate).format('MM/DD/YYYY')} 
                                        defaultEndDate={moment(detailView ? detailsToDate : toDate).format('MM/DD/YYYY')} 
                                        minDate={moment(detailView ? detailsMinDate : minDate).format('MM/DD/YYYY')} 
                                        maxDate={moment().format('MM/DD/YYYY')} 
                                        callback={(start, end) => changeDataSuiteDates(start, end, detailView)}
                                        ranges={
                                        {
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'Last 2 Months': [moment().subtract(2, 'month'), moment()],
                                        'Last Year': [moment().subtract(1, 'year'), moment()]
                                        }
                                        }
                                    />
                                </Col>
                                <Col className="col-2">
                                    <ReactSelect
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        name='dataSet'
                                        styles={selectStyles} 
                                        options = {datasetOptions}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator}}
                                        onChange = {(e) => setSelectedDataSets(e, detailView)}
                                        value = {detailView ? selectedDatasetsDetailsMap : selectedDatasetsMap}
                                        placeholder='Select Data Set'
                                    />
                                </Col>
                                <Col className="col-2">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, Option: InputOption, DropdownIndicator }} 
                                        name='category' 
                                        styles={selectStyles} 
                                        options = {categoriesOptionsMap}
                                        onChange = {(e) => setSelectedCategories(e, detailView)}
                                        value = {detailView ? selectedCategoriesDetailsMap : selectedCategoriesMap}
                                        placeholder='Select Category'
                                    />
                                </Col>
                                {!detailView &&
                                <Col className="col-2">
                                    <ReactSelect 
                                        captureMenuScroll={false}
                                        isMulti
                                        classNamePrefix="custom-select "
                                        filterOption={createFilter({ ignoreAccents: false })} 
                                        hideSelectedOptions={false}
                                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                                        name='operator' 
                                        styles={selectStyles} 
                                        options = {operatorOptionsMap}
                                        onChange = {(e) => setSelectedLocations(e, detailView)}
                                        value = {detailView ? selectedOperatorsDetailsMap : selectedOperatorsMap}
                                        placeholder='Select Operator'
                                    />
                                </Col>
                                }
                                <Col className="w-fc">
                                    <Button 
                                        block 
                                        id="moreFiltersButton"
                                        className='py-2 button-label' 
                                        size='sm' 
                                        style={{width: '120px'}}
                                        color='blueFilter'
                                        onClick={() => clickMoreFilters(parentElement)}
                                    >
                                    {!filtersDrawerOpen && 
                                    <span style={{fontWeight: '600', color: 'white'}}><FaFilter fontSize={'16px'}/> More Filters</span> 
                                    }
                                    {filtersDrawerOpen && 
                                    <span style={{fontWeight: '600', color: 'white'}}><FaTimes fontSize={'18px'} style={{marginBottom: '2px'}}/> Hide Filters</span> 
                                    }
                                    </Button>
                                </Col>
                                {showClearButton && 
                                <Col className="w-fc">
                                    <Button
                                        outline={true}
                                        block
                                        id="clearFiltersButton"
                                        className="py-2 button-label"
                                        size="sm"
                                        style={{ width: "120px" }}
                                        color='blueFilter'
                                        onClick={handleClearFilters}>
                                        <span style={{fontWeight: '600'}}>Clear Filters</span>
                                    </Button>
                                </Col>
                                }
                            </Row>
                            {((selectedDetailsFilters?.includedSearch?.length > 0 || selectedDetailsFilters?.excludedSearch?.length > 0) && parentElement === "DetailsPage") &&
                                <Row style={{width: '99.5%'}}>
                                    <Col className="col-12 mx-2" style={{display: 'flex', border: '1px solid lightgray', padding: '2px', borderRadius: '5px', background: 'white'}}>
                                        {includedSearchTagsMap}
                                        {excludedSearchTagsMap}
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row id={`filters${parentElement}Drawer`} className="mx-0 px-3 w-100" style={{ textWrap:'nowrap', border: '1px lightgrey solid', borderRadius: '0px 0px 5px 5px', background: 'lightgrey', zIndex: '1', position: 'absolute', top: `${topPositions[parentElement]}px`}}>
                <img src={'./FiltersBackground.png'} alt='' className='px-0' style={{position: 'absolute', right: '0px', height: '70px', borderRadius: '0px 0px 5px 5px', opacity: '40%', objectFit: 'cover'}}></img>
                {detailView && 
                <Col className="py-3 col-2">
                    <ReactSelect 
                        captureMenuScroll={false}
                        isMulti
                        classNamePrefix="custom-select "
                        filterOption={createFilter({ ignoreAccents: false })} 
                        hideSelectedOptions={false}
                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                        name='operator' 
                        styles={selectStyles} 
                        options = {operatorOptionsMap}
                        onChange = {(e) => setSelectedLocations(e, detailView)}
                        value = {detailView ? selectedOperatorsDetailsMap : selectedOperatorsMap}
                        placeholder='Select Operator'
                    />
                </Col>
                }
                <Col className="py-3 col-2">
                    <ReactSelect 
                        captureMenuScroll={false}
                        isMulti
                        classNamePrefix="custom-select "
                        filterOption={createFilter({ ignoreAccents: false })} 
                        hideSelectedOptions={false}
                        name='distributor' 
                        styles={selectStyles} 
                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                        options = {distributorOptionsMap}
                        onChange = {(e) => setSelectedDistributors(e, detailView)}
                        value = {detailView ? selectedDistributorsDetailsMap : selectedDistributorsMap}
                        placeholder='Select Distributor'
                    />           
                </Col>
                <Col className="py-3 col-2">
                    <ReactSelect 
                        captureMenuScroll={false}
                        classNamePrefix="custom-select "
                        filterOption={createFilter({ ignoreAccents: false })} 
                        hideSelectedOptions={false}
                        isDisabled={true}
                        name='location' 
                        styles={selectStyles} 
                        components={{ ValueContainer, MenuList: CustomMenuList, Option: InputOption, DropdownIndicator }}
                        placeholder='Select Distribution Center Location'
                    />           
                </Col>
                <Col className="py-3 col-2">
                    <ReactSelect 
                        captureMenuScroll={false}
                        isMulti
                        classNamePrefix="custom-select "
                        filterOption={createFilter({ ignoreAccents: false })} 
                        hideSelectedOptions={false}
                        name='customer' 
                        styles={selectStyles} 
                        components={{ ValueContainer, Option: InputOption, DropdownIndicator }}
                        options = {segmentOptionsMap}
                        onChange = {(e) => setSelectedSegments(e, detailView)}
                        value = {detailView ? selectedSegmentsDetailsMap : selectedSegmentsMap}
                        placeholder='Select Customer Segment'
                    />           
                </Col>
            </Row>
        </Col>
    )   
}

export default FiltersCard;