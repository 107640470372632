import Axios from "axios";

const url = '/merchant';

const merchantApi = {
  get: () => {
    return Axios.get(`${url}`);
  },
  getAll: () => {
    return Axios.get(`${url}/all`);
  },
  available : {
    get: (id) => {
      return Axios.get(`${url}/available/${id}`)
    }
  }
};

export default merchantApi;